import React from 'react'
import defaultAvatar       from '../../../static/images/desktop/annonymous-user-icon.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockOpen }      from '@fortawesome/free-solid-svg-icons'
import { faLock }          from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt }      from '@fortawesome/free-solid-svg-icons'
import ReactTooltip        from 'react-tooltip'
import { Link }            from 'react-router-dom'

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class BloggerItem  extends React.Component {

    blogger = this.props.blogger

    getLockValue = () => {
        //console.log("get blogger: ", this.props.blogger)
        return this.props.blogger.banned ? faLock : faLockOpen
        
    }

    renderActions = () => {
    
        return (
            <div className="ai-actions-container">
                    <FontAwesomeIcon icon={this.getLockValue()} 
                                            onClick={this.props.handleLocking.bind(this, {id: this.props.blogger.id, lock: !this.props.blogger.banned})}
                                            className="edit-icon" 
                                            data-tip={`${this.props.blogger.banned ? 'Unlock' : 'Lock'}`} />
                    <FontAwesomeIcon icon={faTrashAlt} 
                                        className="delete-icon" 
                                        data-tip="Delete" 
                                        onClick={this.props.handleDelete.bind(this, this.props.blogger.id)}/>

            </div>
        )
    }

    renderAvatarUrl = () => (
        this.blogger.avatarUrl 
            ? `${baseBackendUrl}/${this.blogger.avatarUrl}`
            : defaultAvatar
    )

    render() {
        return (
            <li key={this.props.index} className="ai-list-item">
                {/* Avatar Container */}
                <div className="ai-image-container">
                    <img src={this.renderAvatarUrl()} alt='blogger-avatar' />
                </div>
                {/* User Profile Container */}
                <div className="ai-info-container">
                    {/* Name & Title */}
                    <div className="ai-main-info" >
                    <Link to={`/blogger/profile/${this.blogger.id}`} ><span className="ai-u-name" >{`${this.blogger.firstName} ${this.blogger.lastName}, `}</span></Link>
                        <span className="ai-u-title">&nbsp;{ ` ${this.blogger.title || 'Admin'}` }</span>
                    </div>
                    {/* Details */}
                    <div className="ai-details-info">
                        {/* Email */}
                        <div className="tr-details-container">
                            <span className="tr-details-value">{ this.blogger.email || '' }</span>
                        </div>
                        
                        
                    </div>
                </div>
                {/* Actions Container */}
                {this.renderActions()}
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }
    
}

export default BloggerItem