import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Blog from "../../components/Blog/Blog";
import HeaderGuest from "../../components/Header/HeaderGuest";
import Footer from "../../components/Footer/Footer";
import ButtonRoundGradient from "../../components/ButtonRoundGradient/ButtonRoundGradient";
// import Travel from "../../components/Travel/Travel";
import { Helmet } from "react-helmet";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import programSampleImage1 from "../../static/images/desktop/children-preschool-camp.png";
// import programSampleImage2 from "../../static/images/desktop/children-preschool.jpg";
// import programSampleImage3 from "../../static/images/desktop/children-parent-training.jpg";
// import travelSampleImage1 from "../../static/images/desktop/travel-sample-image-1.png";
import becomeAMember from "../../static/images/desktop/become-a-member.png";
import providerProfilesImg from "../../static/images/desktop/provider_profiles_img.png";
// import providerSponsorImg1       from '../../static/images/desktop/CharisHills-Banner.jpg'
// import providerSponsorImg2      from '../../static/images/desktop/CIPLogo.jpg'
// import providerSponsorImg3       from '../../static/images/desktop/CLE1.jpg'
// import providerSponsorImg4       from '../../static/images/desktop/CLESummer.jpg'
// import providerSponsorImg5       from '../../static/images/desktop/FairOaksFarms.jpg'
// import providerSponsorImg6       from '../../static/images/desktop/ForwardMotionLogoN.jpg'
// import providerSponsorImg7       from '../../static/images/desktop/Glenholme.jpg'
// import providerSponsorImg8       from '../../static/images/desktop/MansfieldHall.png'
// import providerSponsorImg9       from '../../static/images/desktop/MaySchoolW.jpg'
// import providerSponsorImg10      from '../../static/images/desktop/ModelMe.gif'
// import Carousel from 'react-bootstrap/Carousel';
import "./Home.scss";

import {
  reqLogPageVisitHome,
  reqGetFeaturedArticle,
  reqGetFeaturedProducts,
  reqGetFeaturedBusinesses,
  reqGetFeaturedPartners,
} from "./actions";

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class Home extends React.Component {
  state = {
    featuredArticle: null,
    featuredProducts: null,
    featuredBusinesses: null,
    featuredPartners: null,
    userId: null,
  };

  listSpecialitiesUrl = "specialities/list";
  logPageVisitUrl = "analitics/page/visit/log";
  featuredArticleUrl = "blog/articles/featured";
  featuredBusinessesUrl = "featured/businesses";
  featuredProductsUrl = "products/featured";
  featuredPartnersUrl = "featured/partners";

  componentDidMount = () => {
    const userDetails = this.props.userDetails;

    if (userDetails.uid) {
      this.setState(() => ({
        userId: userDetails.uid,
      }));
    } else if (userDetails.id) {
      this.setState(() => ({
        userId: userDetails.id,
      }));
    }

    this.logPageVisit();
    this.loadFeaturedArticle();
    this.loadFeaturedProducts();
    this.loadFeaturedBusinesses();
    this.loadFeaturedPartners();
  };

  componentWillReceiveProps = (newProps) => {
    let apiData = newProps.apiData,
      userDetails = newProps.userDetails;

    if (apiData.featuredArticle && apiData.featuredArticle.success) {
      this.setState(() => ({
        featuredArticle: apiData.featuredArticle.article,
      }));
    }

    if (apiData.featuredProducts && apiData.featuredProducts.success) {
      this.setState(() => ({
        featuredProducts: apiData.featuredProducts.products,
      }));
    }

    if (apiData.featuredBusinesses && apiData.featuredBusinesses.success) {
      this.setState(() => ({
        featuredBusinesses: apiData.featuredBusinesses.businesses,
      }));
    }

    if (apiData.featuredPartners && apiData.featuredPartners.success) {
      this.setState(() => ({
        featuredPartners: apiData.featuredPartners.partners,
      }));
    }

    if (userDetails.uid) {
      this.setState(() => ({ userId: userDetails.uid }));
    } else if (userDetails.id) {
      this.setState(() => ({ userId: userDetails.id }));
    }
  };

  logPageVisit = () => {
    const rConfig = {
      credentials: "include",
      mode: "cors",
      body: {
        page: "home",
      },
      resource: this.logPageVisitUrl,
      headers: null,
    };

    this.props.actions.reqLogPageVisitHome(rConfig);
  };

  loadFeaturedArticle = () => {
    this.props.actions.reqGetFeaturedArticle({
      body: {},
      headers: null,
      resource: this.featuredArticleUrl,
    });
  };

  loadFeaturedProducts = () => {
    this.props.actions.reqGetFeaturedProducts({
      body: {},
      headers: null,
      resource: this.featuredProductsUrl,
    });
  };

  loadFeaturedBusinesses = () => {
    this.props.actions.reqGetFeaturedBusinesses({
      body: {},
      headers: null,
      resource: this.featuredBusinessesUrl,
    });
  };

  loadFeaturedPartners = () => {
    this.props.actions.reqGetFeaturedPartners({
      body: {},
      headers: null,
      resource: this.featuredPartnersUrl,
    });
  };

  handleChange = (event) => {
    // this.setState({value: event.target.value});
    let inputName = event.target.name,
      value = event.target.value;

    this.setState(() => {
      return { [inputName]: value };
    });
  };

  renderFeaturedProducts = () => {
    let items = [],
      products = this.state.featuredProducts ? this.state.featuredProducts : [];

    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      items.push(
        <div className="home-product-card" key={`item-sub-art-${i}`}>
          <a href={product.resource_link} target="blank">
            <div className={`product--image-container`}>
              <img
                src={`${baseBackendUrl}/articleContentImage/${product.thumbnail_url}`}
                className="product--image"
                alt=""
                loading="lazy"
              />
            </div>
            <h5 className="product-title">{product.title}</h5>
          </a>
        </div>
      );
    }
    return <ul className="flex-slider--row-favourite">{items}</ul>;
  };

  renderFeaturedBusinesses = () => {
    let items = [],
      businesses = this.state.featuredBusinesses
        ? this.state.featuredBusinesses
        : [];

    for (let i = 0; i < businesses.length; i++) {
      let business = businesses[i];

      items.push(
        <div className="home-business-card" key={`item-sub-art-${i}`}>
          <a href={`/therapist/profile/${business.id}`} target="blank">
            <div className={`business-image-container`}>
              <img
                src={`${baseBackendUrl}/${business.thumbnailUrl}`}
                className="business-image"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="business-name">{business.businessName}</div>
          </a>
        </div>
      );
    }
    return <ul className="flex-slider--row-favourite">{items}</ul>;
  };

  renderPartners = () => {
    let items = [],
      partners = this.state.featuredPartners ? this.state.featuredPartners : [];

    for (let i = 0; i < partners.length; i++) {
      let partner = partners[i];

      items.push(
        <div className="home-business-card" key={`item-sub-art-${i}`}>
          <a href={`/therapist/profile/${partner.id}`} target="blank">
            <div className={`partners-image-container`}>
              <img
                src={`${baseBackendUrl}/${partner.thumbnailUrl}`}
                className="business-image"
                alt=""
                loading="lazy"
              />
            </div>
            <div className="partners-name">{partner.businessName}</div>
          </a>
        </div>
      );
    }
    return <ul className="flex-slider--row-favourite">{items}</ul>;
  };

  renderFeaturedArticle = () => {
    if (!this.state.featuredArticle) return "";

    let art = this.state.featuredArticle;
    let permalink = this.state.featuredArticle.title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[?!]/g, "");

    return (
      <Blog
        image={`${baseBackendUrl}/articleContentImage/${art.thumbnail_url}`}
        date={moment(art.published).format("LL")}
        title={art.title}
        snippet={this.getArticleSnippet(art)}
        url={`/blog/${art.id}/${permalink}`}
        lazyLoad={true}
      />
    );
  };

  getArticleSnippet = (article) =>
    article.body
      .replace(/<figcaption>([^<]+)<\/figcaption>/gi, "")
      .replace(/(<([^>]+)>)/gi, "")
      .replace("&nbsp;", " ")
      .substring(0, 155) + "...";

  render() {
    return (
      <div id="homepage">
        <Helmet>
          <title>
            Find the right Autism Specialists Near Me | Autism Assistant{" "}
          </title>
          <meta
            name="description"
            content='The "best autism specialists near me" are but a few clicks away. Choose their specialty, see what insurances they work with, and how far they are from you.'
          ></meta>
        </Helmet>
        <HeaderGuest menu="home" />
        <section className="container">
          <div className="mb-5">
            <p className="formDescription home">
              Or schedule a free 10 minute 1-on-1 call with Emily
            </p>
            <a
              href="https://calendly.com/autismassistant/one-on-one-w-emily"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <ButtonRoundGradient
                text="Schedule a Free Call"
                otherClass="schedule-call"
              />
            </a>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-5 home-provider-profiles-left">
              <img
                src={providerProfilesImg}
                width="380"
                height="350"
                alt="alt"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 col-md-7 home-provider-profiles-right">
              <h2>Provider Profiles</h2>
              <span className="hp-section-text">
                Search for and review a variety of providers. Find the right fit
                for your child's needs.
              </span>
              <Link to="specialists">
                <button className="btn btn-default">
                  See Provider Profiles
                </button>
              </Link>
            </div>
            <div className="inclusive-text">
              <h3>An Inclusive Environment for Learning & Improving</h3>
              <p>
                Aside from being a tool for you to find the right specialists
                for your needs, Autism Assistant is also dedicated to helping
                you with your child’s integration with those around them. Autism
                awareness has come a long way in the last five to ten years.
                Today, there are special preschool programs, camps, and even
                travel accommodations for people on the spectrum.
              </p>
              <p>
                We aim to list as many of these as possible, as well as partner
                with relevant programs, such as K-12 schools, parent training
                programs, after school programs, and more. Additionally, we
                fully support the employment of people on the spectrum, so our
                members will have the option to post job openings.
              </p>
            </div>
          </div>
        </section>
        <div className="home-second-bg text-center">
          <section className="mt-30 special-section">
            <div className="container mb-50">
              <h2 className="mb-30">Assistance For All</h2>
              <p>
                "Assistance For All" is a pro bono program for families without
                the means to assure therapy on a regular basis. Early
                intervention is key for the age-appropriate development and
                growth of autistic children, but a lot of them keep falling
                through the cracks. Whether you've lost your health insurance,
                can't find a governmental program or are facing financial
                difficulties, we're here to help.
              </p>
              <div className="apply-button-container mt-50 mb-30">
                <a
                  className="btn btn-default apply-button"
                  href="/autism-assistance-for-all"
                >
                  Apply For Pro Bono Therapy
                </a>
              </div>
            </div>

            <h2 className="hp-section-title">Featured businesses</h2>
            <div className="business-flex-slider">
              {this.renderFeaturedBusinesses()}
            </div>
          </section>
          <section className="favorite-section">
            <h2 className="hp-section-title">Our Recommended Products</h2>
            <div className="disclaimer-text-affil-prod">
              Autism Assistant may earn a small commission for the endorsement,
              recommendation or link to any product from this website
            </div>
            <div className="flex-slider">
              {this.state.featuredProducts &&
              this.state.featuredProducts.length ? (
                <div>{this.renderFeaturedProducts()}</div>
              ) : (
                <div className="flex-slider--row-favourite">
                  <div className="flex-slider--item">
                    <iframe
                      title="prod-1"
                      className="iframe"
                      marginWidth="0"
                      marginHeight="0"
                      scrolling="no"
                      frameBorder="0"
                      src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=autismassistant-20&marketplace=amazon&region=US&placement=B0765NNXK2&asins=B0765NNXK2&linkId=da826df8ff848305d678263388865c0c&show_border=true&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
                    ></iframe>
                  </div>
                  <div className="flex-slider--item">
                    <iframe
                      title="prod-1"
                      style={{ width: "120px", height: "140px" }}
                      marginWidth="0"
                      marginHeight="0"
                      scrolling="no"
                      frameBorder="0"
                      src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=autismassistant-20&marketplace=amazon&region=US&placement=B01MQJN7OR&asins=B01MQJN7OR&linkId=db9f96040f5a81900e611deb1c5c23ef&show_border=true&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
                    ></iframe>
                  </div>
                  <div className="flex-slider--item">
                    <iframe
                      title="prod-1"
                      style={{ width: "120px", height: "140px" }}
                      marginWidth="0"
                      marginHeight="0"
                      scrolling="no"
                      frameBorder="0"
                      src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=autismassistant-20&marketplace=amazon&region=US&placement=B01MG6NC6W&asins=B01MG6NC6W&linkId=798ae609c621457936711f01488f4e65&show_border=true&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
                    ></iframe>
                  </div>
                </div>
              )}

              <div className="productsButton mt-120">
                <a href="/products">
                  <ButtonRoundGradient text="See More Autism Friendly Products" />
                </a>
              </div>
            </div>
          </section>

          <section className="container mt-120">
            <h2 className="text-center">Read Our Blog</h2>
            <div className="blog-listing--container">
              {this.renderFeaturedArticle()}
            </div>
            <div className="blog-button text-center">
              <Link to="/blog">
                <ButtonRoundGradient text="See More Articles" />
              </Link>
            </div>
          </section>

          {/* <section className="mt-120 travel-section">
            <div className="container">
              <h2 className="hp-section-title">Travel</h2>
              <h5 className="hp-section-subtitle">COMING SOON</h5>
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <p className="text-d travel-text">
                    We know that traveling with children on the spectrum can be
                    difficult. Fortunately, many businesses are now trying to
                    remove some of the stress and make family travel more
                    enjoyable by educating employees and aligning themselves as
                    autism allies.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-slider">
              <div className="flex-slider--row">
                <Travel
                  className="flex-slider--item"
                  image={travelSampleImage1}
                  url="/"
                  tag=""
                  buttonText=""
                  title="Featured Hotel"
                />
                <Travel
                  className="flex-slider--item"
                  image={travelSampleImage1}
                  url="/"
                  tag=""
                  buttonText=""
                  title="Featured Restaurant"
                />
                <Travel
                  className="flex-slider--item"
                  image={travelSampleImage1}
                  url="/"
                  tag=""
                  buttonText=""
                  title="Featured Resort"
                />
              </div>
            </div>
          </section> */}
        </div>

        {/*<section className="container mt-120">*/}

        <section className="mt-80 special-section">
          <div className="container text-center">
            <h2 className="partners-title">"Assistance for All" Partners</h2>
            <div className="business-flex-slider">{this.renderPartners()}</div>
          </div>
        </section>

        {!this.state.userId ? (
          <section className="mt-50 become-member">
            <img src={becomeAMember} alt="alt" loading="lazy"/>
            <div className="become-member--box">
              <h2>Become a Member</h2>
              <p>
                Are you a provider, business, or blogger, and would like to be
                featured on our site?
              </p>
              <Link to="/signup">
                <button className="btn btn-default become-member--btn">
                  Join Now
                </button>
              </Link>
            </div>
          </section>
        ) : (
          ""
        )}

        <Footer page="home" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.homeReducer,
  userDetails: state.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqLogPageVisitHome: bindActionCreators(reqLogPageVisitHome, dispatch),
      reqGetFeaturedArticle: bindActionCreators(
        reqGetFeaturedArticle,
        dispatch
      ),
      reqGetFeaturedProducts: bindActionCreators(
        reqGetFeaturedProducts,
        dispatch
      ),
      reqGetFeaturedBusinesses: bindActionCreators(
        reqGetFeaturedBusinesses,
        dispatch
      ),
      reqGetFeaturedPartners: bindActionCreators(
        reqGetFeaturedPartners,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
// export default Home
// reqGetFeaturedArticle
