import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_CREATE_ADMIN, REQ_GET_ALL_SPECIALITIES, recCreateAdmin, recGetAllSpecialities } from './actions'

import fetchData from '../../../utils/api'


function* createAdmin(action) {
  
  try {
    //console.log("saga called")
    const data = yield call(fetchData, action.data, action.resource, action.headers)
    yield put(recCreateAdmin(data))
   
  } catch (e) {
      //console.log("Api error: ", e)
   }

}

function* getAllSpecialities(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetAllSpecialities(data))
   
  } catch (e) {console.log("Api error: ", e)}
}


export default function* createAdminSaga() {
    yield takeLatest(REQ_CREATE_ADMIN, createAdmin);
    yield takeLatest(REQ_GET_ALL_SPECIALITIES, getAllSpecialities);
};