import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import loginIcon from '../../static/images/desktop/login_icon.png'
import headerLogoWhite from '../../static/images/desktop/header_logo_white.png'
import headerBackground from '../../static/images/desktop/header-background_missized.png'
import './HeaderGuestSimple.scss'
import ProfileIcon from "../../components/HeaderUser/ProfileIcon";
import { connect } from 'react-redux'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import { Link } from 'react-router-dom';


class HeaderGuestSimple extends React.Component {

    state = {
        menuVisible: false,
        profileIconMenu: false,
        showSubMenu: false,
        userId: null,
        menu: ''
    }

    componentDidMount = () => {
        const userDetails = this.props.userDetails

        if (userDetails.uid) {
            this.setState(() => ({
                userId: userDetails.uid
            }))
        } else if (userDetails.id) {
            this.setState(() => ({
                userId: userDetails.id
            }))
        }   
    }

    componentWillReceiveProps = (newProps) => {
        const userDetails = newProps.userDetails

        if (userDetails) {
            this.setState(() => ({
                userId: userDetails.uid
            }))
        } else {
            this.setState(() => ({
                userId: userDetails.id
            }))
        }
        this.setState(() => ({
            menu: newProps.menu
        }))
    }

    displaySubMenu = () => {
        this.setState( () => ({showSubMenu: true}))
    }

    hideSubMenu = () => {
        this.setState( () => ({showSubMenu: false}))
    }

    displayOptions = () => {
        this.setState( () => ({profileIconMenu: true}))
    }

    hideOptions = () => {
        this.setState( () => ({profileIconMenu: false}))
    }
    
    closeMobileMenu = () => {
        this.setState(() => ({
            menuVisible: false
        }));
    }

    toggle = () => {
        this.setState(() => ({
            menuVisible: !this.state.menuVisible
        }));
    }

    render() {
        const { menuVisible, profileIconMenu, showSubMenu } = this.state
        return (
            <div>
                <header className="header">
                    <link rel="preload" as="image" href={headerBackground} />
                    <img className="bg-image" src={headerBackground} alt="bigImage" width="1847" height="1200" loading="lazy"/>
                 <div className="container">
                <div className="d-flex align-items-center flex-wrap">
                    <Link to="/" className="logo" ><img src={headerLogoWhite} alt="hlgWhite" width="300" height="50" /></Link>
                <ul className={"menu" + (menuVisible === true ? ' visible' : '')}>
                    <li className="close-mobile-menu" onClick={this.closeMobileMenu}>X Close</li>
                    <li><Link to="/">Home</Link></li>  
                    <li><Link to="/about-us">About</Link></li>
                    <li className="sub-menu-link" onMouseEnter={this.displaySubMenu} onMouseLeave={this.hideSubMenu}>
                        <div className="sub-menu-name">Resources <FontAwesomeIcon icon={faAngleDown} /></div>
                        <ul className={`sub-menu ${showSubMenu ? "show-sub-menu" : "" }`}>
                            <li className="sub-menu-item"><Link to="/autism-assistance-for-all">Assistance For All Program</Link></li>
                            <li className="sub-menu-item"><Link to="/autism-therapy-types">Types of Autism Therapies</Link></li>
                            <li className="sub-menu-item"><Link to="/online-therapies">Telemedicine Options</Link></li>
                            <li className="sub-menu-item"><Link to="/products">Autism Friendly Products</Link></li>
                            <li className="sub-menu-item"><Link to="/faq">FAQ</Link></li>
                        </ul>
                    </li>
                    <li><a  href="/specialists">Specialists</a></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    {this.state.userId && (
                    <li className="greeting" onMouseEnter={this.displayOptions} onMouseLeave={this.hideOptions}>
                        <ProfileIcon displayOptions={profileIconMenu}/>
                    </li>)}
                    {!this.state.userId && (
                    <li>
                        <Link to="/signin">
                            <img src={loginIcon} width="22" height="22" alt="alt" loading="lazy"/> Login
                        </Link>
                    </li>)}
                </ul>
            </div>
            <DrawerToggleButton className="burger-menu" click={this.toggle}/>
        </div>
    </header>
    </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
  })

export default connect(mapStateToProps)(HeaderGuestSimple)
