export const REQ_SIGNUP_THERAPIST = 'REQ_SIGNUP_THERAPIST';
export const REC_SIGNUP_THERAPIST = 'REC_SIGNUP_THERAPIS';

export const reqSignupTherapist = (data, resource) => {

    return {
        type: REQ_SIGNUP_THERAPIST,
        data,
        resource
    }
};

export const recSignupTherapist = data => ({type: REC_SIGNUP_THERAPIST, data})


