import React from 'react'

import './Radio.scss'

const Radio = props => (
    <label 
        id={props.id}
        data-name={props.name}
        data-checked={props.isChecked}
        data-value={props.dataValue}
        className={"custom-radio " + props.className}
        onClick={props.click}>
        {props.text}
    </label>
);

export default Radio