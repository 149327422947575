import React from "react";
import HeaderUser from "../../../components/HeaderUser/HeaderUser";
import SidebarBlogger from "../../../components/SidebarBlogger/SidebarBlogger";
import "./ProfileBlogger.scss";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ButtonRoundGradient from "../../../components/ButtonRoundGradient/ButtonRoundGradient";
import {
    reqUpdateTherapistProfile,
    reqUpdateTherapistPassword
} from "./actions";
import { reqFetchUserDetails } from "../../../utils/FetchUser/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainWrapper from "../MainWrapper/MainWrapper";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import { Popup } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import uploadImage from "../../../utils/uploadImage";
import { Link } from 'react-router-dom'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class TherapistProfile extends React.Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        website: "",
        bio: "",
        title: "",
        phone: "",
        errors: [],
        pageLoading: false,
        activeTabItem: "account",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        profileImageName: "",
        percent: 20,
        check: true,
        content:"",
    };

    therapist = {};
    autheHeaders = {};
    submitUrl = "therapist/account/update";
    uploadProfileImgUrl = 'user/profile/update/image'
    updatePasswordUrl = "user/profile/updatePassword";

    componentDidMount = () => {
        this.therapist = this.props.userDetails;
        this.autheHeaders = {
            Authorization: `Bearer ${this.therapist.token}`,
            "Content-type": "application/json"
        };

        const newState = {
            email: this.therapist.email || "",
            firstName: this.therapist.firstName || "",
            lastName: this.therapist.lastName || "",
            website: this.therapist.website || "",
            bio: this.therapist.bio || "",
            phone: this.therapist.phone || "",
            title: this.therapist.title || "",
            profileImageName: this.therapist.profileImageName
        };
        this.setState(() => newState);
    };


    componentWillReceiveProps = newProps => {
        //console.log("newProps: ", newProps);
        let apiData = newProps.apiData;
        if (apiData.profileUpdated && apiData.profileUpdated.success) {
            this.setState(() => ({
                pageLoading: false
            }));
        } else if (
            apiData.passwordUpdated &&
            apiData.passwordUpdated.error &&
            apiData.passwordUpdated.display
        ) {
            this.setState(() => ({
                errors: [{ input: "currentPassword" }],
                errorMessage: apiData.passwordUpdated.error,
                pageLoading: false
            }));
        } else if (apiData.passwordUpdated && apiData.passwordUpdated.success) {
            this.setState(() => ({
                pageLoading: false,
                succesMessage: "Password Succesfully Updated"
            }));
        }
    };

    handleChange = event => {
        let inputName = event.target.name,
            value = event.target.value;

        this.setState(() => ({
            [inputName]: value
        }));
    };

    inputHasErrors = inputName => {
        const errors = this.state.errors.filter(err => err.input === inputName);
        return errors.length ? "input-error" : "";
    };

    handleSubmit = () => {

        this.setState(
            {
                errors: [],
                errorMessage: "",
                pageLoading: true,
            },
            () => {
                if (!this.validateForm()) return;

                if (!this.state.profileImgFile) {
                    this.updateProfileData()
                    return
                }

                this.uploadProfilePhoto().then( res => {
                    let { url: fileName } = res.data
                    this.updateProfileData(fileName)
                }).catch((error) => console.log(error))

            }
        );
    };

    updateProfileData = (profileImageName = null) => {
        const rConfig = {
            body: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                website: this.state.website,
                phone: this.state.phone,
                title: this.state.title,
                bio: this.state.bio,
                profileImageName
            },
            headers: this.autheHeaders,
            resource: this.submitUrl,
            userDetails: this.props.userDetails
        };

        this.props.actions.reqUpdateTherapistProfile(rConfig);
    }

    handleUpdatePassword = () => {
        // console.log("Updating Password");
        this.setState(
            {
                errors: [],
                errorMessage: "",
                pageLoading: true
            },
            () => {
                if (!this.validatePasswordForm()) return;

                this.props.actions.reqUpdateTherapistPassword({
                    body: {
                        oldPassword: this.state.currentPassword,
                        newPassword: this.state.newPassword
                    },
                    headers: this.autheHeaders,
                    resource: this.updatePasswordUrl
                });
            }
        );
    };

    validateForm = () => {
        let formValid = true;

        if (this.state.email === "") {
            formValid = false;
            this.setState(() => ({
                errors: [{ input: "email" }],
                errorMessage: "Email is mandatory",
                pageLoading: false
            }));
        } else if (this.state.firstName === "") {
            formValid = false;
            this.setState(() => ({
                errors: [{ input: "firstName" }],
                errorMessage: "First Name is mandatory",
                pageLoading: false
            }));
        } else if (this.state.lastName === "") {
            formValid = false;
            this.setState(() => ({
                errors: [{ input: "lastName" }],
                errorMessage: "Last Name is mandatory",
                content:"Please fill out all mandatory fields. These are marked with a star (*).",
                pageLoading: false
            }));
        }
        return formValid;
    };

    validatePasswordForm = () => {
        let formValid = true;

        if (this.state.currentPassword === "") {
            formValid = false;
            this.setState(() => ({
                errors: [{ input: "currentPassword" }],
                errorMessage: "Current Password is mandatory",
                pageLoading: false
            }));
        } else if (this.state.newPassword === "") {
            formValid = false;
            this.setState(() => ({
                errors: [{ input: "newPassword" }],
                errorMessage: "New Password is mandatory",
                pageLoading: false
            }));
        } else if (this.state.confirmPassword === "") {
            formValid = false;
            this.setState(() => ({
                errors: [{ input: "confirmPassword" }],
                errorMessage: "Confirm Password is mandatory",
                pageLoading: false
            }));
        } else if (this.state.newPassword !== this.state.confirmPassword) {
            formValid = false;
            this.setState(() => ({
                errors: [{ input: "newPassword" }, { input: "confirmPassword" }],
                errorMessage: "New Password and Confirm Password do not match",
                pageLoading: false
            }));
        }

        return formValid;
    };
    handleChangeWeb = event => {
        let inputName = event.target.name,
            value = event.target.value;
        if (value.indexOf('http://') > -1)
        {
            this.setState(() => ({
                check: true,
            }));
        }
        else if (value.indexOf('https://') > -1)
        {
            this.setState(() => ({
                check: true,
            }));
        }
        else {
            this.setState(() => ({
                check: false,
            }));
        }
        this.setState(() => ({
            [inputName]: value,
        }));
    };
    handleChooseProfileImg = ev => {
        let imageFile = ev.target.files[0];
        let previewFile = URL.createObjectURL(imageFile);

        this.setState(() => ({
            profileImgFile: imageFile,
            profileImgPreview: previewFile
        }));
    };

    uploadProfilePhoto = () => {

        let profileImg = this.state.profileImgFile,
            data = new FormData();

        data.append('profileImage', profileImg)
        let authConfig = {
            headers: {
                Authorization: `Bearer ${this.therapist.token}`
            }
        }

        return uploadImage(this.uploadProfileImgUrl, data, authConfig)
    }

    renderAvatar = () => {
        let imageContent = (
            <div className="profile-img-sub-container">
                <div className="add-photo-btn"> + </div>
                <div className="add-photo-text-container">
                    <span className="white-text"> Add Photo </span>
                </div>
            </div>
        );

        if (this.state.profileImgPreview) {
            imageContent = (
                <div className="avatar-sub-container">
                    <img src={this.state.profileImgPreview} alt="profileImg" />
                    <div className="avatar-cover"> CHANGE </div>
                </div>
            );
        } else if (this.state.profileImageName) {
            imageContent = (
                <div className="avatar-sub-container">
                    <img
                        src={`${baseBackendUrl}/${this.state.profileImageName}`}
                        alt="profileImg"
                    />
                    <div className="avatar-cover"> CHANGE </div>
                </div>
            );
        }

        return (
            <div
                className="avatar-container"
                onClick={e => this.selectProfilePhoto.click()}
            >
                {imageContent}
            </div>
        );
    };

    tabItemActive = tabName =>
        tabName === this.state.activeTabItem ? "active-tab" : "inactive-tab";

    switchTab = tabName => {
        this.setState(() => ({
            activeTabItem: tabName
        }));
    };

    renderTabContent = () =>
        this.state.activeTabItem === "account"
            ? this.renderAccountTab()
            : this.renderPasswordTab();

    renderAccountTab = () => (
        <div className="second-row">
            {/* Profile Form */}
            <div className="leftside-content">
                {/* Avatar */} {this.renderAvatar()} {/* Hidden upload input */}
                <input
                    id="profilePhotoInput"
                    type="file"
                    ref={ref => (this.selectProfilePhoto = ref)}
                    style={{ display: "none" }}
                    onChange={this.handleChooseProfileImg}
                />
            </div>
            <div className="rightside-content">
                <div className="form-container">
                    <form>
                        <div className="input-row">
                            First Name *
                            <input
                                type="text"
                                name="firstName"
                                className={`basic-input ${this.inputHasErrors("firstName")}`}
                                placeholder="First Name"
                                value={this.state.firstName}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="input-row">
                            Last Name *
                            <input
                                type="text"
                                name="lastName"
                                className={`basic-input ${this.inputHasErrors("lastName")}`}
                                placeholder="Last Name"
                                value={this.state.lastName}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="input-row">
                            Email *
                            <input
                                type="text"
                                name="email"
                                className={`basic-input ${this.inputHasErrors("email")}`}
                                placeholder="example@domain.com"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                        </div>
                        {/* Website */}
                        <div className="input-row">
                            Website
                            {/* <FontAwesomeIcon icon={faPager} className="input-icon" /> */}
                            <input
                                type="text"
                                name="website"
                                className={`basic-input`}
                                placeholder="https://example.com"
                                value={this.state.website}
                                onChange={this.handleChangeWeb}
                            />
                            {this.state.check!==true && (
                                <p className="checkStyle">Please enter the whole URL of your website, including the "https://" or "http://" part.</p>)}
                        </div>
                        {/* Phone */}
                        <div className="input-row">
                            Phone
                            {/* <FontAwesomeIcon icon={faPhone} className="input-icon" /> */}
                            <input
                                type="text"
                                name="phone"
                                className={`basic-input ${this.inputHasErrors("phone")}`}
                                placeholder="(123) 456-7890"
                                value={this.state.phone}
                                onChange={this.handleChange}
                            />
                        </div>
                        {/* Title */}
                        <div className="input-row">
                            Title
                            {/* <FontAwesomeIcon icon={faCertificate} className="input-icon" /> */}
                            <input
                                type="text"
                                name="title"
                                className={`basic-input`}
                                placeholder="Blogger"
                                value={this.state.title}
                                onChange={this.handleChange}
                            />
                        </div>
                        {/* Bio */}
                        <div className="input-row textarea-row">
                            <div> Bio </div>
                            {/* <FontAwesomeIcon icon={faAddressCard} className="input-icon" /> */}
                            <textarea
                                name="bio"
                                className={`basic-input`}
                                placeholder="Bio"
                                value={this.state.bio}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div>
                            <p className="error-message"> {this.state.errorMessage} </p>
                        </div>
                        <div className="info-text">
                            <p><FontAwesomeIcon icon={faInfoCircle} /> You can navigate your profile's sections using the menu on the left, or with the button below.
                            </p>
                        </div>

                        <div className="submit-row">
                            <Popup
                                content={this.state.content}
                                on="click"
                                pinned
                                trigger={
                                    <ButtonRoundGradient
                                        text="Save Changes"
                                        otherClass="submit-btn-medium"
                                        onClick={this.handleSubmit}
                                    />
                                }
                            />
                            <Link to="/blogger-articles">Write An Article <FontAwesomeIcon icon={faAngleRight} /></Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

    renderPasswordTab = () => (
        <div className="password-tab">
            <div className="password-form-container">
                {/* Current Password */}
                <div className="input-row">
                    <input
                        type="password"
                        name="currentPassword"
                        className={`basic-input ${this.inputHasErrors("currentPassword")}`}
                        placeholder="Current Password"
                        value={this.state.currentPassword}
                        onChange={this.handleChange}
                    />
                </div>
                {/* New Password */}
                <div className="input-row">
                    <input
                        type="password"
                        name="newPassword"
                        className={`basic-input ${this.inputHasErrors("newPassword")}`}
                        placeholder="New Password"
                        value={this.state.newPassword}
                        onChange={this.handleChange}
                    />
                </div>
                {/* Confirm Password */}
                <div className="input-row">
                    <input
                        type="password"
                        name="confirmPassword"
                        className={`basic-input ${this.inputHasErrors("confirmPassword")}`}
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <p className="error-message"> {this.state.errorMessage} </p>
                    <p className="success-message"> {this.state.succesMessage} </p>
                </div>
                <div className="submit-row">
                    <ButtonRoundGradient
                        text="Update Password"
                        otherClass="submit-btn-medium"
                        onClick={this.handleUpdatePassword}
                    />
                </div>
            </div>
        </div>
    );

    render() {
        const therapist = this.props.userDetails;
        let progress = 20;
        let message="";
        if (therapist.phone) {progress += 20;}
        else {message="Add a Phone Number!"};
        if (therapist.bio) {progress += 20;}
        else{message="Add Your BIO!"};
        if (therapist.profileImg) {progress += 20;}
        else{message="Add a Profile Image!"}
        if (therapist.website) {progress += 20;}
        else{message="Add Your Website!"}

        if (this.state.email === "") {this.state.content= "Please fill out all mandatory fields. These are marked with a star (*)."}
        else if (this.state.check===false) {this.state.content= "Please modify your website's link in order for it to work properly, and then click Save Changes again"}

        else {this.state.content= "The changes to your account information have been saved."};

        return (
            <MainWrapper page="profile">
                <PopupLoader display={this.state.pageLoading} />
                <div id="therapist-profile">
                    <HeaderUser />
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarBlogger page="account" roleId={this.therapist.roleId} />
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row profile-tabs">
                                        {/* Title */}
                                        <h1
                                            className={`page-title tab-item ${this.tabItemActive(
                                                "account"
                                            )}`}
                                            onClick={this.switchTab.bind(this, "account")}
                                        >
                                            Account Information
                                        </h1>
                                        <h1 className="page-title tabs-bar"> | </h1>
                                        <h1
                                            className={`page-title tab-item ${this.tabItemActive(
                                                "password"
                                            )}`}
                                            onClick={this.switchTab.bind(this, "password")}
                                        >
                                            Change Password
                                        </h1>
                                    </div>
                                    <div className="percent">
                                        <p>Your profile is {progress}% complete:</p>
                                        <Progress percent={progress}/>
                                        <p className="message">{message}</p>
                                    </div>
                                    {/* <GooglePlacesAutocomplete
                                                                        onSelect={this.findCoordinates}
                                                                    /> */}
                                    {/* Leftside Content */} {this.renderTabContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainWrapper>
        );
    }
}

const mapStateToProps = state => ({
    apiData: state.therapistUpdateProfileReducer,
    userDetails: state.userDetails
});

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            reqUpdateTherapistProfile: bindActionCreators(
                reqUpdateTherapistProfile,
                dispatch
            ),
            reqFetchUserDetails: bindActionCreators(reqFetchUserDetails, dispatch),
            reqUpdateTherapistPassword: bindActionCreators(
                reqUpdateTherapistPassword,
                dispatch
            )
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapistProfile);
