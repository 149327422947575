import {
  REC_SAVE_STRIPE_CUSTOMER,
  REC_FETCH_STRIPE_CUSTOMER,
  REC_REMOVE_USER_CARD,
  REC_LIST_MEMBERSHIP_PLANS,
  REC_SUBSCRIBE_USER_TO_PLAN,
  REC_CUSTOMER_SWITCH_PLAN,
  REC_CANCEL_CUSTOMER_SUBSCRIPTION,
  REC_BUY_EXTRA_BENEFITS,
} from "./actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case REC_SAVE_STRIPE_CUSTOMER:
      return {
        saveStripeCustomer: data,
      };

    case REC_FETCH_STRIPE_CUSTOMER:
      return {
        fetchStripeCustomer: data,
      };

    case REC_REMOVE_USER_CARD:
      return {
        cardRemoved: data,
      };

    case REC_LIST_MEMBERSHIP_PLANS:
      return {
        membershipPlans: data,
      };

    case REC_SUBSCRIBE_USER_TO_PLAN:
      return {
        subscribeToPlan: data,
      };

    case REC_CUSTOMER_SWITCH_PLAN:
      return {
        switchPlan: data,
      };

    case REC_CANCEL_CUSTOMER_SUBSCRIPTION:
      return {
        cancelPlan: data,
      };

    case REC_BUY_EXTRA_BENEFITS:
      return {
        buyExtraBenefits: data,
      };

    default:
      return state;
  }
};
