import React from "react";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { reqGetArticles, reqSearchArticles } from "../Blog/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import searchImage from "../../static/images/desktop/search_icon_blue.png";
import PopupLoader from "../../components/PopupLoader/PopupLoader";
import Pagination from "../../components/Blog/Pagination";
import ProductCard from "../../components/Product/ProductCard";
import "./Products.scss";

require("dotenv").config();
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class Products extends React.Component {
  state = {
    pageLoading: false,
    products: [],
    currentPage: 1,
    searchInput: "",
  };

  productsPerPage = 9;
  getProductsUrl = "blog/articles/all";
  searchProductsUrl = "blog/articles/search";

  componentDidMount = () => {
    this.authHeader = {
      "Content-type": "application/json",
    };
    this.setState({}, () => {
      this.loadProducts();
    });
  };

  componentWillReceiveProps = (newProps) => {
    const apiData = newProps.apiData;

    // console.log("blog main props: ", newProps);

    if (apiData.articles && apiData.articles.success) {
      let products = apiData.articles.articles.filter(article => article.type === "product")

      this.setState(() => ({
        pageLoading: false,
        products: products,
      }));
    }

    if (apiData.articleSearch && apiData.articleSearch.success) {
      let products = apiData.articleSearch.articles.filter(article => article.type === "product")
      
      this.setState(() => ({
        pageLoading: false,
        products: products,
      }));
    }
  };

  loadProducts = () => {
    const rConfig = {
      body: {},
      resource: `${this.getProductsUrl}`,
      headers: null,
    };
    this.props.actions.reqGetArticles(rConfig);
  };

  getProducts = () => {
    this.setState({ pageLoading: true }, () => {
      this.loadProducts();
    });
  };

  searchProducts = () => {
    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqSearchArticles({
          body: {},
          headers: null,
          resource: `${this.searchProductsUrl}?searchTerm=${this.state.searchInput}`,
        });
      }
    );
  };

  searchSubmit = () => {
    if (this.state.searchInput !== "") {
      this.searchProducts();
      this.changePage(1);
    } else {
      this.getProducts();
    }
  };

  filterSubmit = () => {};

  searchInputChange = (ev) => {
    let val = ev.target.value;

    this.setState(() => ({
      searchInput: val,
    }));
  };

  listProducts = () => {
    const indexOfLastProduct = this.state.currentPage * this.productsPerPage,
      indexOfFirstProduct = indexOfLastProduct - this.productsPerPage,
      currentProducts = this.state.products.slice(
        indexOfFirstProduct,
        indexOfLastProduct
      );

    if (
      typeof this.state.products === "undefined" ||
      !this.state.products.length
    ) {
      return (
        <div className="no-articles-result">
          <div className="no-articles-res-text">
            There are currently no products for this search.
          </div>
        </div>
      );
    }
    let items = [],
      subItems = [],
      products = currentProducts;

    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      let publishedDate = new Date(product.published);

      subItems.push(
        <div className="col-lg-4 col-sm-6 mb-60" key={`item-sub-art-${i}`}>
          <a href={product.resource_link} target="blank">
            <ProductCard
              className="travel-card"
              image={`${baseBackendUrl}/articleContentImage/${product.thumbnail_url}`}
              url={product.resource_link}
              tag={this.formatDate(publishedDate)}
              description={product.description}
              price={product.price}
              buttonText="Buy"
              title={product.title}
              lazyLoad={true}
            />
          </a>
        </div>
      );
    }

    items.push(
      <div className="row" key={`uk`}>
        {subItems}
      </div>
    );

    if (!items.length) {
      return (
        <div className="message-row">
          <p>You have no products listed.</p>
        </div>
      );
    }

    return <ul className="articles-list">{items}</ul>;
  };

  changePage = (pageNumber) => {
    if (pageNumber === "prev") {
      this.setState(
        {
          currentPage: this.state.currentPage - 1,
        },
        () => {
          this.props.history.push({
            pathname: `/products/page-${this.state.currentPage}`,
          });
        }
      );
    }
    if (pageNumber === "next") {
      this.setState(
        {
          currentPage: this.state.currentPage + 1,
        },
        () => {
          this.props.history.push({
            pathname: `/products/page-${this.state.currentPage}`,
          });
        }
      );
    }
    if (typeof pageNumber === "number") {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.props.history.push({
            pathname: `/products/page-${this.state.currentPage}`,
          });
        }
      );
    }

    window.scrollTo(0, 600);
  };

  formatDate(date) {
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      month[date.getMonth()] +
      " " +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      ", " +
      date.getFullYear()
    );
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>
            Information About Autism & More | Autism Assistant Products
          </title>
          <meta name="description" content=""></meta>
        </Helmet>
        <PopupLoader display={this.state.pageLoading} />
        <HeaderGuestSimple menu="products" />
        <div id="productsPage" className="container">
          <h2 className="page-title">Autism Friendly Products</h2>
          <div className="row our-story mb-50 blog-text-ontop">
            <div className="text-section">
              <p>
                With autism awareness being on the rise for the last 5 to 10
                years, companies have started developing various products which
                are a great fit for autistic children. Below, you'll find a
                selection of items which we've either tested ourselves, or that
                we consider to be a great addition to any household with an
                autistic child.
              </p>
              <p>
                Not only can they provide hours of great fun, but they can also
                be amazing for sensory stimulation or helping your child with
                different difficulties and challenges.
              </p>
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-11">
              <div className="article-search--container">
                <div className="article-search--input-background">
                  <input
                    onChange={this.searchInputChange}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.searchSubmit();
                      }
                    }}
                    placeholder="Search Products"
                  />
                  <img
                    src={searchImage}
                    className="search-icon"
                    onClick={this.searchSubmit}
                    alt="img"
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-md-1 col-1 d-flex">
							<img src={filterImage} className="filter-icon" onClick={this.filterSubmit} alt="img"/>
						</div> */}
          </div>
          <div className="blog-listing-container">{this.listProducts()}</div>
          <Pagination
            articlesPerPage={this.productsPerPage}
            totalArticles={this.state.products.length}
            paginate={this.changePage}
            currentPage={this.state.currentPage}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.mainBlogReducer,
  userDetails: state.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetArticles: bindActionCreators(reqGetArticles, dispatch),
      reqSearchArticles: bindActionCreators(reqSearchArticles, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
