import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_LIST_THERAPISTS_ADMIN, REQ_LIST_ALL_SPEC_ADMIN_SEARCH, REQ_SEARCH_THERAPISTS_ADMIN,
         REQ_THERAPISTS_LOCK_UNLOCK, REQ_THERAPISTS_DELETE, REQ_THERAPISTS_TOGGLE_BETA_STATUS, 
         REQ_THERAPISTS_TOGGLE_PUBLISH_STATUS, REQ_SEARCH_POSITION,
         recListTherapists, recListSpecialities, recSearchTherapists, recTherapistLockUnlock,
         recDeleteTherapist, recTherapistToggleBetaStatus, recTherapistTogglePublishStatus, recSearchPosition} from './actions'
import fetchData from '../../../utils/api'




function* listTherapists(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListTherapists(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* listSpecialities(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListSpecialities(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* searchTherapists(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recSearchTherapists(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* lockUnLockTherapist(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recTherapistLockUnlock(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* deleteTherapist(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recDeleteTherapist(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* toggleTherapistBetaStatus(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recTherapistToggleBetaStatus(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* toggleTherapistPublishStatus(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recTherapistTogglePublishStatus(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* searchPosition(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recSearchPosition(data))

  } catch (e) {console.log("Api error: ", e)}

}

export default function* manageTherapistsSaga() {
    yield takeLatest(REQ_LIST_THERAPISTS_ADMIN, listTherapists);
    yield takeLatest(REQ_LIST_ALL_SPEC_ADMIN_SEARCH, listSpecialities);
    yield takeLatest(REQ_SEARCH_THERAPISTS_ADMIN, searchTherapists);
    yield takeLatest(REQ_THERAPISTS_LOCK_UNLOCK, lockUnLockTherapist);
    yield takeLatest(REQ_THERAPISTS_DELETE, deleteTherapist);
    yield takeLatest(REQ_THERAPISTS_TOGGLE_BETA_STATUS, toggleTherapistBetaStatus);
    yield takeLatest(REQ_THERAPISTS_TOGGLE_PUBLISH_STATUS, toggleTherapistPublishStatus);
    yield takeLatest(REQ_SEARCH_POSITION, searchPosition);
};
