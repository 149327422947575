import { REC_GET_THERAPISTS_TOP_PROFILES, REC_GET_THERAPISTS_BY_NAME_GUEST} from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_THERAPISTS_TOP_PROFILES:
            return {
                topProfiles: data
            };

        case REC_GET_THERAPISTS_BY_NAME_GUEST:
            return {
                therapistsByName: data
            }

        default:
            return state
    }
}