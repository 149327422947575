export const REQ_CREATE_ADMIN = 'REQ_CREATE_ADMIN'
export const REC_CREATE_ADMIN = 'REC_CREATE_ADMIN'
export const REQ_GET_ALL_SPECIALITIES = 'REQ_GET_ALL_SPECIALITIES'
export const REC_GET_ALL_SPECIALITIES = 'REC_GET_ALL_SPECIALITIES'

export const reqCreateAdmin = (data, resource, headers=null) => {
    // console.log("Action called")
    return {
        type: REQ_CREATE_ADMIN, 
        data, 
        resource,
        headers
    }
}

export const reqGetAllSpecialities = (config) => ({type: REQ_GET_ALL_SPECIALITIES, config: config})
export const recGetAllSpecialities = data => ({type: REC_GET_ALL_SPECIALITIES, data})


export const recCreateAdmin = data => ({type: REC_CREATE_ADMIN, data})


