import React from "react";
import ReviewFormSection from "./ReviewFormSection";
import "./ArticleReviewsSection.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

class ArticleReviewSection extends React.Component {
  state = {
    displayReplay: false,
    displayRepliesSection: false,
    reviewNum: 0
  };

  displayReplyForm = id => {
    this.setState(() => ({
      displayReplay: true,
      reviewNum: id
    }));
  };

  HideReplyForm = id => {
    this.setState(() => ({
      displayReplay: false,
      reviewNum: id
    }));
  };

  displayRepliesSection = id => {
    if (this.state.displayRepliesSection & (this.state.reviewNum === id)) {
      this.setState(() => ({
        displayRepliesSection: false
      }));
    } else if (
      !this.state.displayRepliesSection &
      (this.state.reviewNum === id)
    ) {
      this.setState(() => ({
        displayRepliesSection: true
      }));
    } else if (
      this.state.displayRepliesSection &
      (this.state.reviewNum !== id)
    ) {
      this.setState(() => ({
        displayRepliesSection: true,
        reviewNum: id
      }));
    } else if (
      !this.state.displayRepliesSection &
      (this.state.reviewNum !== id)
    ) {
      this.setState(() => ({
        displayRepliesSection: true,
        reviewNum: id
      }));
    }
  };

  ReviewInputNames = {
    name: "reviewName",
    email: "reviewEmail",
    comment: "reviewComment"
  };
  ReplyInputNames = {
    name: "replyName",
    email: "replyEmail",
    comment: "replyComment"
  };

  render() {
    const {
      handleChange,
      revFormError,
      submitReview,
      formErrorField,
      revSuccessMessage,
      revFullName,
      revComment,
      revEmail,
      acceptedReviews,
      acceptedReplies,
      repFormError,
      submitReply,
      repSuccessMessage,
      repFullName,
      repEmail,
      repComment
    } = this.props;

    let allReviewsWithReplies = [];

    if (acceptedReplies) {
      for (let i = 0; i < acceptedReplies.length; i++) {
        allReviewsWithReplies.push(acceptedReplies[i].review_id);
      }
    }

    return (
      <div id="article-review-section">
        {/* Section Title */}
        <div className="section-title-container">
          <h2 className="section-title">Comments</h2>
        </div>
        {/* Review Form */}
        <ReviewFormSection
          inputNames={this.ReviewInputNames}
          handleChange={handleChange}
          formError={revFormError}
          submitPost={submitReview}
          formErrorField={formErrorField}
          successMessage={revSuccessMessage}
          revFullName={revFullName}
          revEmail={revEmail}
          reComment={revComment}
          buttonName="Post Comment"
          placeholder="Comment"
        />

        {/* Review List Section */}
        <div id="review-list-section-container">
          <div className="review-list-section">
            {(acceptedReviews || []).map((r, i) => (
              <div className="rev-list-item" key={`a-r-k-${i}`}>
                <div className="rev-list-item-name">{r.r_full_name}</div>
                <div className="rev-list-item-comment">
                  <span>{r.r_comment}</span>
                  <span
                    className={`arrow-replies ${
                      allReviewsWithReplies.includes(r.id)
                        ? " visible"
                        : " hidden"
                    }`}
                    onClick={e => this.displayRepliesSection(i)}
                  >
                    {this.state.displayRepliesSection &
                    (this.state.reviewNum === i) ? (
                      <FontAwesomeIcon icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon icon={faAngleDown} />
                    )}
                  </span>
                </div>
                {this.state.displayRepliesSection &
                (this.state.reviewNum === i) ? (
                  <div className="reply-section">
                    {(acceptedReplies || []).map((rep, j) =>
                      rep.review_id === r.id ? (
                        <div
                          className="rev-list-item reply-section"
                          key={`a-r-k-${j}`}
                        >
                          <div className="rev-list-item-name">
                            {rep.full_name}
                          </div>
                          <div className="rev-list-item-comment">
                            {rep.reply}
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                ) : (
                  ""
                )}
                {this.state.displayReplay & (this.state.reviewNum === i) ? (
                  <div className="reply-form">
                    <ReviewFormSection
                      inputNames={this.ReplyInputNames}
                      handleChange={handleChange}
                      formError={repFormError}
                      submitPost={submitReply.bind(this, r.id)}
                      formErrorField={formErrorField}
                      successMessage={repSuccessMessage}
                      repFullName={repFullName}
                      repEmail={repEmail}
                      repComment={repComment}
                      buttonName="Post Reply"
                      placeholder="Reply"
                    />
                  </div>
                ) : (
                  ""
                )}
                {this.state.displayReplay & (this.state.reviewNum === i) ? (
                  <div
                    className="rev-list-item-reply"
                    onClick={e => this.HideReplyForm(i)}
                  >
                    Close
                  </div>
                ) : (
                  <div
                    className="rev-list-item-reply"
                    onClick={e => this.displayReplyForm(i)}
                  >
                    Reply
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleReviewSection;
