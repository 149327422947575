export const REQ_GET_ALL_SPECIALITIES = 'REQ_GET_ALL_SPECIALITIES'
export const REC_GET_ALL_SPECIALITIES = 'REC_GET_ALL_SPECIALITIES'

export const REQ_ADD_SPECIALITY_THERAPIST = 'REQ_ADD_SPECIALITY_THERAPIST'
export const REC_ADD_SPECIALITY_THERAPIST = 'REC_ADD_SPECIALITY_THERAPIST'

export const REQ_REMOVE_SPECIALITY_THERAPIST = 'REQ_REMOVE_SPECIALITY_THERAPIST'
export const REC_REMOVE_SPECIALITY_THERAPIST = 'REC_REMOVE_SPECIALITY_THERAPIST'


export const reqGetAllSpecialities = (config) => ({type: REQ_GET_ALL_SPECIALITIES, config: config})
export const recGetAllSpecialities = data => ({type: REC_GET_ALL_SPECIALITIES, data})

export const reqAddSpeciality = (config) => ({type: REQ_ADD_SPECIALITY_THERAPIST, config: config})
export const recAddSpeciality = data => ({type: REC_ADD_SPECIALITY_THERAPIST, data})

export const reqRemoveSpeciality = (config) => ({type: REQ_REMOVE_SPECIALITY_THERAPIST, config: config})
export const recRemoveSpeciality = data => ({type: REC_REMOVE_SPECIALITY_THERAPIST, data})

 