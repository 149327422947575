import React from 'react'
// import ReactTooltip from 'react-tooltip'

import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

const containerStyle = {
    width: '98%',
    maxWidth: '1195px',
    background: '#fff',
    borderRadius: '20px',
    color: '#111',
    padding: '10px 30px 10px 0',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    maxHeight: '94vh',
    height: '100%',
};

const overflowContainer = {
    height: '100%',
    padding: '0 0 0 30px',
    overflowY: 'scroll',
    width: '100%',
};

const titleStyle = {
    textAlign: 'center',
    fontWeight: '600'
};

// const textStyle = {
//     textAlign: 'center',
//     fontSize: '17px',
//     display: 'block',
//     margin: '15px auto'
// }

const optionsCStyle = {
    display: 'flex'
};

const validationError = {
    color: '#dc3545',
    fontSize: '14px',
    lineHeight: '1.2'
};

class ImageUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                this._initRequest();
                this._initListeners( resolve, reject, file );
                this._sendRequest( file );
            } ) );
    }

    // Aborts the upload process.
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();

        // Note that your request may look different. It is up to you and your editor
        // integration to choose the right communication channel. This example uses
        // a POST request with JSON as a data structure but your configuration
        // could be different.
        xhr.open( 'POST', `${baseBackendUrl}/api/therapist/article/upload_image`, true );
        xhr.responseType = 'json';
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners( resolve, reject, file ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;

        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;

            // This example assumes the XHR server's "response" object will come with
            // an "error" which has its own "message" that can be passed to reject()
            // in the upload promise.
            //
            // Your integration may handle upload errors in a different way so make sure
            // it is done properly. The reject() function must be called when the upload fails.
            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            // This URL will be used to display the image in the content. Learn more in the
            // UploadAdapter#upload documentation.
            resolve( {
                default: response.url
            } );
        } );

        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', evt => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }

    // Prepares the data and sends the request.
    _sendRequest( file ) {
        // Prepare the form data.
        const data = new FormData();

        data.append( 'upload', file );

        // Important note: This is the right place to implement security mechanisms
        // like authentication and CSRF protection. For instance, you can use
        // XMLHttpRequest.setRequestHeader() to set the request headers containing
        // the CSRF token generated earlier by your application.

        // Send the request.
        this.xhr.send( data );
    }
}

function imageUploadAdapterFunction( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new ImageUploadAdapter( loader );
    };
}

const editorConfiguration = {
    extraPlugins: [ imageUploadAdapterFunction ],
};

const renderThumbnail = props => {
    let previewUrl = props.thumbnailPreview;

    if (!previewUrl) {
        if (!props.articleThumbnailUrl) return '';

        let thbUrl = `${baseBackendUrl}/articleContentImage/${props.articleThumbnailUrl}`;
        let el = (
            <div className="art-thb-container">
                <img src={thbUrl} alt="articleThumbnail" loading="lazy"/>
            </div>
        );

        return el;
    }

    let el = (
        <div className="art-thb-container">
            <img src={previewUrl} alt="articleThumbnail" loading="lazy"/>
        </div>
    );

    return el;
};

class ArticlePopupForm  extends React.Component {

    render() {
        return (
            <div className="dark-overlay"
                 style={{
                    display: this.props.display ? 'block' : 'none',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: '100',
                    background: 'rgba(0, 0, 0, 0.5)',
                }}>
                <div className="confirm-container"
                     style={containerStyle}>
                    <div className="overflow-container"
                         style={overflowContainer}>
                        <h5 className="pc-title" style={titleStyle}>{this.props.title}</h5>
                        
                        <div className="mb-4">
                            <label>Title</label>
                            <input name="articleTitle"
                                   className="form-control"
                                   type="text"
                                   value={this.props.articleTitle}
                                   onChange={this.props.changeInputAction} />
                        </div>
                        
                        <div className="article-thumbnail-row">
                            <label>Thumbnail Image</label>
                            {/* ThumbnailImage */}
                            { renderThumbnail(this.props) }
                            {/* Upload Btn */}
                            <div
                                className="btn btn-default add-thumbnail-btn"
                                onClick={(e) => this.selectArtThumbnail.click()}
                            >{ this.props.thumbnailPreview ? 'Change' : 'Upload'}</div>
                            {/* Hidden upload input */}
                            <input
                                id="arThbImg"
                                type="file"
                                ref={(ref) => this.selectArtThumbnail = ref}
                                style={{ display: 'none' }}
                                onChange={this.props.handleChooseThumbnail}
                            />

                        </div>
                        {this.props.articleType!=='product' ?
                        (<div className="mb-4">
                            <label>Content</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={this.props.articleContent}
                                config={editorConfiguration}
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    let alteredEvent = event;
                                    alteredEvent.target = {
                                        name: 'articleContent',
                                        value: data
                                    };
                                    this.props.changeInputAction(alteredEvent)
                                } }
                                onBlur={ editor => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ editor => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        </div>) : ""}
                        <div className="mb-4">
                            <label>Tags</label>
                            {this.props.loadTags()}
                            <div className="row">
                                <div className="col-md-6">
                                    <input name="tagInput"
                                           className="form-control"
                                           type="text"
                                           value={this.props.tagInput}
                                           onChange={this.props.changeInputAction} />
                                    <span style={validationError}>{this.props.tagInputError}</span>
                                </div>
                                <div className="col-md-4">
                                    <button type="button"
                                            className="btn btn-primary ml-3"
                                            onClick={this.props.addTagAction}>{'Add Tag'}</button>
                                </div>
                            </div>
                        </div>
                        {this.props.articleType==='product' ? 
                            (<div>
                        <div className="mb-4">
                            <label>Description</label>
                            <textarea name="resourceDescription"
                                   className="form-control input-width textarea-row"
                                   rows="5"
                                   type="text"
                                   value={this.props.resourceDescription}
                                   onChange={this.props.changeInputAction} />
                        </div>
                            
                        <div className="mb-4">
                            <label>Price</label>
                            <input name="resourcePrice"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.resourcePrice}
                                   onChange={this.props.changeInputAction} />
                        </div>

                        <div className="mb-4">
                            <label>Product Link</label>
                            <input name="resourceLink"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.resourceLink}
                                   onChange={this.props.changeInputAction} />
                        </div>
                        </div>) : ""}
                        <div className="error-message">{this.props.errorMessage}</div>

                        <div className="pc-options-container" style={optionsCStyle}>
                            <button type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={this.props.submitForm.bind(this, 1)}>{this.props.confirmText || 'Submit'}</button>
                            <button type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={this.props.submitForm.bind(this, 0)}>Save as Draft</button>
                            <button type="button"
                                    className="btn btn-danger ml-3"
                                    onClick={this.props.cancelAction}>{this.props.cancelText || 'Cancel'}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ArticlePopupForm
