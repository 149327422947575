import React from 'react'
import './RoundInputWLabel.scss'
const RoundInputWLabel = props => (
    <div className="round-input-w-label-outer">
        <div className="round-input-w-label-inner">
            <div className="riwl-label">
                <span className="riwl-label-text"><span>{props.label || ''}</span></span>
            </div> 
            <div className="riwl-input-container">
                <input 
                    className="riwl-input" 
                    placeholder={props.placeholder || ''}
                    onChange={props.onChange}  
                />
            </div>
        </div> 
    </div>  
)

export default RoundInputWLabel