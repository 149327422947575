import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_ALL_SPECIALITIES, REQ_ADD_SPECIALITY_THERAPIST, REQ_REMOVE_SPECIALITY_THERAPIST,
  recGetAllSpecialities, recAddSpeciality, recRemoveSpeciality} from './actions'

import fetchData from '../../../utils/api'
import mergeInUserDetails from '../../../utils/mergeInUserDetails'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'


function* getAllSpecialities(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetAllSpecialities(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* addSpeciality(action) {
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
  
    if (data.success) {
      const newUserDetails = {...data};
      delete newUserDetails.success
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
    yield put(recAddSpeciality(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* removeSpeciality(action) {
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    
    if (data.success) {
      const newUserDetails = {...data};
      delete newUserDetails.success
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
    yield put(recRemoveSpeciality(data))
   
  } catch (e) {console.log("Api error: ", e)}
}


export default function* therapistManageSpecialities() {
    yield takeLatest(REQ_GET_ALL_SPECIALITIES, getAllSpecialities);
    yield takeLatest(REQ_ADD_SPECIALITY_THERAPIST, addSpeciality);
    yield takeLatest(REQ_REMOVE_SPECIALITY_THERAPIST, removeSpeciality);
};  