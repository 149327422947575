import React from "react";
import { Provider } from "react-redux";

import store from "./store";
// import persistor from "./store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";

import AdminSignin from "./containers/Admin/Signin/Signin";
import AdminDashboard from "./containers/Admin/Dashboard/Dashboard";
import AdminProfile from "./containers/Admin/Profile/Profile";
import AdminCreateAdmin from "./containers/Admin/CreateAdmin/CreateAdmin";
import AdminManageSpecialities from "./containers/Admin/ManageSpecialities/ManageSpecialities";
import AdminManageInsurances from "./containers/Admin/ManageInsurances/ManageInsurances";
import AdminManageArticles from "./containers/Admin/ManageBlog/ManageArticles";
import ManageAdmins from "./containers/Admin/ManageAdmins/ManageAdmins";
import AdminActivateAccount from "./containers/Admin/ActivateAccount/ActivateAccount";
import Support from "./containers/Admin/Support/Support";
import ManageTherapists from "./containers/Admin/ManageTherapists/ManageTherapists";
import ManageBloggers from "./containers/Admin/ManageBloggers/ManageBloggers";
import Specialities from "./containers/Therapist/Specialities/Specialities";
import Insurances from "./containers/Therapist/Insurances/Insurances";
import Signin from "./containers/Therapist/Signin/Signin";
import Signup from "./containers/Therapist/Signup/Signup";
//import Details from './containers/Therapist/Details/Details'
import DetailsBlogger from "./containers/Blogger/Details/Details";
import PublicProfile from "./containers/Therapist/PublicProfile/PublicProfile";
import TherapistProfile from "./containers/Therapist/Profile/Profile";
import BloggerProfile from "./containers/Blogger/Profile/ProfileBlogger";
import Articles from "./containers/Therapist/Blog/Articles";
import BloggerArticles from "./containers/Blogger/Blog/Articles";
import ThankYouContact from "./components/ThankYouPage/ThankYouContact";
import ThankYouPage from "./containers/AssistanceForAll/ThankYouPage";
import Products from './containers/Products/Products';
import Home from "./containers/Home/Home";
import LandingPage from "./containers/LandingPage/LandingPage";
import AboutUs from "./containers/AboutUs/AboutUs";
import TherapyTypes from "./containers/TherapyTypes/TherapyTypes";
import OnlineTherapies from "./containers/OnlineTherapies/OnlineTherapies";
import Contact from "./containers/Contact/Contact";
import Travel from "./containers/Travel/Travel";
import AssistanceForAll from "./containers/AssistanceForAll/AssistanceForAll";
import BlogMain from "./containers/Blog/BlogMain";
import TermesAndConditions from "./containers/LegalPages/TermsAndConditions"
import PrivacyPolicy from "./containers/LegalPages/PrivacyPolicy"
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import RequestResetPassword from "./containers/RequestResetPassword/RequestResetPassword";
import NotFound from "./containers/404 Page/404";
// import SupportMessages from './containers/Admin/SupportMessages/SupportMessages'
import ReviewsTherapist from "./containers/Admin/ReviewsTherapist/ReviewsTherapist";
import Locations from "./containers/Therapist/Locations/Locations";
import Payment from "./containers/Therapist/Payment/Payment";
import PaymentFirst from "./containers/Therapist/Payment/PaymentFirst";
import Sponsorship from "./containers/Therapist/Sponsorship/Sponsorship";
import Article from "./containers/Article/Article";
import Faq from "./containers/Faq/Faq";
import TherapyArticle from "./containers/Article/TherapyArticle";
import ManageArticleReviews from "./containers/Admin/ManageArticleReviews/ManageArticleReviews";
import TherapistTopLocations from "./containers/SEO/TherapistsTopLocations/TherapistsTopLocations";
import TherapistsByLocation from "./containers/SEO/TherapistsByLocation/TherapistsByLocation";
import TherapistsByLocationA from "./containers/SEO/TherapistsByLocation/TherapistsByLocationA";
import TherapistsByLocationB from "./containers/SEO/TherapistsByLocation/TherapistsByLocationB";
// import ReactGA from "react-ga";
import TagManager from 'react-gtm-module'
import TherapistProfiles from "./containers/TherapistProfiles/TherapistProfiles";
import TopTherapist from "./containers/TopTherapist/TopTherapist";
import CookiesConsent from "./components/CookiesConsent/CookiesConsent";
import ScrollToTop from "./utils/ScrollToTop";
import { Helmet } from "react-helmet";

const tagManagerArgs = {gtmId: 'GTM-KRF5CBX'}
      // trackingId = "UA-150407870-1",
// ReactGA.initialize(trackingId);
// ReactGA.pageview(window.location.pathname + window.location.search);

TagManager.initialize(tagManagerArgs)

export default () => (
  <Provider store={store}>
    {/* <Home /> */}

    <Helmet>
      {/* <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-700408382"
      ></script>
      <script>
        {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
         gtag('config', 'AW-700408382');
        `}
      </script> */}

      <link rel="preload" href="./fonts/Montserrat/Montserrat-Regular.ttf" as="font" type="font/ttf" onload="this.onload=null;this.rel='stylesheet'" crossorigin></link>

    </Helmet>
    <Router>
      <ScrollToTop>
        <Switch>
          {/* Home */}
          <Route path="/" exact component={Home} />

          {/* Thank you page contact */}
          <Route path="/thank-you" component={ThankYouContact} />

          {/* Pages */}
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/travel" exact component={Travel} />
          {/* <Route path="/products" exact component={ProductPage} /> */}
          <Route path="/therapist/profile/:id" component={PublicProfile} />
          <Route path="/blogger/profile/:id" component={PublicProfile} />
          <Route path="/blog" exact component={BlogMain} />
          <Route path="/products" exact component={Products} />
          <Route path="/products/page-:id" exact component={Products} />
          <Route path="/blog/page-:id" exact component={BlogMain} />
          <Route path="/blog/:id/:permalink" component={Article} />
          <Route path="/autism-therapy-types/:id/:permalink" component={TherapyArticle} />
          <Route
            path="/therapists/top/:locationType"
            component={TherapistTopLocations}
          />
          <Route path="/terms-and-conditions" component={TermesAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            path="/top/therapists/list/:locationType/:locationName/:region"
            component={TherapistsByLocation}
          />
          <Route
            path="/ads/therapists/list/:locationType/:locationName/:region"
            component={TherapistsByLocationA}
          />
          <Route
              path="/ads2/therapists/list/:locationType/:locationName/:region"
              component={TherapistsByLocationB}
          />
          <Route path="/specialists" component={TherapistProfiles} />
          <Route path="/how-we-can-help-you" component={LandingPage} />

          {/*User activate account rout*/}
          <Route path="/activate-account/:token/:email" component={Signin} />

          {/*Therapist first login*/}
          <Route path="/payment-first" component={PaymentFirst} />

          {/* Therapist Routes */}
          <Route path="/signup" component={Signup} />
          <Route path="/signin" component={Signin} />
          <Route path="/profile" component={TherapistProfile} />
          <Route path="/blogger-profile" component={BloggerProfile} />
          <Route path="/reset-password/:token/:uid" component={ResetPassword} />
          <Route
            path="/request-reset-password"
            component={RequestResetPassword}
          />
          <Route path="/specialities" component={Specialities} />
          <Route path="/insurances" component={Insurances} />
          <Route path="/blogger-details" component={DetailsBlogger} />
          {/* <Route path="/details" component={Details}/> */}
          <Route path="/therapist-locations" component={Locations} />
          <Route path="/payment" component={Payment} />
          <Route path="/sponsorship" component={Sponsorship} />
          <Route path="/articles" component={Articles} />
          <Route path="/blogger-articles" component={BloggerArticles} />
          <Route path="/top-therapist" component={TopTherapist} />
          <Route path="/autism-therapy-types" component={TherapyTypes} />
          <Route path="/online-therapies" component={OnlineTherapies} />
          <Route path="/faq" component={Faq} />
          <Route path="/autism-assistance-for-all/thank-you" component={ThankYouPage} />
          <Route path="/autism-assistance-for-all" component={AssistanceForAll} />
          
          {/* Admin Routes */}
          <Route path="/admin/signin" component={AdminSignin} />
          <Route path="/admin/dashboard" component={AdminDashboard} />
          <Route path="/admin/" exact component={AdminDashboard} />
          <Route path="/admin/profile" component={AdminProfile} />
          <Route path="/admin/create/admin" component={AdminCreateAdmin} />
          <Route
            path="/admin/manage/specialities"
            component={AdminManageSpecialities}
          />
          <Route
            path="/admin/manage/insurances"
            component={AdminManageInsurances}
          />
          <Route
            path="/admin/activate/:token/:uid"
            component={AdminActivateAccount}
          />
          <Route path="/admin/manage/admins" component={ManageAdmins} />
          <Route
            path="/admin/manage/articles"
            component={AdminManageArticles}
          />
          <Route path="/admin/manage/support/email" component={Support} />
          {/* <Route path="/admin/manage/support/messages" component={SupportMessages}/> */}
          <Route path="/admin/manage/therapists" component={ManageTherapists} />
          <Route path="/admin/manage/bloggers" component={ManageBloggers} />
          <Route
            path="/admin/manage/reviews/therapists"
            component={ReviewsTherapist}
          />
          <Route
            path="/admin/manage/reviews/articles"
            component={ManageArticleReviews}
          />

          <Route path="*" component={NotFound} />
        </Switch>
      </ScrollToTop>
      <CookiesConsent />
    </Router>
  </Provider>
);
