export const REQ_UPDATE_THERAPIST_PROFILE = 'REQ_UPDATE_THERAPIST_PROFILE'
export const REC_UPDATE_THERAPIST_PROFILE = 'REC_UPDATE_THERAPIST_PROFILE'

export const REQ_UPDATE_THERAPIST_PASSWORD = 'REQ_UPDATE_THERAPIST_PASSWORD'
export const REC_UPDATE_THERAPIST_PASSWORD = 'REC_UPDATE_THERAPIST_PASSWORD'

export const reqUpdateTherapistProfile = (config) => ({type: REQ_UPDATE_THERAPIST_PROFILE,  config: config})
export const recUpdateTherapistProfile = data => ({type: REC_UPDATE_THERAPIST_PROFILE, data})

export const reqUpdateTherapistPassword = (config) => ({type: REQ_UPDATE_THERAPIST_PASSWORD,  config: config})
export const recUpdateTherapistPassword = data => ({type: REC_UPDATE_THERAPIST_PASSWORD, data})


