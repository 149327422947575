import { REC_ADD_INSURANCE, REC_GET_INSURANCES, REC_DELETE_INSURANCE, REC_UPDATE_INSURANCE, REC_SEARCH_INSURANCES } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_ADD_INSURANCE:
            return {
                addInsurance: data
            };
        
        case REC_GET_INSURANCES:
            return {
                insurances: data
            };
        
        case REC_DELETE_INSURANCE:
            return {
                insDeleted: data
            }
        
        case REC_UPDATE_INSURANCE:
            return {
                insUpdate: data
            }

        case REC_SEARCH_INSURANCES:
            return {
                insSearch: data
            }

        default:
            return state
    }
}