import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_REQUEST_RESET_PASSWORD, recRequestResetPassword} from './actions'

import fetchData from '../../utils/api'


function* requestResetPassword(action) {
  
  try {
    //console.log("called saga")
    const data = yield call(fetchData, action.data, action.resource)
    yield put(recRequestResetPassword(data))
   
  } catch (e) {
      //console.log("Api error: ", e)
   }

}


export default function* resetPasswordSaga() {
    yield takeLatest(REQ_REQUEST_RESET_PASSWORD, requestResetPassword);
};