import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

class MainWrapper extends React.Component {

    state = {
        redirect: false,
        redirectUrl: ''
    }

    componentDidMount = () => {
        const user = this.props.userDetails;
        
        // if (!(user.roleId === 3)) {
        //     this.setState(() => ({
        //         redirect: true,
        //         redirectUrl: '/signin'
        //     }))
        // } 

        if (!this.checkUserAccess(user.roleId, this.props.page)) {
            this.setState(() => ({
                redirect: true,
                redirectUrl: '/signin'
            }))
        }
    }

    checkUserAccess = (role, page) => {
        let pages = [
            { name: 'profile', roles: [ 3, 4] },
            { name: 'specialities', roles: [ 3] },
            { name: 'insurances', roles: [ 3] },
            { name: 'articles', roles: [ 3, 4] },
            { name: 'details', roles: [ 3, 4] },
            { name: 'therapist-locations', roles: [ 3] },
            { name: 'payment', roles: [ 3] },
            { name: 'sponsorship', roles: [ 3] },
        ]

        let pageRoles = pages.filter( p => (p.name === page))[0].roles,
            hasRole = pageRoles.filter(pr => pr === role).length > 0;

        return hasRole

    }

    
    render() {

        if(this.state.redirect) {
            return (
                <Redirect to={this.state.redirectUrl} />
            )
        }

        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
})

export default connect(mapStateToProps, null)(MainWrapper)
