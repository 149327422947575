export const REQ_LIST_REVIEWS_MANAGEMENT = 'REQ_LIST_REVIEWS_MANAGEMENT'
export const REC_LIST_REVIEWS_MANAGEMENT = 'REC_LIST_REVIEWS_MANAGEMENT'

export const REQ_TOGGLE_REVIEW_STATUS_MANAGEMENT = 'REQ_TOGGLE_REVIEW_STATUS_MANAGEMENT'
export const REC_TOGGLE_REVIEW_STATUS_MANAGEMENT = 'REC_TOGGLE_REVIEW_STATUS_MANAGEMENT'

export const REQ_LIST_REPLIES_MANAGEMENT = 'REQ_LIST_REPLIES_MANAGEMENT'
export const REC_LIST_REPLIES_MANAGEMENT = 'REC_LIST_REPLIES_MANAGEMENT'

export const REQ_TOGGLE_REPLY_STATUS_MANAGEMENT = 'REQ_TOGGLE_REPLY_STATUS_MANAGEMENT'
export const REC_TOGGLE_REPLY_STATUS_MANAGEMENT = 'REC_TOGGLE_REPLY_STATUS_MANAGEMENT'

export const reqListReviewsManagement = (config) => ({type: REQ_LIST_REVIEWS_MANAGEMENT, config: config})
export const recListReviewsManagement = data => ({type: REC_LIST_REVIEWS_MANAGEMENT, data})

export const reqToggleReviewStatusManagement = (config) => ({type: REQ_TOGGLE_REVIEW_STATUS_MANAGEMENT, config: config})
export const recToggleReviewStatusManagement = data => ({type: REC_TOGGLE_REVIEW_STATUS_MANAGEMENT, data})

export const reqListRepliesManagement = (config) => ({type: REQ_LIST_REPLIES_MANAGEMENT, config: config})
export const recListRepliesManagement = data => ({type: REC_LIST_REPLIES_MANAGEMENT, data})

export const reqToggleReplyStatusManagement = (config) => ({type: REQ_TOGGLE_REPLY_STATUS_MANAGEMENT, config: config})
export const recToggleReplyStatusManagement = data => ({type: REC_TOGGLE_REPLY_STATUS_MANAGEMENT, data})

