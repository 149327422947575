import React from 'react'
import './PlanCard.scss'


const renderPlanBenefits = plan => (
    <div className="p-benefits-container">
        {plan.benefits.map( (p, idx)=> (
            <div className="p-benefit-item" key={idx}>
                {/* <FontAwesomeIcon icon={faCircle}/> */}
                <span className="p-benefit-value">{p}</span>
            </div>
        ))}
    </div>
)

const getPlanOrderClass = (plan) => {
    switch (plan.name.toLowerCase()) {
        case 'free plan':
            return 'order4'
        
        case 'silver membership plan':
            return 'order3'
        
        case 'golden membership plan':
            return 'order2'

        case 'platinum membership plan' :
            return 'order1'
        
        default:
            return 'order5'
    }
}

const renderChooseButton = (props, plan) => {
    let el = null;

    if (plan.active) {
        el = (
            <div className="current-plan-marker">Current Plan</div>
        )
    } else {
        el = (
            <button 
                type="button" 
                className="btn btn-default btn-smalll" 
                onClick={props.handleChoosePlan.bind(this, plan.id)}
            >Choose Plan</button>
        )
    }

    return el
}

const PlanCard = props => (
    <div className={`plan-card ${getPlanOrderClass(props.plan)}`}>
    {props.plan.name === "Executive Membership" ? (<div className="project"><div className="diagonal badge color">Recommended</div></div>) : ""}
        <div className="p-name-container">
            <span className="p-name">{props.plan.name}</span>
        </div>
        <div className="p-price-container">
            <span className="p-price">{`$${props.plan.price}`}</span>
            <span className="p-price-period">per month</span>
        </div>
        <div className="p-name-container">
            <span className="p-name">Benefits:</span>
        </div>
        {renderPlanBenefits(props.plan)}
        <div className="choose-plan-container">
            {renderChooseButton(props, props.plan)}
        </div>
    </div>
)

export default PlanCard