import { REC_ACTIVATE_USER_ACCOUNT } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_ACTIVATE_USER_ACCOUNT:
            return data;
        default:
            return state
    }
}
