import React from "react";

import ReviewCard from "./ReviewCard";
import { reqGetPublicReviews, reqGetPublicReplies, reqPostReply } from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReviewFormSection from "../../Article/ArticleReviewsSection/ReviewFormSection";
import PopupLoader from '../../../components/PopupLoader/PopupLoader'

class ReviewsSection extends React.Component {
  state = {
    displayReplay: false,
    pageLoading: false,
    displayRepliesSection: false,
    replyName: '',
    replyComment: '',
    replyEmail: '',
    reviews: [],
    replies: [],
    reviewNum: 0
  };

  reviewsUrl = "therapist/reviews/public";
  repliesUrl = "therapist/replies/public";
  addReplyUrl = 'therapist/reply/add';

  ReplyInputNames = {
    name: "replyName",
    email: "replyEmail",
    comment: "replyComment"
  };

  componentWillMount = () => {
    this.getReviews();
    this.getReplies();
  };

  componentWillReceiveProps = newProps => {
    const apiData = newProps.apiData;
   // console.log("apidata: ", apiData);

    if (apiData.reviews && apiData.reviews.success) {
      const reviews = apiData.reviews.reviews;

      this.setState(() => ({
        reviews: reviews
      }));
    } else if (apiData.replies && apiData.replies.success) {
      const replies = apiData.replies.replies;

      this.setState(() => ({
        replies: replies
      }));
    } else if (apiData.reply && apiData.reply.success) {
        this.setState(() => ({
            successMessage: 'Your reply was successfully submitted. It will be displayed on the page once approved by an administrator.',
            pageLoading: false,
            fullName: '',
            email: '',
            reply: '',
            
        }))
    }

  };

  getReviews = () => {
    const rConfig = {
      body: {},
      headers: null,
      resource: `${this.reviewsUrl}?tid=${this.props.therapistId}`
    };

    this.props.actions.reqGetPublicReviews(rConfig);
  };

  getReplies = () => {
    const rConfig = {
      body: {},
      headers: null,
      resource: `${this.repliesUrl}?tid=${this.props.therapistId}`
    };

    this.props.actions.reqGetPublicReplies(rConfig);
  };

  handleChange = ev => {
    let name = ev.target.name,
        val = ev.target.value;

    this.setState(() => ({
        [name]: val
    }))
}

  handleSubmitReply = (reviewId, therapistId) => {
    let formValid = this.validateReplyForm();

    if (!formValid.value) {
      this.setState(() => ({
        replyFormError: formValid.formError,
        formErrorField: formValid.formErrorField
      }));
      return;
    } else {
      this.setState(
        {
          replyFormError: "",
          formErrorField: ""
        },
        () => {
          this.submitReply(reviewId, therapistId);
        }
      );
    }
  };

  submitReply = (reviewId, therapistId) => {
    let fullName = this.state.replyName,
      email = this.state.replyEmail,
      reply = this.state.replyComment,
      reviewID = reviewId,
      therapistID = therapistId
    this.setState(
      {
        pageLoading: true
      },
      () => {
        this.props.actions.reqPostReply({
          body: {
            reviewID,
            fullName,
            email,
            reply,
            therapistID
          },
          headers: null,
          resource: this.addReplyUrl
        });
      }
    );
  };

  validateReplyForm = () => {

    if (!this.state.replyName) {
        return {
            value: false,
            formError: 'Name field is mandatory',
            formErrorField: 'replyName'
        }
    }

    if (!this.state.replyEmail) {
        return {
            value: false,
            formError: 'Email field is mandatory',
            formErrorField: 'replyEmail'
        }
    }

    if (!this.state.replyComment) {
        return {
            value: false,
            formError: 'Reply text field is mandatory',
            formErrorField: 'replyComment'
        }
    }

    return {
        value: true
    }
}

  displayReplyForm = id => {
    this.setState(() => ({
      displayReplay: true,
      reviewNum: id
    }));
  };

  HideReplyForm = id => {
    this.setState(() => ({
      displayReplay: false,
      reviewNum: id
    }));
  };

  displayRepliesSection = id => {
    if (this.state.displayRepliesSection & (this.state.reviewNum === id)) {
      this.setState(() => ({
        displayRepliesSection: false
      }));
    } else if (
      !this.state.displayRepliesSection &
      (this.state.reviewNum === id)
    ) {
      this.setState(() => ({
        displayRepliesSection: true
      }));
    } else if (
      this.state.displayRepliesSection &
      (this.state.reviewNum !== id)
    ) {
      this.setState(() => ({
        displayRepliesSection: true,
        reviewNum: id
      }));
    } else if (
      !this.state.displayRepliesSection &
      (this.state.reviewNum !== id)
    ) {
      this.setState(() => ({
        displayRepliesSection: true,
        reviewNum: id
      }));
    }
  };

  renderReviews = () => {
    const {
      repFormError,
      formErrorField,
      repSuccessMessage,
      repFullName,
      repEmail,
      repComment
    } = this.state;

    let allReviewsWithReplies = [],
      replies = this.state.replies;

    if (replies) {
      for (let i = 0; i < replies.length; i++) {
        allReviewsWithReplies.push(replies[i].review_id);
      }
    }

    const reviews = this.state.reviews.map((r, i) => (
      <div key={`rev-${i}`}>
        <ReviewCard
          review={r}
          
          displaySection={
            this.state.displayRepliesSection & (this.state.reviewNum === i)
          }
          allReviewsWithReplies={allReviewsWithReplies}
          displayRepliesSection={this.displayRepliesSection.bind(this, i)}
        />
        {this.state.displayRepliesSection & (this.state.reviewNum === i) ? (
          <div className="reply-section">
            {(replies || []).map((rep, j) =>
              rep.reviewId === r.id ? (
                <div className="rev-list-item reply-section" key={`a-r-k-${j}`}>
                  <div className="rev-list-item-name">{rep.fullName}</div>
                  <div className="rev-list-item-comment">{rep.reply}</div>
                </div>
              ) : (
                ""
              )
            )}
          </div>
        ) : (
          ""
        )}

        {this.state.displayReplay & (this.state.reviewNum === i) ? (
          <div className="reply-form">
            <ReviewFormSection
              inputNames={this.ReplyInputNames}
              handleChange={this.handleChange}
              formError={repFormError}
              submitPost={this.handleSubmitReply.bind(this, r.id, r.therapistId)}
              formErrorField={formErrorField}
              successMessage={repSuccessMessage}
              repFullName={repFullName}
              repEmail={repEmail}
              repComment={repComment}
              buttonName="Submit Reply"
              placeholder="Reply"
            />
          </div>
        ) : (
          ""
        )}
        {this.state.displayReplay & (this.state.reviewNum === i) ? (
          <div
            className="rev-list-item-reply"
            onClick={e => this.HideReplyForm(i)}
          >
            Close
          </div>
        ) : (
          <div
            className="rev-list-item-reply"
            onClick={e => this.displayReplyForm(i)}
          >
            Reply
          </div>
        )}
      </div>
    ));

    return <div className="reviews-container">{reviews}</div>;
  };

  render() {
    return (
      <section className="write-review-section">
      <PopupLoader display={this.state.pageLoading} />
        <h3 className="section-title">Recommended Reviews</h3>
        {this.renderReviews()}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  apiData: state.publicProfileReducer
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetPublicReviews: bindActionCreators(reqGetPublicReviews, dispatch),
      reqGetPublicReplies: bindActionCreators(reqGetPublicReplies, dispatch),
      reqPostReply: bindActionCreators(reqPostReply, dispatch),

    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsSection);
// export default ReviewsSection
