import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_LIST_BLOGGERS_ADMIN, REQ_SEARCH_BLOGGERS_ADMIN,
         REQ_BLOGGERS_LOCK_UNLOCK, REQ_BLOGGERS_DELETE,
         recListBloggers, recSearchBloggers, recBloggerLockUnlock,
         recDeleteBlogger, } from './actions'
import fetchData from '../../../utils/api'




function* listBloggers(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListBloggers(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* searchBloggers(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recSearchBloggers(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* lockUnLockBlogger(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recBloggerLockUnlock(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* deleteBlogger(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recDeleteBlogger(data))

  } catch (e) {console.log("Api error: ", e)}

}

export default function* manageBloggersSaga() {
    yield takeLatest(REQ_LIST_BLOGGERS_ADMIN, listBloggers);
    yield takeLatest(REQ_SEARCH_BLOGGERS_ADMIN, searchBloggers);
    yield takeLatest(REQ_BLOGGERS_LOCK_UNLOCK, lockUnLockBlogger);
    yield takeLatest(REQ_BLOGGERS_DELETE, deleteBlogger);
};
