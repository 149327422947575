import React from 'react'
import leftShape from '../../../static/images/desktop/admin-signin-left-shape.png'
import rightEllipse from '../../../static/images/desktop/admin-signin-righ-ellipse.png'
import rightShape from '../../../static/images/desktop/admin-signin-right-shape.png'
import leftEllipse from '../../../static/images/desktop/admin-signin-left-ellipse.png'
import logoTextBlue from '../../../static/images/desktop/logo-text-blue.png'
import './Signin.scss';
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
// import CheckboxRound from '../../../components/CheckboxRound/CheckboxRound'
import { Redirect, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reqSigninAdmin } from './actions';

class AdminSignin extends React.Component {

    state = {
        email: '',
        password: '',
        errors: [],
        redirectUrl: '/admin/dashboard',
        redirect: false,
        errorMessage: '',
        pageLoading: false
    }

    user = {}
    submitUrl = 'admin/login'

    componentDidMount = () => {
        this.user = this.props.userDetails

        if (this.user.roleId === 1) {
            this.setState(() => ({
                redirect: true
            }))
        }
    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData,
              err = apiData.error ? apiData.display ? apiData.error : 'An error occured' : '',
              redirect = apiData.user || false;

        const newState = {
            redirect: redirect,
            errorMessage: err
        }

        this.setState( () => (newState))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    }

    handleChange = (event) => {

        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({[inputName]: value}))
    }

    handleSubmit = () => {

        this.setState({
            errors: [],
            errorMessage: ''
        }, () => {

            if (!this.validateForm()) return;


            const rConfig = {
                body: {
                    email: this.state.email,
                    password: this.state.password
                },
                headers: null,
                resource: this.submitUrl
            }

            this.props.reqSigninAdmin(rConfig);

        })
    }

    validateForm = () => {

        let formValid = true;

        if (this.state.email === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'email'}
                ],
                errorMessage: 'Email is mandatory'
            }))
        } else if (this.state.password === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'password'}
                ],
                errorMessage: 'Password is mandatory'
            }))
        }

        return formValid

    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to={this.state.redirectUrl} />
            )
        }

        return (
            <div id="admin-signin">
                <div className="page-background">
                    <img src={leftShape} alt="leftShape" className="left-shape" />
                    <img src={rightEllipse} alt="rightEllipse" className="right-ellipse" />
                    <img src={rightShape} alt="rightShape" className="right-shape" />
                    <img src={leftEllipse} alt="leftEllipse" className="left-ellipse" />
                </div>

                <div className="page-content">
                    <Link to="/">
                        <img src={logoTextBlue} className="logo" alt="logo" />
                    </Link>
                    <h1 className="page-title">Welcome Back to Autism Assistant, Please Log In to Your Account.</h1>
                    <div className="form-container">
                        <form>
                            <div className="input-row">
                                <input type="text"
                                       placeholder="Email"
                                       name="email"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("email")}`}/>
                            </div>
                            <div className="input-row">
                                <input type="password"
                                       placeholder="Password"
                                       name="password"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("password")}`}/>
                            </div>

                            {/* <div className="remember-me-row">
                                <CheckboxRound id="rmb-me"/>
                                <span className="grey-text">Remember Me</span>
                            </div> */}

                            <div className="forgot-password-row">
                                <Link to='/request-reset-password'>
                                    <span className="grey-text underline-text">Forgot Password</span>
                                </Link>
                            </div>

                            <div>
                                <p className="error-message">{this.state.errorMessage}</p>
                            </div>

                            <div className="login-row">
                                <ButtonRoundGradient text="Log In" onClick={this.handleSubmit} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.adminSignin,
    userDetails: state.userDetails
})

const mapDispatchToProps = dispatch => bindActionCreators({reqSigninAdmin}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdminSignin)
