import React from 'react'
// import blogImage2  from '../../static/images/desktop/blog-listing-sample-image2.png'
// import blogImage2 from '../../../static/images/desktop/blog-listing-sample-image2.png'
import moment from 'moment'
import { Link } from 'react-router-dom'
 
const SimilarArticleCard = props => (
    <div className="sim-art-card-container">
        {/* Card */}
        <Link to={props.url}>
            <div className="sim-art-card">
                {/* Data */}
                <div className="sa-data-row">
                    {/* Thumbnail */}
                    <div className="sim-art-thumbnail-container">
                        <img src={props.thumbnail} alt="thumbnail" className="sim-art-thumb" loading="lazy" />
                    </div>
                    {/* Date & Title */}
                    <div className="sim-art-details-container">
                        <div className="sa-date-row">{moment(props.article.published).format('LL')}</div>
                        <div className="sa-title-row">{props.article.title}</div>
                    </div>
                </div>
                {/* Action */}
                <div className="sa-action-row">
                    <div className="btn btn-default read-sim-art-btn">Read Article</div>
                </div>
            </div>
        </Link>
    </div>
)

export default SimilarArticleCard