export const REQ_POST_REVIEW = 'REQ_POST_REVIEW'
export const REC_POST_REVIEW = 'REC_POST_REVIEW'

export const REQ_POST_REPLY = 'REQ_POST_REPLY'
export const REC_POST_REPLY = 'REC_POST_REPLY'

export const REQ_GET_PUBLIC_PROFILE = 'REQ_GET_PUBLIC_PROFILE'
export const REC_GET_PUBLIC_PROFILE = 'REC_GET_PUBLIC_PROFILE'

export const REQ_GET_PUBLIC_REVIEWS = 'REQ_GET_PUBLIC_REVIEWS'
export const REC_GET_PUBLIC_REVIEWS = 'REC_GET_PUBLIC_REVIEWS'

export const REQ_GET_PUBLIC_REPLIES = 'REQ_GET_PUBLIC_REPLIES'
export const REC_GET_PUBLIC_REPLIES = 'REC_GET_PUBLIC_REPLIES'

export const REQ_GET_THERAPIST_LOCATIONS_VISITOR = 'REQ_GET_THERAPIST_LOCATIONS_VISITOR'
export const REC_GET_THERAPIST_LOCATIONS_VISITOR = 'REC_GET_THERAPIST_LOCATIONS_VISITOR'

export const REQ_LOG_PROFILE_VISIT = 'REQ_LOG_PROFILE_VISIT'
export const REC_LOG_PROFILE_VISIT = 'REC_LOG_PROFILE_VISIT'

export const REQ_REQUEST_CLAIM_LISTING = 'REQ_REQUEST_CLAIM_LISTING'
export const REC_REQUEST_CLAIM_LISTING = 'REC_REQUEST_CLAIM_LISTING'

export const REQ_FORM_SUBMIT = 'REQ_FORM_SUBMIT'
export const REC_FORM_SUBMIT = 'REC_FORM_SUBMIT'

export const REQ_REQUEST_NETWORKING = 'REQ_REQUEST_NETWORKING'
export const REC_REQUEST_NETWORKING = 'REC_REQUEST_NETWORKING'


export const reqPostReview = (config) => ({type: REQ_POST_REVIEW, config: config})
export const recPostReview = data => ({type: REC_POST_REVIEW, data})

export const reqPostReply = (config) => ({type: REQ_POST_REPLY, config: config})
export const recPostReply = data => ({type: REC_POST_REPLY, data})

export const reqGetPublicProfile = (config) => ({type: REQ_GET_PUBLIC_PROFILE, config: config})
export const recGetPublicProfile = data => ({type: REC_GET_PUBLIC_PROFILE, data})

export const reqGetPublicReviews = (config) => ({type: REQ_GET_PUBLIC_REVIEWS, config: config})
export const recGetPublicReviews = data => ({type: REC_GET_PUBLIC_REVIEWS, data})

export const reqGetPublicReplies = (config) => ({type: REQ_GET_PUBLIC_REPLIES, config: config})
export const recGetPublicReplies = data => ({type: REC_GET_PUBLIC_REPLIES, data})

export const reqGetTherapistLocationsVisitor = (config) => ({type: REQ_GET_THERAPIST_LOCATIONS_VISITOR, config: config})
export const recGetTherapistLocationsVisitor = data => ({type: REC_GET_THERAPIST_LOCATIONS_VISITOR, data})

export const reqLogProfileVisit = (config) => ({type: REQ_LOG_PROFILE_VISIT, config: config})
export const recLogProfileVisit = data => ({type: REC_LOG_PROFILE_VISIT, data})

export const reqRequestClaimListing = (data, resource) => {return {type: REQ_REQUEST_CLAIM_LISTING, data, resource }}
export const recRequestClaimListing = data => ({type: REC_REQUEST_CLAIM_LISTING, data})

export const reqRequestNetworking = (data, resource) => {return {type: REQ_REQUEST_NETWORKING, data, resource }}
export const recRequestNetworking = data => ({type: REC_REQUEST_NETWORKING, data})

export const reqFormSubmit = (config) => ({type: REQ_FORM_SUBMIT, config: config})
export const recFormSubmit = data => ({type: REC_FORM_SUBMIT, data})
 