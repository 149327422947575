import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarBlogger from '../../../components/SidebarBlogger/SidebarBlogger'
import './Details.scss'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import { reqUpdateTherapistDetails } from './actions'
// import { reqFetchUserDetails } from '../../../utils/FetchUser/actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import MainWrapper from '../MainWrapper/MainWrapper'
import PopupLoader from '../../../components/PopupLoader/PopupLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPager } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { faCertificate } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import uploadImage from '../../../utils/uploadImage'
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class TherapistDetails extends React.Component {

    state = {
        website: '',
        bio: '',
        title: '',
        phone: '',
        errors: [],
        pageLoading: false
    }

    therapist = {}
    autheHeaders = {}
    submitUrl = 'therapist/details/update'
    uploadProfileImgUrl = 'user/profile/update/image'

    componentDidMount = () => {

        this.therapist = this.props.userDetails;
        this.autheHeaders = {
            Authorization: `Bearer ${this.therapist.token}`,
            "Content-type": "application/json"
        }
        this.setState(() => ({
            website: this.therapist.website || '',
            bio: this.therapist.bio || '',
            phone: this.therapist.phone || '',
            title: this.therapist.title || '',
            profileImageName: this.therapist.profileImageName
        }))

    }


    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;

        if (apiData.success) {
            this.setState(() => ({
                pageLoading: false
            }))
        }
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({[inputName]: value}))
    }

    handleSubmit = () => {

        this.setState({
            errors: [],
            errorMessage: '',
            pageLoading: true
        }, () => {

            if (!this.validateForm()) return;

            if (!this.state.profileImgFile) {
                this.updateProfileData()
                return
            }

            this.uploadProfilePhoto().then( res => {
                let { url: fileName } = res.data
                this.updateProfileData(fileName)
            })
        })
    }

    updateProfileData = (profileImageName = null) => {
        const rConfig = {
            body: {
                phone: this.state.phone,
                title: this.state.title,
                bio: this.state.bio,
                website: this.state.website,
                profileImageName: profileImageName
            },
            headers: this.autheHeaders,
            resource: this.submitUrl,
            userDetails: this.props.userDetails
        }
        this.props.actions.reqUpdateTherapistDetails(rConfig);
    }

    uploadProfilePhoto = () => {

        let profileImg = this.state.profileImgFile,
            data = new FormData();

        data.append('profileImage', profileImg)
        let authConfig = {
            headers: {
                Authorization: `Bearer ${this.therapist.token}`
            }
        }

        return uploadImage(this.uploadProfileImgUrl, data, authConfig)
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    }

    validateForm = () => {

        let formValid = true;

        if (this.state.phone === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'phone'}
                ],
                errorMessage: 'Phone is mandatory',
                pageLoading: false
            }))
        }

        return formValid

    }

    handleChooseProfileImg = (ev) => {

        let imageFile = ev.target.files[0]
        let previewFile = URL.createObjectURL(imageFile)

        this.setState(() => ({
            profileImgFile: imageFile,
            profileImgPreview: previewFile
        }))
    }

    renderAvatar = () => {
        let imageContent = (
            <div className="profile-img-sub-container">
                <div className="add-photo-btn">+</div>
                <div className="add-photo-text-container">
                    <span className="white-text">Add Photo</span>
                </div>
            </div>
        )

        if (this.state.profileImgPreview) {
            imageContent = (
                <div className="avatar-sub-container">
                    <img src={this.state.profileImgPreview} alt="profileImg" />
                    <div className="avatar-cover">CHANGE</div>
                </div>
            )
        } else if (this.state.profileImageName) {
            imageContent = (
                <div className="avatar-sub-container">
                    <img src={`${baseBackendUrl}/${this.state.profileImageName}`} alt="profileImg" />
                    <div className="avatar-cover">CHANGE</div>
                </div>
            )
        }

        return (
            <div className="avatar-container"
              onClick={(e) => this.selectProfilePhoto.click()}>
                {imageContent}
            </div>
        )
    }


    render () {

        return (

           <MainWrapper page="details">
               <PopupLoader display={this.state.pageLoading} />

                <div id="therapist-details">
                    <HeaderUser/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarBlogger page="details" roleId={this.therapist.roleId}/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row">
                                        {/* Title */}
                                        <h1 className="page-title">Update Your Profile Details</h1>
                                    </div>
                                    <div className="second-row">
                                        {/* Leftside Content */}
                                        <div className="leftside-content">
                                            {/* Avatar */}
                                            { this.renderAvatar() }

                                            {/* Hidden upload input */}
                                            <input
                                                id="profilePhotoInput"
                                                type="file"
                                                ref={(ref) => this.selectProfilePhoto = ref}
                                                style={{ display: 'none' }}
                                                onChange={this.handleChooseProfileImg}
                                            />
                                        </div>
                                        {/* RightSide Content */}
                                        <div className="rightside-content">
                                            <div className="form-container">
                                                {/* Profile Form */}
                                                <form>
                                                    {/* Website */}
                                                    <div className="input-row">
                                                        <FontAwesomeIcon icon={faPager} className="input-icon"/>
                                                        <input type="text"
                                                            name="website"
                                                            className={ `basic-input`}
                                                            placeholder="Website"
                                                            value={this.state.website}
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                    {/* Phone */}
                                                    <div className="input-row">
                                                        <FontAwesomeIcon icon={faPhone} className="input-icon"/>
                                                        <input type="text"
                                                            name="phone"
                                                            className={ `basic-input ${this.inputHasErrors("phone")}`}
                                                            placeholder="Phone"
                                                            value={this.state.phone}
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                    {/* Title */}
                                                    <div className="input-row">
                                                        <FontAwesomeIcon icon={faCertificate} className="input-icon"/>
                                                        <input type="text"
                                                            name="title"
                                                            className={ `basic-input`}
                                                            placeholder="Title"
                                                            value={this.state.title}
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                    {/* Bio */}
                                                    <div className="input-row textarea-row">
                                                        <FontAwesomeIcon icon={faAddressCard} className="input-icon" />
                                                        <textarea
                                                            name="bio"
                                                            className={ `basic-input`}
                                                            placeholder="Bio"
                                                            value={this.state.bio}
                                                            onChange={this.handleChange}/>
                                                    </div>

                                                    <div className="message-row">
                                                        <span className="error-message">{this.state.errorMessage}</span>
                                                    </div>

                                                    <div className="submit-row">
                                                        <ButtonRoundGradient text="Update Profile"
                                                                            otherClass="submit-btn-medium"
                                                                            onClick={this.handleSubmit}/>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.therapistDetailsReducer,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqUpdateTherapistDetails: bindActionCreators(reqUpdateTherapistDetails, dispatch)
      }
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(TherapistDetails)
// export default TherapistDetails
