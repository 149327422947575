import { REC_LOAD_SPONSORSHIP_PLANS, REC_SUBSCRIBE_TO_SPONSORSHIP_PLAN, REC_GET_SPONSORSHIP_SETTINGS, 
    REC_CANCEL_SPONSORSHIP_PLAN, REC_UPDATE_SPONSORSHIP_PLAN} from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_LOAD_SPONSORSHIP_PLANS:
            return {
                sponsorshipPlans: data
            };
        
        case REC_SUBSCRIBE_TO_SPONSORSHIP_PLAN:
            return {
                sponsorshipSubscription: data
            };

        case REC_GET_SPONSORSHIP_SETTINGS:
            return {
                sponsorshipSettings: data
            };

        case REC_CANCEL_SPONSORSHIP_PLAN:
            return {
                planCancelled: data
            };

        case REC_UPDATE_SPONSORSHIP_PLAN:
            return {
                sponsorshipUpdated: data
            };

        default:
            return state
    }
}