import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import './ManageSpecialities.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'
import RoundInputGradient from '../../../components/RoundInputGradient/RoundInputGradient';
import ButtonRoundGradientWIcon from '../../../components/ButtonRoundGradientWIcon/ButtonRoundGradientWicon'
import searchIcon from '../../../static/images/desktop/search_icon_white.png'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import { reqAddSpeciality, reqGetSpecialities, reqDeleteSpeciality, reqUpdateSpeciality, reqSearchSpecialities } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import SpecialityItem from './SpecialityItem'
import PopuConfirm from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper from '../MainWrapper/MainWrapper'

class AdminManageSpecialities extends React.Component {

    state = {
        searchSpName: '',
        addSpName: '',
        addSpDesc: '',
        pageLoading: false,
        showPopup: false,
        successMessage: '',
        deleteId: '',
        specialities: [],
        errors: [],
    }

    admin = {}
    addSpUrl = 'admin/speciality/create'
    listSpUrl = 'specialities/list'
    deleteUrl = 'admin/speciality/delete'
    updateUrl = 'admin/speciality/update'
    searchUrl = 'admin/speciality/search'
    authHeader = {}

    componentDidMount = () => {

        this.admin = this.props.userDetails;
        this.authHeader = {  
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json" 
        }

        const reqConfig = {
            body: {},
            resource: this.listSpUrl,
            headers: null
        }

        // Load Specialities
        this.props.actions.reqGetSpecialities(reqConfig);

    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;

        if (apiData.addSpeciality && apiData.addSpeciality.success) {
            this.setState( {
                pageLoading: false,
                successMessage: 'Speciality Succesfully Added',
                addSpName: '',
                addSpDesc: ''
            }, () => {
                this.loadSpecialities()
            })

        } else if (apiData.specialities) {
            this.setState(() => ({
                specialities: apiData.specialities.specialities
            }))
        } else if (apiData.spDeleted && apiData.spDeleted.success) {
            this.setState({pageLoading: false}, () => {
                this.loadSpecialities()
            })
        } else if (apiData.spUpdated && apiData.spUpdated.success) {
            this.setState({pageLoading: false}, () => {
                this.loadSpecialities()
            })
        } else if (apiData.spSearch) {
            this.setState( () => ({
                pageLoading: false,
                specialities: apiData.spSearch.specialities
            }))
        }
        
    }

    loadSpecialities = () => {
        const reqConfig = {
            body: {},
            resource: this.listSpUrl,
            headers: null
        }
        this.props.actions.reqGetSpecialities(reqConfig)
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value
        
        this.setState(() => ({[inputName]: value}))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length;
    }

    getInpuErrorMessage = (inputName) => {
        const message = this.state.errors.filter(err => err.input === inputName)
                                        .map( err => err.message) || []
        
        return message[0] || '';
    }

    addSpeciality = () => {

        this.setState({
            errors: []
        }, () => {
            let formValid = true;
            
            // Validate fields for creating speciality
            if (this.state.addSpName === '') {
                formValid = false;
                this.setState( () => ({
                    errors: [
                        {
                            input: 'addSpName',
                            message: 'Speciality Name is mandatory'
                        }
                    ]
                }))
            }

            if (!formValid) return;

            const config = {
                body: {
                    name: this.state.addSpName,
                    description: this.state.addSpDesc || ' '
                },
                headers: this.authHeader,
                resource: this.addSpUrl
            }

            this.setState({
                pageLoading: true
            }, () => {
                this.props.actions.reqAddSpeciality(config)
            })
            
        
        })
        
    }

    renderSpecialities = () => {
        let items = [];
        
        for(let i=0; i< this.state.specialities.length; i++) {

            let speciality = this.state.specialities[i]
            
            items.push(
                <SpecialityItem index={speciality.id} 
                                key={speciality.id} 
                                speciality={speciality}
                                deleteAction={this.deleteSpeciality}
                                updateAction={this.updateSpeciality}/>
            )
        }

        return (
            <ul className="specialities-list">
                {items}
            </ul>
        )

    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    deleteSpeciality = (id) => {
        this.setState(() => ({
            deleteId: id,
            showPopup: true
        }))
    }

    confirmDelete = () => {
        const rConfig = {
            body: {
                id: this.state.deleteId
            },
            resource: this.deleteUrl,
            headers: this.authHeader
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqDeleteSpeciality(rConfig)
        })
        
    }

    updateSpeciality = (speciality) => {
    
        const rConfig = {
            body: {
                id: speciality.id,
                name: speciality.name,
                description: speciality.description
            },
            headers: this.authHeader,
            resource: this.updateUrl
        }
        
        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqUpdateSpeciality(rConfig)
        })
    }

    searchSpecialities = () => {

        this.setState({
            pageLoading: true
        }, () => {

            
            const config = {
                body: {
                    name: this.state.searchSpName
                },
                headers: this.authHeader,
                resource: this.searchUrl
            }
            this.props.actions.reqSearchSpecialities(config)
            
            
        })

    }

    render () {
        return (
            <MainWrapper>
                <div id="admin-manage-specialities">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup} 
                                hidePopup={this.hidePopup}
                                calcelAction={this.hidePopup}
                                confirmAction={this.confirmDelete}/>

                    <HeaderUser adminFullName="John Doe"/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="specialities"/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row">
                                        {/* Title */}
                                        <h1 className="page-title">Manage Available Specialities</h1>
                                    </div>
                                    
                                    <div className="search-row">
                                        {/* Leftside Content */}
                                        <div className="leftside-content">
                                            <RoundInputGradient 
                                                placeholder="Speciality Name" 
                                                className="sp-search-input"
                                                inputName="searchSpName"
                                                value={this.state.searchSpName}
                                                onChange={this.handleChange}/>
                                        </div>
                                        {/* RightSide Content */}
                                        <div className="rightside-content">
                                            <ButtonRoundGradientWIcon text="Search" 
                                                                    otherClass="small-gradient-button"
                                                                    icon={searchIcon}
                                                                    onClick={this.searchSpecialities}/>
                                        </div>
                                    </div> 

                                    <div className="add-row">
                                        {/* Leftside Content */}
                                        <div className="leftside-content">
                                            <RoundInputGradient 
                                                placeholder="Speciality Name" 
                                                className={`sp-search-input`}
                                                invalid={this.inputHasErrors('addSpName')}
                                                inputName="addSpName"
                                                value={this.state.addSpName}
                                                onChange={this.handleChange}/>

                                            <div className="message-span-container">
                                                <span className="error-message">{this.getInpuErrorMessage('addSpName')}</span>
                                            </div>

                                            <RoundInputGradient 
                                                placeholder="Speciality Description" 
                                                className="sp-search-input"
                                                inputName="addSpDesc"
                                                value={this.state.addSpDesc}
                                                onChange={this.handleChange}/>

                                            <div className="message-span-container">
                                                <span className="success-message">{this.state.successMessage}</span>
                                            </div>
                                        </div>
                                        {/* RightSide Content */}
                                        <div className="rightside-content">
                                            <ButtonRoundGradient text="Add" 
                                                                otherClass="small-gradient-button"
                                                                onClick={this.addSpeciality}/>
                                        </div>
                                    </div>  

                                    <div className="results-section">
                                        {/* Results Title */}
                                        <h2 className="page-title">Results</h2>
                                        <div className="specialities-container">
                                            {this.renderSpecialities()}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.adminManageSpecialities,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqAddSpeciality: bindActionCreators(reqAddSpeciality, dispatch),
        reqGetSpecialities: bindActionCreators(reqGetSpecialities, dispatch),
        reqDeleteSpeciality: bindActionCreators(reqDeleteSpeciality, dispatch),
        reqUpdateSpeciality: bindActionCreators(reqUpdateSpeciality, dispatch),
        reqSearchSpecialities: bindActionCreators(reqSearchSpecialities, dispatch)
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(AdminManageSpecialities)
