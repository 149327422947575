import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AttachedLocationItem  extends React.Component {

    // state = {
    //     name: this.props.insurance.name,
    // }

    render() {
        return (
            <li key={this.props.index} className="loc-at-item">
                <div className="loc-at-outer">
                    <div className="loc-at-info">
                        <h4 className="loc-at-name" >{this.props.location.address}</h4>
                    </div>
                    <div className="loc-at-actions" >
                        <FontAwesomeIcon icon={faTimesCircle} 
                                        className="delete-icon" 
                                        data-tip="Remove Location" 
                                        onClick={this.props.removeAction.bind(this, this.props.location.id)}/>
                    </div>
                </div>
                
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }
    
}

export default AttachedLocationItem