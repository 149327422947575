import React from 'react'
import ReactLoading from 'react-loading';

const options = {
    width: 130,
    height: 130
}

const PopupLoader = props => (
    <div className="dark-overlay" 
         style={{
            display: props.display ? 'block' : 'none',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: '100',
            background: 'rgba(0, 0, 0, 0.5)',
        }}>
        <div className="loading-container" 
             style={{
                display: 'flex',
                margin: `${window.innerHeight/2 - options.height/2}px auto 0`,
                justifyContent: 'space-around'
            }}>
            <ReactLoading type='spinningBubbles'
                        color='#fff'
                        height={options.width} 
                        width={options.height} />
        </div>
    </div>
);

export default PopupLoader