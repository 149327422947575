export const REQ_LOAD_SPONSORSHIP_PLANS = 'REQ_LOAD_SPONSORSHIP_PLANS'
export const REC_LOAD_SPONSORSHIP_PLANS = 'REC_LOAD_SPONSORSHIP_PLANS'

export const REQ_SUBSCRIBE_TO_SPONSORSHIP_PLAN = 'REQ_SUBSCRIBE_TO_SPONSORSHIP_PLAN'
export const REC_SUBSCRIBE_TO_SPONSORSHIP_PLAN = 'REC_SUBSCRIBE_TO_SPONSORSHIP_PLAN'

export const REQ_GET_SPONSORSHIP_SETTINGS = 'REQ_GET_SPONSORSHIP_SETTINGS'
export const REC_GET_SPONSORSHIP_SETTINGS = 'REC_GET_SPONSORSHIP_SETTINGS'

export const REQ_CANCEL_SPONSORSHIP_PLAN = 'REQ_CANCEL_SPONSORSHIP_PLAN'
export const REC_CANCEL_SPONSORSHIP_PLAN = 'REC_CANCEL_SPONSORSHIP_PLAN'

export const REQ_UPDATE_SPONSORSHIP_PLAN = 'REQ_UPDATE_SPONSORSHIP_PLAN'
export const REC_UPDATE_SPONSORSHIP_PLAN = 'REC_UPDATE_SPONSORSHIP_PLAN'

export const reqLoadSponsorshipPlans = (config) => ({type: REQ_LOAD_SPONSORSHIP_PLANS, config: config})
export const recLoadSponsorshipPlans = data => ({type: REC_LOAD_SPONSORSHIP_PLANS, data})

export const reqSubscribeToSponsorshipPlan = (config) => ({type: REQ_SUBSCRIBE_TO_SPONSORSHIP_PLAN, config: config})
export const recSubscribeToSponsorshipPlan = data => ({type: REC_SUBSCRIBE_TO_SPONSORSHIP_PLAN, data})

export const reqGetSponsorshipSettings = (config) => ({type: REQ_GET_SPONSORSHIP_SETTINGS, config: config})
export const recGetSponsorshipSettings = data => ({type: REC_GET_SPONSORSHIP_SETTINGS, data})

export const reqCancelSponsorshipPlan = (config) => ({type: REQ_CANCEL_SPONSORSHIP_PLAN, config: config})
export const recCancelSponsorshipPlan = data => ({type: REC_CANCEL_SPONSORSHIP_PLAN, data})

export const reqUpdateSponsorshipPlan = (config) => ({type: REQ_UPDATE_SPONSORSHIP_PLAN, config: config})
export const recUpdateSponsorshipPlan = data => ({type: REC_UPDATE_SPONSORSHIP_PLAN, data})


 