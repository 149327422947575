import { combineReducers }           from 'redux'
import resetPasswordReducer          from './containers/ResetPassword/resetPasswordReducer'
import requestResetPasswordReducer   from './containers/RequestResetPassword/requestResetPasswordreducer'
import signinAdminReducer            from './containers/Admin/Signin/signinReducer'
import activateAccountAdmin          from './containers/Admin/ActivateAccount/activateAccountReducer'
import createAdminReducer            from './containers/Admin/CreateAdmin/createAdminReducer'
import adminUpdateProfileReducer     from './containers/Admin/Profile/updateProfileReducer'
import adminManageSpecialities       from './containers/Admin/ManageSpecialities/manageSpecialitiesReducer'
import adminManageInsurances         from './containers/Admin/ManageInsurances/manageInsurancesReducer'
import manageAdminsReducer           from './containers/Admin/ManageAdmins/manageAdminsReducer'
import supportReducer                from './containers/Admin/Support/supportReducer'
import adminManageArticles           from './containers/Admin/ManageBlog/manageArticlesReducer'

import mainBlogReducer               from './containers/Blog/blogReducer'

import therapistUpdateProfileReducer from './containers/Therapist/Profile/updateTherapistProfileReducer'
import fetchUserDetailsReducer       from './utils/FetchUser/fetchUserDetailsReducer'
import headerReducer                 from './components/HeaderUser/headerReducers'
import homeReducer                   from './containers/Home/homeReducer'
import searchFormReducer             from './components/Header/searchFormReducer'
import therapistManageSpecialities   from './containers/Therapist/Specialities/manageSpecialitiesReducer'
import therapistManageInsurances     from './containers/Therapist/Insurances/insurancesReducer'
import therapistDetailsReducer       from './containers/Therapist/Details/detailsReducer'
import contactReducer                from './containers/Contact/contactReducer'
import manageSupportMessagesAdmin    from './containers/Admin/SupportMessages/supportMessagesReducer'
import manageTherapistsReducer       from './containers/Admin/ManageTherapists/manageTherapistsReducer'
import manageBloggersReducer         from './containers/Admin/ManageBloggers/manageBloggersReducer'
import publicProfileReducer          from './containers/Therapist/PublicProfile/publicProfileReducer'
import manageTherapistReviews        from './containers/Admin/ReviewsTherapist/reviewsTherapistReducer'
import manageLocations               from './containers/Therapist/Locations/manageLocationsReducer'
import therapistManagePayment        from './containers/Therapist/Payment/paymentReducer'
import analiticsReducer              from './containers/Admin/Dashboard/dashboardReducer'
import sponsorshipPlansReducer       from './containers/Therapist/Sponsorship/sponsorshipReducer'

import signinReducer                 from './containers/Therapist/Signin/signinReducer'
import confirmUserAccountReducer     from './containers/Therapist/Signin/confirmUserAccountReducer'
import signupReducer                 from './containers/Therapist/Signup/signupReducer'
import therapistManageArticle        from './containers/Therapist/Blog/articlesReducer'
import guestArticleReducer           from './containers/Article/articleReducer'
import manageArticleReviewReducer    from './containers/Admin/ManageArticleReviews/ManageArticleReviewsReducer'
import topLocationsReducer           from './containers/SEO/seoReducer'
import topProfilesReducer            from './containers/TherapistProfiles/therapistProfilesReducer'

export default combineReducers({
    signup: signupReducer,
    signin: signinReducer,
    confirmUserAccount: confirmUserAccountReducer,
    resetPassword: resetPasswordReducer,
    requestResetPassword: requestResetPasswordReducer,
    adminSignin: signinAdminReducer,
    adminActivateAccount: activateAccountAdmin,
    createAdmin: createAdminReducer,
    adminUpdateProfile: adminUpdateProfileReducer,
    adminManageSpecialities: adminManageSpecialities,
    adminManageInsurances: adminManageInsurances,
    therapistUpdateProfileReducer: therapistUpdateProfileReducer,
    userDetails: fetchUserDetailsReducer,
    headerReducer: headerReducer,
    manageAdmins: manageAdminsReducer,
    homeReducer: homeReducer,
    searchFormReducer: searchFormReducer,
    support: supportReducer,
    therapistManageSpecialities: therapistManageSpecialities,
    therapistManageInsurances: therapistManageInsurances,
    therapistDetailsReducer: therapistDetailsReducer,
    contactReducer: contactReducer,
    manageSupportMessagesAdmin: manageSupportMessagesAdmin,
    manageTherapistsReducer: manageTherapistsReducer,
    manageBloggersReducer: manageBloggersReducer,
    publicProfileReducer: publicProfileReducer,
    manageTherapistReviews: manageTherapistReviews,
    manageLocations: manageLocations,
    therapistManagePayment: therapistManagePayment,
    analitics: analiticsReducer,
    sponsorship: sponsorshipPlansReducer,
    adminManageArticles: adminManageArticles,
    therapistManageArticle: therapistManageArticle,
    mainBlogReducer: mainBlogReducer,
    guestArticleReducer: guestArticleReducer,
    manageArticleReviewReducer: manageArticleReviewReducer,
    topLocations: topLocationsReducer,
    topProfiles: topProfilesReducer
})
