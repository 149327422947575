import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import RoundInputGradient from '../../../components/RoundInputGradient/RoundInputGradient';
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import BloggerItem from './BloggerItem'
import './ManageBloggers.scss'
import PopuConfirm from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper from '../MainWrapper/MainWrapper'
import ButtonRoundGradientWIcon from '../../../components/ButtonRoundGradientWIcon/ButtonRoundGradientWicon'
import searchIcon from '../../../static/images/desktop/search_icon_white.png'

import { reqListBloggers, reqSearchBloggers, reqBloggerLockUnlock, reqDeleteBlogger } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

class ManageBloggers extends React.Component {

    state = {
        pageLoading: false,
        showPopup: false,
        deleteId: '',
        lockId: '',
        errors: [],
        popupText: '',
        popupTitle: '',
        confirmHandler: null,
        bloggers: [],
        searchFullName: '',
        searchEmail: '',
        redirect: false,
        redirectUrl: '/blogger/profile'
    }

    admin = {}
    listBloggersUrl = 'admin/list/bloggers'
    searchBloggersUrl = 'admin/search/blogger'
    lockBloggerUrl = 'admin/ban/blogger'
    unlockBloggerUrl = 'admin/unlock/blogger'
    deleteBloggerUrl = 'admin/delete/blogger'

    componentDidMount = () => {

        this.admin = this.props.userDetails;
        this.authHeader = {  
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json" 
        }

        this.loadBloggers();

    }

    isActiveSearchFilter = () => {
        let nameFilter = this.state.searchFullName !== '',
            emailFilter = this.state.searchEmail !== '';
        
        return nameFilter || emailFilter;
    }

    componentWillReceiveProps = (newProps) => {
        //console.log("newProps: ", newProps)
        const apiData = newProps.apiData;

        if (apiData.bloggerList && apiData.bloggerList.success) {
            this.setState(() => ({
                bloggers: apiData.bloggerList.bloggers
            }))
        } else if (apiData.bloggerLockUnLock && apiData.bloggerLockUnLock.success) {
            this.setState({
                pageLoading: false
            }, () => {
                let isSearchFilter = this.isActiveSearchFilter
                this.loadBloggers(isSearchFilter)
            })
        } else if (apiData.bloggerDelete && apiData.bloggerDelete.success) {
            this.setState({
                pageLoading: false
            }, () => {
                let isSearchFilter = this.isActiveSearchFilter
                this.loadBloggers(isSearchFilter)
            })
        } else if (apiData.bloggerBetaStatus && apiData.bloggerBetaStatus.success) {
            let isSearchFilter = this.isActiveSearchFilter
            this.loadBloggers(isSearchFilter)
        } else if (apiData.bloggerPublishStatus && apiData.bloggerPublishStatus.success) {
            let isSearchFilter = this.isActiveSearchFilter
            this.loadBloggers(isSearchFilter)
        }
        
    }

    loadBloggers = (search = false) => {
        
        let resource = this.listBloggersUrl
        //console.log("loading all bloggers")
        if (search) {
            //console.log("search")
            const name = this.state.searchFullName,
                  email =  this.state.searchEmail
            
            resource = `${this.searchBloggersUrl}?name=${name}&email=${email}`
        }

        const reqConfig = {
            body: {},
            resource: resource,
            headers: this.authHeader
        }
        this.props.actions.reqListBloggers(reqConfig)
    }

    suggestOpen = () => {
        return this.state.suggestOpen ? '' : 'hidden-element';
    }

    closeSuggest = () => {
        this.setState(() => ({
            suggestOpen: false
        }))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length;
    }

    getInpuErrorMessage = (inputName) => {
        const message = this.state.errors.filter(err => err.input === inputName)
                                        .map( err => err.message) || []
        
        return message[0] || '';
    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    confirmBloggerLock = () => {
        const rConfig = {
            body: {
                uid: this.state.lockId
            },
            headers: this.authHeader,
            resource: this.state.lockValue ? this.lockBloggerUrl : this.unlockBloggerUrl
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqBloggerLockUnlock(rConfig)
        })
        
    }

    confirmBloggerDelete = () => {
        const rConfig = {
            body: {
                uid: this.state.deleteId
            },
            headers: this.authHeader,
            resource: this.deleteBloggerUrl
        }

        this.props.actions.reqDeleteBlogger(rConfig)
    }

    handleBloggerLock = (data) => {
        const lockText = 'Are you sure you want to lock this blogger account? This blogger will not be able to login untill you unlock the account!',
              unlockText = 'Are you sure you want to unlock this account? This blogger will be able to login again'

        this.setState(() => ({
            showPopup: true,
            lockId: data.id,
            lockValue: data.lock,
            popupTitle: 'Lock Blogger?',
            confirmHandler: this.confirmBloggerLock,
            popupText: data.lock ? lockText : unlockText
        }))
    }

    handleBloggerDelete = (id) => {
        const deleteText = 'Are you sure you want to delete this blogger account? This action is not reversible!'

        this.setState(() => ({
            showPopup: true,
            deleteId: id,
            popupTitle: 'Delete Blogger?',
            confirmHandler: this.confirmBloggerDelete,
            popupText: deleteText
        }))
    }

    handleBlur = () => {
        this.setState(() => ({
            searchSpecialitiesTerm: ''
        }))
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value
        
        this.setState(() => ({[inputName]: value}))
    }

    handleViewProfile = (id) => {
        let redirectUrl = `${this.state.redirectUrl}/${id}`;
        this.setState(() => ({
            redirect: true,
            redirectUrl: redirectUrl
        }))
    }

    renderBloggers = () => {
        let bloggerItems = [];
        
        for(let i=0; i< this.state.bloggers.length; i++) {

            let blogger = this.state.bloggers[i]
            
            bloggerItems.push(
                <BloggerItem index={blogger.id} 
                               key={blogger.id} 
                               blogger={blogger}
                               handleLocking={this.handleBloggerLock}
                               handleDelete={this.handleBloggerDelete}
                />
            )
        }

        return (
            <ul className="admin-list">
                {bloggerItems}
            </ul>
        )

    }

    render () {
        return (
            <MainWrapper>
                <div id="manage-bloggers">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup} 
                                 text={this.state.popupText}
                                 title={this.state.popupTitle}
                                 hidePopup={this.hidePopup}
                                 calcelAction={this.hidePopup}
                                 confirmAction={this.state.confirmHandler}/>

                    <HeaderUser/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="bloggers"/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row">
                                        {/* Title */}
                                        <h1 className="page-title">Manage Bloggers</h1>
                                    </div>
                                    
                                    <div className="search-row">
                                        <div className="leftside-content content-half">
                                             {/* Full Name */}
                                            <RoundInputGradient 
                                                placeholder="Name" 
                                                className="t-search-input"
                                                inputName="searchFullName"
                                                value={this.state.searchFullName}
                                                onChange={this.handleChange}/>
                                    
                                           
                                            
                                        </div>
                                        <div className="rightside-content content-half">
                                             {/* Email */}
                                             <RoundInputGradient 
                                                placeholder="Email" 
                                                className="t-search-input"
                                                inputName="searchEmail"
                                                value={this.state.searchEmail}
                                                onChange={this.handleChange}/>
                                            
                                            <ButtonRoundGradientWIcon text="Search" 
                                                                      otherClass="t-search-button"
                                                                      icon={searchIcon}
                                                                      onClick={this.loadBloggers.bind(this, true)}/>
                                        </div>

                                    </div>  

                                    <div className="results-section">
                                        {/* Results Title */}
                                        <h2 className="page-title">Results</h2>
                                        <div className="bloggers-container">
                                            {this.renderBloggers()}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.manageBloggersReducer,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqListBloggers: bindActionCreators(reqListBloggers, dispatch),
        reqSearchBloggers: bindActionCreators(reqSearchBloggers, dispatch),
        reqBloggerLockUnlock: bindActionCreators(reqBloggerLockUnlock, dispatch),
        reqDeleteBlogger: bindActionCreators(reqDeleteBlogger, dispatch)
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ManageBloggers)
// export default ManageBloggers
