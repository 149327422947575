import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import './Support.scss'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import { reqGetSupportEmail, reqUpdateSupportEmail } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import  MainWrapper from '../MainWrapper/MainWrapper'

class Support extends React.Component {

    state = {
        email: '',
        errors: [],
        errorMessage: ''
    }

    admin = {}
    authHeaders = {}
    getUrl = 'admin/support/email'
    updateUrl = 'admin/support/email/create'

    componentDidMount = () => {

        this.admin = this.props.userDetails
        this.authHeaders = {  
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json" 
        }
        // console.log("admin: ", this.admin)

        const rConfig ={
            body: {},
            resource: this.getUrl,
            headers: null
        }

        this.props.actions.reqGetSupportEmail(rConfig)
    }

    componentWillReceiveProps = (newProps) => {
        //console.log("props received: ", newProps)
        const apiData = newProps.apiData;

        if (apiData.supportEmail && apiData.supportEmail.emails) {
            this.setState(() => ({
                email: apiData.supportEmail.emails[0] ? apiData.supportEmail.emails[0].email : ''
            }))
        }

    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value
        
        this.setState(() => ({[inputName]: value}))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    }

    handleSubmit = () => {
        //console.log("Submitting form")
        this.setState({
            errors: [],
            errorMessage: ''
        }, () => {
            
            if (!this.validateForm()) return;

            const rConfig = {
                body: {
                    email: this.state.email,
                }, 
                headers: this.authHeaders,
                resource: this.updateUrl
            }
            // console.log("rConfig: ", this.admin)
            this.props.actions.reqUpdateSupportEmail(rConfig);

        })
        
    }

    validateForm = () => {

        let formValid = true;

        if (this.state.email === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'email'}
                ],
                errorMessage: 'Email is mandatory'
            }))
        } 
        //console.log("form valid: ", formValid)
        //console.log("this state: ", this.state)
        return formValid

    }

    render () {
        return (
                 
            <MainWrapper>
                
                <div id="support">
                    <HeaderUser/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="support-email"/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row">
                                        {/* Title */}
                                        <h1 className="page-title">Manage Support Email</h1>
                                    </div>
                                    <div className="form-container">
                                        <form>
                                            <div className="input-row">
                                                <input className={ `basic-input ${this.inputHasErrors('email')}`}
                                                       type="text" 
                                                       placeholder="Support Email"
                                                       name="email"
                                                       value={this.state.email}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div>
                                                <p className="error-message">{this.state.errorMessage}</p>
                                            </div>
                                            <div className="input-row">
                                                <ButtonRoundGradient text="Update"
                                                                     otherClass="submit-button"
                                                                     onClick={this.handleSubmit} />
                                            </div>
                                        </form>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </MainWrapper>

           
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.support,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqGetSupportEmail: bindActionCreators(reqGetSupportEmail, dispatch),
        reqUpdateSupportEmail: bindActionCreators(reqUpdateSupportEmail, dispatch)
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Support)

