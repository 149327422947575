import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_SIGNUP_THERAPIST, recSignupTherapist} from './actions'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'
import fetchData from '../../../utils/api'


function* signupTherapist(action) {

  try {
    const data = yield call(fetchData, action.data, action.resource)
    if (data.user) {
      // console.log("data in sagas: ", data)
      // data.user['roleId'] = 3;
      yield put(recFetchUserDetails(data))
    }
    // console.log('data',data);
    yield put(recSignupTherapist(data))

  } catch (e) {
      console.log("Api error: ", e)
   }

}


export default function* signupSaga() {
    yield takeLatest(REQ_SIGNUP_THERAPIST, signupTherapist);
};
