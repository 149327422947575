export const REQ_GET_THERAPISTS_TOP_PROFILES = 'REQ_GET_THERAPISTS_TOP_PROFILES'
export const REC_GET_THERAPISTS_TOP_PROFILES = 'REC_GET_THERAPISTS_TOP_PROFILES'

export const REQ_GET_THERAPISTS_BY_NAME_GUEST = 'REQ_GET_THERAPISTS_BY_NAME_GUEST'
export const REC_GET_THERAPISTS_BY_NAME_GUEST = 'REC_GET_THERAPISTS_BY_NAME_GUEST'

export const reqGetTherapistsTopProfiles = (config) => ({type: REQ_GET_THERAPISTS_TOP_PROFILES, config: config})
export const recGetTherapistsTopProfiles = data => ({type: REC_GET_THERAPISTS_TOP_PROFILES, data})

export const reqGetTherapistByNameGuest = (config) => ({type: REQ_GET_THERAPISTS_BY_NAME_GUEST, config: config})
export const recGetTherapistByNameGuest = data => ({type: REC_GET_THERAPISTS_BY_NAME_GUEST, data})
