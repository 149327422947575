import React from "react";
import HeaderUser from "../../../components/HeaderUser/HeaderUser";
import SidebarAdmin from "../../../components/SidebarAdmin/SidebarAdmin";
import "./ManageTherapists.scss";
import RoundInputGradient from "../../../components/RoundInputGradient/RoundInputGradient";
import ButtonRoundGradientWIcon from "../../../components/ButtonRoundGradientWIcon/ButtonRoundGradientWicon";
import searchIcon from "../../../static/images/desktop/search_icon_white.png";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import TherapistItem from "./TherapistItem";
import PopuConfirm from "../../../components/PopupConfirm/PopupConfirm";
import MainWrapper from "../MainWrapper/MainWrapper";
import SuggestSpecialities from "./SuggestSpecialities";
import WideModal from "../../../components/Modal/WideModal";
import {
  reqListTherapists,
  reqSearchTherapists,
  reqListSpecialities,
  reqTherapistLockUnlock,
  reqDeleteTherapist,
  reqTherapistToggleBetaStatus,
  reqTherapistTogglePublishStatus,
  reqSearchPosition,
} from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


class ManageTherapists extends React.Component {
  state = {
    page: 1,
    hasMore: true,
    pageLoading: false,
    showPopup: false,
    showModal: false,
    searchPopup: false,
    deleteId: "",
    lockId: "",
    errors: [],
    popupText: "",
    popupTitle: "",
    confirmHandler: null,
    therapists: [],
    searchFullName: "",
    searchEmail: "",
    suggestedSpecialities: [],
    searchSpecialitiesTerm: "",
    allSpecialities: [],
    suggestOpen: false,
    redirect: false,
    redirectUrl: "/therapist/profile",
    searchPosition: [],
    confirmText: "Yes",
    cancelText: "No",
    firstPosition: 0,
    secondPosition: 0,
    thirdPosition: 0,
    clicks: 0,
    startDate: "",
    endDate: "",
    currentDate: new Date(),
  };

  admin = {};
  listTherapistsUrl = "admin/list/therapists";
  listSpecialitiesUrl = "specialities/list";
  searchTherapistsUrl = "admin/search/therapist";
  lockTherapistUrl = "admin/ban/therapist";
  unlockTherapistUrl = "admin/unlock/therapist";
  deleteTherapistUrl = "admin/delete/therapist";
  toggleBetaStatusUrl = "admin/therapist/beta-status/toggle";
  togglePublishStatusUrl = "admin/therapist/publish-status/toggle";
  getSearchPositionUrl = "admin/therapist/search-position";

  componentDidMount = () => {
    this.admin = this.props.userDetails;
    this.authHeader = {
      Authorization: `Bearer ${this.admin.token}`,
      "Content-type": "application/json",
    };

    this.getTherapists();
    this.loadSpecialities();
    this.resetAnalyticsInterval();
  };

  isActiveSearchFilter = () => {
    let nameFilter = this.state.searchFullName !== "",
      emailFilter = this.state.searchEmail !== "",
      specsFilter = this.state.allSpecialities.filter((s) => s.isChecked)
        .length;

    // console.log("nameFilter: ", nameFilter)

    return nameFilter || emailFilter || specsFilter;
  };

  componentWillReceiveProps = (newProps) => {
    // console.log("newProps: ", newProps)
    const apiData = newProps.apiData;

    if (apiData.therapistList && apiData.therapistList.success) {
      let hasMore = true,
        therapists = apiData.therapistList.therapists;

      if (therapists.length < 10) {
        hasMore = false;
      }

      this.setState(() => ({
        hasMore: hasMore,
        therapists: this.state.therapists.concat(therapists),
        pageLoading: false,
      }));
    } else if (apiData.specialitiesList) {
      this.setState(() => ({
        allSpecialities: apiData.specialitiesList.specialities,
      }));
    } else if (
      apiData.therapistLockUnLock &&
      apiData.therapistLockUnLock.success
    ) {
      this.setState(
        {
          pageLoading: false,
        },
        () => {
          let isSearchFilter = this.isActiveSearchFilter;
          this.setState({ therapists: [] });
          this.loadTherapists(isSearchFilter);
        }
      );
    } else if (apiData.therapistDelete && apiData.therapistDelete.success) {
      this.setState(
        {
          pageLoading: false,
        },
        () => {
          let isSearchFilter = this.isActiveSearchFilter;
          this.setState({ therapists: [] });
          this.loadTherapists(isSearchFilter);
        }
      );
    } else if (
      apiData.therapistBetaStatus &&
      apiData.therapistBetaStatus.success
    ) {
      let isSearchFilter = this.isActiveSearchFilter;
      this.setState({ therapists: [] });
      this.loadTherapists(isSearchFilter);
    } else if (
      apiData.therapistPublishStatus &&
      apiData.therapistPublishStatus.success
    ) {
      let isSearchFilter = this.isActiveSearchFilter;
      this.setState({ therapists: [] });
      this.loadTherapists(isSearchFilter);
    } else if (apiData.therapistSearch && apiData.therapistSearch.success) {
      let hasMore = true,
        therapists = apiData.therapistSearch.searchedTherapists;

      if (therapists.length < 10) {
        hasMore = false;
      }

      this.setState(() => ({
        hasMore: hasMore,
        therapists: therapists,
        pageLoading: false,
      }));
    } else if (
      apiData.therapistSearchPosition &&
      apiData.therapistSearchPosition.success
    ) {
      let searchPosition = apiData.therapistSearchPosition.data;

      this.setState(
        {
          firstPosition: searchPosition[0].searches,
          secondPosition: searchPosition[1].searches,
          thirdPosition: searchPosition[2].searches,
          clicks: searchPosition[3].clicks
        },
        () => {
          if (this.state.searchPopup) {
            this.showSearchPosition();
          }
        }
      );
    }
  };

  noMoreData = () => (this.state.hasMore ? "" : "invisible-btn");

  getTherapists = () => {
    this.setState({ pageLoading: true }, () => {
      this.loadTherapists();
    });
  };

  resetAnalyticsInterval = () => {
    let currentDay = new Date(),
      currentMonthFirstDay = new Date(
        currentDay.getFullYear(),
        currentDay.getMonth(),
        1
      );

    this.setState({ startDate: currentMonthFirstDay, endDate: currentDay });
  };

  loadTherapists = (search = false) => {
    if (search) {
      const name = this.state.searchFullName,
        email = this.state.searchEmail,
        specialities = this.state.allSpecialities
          .filter((s) => s.isChecked)
          .map((s) => s.id),
        reqConfig = {
          body: {},
          resource: `${this.searchTherapistsUrl}?name=${name}&email=${email}&specialities=${specialities}&page=${this.state.page}`,
          headers: this.authHeader,
        };

      this.setState(
        {
          pageLoading: true,
        },
        () => {
          this.props.actions.reqSearchTherapists(reqConfig);
        }
      );
    } else {
      const reqConfig = {
        body: {},
        resource: `${this.listTherapistsUrl}?page=${this.state.page}`,
        headers: this.authHeader,
      };
      this.setState(
        {
          pageLoading: true,
        },
        () => {
          this.props.actions.reqListTherapists(reqConfig);
        }
      );
    }
  };

  loadNextPage = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.loadTherapists();
      }
    );
  };

  loadSpecialities = () => {
    const rConfig = {
      body: {},
      resource: this.listSpecialitiesUrl,
      headers: null,
    };
    this.props.actions.reqListSpecialities(rConfig);
  };

  suggestSpecialities = (event) => {
    const term = event.target.value;
    const suggestedSpecialities = this.state.allSpecialities.filter(
      (s) => s.name.toLowerCase().indexOf(term.toLowerCase()) > -1
    );

    this.setState(() => ({
      searchSpecialitiesTerm: term,
      suggestedSpecialities: suggestedSpecialities,
      suggestOpen: true,
    }));
  };

  suggestOpen = () => {
    return this.state.suggestOpen ? "" : "hidden-element";
  };

  closeSuggest = () => {
    this.setState(() => ({
      suggestOpen: false,
    }));
  };

  inputHasErrors = (inputName) => {
    const errors = this.state.errors.filter((err) => err.input === inputName);

    return errors.length ? "input-error" : "";
  };

  displayErrorMessage = () => {
    const errors = this.state.errors;

    return errors.length ? errors[0].message : "";
  };

  // getInpuErrorMessage = (inputName) => {
  //     const message = this.state.errors.filter(err => err.input === inputName)
  //                                     .map( err => err.message) || []

  //     return message[0] || '';
  // }

  hidePopup = () => {
    this.setState(() => ({
      showPopup: false,
      showModal: false,
    }));
  };

  confirmTherapistLock = () => {
    const rConfig = {
      body: {
        uid: this.state.lockId,
      },
      headers: this.authHeader,
      resource: this.state.lockValue
        ? this.lockTherapistUrl
        : this.unlockTherapistUrl,
    };

    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqTherapistLockUnlock(rConfig);
      }
    );
  };

  confirmTherapistDelete = () => {
    const rConfig = {
      body: {
        uid: this.state.deleteId,
      },
      headers: this.authHeader,
      resource: this.deleteTherapistUrl,
    };

    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqDeleteTherapist(rConfig);
      }
    );
  };

  handleTherapistLock = (data) => {
    const lockText =
        "Are you sure you want to lock this therapist account? This therapist will not be able to login untill you unlock the account!",
      unlockText =
        "Are you sure you want to unlock this account? This therapist will be able to login again";

    this.setState(() => ({
      showPopup: true,
      lockId: data.id,
      lockValue: data.lock,
      popupTitle: "Lock Therapist?",
      confirmHandler: this.confirmTherapistLock,
      popupText: data.lock ? lockText : unlockText,
      confirmText: "Yes",
      cancelText: "No",
    }));
  };

  handleTherapistDelete = (id) => {
    const deleteText =
      "Are you sure you want to delete this therapist account? This action is not reversible!";

    this.setState(() => ({
      showPopup: true,
      deleteId: id,
      popupTitle: "Delete Therapist?",
      confirmHandler: this.confirmTherapistDelete,
      popupText: deleteText,
      confirmText: "Yes",
      cancelText: "No",
    }));
  };

  toggleBetaTesterStatus = (uid, newStatus) => {
    //  console.log("toggle beta status for user: ", uid);
    //  console.log("new status: ", newStatus);

    const grantText =
        "Are you sure you want to make this user a beta tester & grant him access to all features of the site?",
      revokeText =
        "Are you sure you want to revoke the beta tester access from this user?",
      grantTitle = "Grant Beta Tester Access?",
      revokeTitle = "Revoke Beta Tester Access?",
      popupText = newStatus ? grantText : revokeText,
      popupTitle = newStatus ? grantTitle : revokeTitle;

    this.setState(() => ({
      showPopup: true,
      popupTitle: popupTitle,
      popupText: popupText,
      confirmHandler: this.confirmToggleBetaStatus.bind(this, uid, newStatus),
      confirmText: "Yes",
      cancelText: "No",
    }));
  };

  confirmToggleBetaStatus = (uid, newStatus) => {
    this.props.actions.reqTherapistToggleBetaStatus({
      resource: this.toggleBetaStatusUrl,
      body: {
        uid: uid,
        status: newStatus,
      },
      headers: this.authHeader,
    });
  };

  toglePublishProfileStatus = (uid, newStatus) => {
    //console.log("toggle beta status for user: ", uid);
    //console.log("new status: ", newStatus);

    const publishText =
        "Are you sure you want to make this therapists profile public?",
      hideText = "Are you sure you want to hide this therapists profile?",
      publishTitle = "Publish therapist profile?",
      hideTitle = "Hide therapist profile?",
      popupText = newStatus ? publishText : hideText,
      popupTitle = newStatus ? publishTitle : hideTitle;

    this.setState(() => ({
      showPopup: true,
      popupTitle: popupTitle,
      popupText: popupText,
      confirmHandler: this.confirmTogglePublishStatus.bind(
        this,
        uid,
        newStatus
      ),
      confirmText: "Yes",
      cancelText: "No",
    }));
  };

  confirmTogglePublishStatus = (uid, newStatus) => {
    this.props.actions.reqTherapistTogglePublishStatus({
      resource: this.togglePublishStatusUrl,
      body: {
        uid: uid,
        status: newStatus,
      },
      headers: this.authHeader,
    });
  };

  getSearchPosition = (uid) => {
    const rConfig = {
      resource: this.getSearchPositionUrl,
      body: {
        uid: uid,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      },
      headers: this.authHeader,
    };

    this.setState(
      {
        pageLoading: true,
        searchPopup: true,
      },
      () => {
        this.props.actions.reqSearchPosition(rConfig);
      }
    );
  };

  showSearchPosition = () => {
    
    this.setState(() => ({
      pageLoading: false,
      showModal: true,
      popupTitle: "Therapist analytics",
      confirmText: "Ok",
      cancelText: " ",
      searchPopup: false,
    }));
  };

  handleBlur = () => {
    this.setState(() => ({
      searchSpecialitiesTerm: "",
    }));
  };

  handleSpecialityCheck = (state, speciality) => {
    const allSpecsUpdated = this.state.allSpecialities.map((s) => {
      if (s.id === speciality.id) {
        s["isChecked"] = state;
      }

      return s;
    });

    this.setState(() => ({
      allSpecialities: allSpecsUpdated,
    }));
  };

  handleChange = (event) => {
    let inputName = event.target.name,
      value = event.target.value;

    this.setState(() => ({ [inputName]: value }));
  };

  saveStartDate = (event) => {
    let value = event.target.value;

    const dateValid = this.validateStartDate(value);

    if (dateValid) {
      this.setState(() => ({
        startDate: value,
        errorMessage: "",
        errors: [],
      }));
    }
  };

  saveEndDate = (event) => {
    let value = event.target.value;

    const dateValid = this.validateEndDate(value);

    if (dateValid) {
      this.setState(() => ({
        endDate: value,
        errorMessage: "",
        errors: [],
      }));
    }
  };

  validateStartDate = (date) => {
    let dateValid = true,
      endDate = new Date(this.state.endDate),
      newDate = new Date(date);

    if (endDate < newDate) {
      dateValid = false;
      this.setState({
        errors: [{ input: "startDate" }],
        errorMessage: `Please select start date before ${this.dateToString(
          endDate
        )}`,
      });
    }

    return dateValid;
  };

  validateEndDate = (date) => {
    let dateValid = true,
      startDate = new Date(this.state.startDate),
      newDate = new Date(date);

    if (startDate > newDate) {
      dateValid = false;
      this.setState({
        errors: [{ input: "endDate" }],
        errorMessage: `Please select end date after ${this.dateToString(
          startDate
        )}`,
      });
    }

    return dateValid;
  };

  dateToString = (date) => {
    let newDate = new Date(date),
      dateString =
        newDate.getFullYear() +
        "-" +
        ("0" + (newDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + newDate.getDate()).slice(-2);

    return dateString;
  };

  handleViewProfile = (id) => {
    let redirectUrl = `${this.state.redirectUrl}/${id}`;
    this.setState(() => ({
      redirect: true,
      redirectUrl: redirectUrl,
    }));
  };

  renderTherapists = () => {
    let therapistItems = [];

    for (let i = 0; i < this.state.therapists.length; i++) {
      let therapist = this.state.therapists[i];

      therapistItems.push(
        <TherapistItem
          index={therapist.id}
          key={therapist.id}
          therapist={therapist}
          handleLocking={this.handleTherapistLock}
          handleDelete={this.handleTherapistDelete}
          toglePublishProfileStatus={this.toglePublishProfileStatus}
          toggleBetaTesterStatus={this.toggleBetaTesterStatus}
          showSearchPosition={this.getSearchPosition}
        />
      );
    }

    return <ul className="admin-list">{therapistItems}</ul>;
  };

  renderSpecialityDisplayText = () => {
    let selectedCnt = this.state.allSpecialities.filter((s) => s.isChecked)
      .length;

    return selectedCnt ? `(${selectedCnt}) selected` : "Speciality";
  };

  renderSpecialityLabelTooltip = () => {
    let selectedText = this.state.allSpecialities
      .filter((s) => s.isChecked)
      .map((s) => s.name)
      .join(" || ");

    return selectedText;
  };

  render() {
    
    return (
      <MainWrapper>
        <div id="manage-therapists">
          <PopupLoader display={this.state.pageLoading} />
          <PopuConfirm
            display={this.state.showPopup}
            text={this.state.popupText}
            title={this.state.popupTitle}
            hidePopup={this.hidePopup}
            calcelAction={this.hidePopup}
            confirmAction={this.state.confirmHandler}
            confirmText={this.state.confirmText}
            cancelText={this.state.cancelText}
          />
          <WideModal
            display={this.state.showModal}
            close={this.hidePopup}
            title={this.state.popupTitle}
            text={this.state.popupText}
            confirmAction={this.hidePopup}
            calcelAction={this.hidePopup}
            confirmText={this.state.confirmText}
            cancelText={this.state.cancelText}
          >
              <div>
                <p>Selected dates:</p>
                <p><strong>{this.dateToString(this.state.startDate)} - {this.dateToString(this.state.endDate)}</strong></p>
                <p>Pageviews : <strong>{this.state.clicks}</strong></p>
                <p>First position: <strong>{this.state.firstPosition}</strong></p>
                <p>Second position: <strong>{this.state.secondPosition}</strong></p>
                <p>Third position: <strong>{this.state.thirdPosition}</strong></p>
              </div>
          </WideModal>
          <HeaderUser />
          <div className="page-background">
            {/* Page Content */}
            <div className="page-content">
              {/* Sidebar */}
              <SidebarAdmin page="therapists" />
              {/* Inner Content */}
              <div className="inner-content-container">
                <div className="inner-content">
                  <div className="top-row">
                    {/* Title */}
                    <h1 className="page-title">Manage Therapists</h1>
                  </div>

                  <div className="search-row">
                    <div className="leftside-content content-half">
                      {/* Full Name */}
                      <RoundInputGradient
                        placeholder="Name"
                        className="t-search-input"
                        inputName="searchFullName"
                        value={this.state.searchFullName}
                        onChange={this.handleChange}
                      />

                      {/* Email */}
                      <RoundInputGradient
                        placeholder="Email"
                        className="t-search-input"
                        inputName="searchEmail"
                        value={this.state.searchEmail}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="rightside-content content-half">
                      {/* Speciality */}
                      <div className="suggest-container">
                        <RoundInputGradient
                          placeholder={this.renderSpecialityDisplayText()}
                          className="t-search-input autosugest-input"
                          tooltip={this.renderSpecialityLabelTooltip()}
                          inputName="searchSpecialitiesTerm"
                          value={this.state.searchSpecialitiesTerm}
                          onChange={this.suggestSpecialities}
                        />
                        <SuggestSpecialities
                          suggestedSpecialities={
                            this.state.suggestedSpecialities
                          }
                          handleBlur={this.handleBlur}
                          handleSpecialityCheck={this.handleSpecialityCheck}
                          searchTerm={this.state.searchSpecialitiesTerm}
                        />
                      </div>

                      <ButtonRoundGradientWIcon
                        text="Search"
                        otherClass="t-search-button"
                        icon={searchIcon}
                        onClick={this.loadTherapists.bind(this, true)}
                      />
                    </div>
                  </div>
                  <h4>Select date interval for therapist analytics data</h4>
                  <div className="datepicker">
                    <div className="date-input">
                      <label>Start date</label>
                      <input
                        name="startDate"
                        className={`form-control ${this.inputHasErrors(
                          "startDate"
                        )}`}
                        type="date"
                        max={this.dateToString(this.state.currentDate)}
                        value={this.dateToString(this.state.startDate)}
                        onChange={this.saveStartDate}
                      />
                    </div>
                    <div className="date-input">
                      <label>End date</label>
                      <input
                        name="endDate"
                        className={`form-control ${this.inputHasErrors(
                          "endDate"
                        )}`}
                        type="date"
                        value={this.dateToString(this.state.endDate)}
                        max={this.dateToString(this.state.currentDate)}
                        onChange={this.saveEndDate}
                      />
                    </div>
                  </div>
                  <p className="error-message">{this.state.errorMessage}</p>
                  <div className="results-section">
                    {/* Results Title */}
                    <h2 className="page-title">Results</h2>
                    <div className="therapists-container">
                      {this.renderTherapists()}
                      <div
                        className={`btn btn-default load-more-btn ${this.noMoreData()}`}
                        onClick={this.loadNextPage}
                      >
                        Load More
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.manageTherapistsReducer,
  userDetails: state.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqListTherapists: bindActionCreators(reqListTherapists, dispatch),
      reqSearchTherapists: bindActionCreators(reqSearchTherapists, dispatch),
      reqListSpecialities: bindActionCreators(reqListSpecialities, dispatch),
      reqTherapistLockUnlock: bindActionCreators(
        reqTherapistLockUnlock,
        dispatch
      ),
      reqDeleteTherapist: bindActionCreators(reqDeleteTherapist, dispatch),
      reqTherapistToggleBetaStatus: bindActionCreators(
        reqTherapistToggleBetaStatus,
        dispatch
      ),
      reqTherapistTogglePublishStatus: bindActionCreators(
        reqTherapistTogglePublishStatus,
        dispatch
      ),
      reqSearchPosition: bindActionCreators(reqSearchPosition, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTherapists);
// export default ManageTherapists
