import { REC_ADD_SPECIALITY, REC_GET_SPECIALITIES, REC_DELETE_SPECIALITY, REC_UPDATE_SPECIALITY, REC_SEARCH_SPECIALITIES } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_ADD_SPECIALITY:
        // console.log('Admin Speciality create');
            return {
                addSpeciality: data
            };
        
        case REC_GET_SPECIALITIES:
        // console.log('Admin Speciality list');
            return {
                specialities: data
            };
        
        case REC_DELETE_SPECIALITY:
        // console.log('Admin Speciality delete');
            return {
                spDeleted: data
            }
        
        case REC_UPDATE_SPECIALITY:
        // console.log('Admin Speciality update');
            return {
                spUpdated: data
            }

        case REC_SEARCH_SPECIALITIES:
        // console.log('Admin Speciality search');
            return {
                spSearch: data
            }

        default:
            return state
    }
}