import React from 'react'

import './Travel.scss'

const Travel = props => (

    <div className={`travel ${ props.className }`}>
        <a className="travel--title" href={props.url}>{props.title}</a>
        <div className="travel--image">
            <img src={props.image} width="100%" alt="alt" loading="lazy"/>
        </div>
        { props.tag !== '' && (
          <div className="travel--tag">{props.tag}</div>
        )}
        { props.buttonText !== '' && (
        <div className="travel--button-container">  
          <div className="btn btn-default travel--button">{props.buttonText}</div>
        </div>
        )}
    </div>
);

export default Travel