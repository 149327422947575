import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import './ManageArticles.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'
import RoundInputGradient from '../../../components/RoundInputGradient/RoundInputGradient';
import ButtonRoundGradientWIcon from '../../../components/ButtonRoundGradientWIcon/ButtonRoundGradientWicon'
import searchIcon from '../../../static/images/desktop/search_icon_white.png'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import { reqAddArticle, reqGetArticles, reqDeleteArticle, reqUpdateArticle, reqSearchArticles, reqAcceptArticle, reqDeclineArticle } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import ArticleItem from './ArticleItem'
import PopuConfirm from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper from '../MainWrapper/MainWrapper'
import ArticlePopupForm from './ArticlePopupForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import uploadImage from '../../../utils/uploadImage'


const BLOG = 'blog',
      THERAPIES = 'therapy',
      EVENTS = 'event',
      PRODUCTS = 'product'

class AdminManageArticles extends React.Component {

    state = {
        searchInsName: '',
        addInsName: '',
        pageLoading: false,
        showPopup: false,
        successMessage: '',
        deleteId: '',
        articles: [],
        errors: [],
        aid: '',
        articleTitle: '',
        articlePermalink: '',
        articleCardTitle: '',
        articleTags: [],
        articleContent: '',
        showArticleForm: false,
        showArticleTitle: '',
        tagInput: '',
        articleMetaTitle: '',
        articleMetaDescription: '',
        articleCanonical: '',
        articleSchema: '',
        tagInputError: '',
        articleThumbnailPreview: '',
        articleThumbnailFile: '',
        articleThumbnailUrl: '',
        articlePublishedDate: '',
        articleType: 'blog',
        filter: BLOG,
        searchResults: [],
        resourceLink: '',
        resourcePrice: 0,
        resourceDescription: ''
    }

    admin = {}
    addArticleUrl = 'admin/article/create'
    listArticlesUrl = 'admin/articles/list'
    deleteArticleUrl = 'admin/article/delete'
    updateArticleUrl = 'admin/article/update'
    searchArticlesUrl = 'admin/articles/search'
    acceptArticleUrl = 'admin/article/accept'
    declineArticleUrl = 'admin/article/decline'
    uploadThumbnailUrl = 'therapist/article/upload_thumbnail'
    authHeader = {}

    componentDidMount = () => {

        this.admin = this.props.userDetails;
        this.authHeader = {
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json"
        }

        const reqConfig = {
            body: {},
            resource: this.listArticlesUrl,
            headers: this.authHeader
        }

        // Load Articles
        this.props.actions.reqGetArticles(reqConfig);

    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;

        if (apiData.artCreate) {
            this.setState( {
                pageLoading: false,
                successMessage: 'Article Succesfully Added',
                addInsName: '',
            }, () => {
                this.loadAllArticles()
            })

        } else if (apiData.articles) {
            this.setState(() => ({
                articles: (apiData.articles.articles || [])
            }))
        } else if (apiData.artDeleted && apiData.artDeleted.success) {
            this.setState({pageLoading: false}, () => {
                this.loadAllArticles()
            })
        } else if (apiData.insUpdate && apiData.insUpdate.success) {
            this.setState({pageLoading: false}, () => {
                this.loadAllArticles()
            })
        } else if (apiData.artAccept && apiData.artAccept.success) {
            this.setState({pageLoading: false}, () => {
                this.loadAllArticles()
            })
        } else if (apiData.artDecline && apiData.artDecline.success) {
            this.setState({pageLoading: false}, () => {
                this.loadAllArticles()
            })
        } else if (apiData.insSearch) {
            this.setState( () => ({
                pageLoading: false,
                articles: apiData.insSearch.articles
            }))
        }

    }

    filterActive = (filter) => {
        return this.state.filter === filter ? 'active-filter' : '';
    }

    applyFilter = (filter) => {
      
        this.setState( () => ({
            filter: filter
            
        }))
    }

    loadAllArticles = () => {
        const reqConfig = {
            body: {},
            resource: this.listArticlesUrl,
            headers: this.authHeader
        }
        this.props.actions.reqGetArticles(reqConfig)
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({[inputName]: value}))
    }

    handleAddTag = () => {
        if (this.state.tagInput === '') {
            this.setState({tagInputError: 'Tag can\'t be empty'})
            return
        }
        if (this.state.tagInput.match("^[a-zA-Z0-9_ -]+$") === null) {
            this.setState({tagInputError: 'Invalid characters detected! You can use only alphanumeric characters, whitespace, hyphen and underscore'})
            return
        }

        let input = this.state.tagInput;
        input = input.toLowerCase();
        input = input.charAt(0).toUpperCase() + input.slice(1);

        if (this.state.articleTags.includes(input)) {
            this.setState({tagInputError: 'You\'ve already added this tag'})
            return
        }

        this.state.articleTags.push(input);
        this.setState({tagInput: ''})
    };

    handleRemoveTag = (tagIdx) => {
        let newList = [...this.state.articleTags];
        newList.splice(tagIdx, 1)

        this.setState(() => ({
            articleTags: newList
        }))
    };

    handleChooseThumbnail = (ev) => {

        let thumbnailFile = ev.target.files[0]
        let previewFile = URL.createObjectURL(thumbnailFile)

        this.setState(() => ({
            articleThumbnailFile: thumbnailFile,
            articleThumbnailPreview: previewFile
        }))
    };

    loadTags = () => {
        let items = [];
        for(let i=0; i< this.state.articleTags.length; i++) {
            items.push(
                <div className="article-tag" key={`article-tag-k-${i}`}>
                    <span>{this.state.articleTags[i]}</span>
                    <FontAwesomeIcon icon={faTimes}
                                     className="delete-icon"
                                     data-tip="Delete Tag"
                                     onClick={this.handleRemoveTag.bind(this, i)} />
                </div>
            )
        }

        if (!items.length) {
            return (
                <div className="message-row">
                    <p>This article has no tags.</p>
                </div>
            )
        }

        return (
            <div className="article-tags">
                {items}
            </div>
        )
    }

    handleAddArticle = () => {
        this.setState(() => ({
            showArticleForm: true,
            showArticleTitle: 'Add a new resource',
            aid: '',
            articleTitle: '',
            articleContent: '',
            articleThumbnailUrl: '',
            articleMetaDescription: '',
            articleCanonical: '',
            articleSchema: '',
            articleMetaTitle: '',
            articleCardTitle:'',
            articlePermalink:'',
            articleTags: [],
            articlePublishedDate: '',
            articleType: 'blog',
            articleThumbnailPreview: '',
            resourcePrice: '',
            resourceLink: '',
            resourceDescription: ''
        }))
        document.body.classList.add('overflow-hidden');
    }

    handleEditArticle = (article) => {

        this.setState(() => ({
            showArticleForm: true,
            showArticleTitle: `Edit article - ${article.title}`,
            aid: article.id,
            articleTitle: article.title,
            articleContent: article.body,
            articleThumbnailUrl: article.thumbnail_url,
            articleTags: (article.tags || '').split(','),
            articleMetaTitle: article.metaTitle,
            articleMetaDescription: article.metaDescription,
            articleCanonical: article.canonical,
            articleSchema: article.schema_org,
            articlePublishedDate: new Date(article.published),
            articlePermalink: article.permalink,
            articleCardTitle: article.card_title,
            articleType: article.type,
            resourceLink: article.resource_link,
            resourcePrice: article.price,
            resourceDescription: article.description
        }))
        document.body.classList.add('overflow-hidden');
    }

    handleRemoveArticle = (articleId) => {
        this.setState(() => ({
            showPopup: true,
            removeId: articleId,
            popupText: 'Confirm removing this from your resources?'
        }))
    }

    handleArticleInputChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value
        this.setState({
            [inputName]: value
        })
    }

    handleArticleSelectChange = (event) => {
        let value = event.target.value

        this.setState({
            articleType: value
        })
    }

    handleAcceptArticle = (articleId) => {
        const rConfig = {
            body: {
                articleId: articleId
            },
            resource: this.acceptArticleUrl,
            headers: this.authHeader
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqAcceptArticle(rConfig)
        })

    }

    handleDeclineArticle = (articleId) => {
        const rConfig = {
            body: {
                articleId: articleId
            },
            resource: this.declineArticleUrl,
            headers: this.authHeader
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqDeclineArticle(rConfig)
        })

    }

    submitArticleForm = () => {

        let articleFormValid = this.validateArticleForm();

        if (!articleFormValid.value) {
            this.setState(() => ({
                errorMessage: articleFormValid.errorMessage
            }))

            return;
        }

        let resource = this.updateArticleUrl

        if (this.state.aid === '') {
            resource = this.addArticleUrl
        }

        if (!this.state.articleThumbnailFile) {
            this.saveArticle(resource)
            return;
        }

        this.uploadArticleThumbNail().then(
            res => {
                let { filename: thumbnailName } = res.data.details
                this.saveArticle(resource, thumbnailName)
            }
        )
        // this.saveArticle(resource)

    }

    validateArticleForm = () => {

        if (!this.state.articleTitle) {
            return {
                value: false,
                errorMessage: 'Please add a title to your article'
            }
        }

        if (!this.state.articleThumbnailPreview && !this.state.articleThumbnailUrl) {
            return {
                value: false,
                errorMessage: 'Please select a thumbnail image for your article'
            }
        }

        return {
            value: true
        }
    };

    escapeHtml = (unsafe) => {
        return unsafe
            //.replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };

    saveArticle = (resource, thumbnailName = null) => {
        const { aid, articleTitle, articleContent, articleTags , articleMetaTitle , articleMetaDescription, 
            articleCanonical, articleSchema, articlePublishedDate, articleType, articlePermalink, articleCardTitle, 
            resourcePrice, resourceLink , resourceDescription} = this.state;

        const rConfig = {
            body: {
                articleId: aid,
                title: articleTitle,
                body: this.escapeHtml(articleContent),
                tags: articleTags.join(','),
                metaTitle: articleMetaTitle,
                metaDescription: articleMetaDescription,
                canonical: articleCanonical,
                schema: articleSchema,
                cardTitle: articleCardTitle,
                permalink: articlePermalink,
                thumbnailName,
                publishedDate: articlePublishedDate,
                public: 1,
                type: articleType,
                price: resourcePrice,
                resourceLink: resourceLink,
                resourceDescription: resourceDescription
            },
            headers: this.authHeader,
            resource: resource,
            userDetails: this.props.userDetails
        };

        // console.log("body: ", rConfig.body.body)

        this.setState({
            pageLoading: true,
            showPopup: false
        }, () => {
            if (this.state.aid === '') {
                this.props.actions.reqAddArticle(rConfig)
            } else {
                this.props.actions.reqUpdateArticle(rConfig)
            }
        });

        this.hideArticleForm()
    }


    uploadArticleThumbNail = () => {

        let thumbnailFile = this.state.articleThumbnailFile,
            data = new FormData();

        data.append('upload', thumbnailFile)

        return uploadImage(this.uploadThumbnailUrl, data);
    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    hideArticleForm = () => {
        this.setState( () => ({
            showArticleForm: false,
            aid: '',
            articleTitle: '',
            articleTags: [],
            articleContent: '',
            articleThumbnailUrl: '',
            articleMetaDescription: '',
            articleMetaTitle: '',
            articleCanonical: '',
            articleSchema: '',
            articleThumbnailPreview: '',
            articleThumbnailFile: '',
            articlePublishedDate:'',
            articlePermalink: '',
            articleCardTitle: '',
            resourceLink: '',
            resourcePrice: '',
            resourceDescription: ''
        }));
        document.body.classList.remove('overflow-hidden');
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length;
    }

    getInpuErrorMessage = (inputName) => {
        const message = this.state.errors.filter(err => err.input === inputName)
                                        .map( err => err.message) || []

        return message[0] || '';
    }

    renderArticles = () => {
        let items = [];

        for(let i=0; i< this.state.articles.length; i++) {

            let article = this.state.articles[i]
            if(article.type === this.state.filter){
            items.push(
                <ArticleItem index={article.id}
                                key={article.id}
                                article={article}
                                acceptAction={this.handleAcceptArticle}
                                declineAction={this.handleDeclineArticle}
                                editAction={this.handleEditArticle}
                                removeAction={this.handleRemoveArticle}/>
            )
        }
    }

        return (
            <ul className="specialities-list">
                {items}
            </ul>
        )

    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    deleteArticle = (id) => {
        this.setState(() => ({
            deleteId: id,
            showPopup: true
        }))
    }

    confirmDelete = () => {
        const rConfig = {
            body: {
                articleId: this.state.removeId
            },
            resource: this.deleteArticleUrl,
            headers: this.authHeader
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqDeleteArticle(rConfig)
        })

    }

    searchArticles = () => {

        let searchResults = []

        this.state.articles.map((article) => {

            if(this.state.searchInsName === ''){
                this.loadAllArticles()
            }

            if (article.title.includes(this.state.searchInsName)){
                searchResults.push(article)
            }

            return searchResults
        })

        // console.log("searchResults: ",searchResults)

        this.setState(() => ({
            articles : searchResults,
            searchInsName: ''
        }))

        // this.setState({
        //     pageLoading: true
        // }, () => {


        //     const config = {
        //         body: {
        //             articleName: this.state.searchInsName
        //         },
        //         headers: this.authHeader,
        //         resource: this.searchArticlesUrl
        //     }
        //     //console.log("config: ", config)
        //     this.props.actions.reqSearchArticles(config)
        // })

    }

    render () {
        return (
            <MainWrapper logoutRedirect="/admin/signin">
                <div id="admin-manage-articles">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup}
                                hidePopup={this.hidePopup}
                                calcelAction={this.hidePopup}
                                confirmAction={this.confirmDelete}/>

                    <HeaderUser adminFullName="John Doe"/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="articles"/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row row">
                                        {/* Title */}
                                        <div className="col-md-9">
                                            <h2 className="page-title mb-0">Manage Available Articles</h2>
                                        </div>
                                        <div className="col-md-3 text-right add-article-btn-container">
                                            <ButtonRoundGradient text="New resource"
                                                                otherClass="small-gradient-button"
                                                                onClick={this.handleAddArticle}/>
                                        </div>
                                    </div>

                                    <div className="search-row">
                                        {/* Leftside Content */}
                                        <div className="leftside-content">
                                            <RoundInputGradient
                                                placeholder="Article Name"
                                                className="sp-search-input"
                                                inputName="searchInsName"
                                                value={this.state.searchInsName}
                                                onChange={this.handleChange}
                                                action={this.searchArticles}
                                                />
                                        </div>
                                        {/* RightSide Content */}
                                        <div className="rightside-content">
                                            <ButtonRoundGradientWIcon text="Search"
                                                                    otherClass="small-gradient-button"
                                                                    icon={searchIcon}
                                                                    onClick={this.searchArticles}/>
                                        </div>
                                    </div>
                                    <div className="filter-row">
                                        <div className="filters">
                                            <div 
                                                className={`filter-item middle-filter ${this.filterActive(BLOG)}`}
                                                onClick={this.applyFilter.bind(this, BLOG)}
                                            >Blog</div>
                                            <div 
                                                className={`filter-item middle-filter ${this.filterActive(THERAPIES)}`}
                                                onClick={this.applyFilter.bind(this, THERAPIES)}
                                            >Therapy</div>
                                            <div 
                                                className={`filter-item middle-filter ${this.filterActive(EVENTS)}`}
                                                onClick={this.applyFilter.bind(this, EVENTS)}
                                            >Events</div>
                                            <div 
                                                className={`filter-item ${this.filterActive(PRODUCTS)}`}
                                                onClick={this.applyFilter.bind(this, PRODUCTS)}
                                            >Products</div>
                                        </div>
                                    </div>

                                    <div className="results-section">
                                        {/* Results Title */}
                                        
                                        <div className="specialities-container">
                                            {this.renderArticles()}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <ArticlePopupForm display={this.state.showArticleForm}
                                      title={this.state.showArticleTitle}
                                      changeInputAction={this.handleArticleInputChange}
                                      changeSelectAction={this.handleArticleSelectChange}
                                      cancelAction={this.hideArticleForm}
                                      articleTitle={this.state.articleTitle}
                                      articleContent={this.state.articleContent}
                                      addTagAction={this.handleAddTag}
                                      tagInputError={this.state.tagInputError}
                                      tagInput={this.state.tagInput}
                                      loadTags={this.loadTags}
                                      articleMetaTitle={this.state.articleMetaTitle}
                                      articleMetaDescription={this.state.articleMetaDescription}
                                      articlePublishedDate={this.state.articlePublishedDate}
                                      articleCardTitle={this.state.articleCardTitle}
                                      articlePermalink={this.state.articlePermalink}
                                      articleCanonical={this.state.articleCanonical}
                                      articleSchema={this.state.articleSchema}
                                      submitForm={this.submitArticleForm}
                                      thumbnailPreview={this.state.articleThumbnailPreview}
                                      handleChooseThumbnail={this.handleChooseThumbnail}
                                      articleThumbnailUrl={this.state.articleThumbnailUrl}
                                      errorMessage={this.state.errorMessage}
                                      articleType={this.state.articleType}
                                      resourceDescription={this.state.resourceDescription}
                                      resourceLink={this.state.resourceLink}
                                      resourcePrice={this.state.resourcePrice}
                                      />
                </div>
            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.adminManageArticles,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqAddArticle: bindActionCreators(reqAddArticle, dispatch),
        reqGetArticles: bindActionCreators(reqGetArticles, dispatch),
        reqDeleteArticle: bindActionCreators(reqDeleteArticle, dispatch),
        reqUpdateArticle: bindActionCreators(reqUpdateArticle, dispatch),
        reqSearchArticles: bindActionCreators(reqSearchArticles, dispatch),
        reqAcceptArticle: bindActionCreators(reqAcceptArticle, dispatch),
        reqDeclineArticle: bindActionCreators(reqDeclineArticle, dispatch)
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(AdminManageArticles)
