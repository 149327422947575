
const mergeInUserDetails = (oldUserDetails, newUserDetails) => {
    const newKeys = Object.keys(newUserDetails)
    const localUserDetails = {...oldUserDetails}
    newKeys.forEach( item => {
        localUserDetails[item] = newUserDetails[item]
    })

    return {
        user: localUserDetails
    }
}

export default mergeInUserDetails