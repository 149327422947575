import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_RESET_PASSWORD, recResetPassword} from './actions'

import fetchData from '../../utils/api'


function* resetPassword(action) {

  try {
    const data = yield call(fetchData, action.data, action.resource)
    yield put(recResetPassword(data))

  } catch (e) {
      console.log("Api error: ", e)
   }

}


export default function* resetPasswordSaga() {
    yield takeLatest(REQ_RESET_PASSWORD, resetPassword);
};
