export const REQ_LIST_ADMINS = 'REQ_LIST_ADMINS'
export const REC_LIST_ADMINS = 'REC_LIST_ADMINS'

export const REQ_SEARCH_ADMINS = 'REQ_SEARCH_ADMINS'
export const REC_SEARCH_ADMINS = 'REC_SEARCH_ADMINS'

export const REQ_ADMIN_LOCK = 'REQ_ADMIN_LOCK'
export const REC_ADMIN_LOCK = 'REC_ADMIN_LOCK'

export const REQ_ADMIN_DELETE = 'REQ_ADMIN_DELETE'
export const REC_ADMIN_DELETE = 'REC_ADMIN_DELETE'


export const reqListAdmins = (config) => ({type: REQ_LIST_ADMINS, config: config})
export const recListAdmins = data => ({type: REC_LIST_ADMINS, data})

export const reqSearchAdmins = (config) => ({type: REQ_SEARCH_ADMINS, config: config})
export const recSearchAdmins = data => ({type: REC_SEARCH_ADMINS, data})

export const reqAdminLock = (config) => ({type: REQ_ADMIN_LOCK, config: config})
export const recAdminLock = data => ({type: REC_ADMIN_LOCK, data})

export const reqAdminDelete = (config) => ({type: REQ_ADMIN_DELETE, config: config})
export const recAdminDelete = data => ({type: REC_ADMIN_DELETE, data})

