import React from "react";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import Footer from "../../components/Footer/Footer";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import SearchFormOnline from "../../components/Header/SearchFormOnline";
import { Helmet } from "react-helmet";
import "./OnlineTherapies.scss";
import { Link } from "react-router-dom";
import ArticleCard from "../../components/Blog/ArticleCard";
import { reqGetArticles, reqSearchArticles } from "../Blog/actions";
import { bindActionCreators } from "redux";
// import onlineImage1 from "../../static/images/desktop/autism-telehealth.jpg";
import onlineImage2 from "../../static/images/desktop/aba-telehealth.jpg";
import onlineImage3 from "../../static/images/desktop/telehealth-bcba.jpg";
import onlineImage4 from "../../static/images/desktop/online-music-therapy.jpg";
import onlineImage5 from "../../static/images/desktop/online-occupational-therapy.jpg";
import onlineImage6 from "../../static/images/desktop/online-speech-therapy.jpg";
import { connect } from "react-redux";
import PopupLoader from "../../components/PopupLoader/PopupLoader";
import ButtonRoundGradient from "../../components/ButtonRoundGradient/ButtonRoundGradient";
import scrollToComponent from 'react-scroll-to-component';

require("dotenv").config();
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class OnlineTherapies extends React.Component {
  state = {
    pageLoading: false,
    articles: [],
    currentPage: 1
  };

  articlesPerPage = 11;
  getArticlesUrl = "blog/articles/all";
  searchArticleUrl = "blog/articles/search";

  componentDidMount = () => {
    this.authHeader = {
      "Content-type": "application/json"
    };
    this.setState({}, () => {
      this.loadArticles();
    });
  };

  componentWillReceiveProps = newProps => {
    const apiData = newProps.apiData;

    if (apiData.articles && apiData.articles.success) {
      let articlesArray = apiData.articles.articles;

      this.setState(() => ({
        pageLoading: false,
        articles: articlesArray
      }));
    }

    if (apiData.articleSearch && apiData.articleSearch.success) {
      this.setState(() => ({
        pageLoading: false,
        articles: apiData.articleSearch.articles
      }));
    }
  };

  loadArticles = () => {
    const rConfig = {
      body: {},
      resource: `${this.getArticlesUrl}`,
      headers: null
    };
    this.props.actions.reqGetArticles(rConfig);
  };

  getArticles = () => {
    this.setState({ pageLoading: true }, () => {
      this.loadArticles();
    });
  };

  searchArticles = () => {
    this.setState(
      {
        pageLoading: true
      },
      () => {
        this.props.actions.reqSearchArticles({
          body: {},
          headers: null,
          resource: `${this.searchArticleUrl}?searchTerm=${this.state.searchInput}`
        });
      }
    );
  };

  scrollToSearch = () => {
    var el = this.refs.wrap;
    scrollToComponent(el ,{duration: 10})
  }

  searchSubmit = () => {
    this.searchArticles();
  };

  filterSubmit = () => {};

  searchInputChange = ev => {
    let val = ev.target.value;

    this.setState(() => ({
      searchInput: val
    }));
  };

  getArticlePermalink = title =>
    title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[’,?!]/g, "");

  getArticleSnippet = (article, size = 155) =>
    article.body
      .replace(/<figcaption>([^<]+)<\/figcaption>/gi, "")
      .replace(/(<([^>]+)>)/gi, "")
      .replace("&nbsp;", " ")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .substring(0, size) + "...";

  listArticles = () => {
    const indexOfLastArticle = this.state.currentPage * this.articlesPerPage,
      indexOfFirstArticle = indexOfLastArticle - this.articlesPerPage,
      currentArticles = this.state.articles.slice(
        indexOfFirstArticle,
        indexOfLastArticle
      );

    if (
      typeof this.state.articles === "undefined" ||
      !this.state.articles.length
    ) {
      return (
        <div className="no-articles-result">
          <div className="no-articles-res-text">
            There are currently no articles for this search.
          </div>
        </div>
      );
    }
    let items = [],
      subItems = [],
      articles = currentArticles;

    for (let i = 0; i < articles.length; i++) {
      if (articles[i].type === "therapy") {
        let article = articles[i];

        // console.log("filterd article: ", article);

        let publishedDate = new Date(article.published);
        let thumblink = article.thumbnail_url
          .toLowerCase()
          .replace(/-/g, " ")
          .replace(/[?!]/g, "")
          .replace(/_/g, "")
          .replace(/[1234567890.]/g, "")
          .replace(/jpeg/g, "")
          .replace(/png/g, "")
          .replace(/jpg/g, "");
        let permalink = this.getArticlePermalink(article.title);

        subItems.push(
          <div className="col-lg-4 col-sm-6 mb-60" key={`item-sub-art-${i}`}>
            <Link to={`/autism-therapy-types/${article.id}/${permalink}`}>
              <ArticleCard
                // key={`item-sub-art-${i}`}
                image={`${baseBackendUrl}/articleContentImage/${article.thumbnail_url}`}
                url={`/blog/${article.id}/${permalink}`}
                alt={`${thumblink}`}
                tag={`TAG, ` + this.formatDate(publishedDate)}
                buttonText="Read Article"
                title={article.title}
                firstArticle={false}
                shortContent={this.getArticleSnippet(article, 90)}
                lazyLoad={true}
              />
            </Link>
          </div>
        );
      }
    }

    items.push(
      <div className="row" key={`uk`}>
        {subItems}
      </div>
    );

    if (!items.length) {
      return (
        <div className="message-row">
          <p>You have no articles written.</p>
        </div>
      );
    }

    return <ul className="articles-list">{items}</ul>;
  };

  changePage = pageNumber => {
    if (pageNumber === "prev") {
      this.setState(() => ({
        currentPage: this.state.currentPage - 1
      }));
    }
    if (pageNumber === "next") {
      this.setState(() => ({
        currentPage: this.state.currentPage + 1
      }));
    }
    if (typeof pageNumber === "number") {
      this.setState(() => ({
        currentPage: pageNumber
      }));
    }
    window.scrollTo(0, 600);
  };

  formatDate(date) {
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return (
      month[date.getMonth()] +
      " " +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      ", " +
      date.getFullYear()
    );
  }

  render() {
    // eslint-disable-next-line
    let sideDrawer = null;

    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer />;
    }

    return (
      <div>
        <Helmet>
          <title>
            Autism Telehealth Options for Your Child | Autism Assistant
          </title>
          <meta
            name="description"
            content="Traditional therapy sessions aren’t the only ones available anymore. There are many autism telehealth options out there. We’ll help you find them."
          ></meta>
        </Helmet>
        <PopupLoader display={this.state.pageLoading} />
        <HeaderGuestSimple menu="therapies" />
        <section className="container mt-50">
          <h1 className="text-center aboutUsHeading">
            Autism Telehealth Options for Your Child
          </h1>
          <div className="row our-story-therapy">
            <div className="text-section-therapy">
              {/* <img src={onlineImage1} alt="autism telehealth" /> */}
              <p>
                At Autism Assistant, we’re dedicated to ensuring you have access
                to all the therapies that your child requires, in a fast and
                intuitive manner. We believe that{" "}
                <strong>finding help should be easy</strong>. That’s why, on our
                platform, we have a{" "}
                <strong>ready-to-use database of providers</strong>, which you can{" "}
                <strong>search completely for free!</strong> Simply select the
                specialities which are relevant for you, and we’ll do the rest.
                Moreover, we’re interested in placing as many quality options as
                possible at your disposal, whichever area of autism they may
                refer to. Therefore, we thought it’s only natural that we also
                have a section about autism telehealth.
                
              </p>
            </div>
          </div>
          <div className="mt-50" ref='wrap'>
            <SearchFormOnline />
          </div>
          <div className="row our-story-therapy">
            <div className="text-section-therapy">
              <h2>Does Autism Telehealth Use a Different Approach?</h2>
              <figure className="image-style-side-right">
                <img src={onlineImage2} alt="aba-telehealth" loading="lazy"/>
              </figure>
              <p>
                First off, let’s take into consideration{" "}
                <a href="https://autismassistant.com/autism-therapy-types/153/aba-therapy">
                  ABA therapy
                </a>
                , which is widely recognized by lots of specialists (endorsed by
                both the U.S Surgeon General, as well as the American Academy of
                Pediatrics) to be the therapy that a child with autism should be
                a part of. Its basic principles revolve around your child being
                able to appropriately take in and navigate their surroundings
                via looking, listening, imitating others, making requests, etc.
                As you can imagine, ABA telehealth can achieve these goals too,
                as the interaction between your child and their therapist would
                exist just the same. Even when they’re going to be taught more
                complex skills, such as reading, or applying empathy to
                conversations, you’ll be there to assist as needed, and provide
                certain items that might be required.
              </p>
             
              <div className="button-container">
              <ButtonRoundGradient 
                text="Search for Autism Specialists" 
                onClick={this.scrollToSearch}
                />
              </div>
              <figure className="image-style-side-left">
                <img src={onlineImage3} alt="telehealth bcba" loading="lazy" />
              </figure>
              <p>
                Now don’t think you’d need to have some exotic items on hand.
                The positive reinforcement methods a telehealth{" "}
                <a href="https://www.bacb.com/bcba/" rel="nofollow">BCBA</a> (Board Certified
                Behavior Analyst) would use are common, specifically because the
                child needs to know what they are, and like them. After learning
                a new skill, or showing good behavior, you might play with him
                or her for just a bit, or maybe the telehealth BCBA is already
                applying relaxation methods within the session. Make sure to
                discuss these aspects in detail, so that you can pick the
                perfect provider for your child.
              </p>
              <p>
                To sum it up, ABA telehealth ensures, just like a traditional
                session, that your child is going to reach age-appropriate
                checkpoints, that are customized to their particular needs and
                development. This can range from developing their social skills,
                to achieving better results in an academic session. But what
                about the other{" "}
                <a href="https://autismassistant.com/autism-therapy-types">
                  types of therapies
                </a>
                ? Well, you’ll be happy to hear that many of them can have an
                autism telehealth option.<br></br>
                <br></br>
              </p>
              <figure className="image-style-side-right">
                <img src={onlineImage4} alt="online music therapy" loading="lazy"/>
              </figure>
              <h3>Online Music Therapy</h3>
              <p>
                The only thing your child would require in the case of online
                music therapy, is an instrument. You don’t have to go overboard
                with its specifications either, as the scope here isn’t for them
                to actually learn the instrument, but to connect with the
                therapist through it. The music will not only soothe them
                (lessening outbursts and troublesome behavior), but also help
                them verbalize their thoughts, and help define motor skills such
                as hand-eye coordination.
              </p>
              <br></br>
              <div className="button-container">
              <ButtonRoundGradient 
                text="Search for Autism Specialists" 
                onClick={this.scrollToSearch}
                />
                </div>
              <figure className="image-style-side-left">
                <img src={onlineImage5} alt="online occupational therapy" loading="lazy"/>
              </figure>
              <h3>Online Occupational Therapy</h3>
              <p>
                As with all therapies intended for autism, the main focus point
                of online occupational therapy is going to vary depending on the
                specific case of your child. Still, if you’ve ever taken them to
                a traditional session before, you’ve seen that some standard
                activities can include jumping, drawing, coloring, or making
                shapes out of plasticine - all the activities which can be
                performed at home, and through which your child strengthens
                their muscles, reaches sensory integration, and eventually
                becomes more independent.
              </p>
              <br></br>
              <figure className="image-style-side-right">
                <img src={onlineImage6} alt="online speech therapy" loading="lazy"/>
              </figure>
              <h3>Online Speech Therapy</h3>
              <p>
                Another great therapy option for your child is online speech
                therapy. This might be, in fact, one of the most intuitive ones,
                since the core aspects that it targets are verbal and nonverbal
                communication, which can be easily tackled via telemedicine too!
                Proper grammar, abstract skills, prosody, all these can be
                taught to your child by a specialist in the field, in the
                comfort of your own home.
              </p>
              <br></br>
              
              <h2>Don’t Hesitate to Take Advantage of Autism Telehealth</h2>
              <p>
                As you’ve read above, autism telehealth is as versatile as it is
                useful. If it’s difficult for you to take your child to regular,
                traditional sessions, or if they simply enjoy a home setting
                more, then we strongly advise you to contact the needed
                therapists and get into details with them. On our platform, you
                can <button className="link-button" onClick={this.scrollToSearch}>search for autism
                specialists</button>, for free, because we believe
                finding help should be easy.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  apiData: state.mainBlogReducer,
  userDetails: state.userDetails
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetArticles: bindActionCreators(reqGetArticles, dispatch),
      reqSearchArticles: bindActionCreators(reqSearchArticles, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineTherapies);
