import React from 'react'
import Rating from 'react-rating'
import ratingStarFull from '../../../static/images/desktop/rating-star-full.png'
import ratingStarEmpty from '../../../static/images/desktop/rating-star-empty.png'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import PopupLoader from '../../../components/PopupLoader/PopupLoader'
import { reqPostReview } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'



class ReviewSection extends React.Component {


    state = {
        fullName: '',
        email: '',
        review: '',
        rating: 0,
        errors: [],
        errorMessage: '',
        successMessage: '',
        pageLoading: false
    }

    addReviewUrl = 'therapist/review/add';

    ratingChanged = (newRating) => {
        // console.log("Rating: ", newRating);
        this.setState(() => ({
            rating: newRating
        }))
    }

    // componentDidMount = () => {
    //     console.log("TherapistId: ", this.props.therapistId)
    // }

    componentWillReceiveProps = (newProps) => {
        // console.log("New Props: ", newProps)

        const apiData = newProps.apiData

        if (apiData.review && apiData.review.success) {
            this.setState(() => ({
                successMessage: 'Your review was successfully submitted. It will be displayed on the page once approved by an administrator.',
                pageLoading: false,
                fullName: '',
                email: '',
                review: '',
                rating: 0,
            }))
        }
    }

    handleChange = (event) => {

        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({
            [inputName]: value
        }))
    }

    submitReview = () => {

        this.setState({
            errors: [],
            errorMessage: ''
        }, () => {

            if (!this.validateForm()) return;

            // console.log("Can post review")
            this.setState({
                pageLoading: true
            }, () => { this.reqSubmitReview() })
        })
    }

    reqSubmitReview = () => {
        const rConfig = {
            body: {
                fullName: this.state.fullName,
                stars: this.state.rating,
                review: this.state.review,
                tid: this.props.therapistId,
                email: this.state.email
            },
            headers: null,
            resource: this.addReviewUrl
        }

        this.props.actions.reqPostReview(rConfig);
    }

    validateForm = () => {

        let formValid = true;


        if (this.state.rating === 0) {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'rating'}
                ],
                errorMessage: 'Please select a rating for the therapist by clicking or tapping on the stars above.'
            }))
        } else if (this.state.email === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'email'}
                ],
                errorMessage: 'Email field is mandatory'
            }))
        } else if (this.state.fullName === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'fullName'}
                ],
                errorMessage: 'Name field is mandatory'
            }))
        } else if (this.state.review === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'review'}
                ],
                errorMessage: 'Review field is mandatory'
            }))
        }

        return formValid

    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    }

    render () {
        return (
            <section className="write-review-section">
                <PopupLoader display={this.state.pageLoading} />
                <div className="review-first-row">
                    <div className="half-width">
                        <h3 className="section-title">Reviews</h3>
                    </div>
                    <div className={`half-width ${this.inputHasErrors("fullName")}`}>
                        <Rating emptySymbol={<img src={ratingStarEmpty} className="icon" alt="ratingEmpty" width="21" height="21"/> }
                                fullSymbol={<img src={ratingStarFull} className="icon" alt="ratingFull" width="21" height="21" /> }
                                placeholderSymbol={<img src={ratingStarFull} className="icon" alt="ratingFull" width="21" height="21" />}
                                onChange={this.ratingChanged}
                                initialRating={this.state.rating}
                        />
                    </div>
                </div>
                <form className="review-form">
                    <div className="input-row">
                        <div className="leftside-input-container">
                            <input
                                type="text"
                                name="fullName"
                                className={ `basic-input half-input ${this.inputHasErrors("fullName")}`}
                                value={this.state.fullName}
                                onChange={this.handleChange}
                                placeholder="Name"/>
                        </div>
                        <div className="rightside-input-container">
                            <input
                                type="text"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                className={ `basic-input half-input ${this.inputHasErrors("email")}`}
                                placeholder="Email"/>
                        </div>
                    </div>
                    <div className="review-row">
                        <input
                            type="text"
                            name="review"
                            value={this.state.review}
                            onChange={this.handleChange}
                            className={ `basic-input full-input ${this.inputHasErrors("review")}`}
                            placeholder="Write your review"/>
                    </div>
                    <div className="info-row">
                        <span className="error-message">{this.state.errorMessage}</span>
                        <span className="success-message">{this.state.successMessage}</span>
                    </div>
                    <div className="submit-row">
                        <ButtonRoundGradient
                            text="Submit Review"
                            otherClass="small-btn submit-btn"
                            onClick={this.submitReview}/>
                    </div>
                </form>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.publicProfileReducer
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqPostReview: bindActionCreators(reqPostReview, dispatch),
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSection)

// export default ReviewSection
