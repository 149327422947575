import React from 'react'
import './ShareButtons.scss'
import { Facebook, Twitter, Pinterest } from 'react-sharingbuttons'
import 'react-sharingbuttons/dist/main.css'

const ShareButtons = prop => {

  const url = 'https://autismassistant.com/blog';
   const shareText = 'Check this article!';
   const currentUrl = window.location.href;

    return (
        <div className="share-section">
            <div className="share-sec-title">Share this article</div>
            <div>
        <Facebook url={url} shareText={shareText}/>
        <Twitter url={url} shareText={shareText} />
        <Pinterest url={url} shareText={shareText} />
        <a href={`https://mix.com/add?url=${currentUrl}`} target="_blank"  rel="noopener noreferrer nofollow">
            <button className="mix-button">
                <img src="//static1.squarespace.com/static/5a01d9004c0dbf037353c4d3/t/5a01d95853450ab25a9b2850/1585090773705/?format=1500w" alt="" className="mix-image" width="20" height="20" />
                &nbsp; Mix Platform
            </button>
        </a>
            </div>
        </div>
    )
}

export default ShareButtons
