import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class InsuranceItem  extends React.Component {

    state = {
        name: this.props.insurance.name,
    }

    render() {
        return (
            <li key={this.props.index} className="ins-list-item">
                <div className="ins-li-info">
                    <h4 className="ins-li-name" >{this.state.name}</h4>
                </div>
                <div className="ins-li-actions" >
                    <FontAwesomeIcon icon={faPlus} 
                                     className="add-icon" 
                                     data-tip="Mark insurance as accepted" 
                                     onClick={this.props.attachAction.bind(this, this.props.insurance.id)}/>
                </div>
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }
    
}

export default InsuranceItem