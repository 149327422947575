import React from "react";
import "./NumericInputWithButtons.scss";

const NumericInputWithButtons = (props) => {
  return (
    <div className="numeric-input-container">
      <div className="numeric-input">
        <div className="numeric-input-group">
          <input
            type="button"
            name="-"
            defaultValue="-"
            className="button-minus"
            onClick={props.handleChange}
          />
          <input
            type="number"
            value={props.extraBenefitsNumber}
            onChange={props.handleChange}
            name="quantity"
            className="quantity-field"
          />
          <input
            type="button"
            name="+"
            defaultValue="+"
            className="button-plus"
            onClick={props.handleChange}
          />
        </div>
        <h4>{props.lable}</h4>
      </div>
      <div className="extra-cost">
        <div className="extra-cost-container">
          <div className="cost-input-outer">
            <div className="cost-input-inner">
              <div className="cost-label">Total $</div>
              <div className="cost-input-container">
                <input
                  type="number"
                  placeholder="0"
                  value={props.totalCost}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="extra-cost-btn">
          <button
            type="button"
            className="btn btn-default btn-smalll"
            onClick={props.handleExtrasPay}
          >
            Buy
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumericInputWithButtons;
