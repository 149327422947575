import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_SUPPERT_EMAIL, REQ_UPDATE_SUPPORT_EMAIL,
  recGetSupportEmail, recUpdateSupportEmail} from './actions'
import fetchData from '../../../utils/api'




function* getSupportEmail(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetSupportEmail(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* updateSupportEmail(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recUpdateSupportEmail(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

export default function* supportEmailSaga() {
    yield takeLatest(REQ_GET_SUPPERT_EMAIL, getSupportEmail);
    yield takeLatest(REQ_UPDATE_SUPPORT_EMAIL, updateSupportEmail);
};  