require('dotenv').config()
const baseUrl = process.env.REACT_APP_API_BASE_PATH;

const fetchData = async(rBody, resource, headers, method='POST') => {
   
    try {

        const rMethod = method,
              body = JSON.stringify(rBody),
              rHeaders = (headers) || {"Content-type": "application/json"};
              
        let rConfig = {
            method: rMethod,
            body: rMethod === 'POST' ? body : null,
            headers: rHeaders
        }

        const response = await fetch(`${baseUrl}/${resource}`, rConfig)
        const data = await response.json();
        return data
    } catch (e) {
        console.log("fetch error: ", e)
    }
    
}

export default fetchData
