import React from 'react'
import './HeaderUser.scss'
import logoOnly from '../../static/images/desktop/logo-only.png'
import avatar from '../../static/images/desktop/admin-avatar.png'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reqLogoutUser } from './actions';
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;


class HeaderUser extends React.Component {

    state = {
        displayOptions: false,
        userNameShor: '',
        redirect: false,
        redirectUrl: '',
        profileUrl: '',
        profileImageUrl: '',
        logoutUrl: '',
        logout: false
    }

    user = {}

    componentDidMount = () => {
        this.updateStateBasedOnProps(this.props);
    }

    generateProfileImageUrl = (user) => (
        user.profileImageName
            ? `${baseBackendUrl}/${user.profileImageName}`
            : ''
    )

    updateStateBasedOnProps = (props) => {
        this.user = props.userDetails || {};
        const fullName = `${this.user.firstName} ${this.user.lastName}` || '',
              nameShort = fullName.split(' ')
                             .map(part => part.substring(0, 1))
                             .join(' ');

        let logoutUrl = '',
            profileUrl = '',
            avatarUrl = this.generateProfileImageUrl(this.user)

        // console.log("user in header: ", this.user)

        if (!this.user.token) return ;
        // Configure header links based on user role
        if (this.user.roleId === 1) {
            logoutUrl = '/admin/signin';
            profileUrl = '/admin/profile';
        } else if (this.user.roleId === 3) {
            logoutUrl = '/signin';
            profileUrl = '/profile';
        } else if (this.user.roleId === 4) {
            logoutUrl = '/signin';
            profileUrl = '/blogger-profile';
        }

        this.setState( () => ({
            userNameShor: nameShort,
            logoutUrl: logoutUrl,
            profileUrl: profileUrl,
            avatarUrl: avatarUrl
        }))
    }

    componentWillReceiveProps = (newProps) => {
        this.updateStateBasedOnProps(newProps);
    }

    displayPanel = () => {
        return this.state.displayOptions ? 'visible-item' : 'hidden-item'
    }

    displayOptions = () => {
        this.setState( () => ({displayOptions: true}))
    }

    hideOptions = () => {
        this.setState( () => ({displayOptions: false}))
    }

    logoutUser = () => {
        this.props.actions.reqLogoutUser({})
        localStorage.removeItem('signedUp');
        this.setState(() => ({
            logout: true
        }))
    }

    getUserAvatar = () => (
        this.state.avatarUrl ? this.state.avatarUrl : avatar
    )

    render () {

        if (this.state.logout) {
            return (
                <Redirect to={this.state.logoutUrl} />
            )
        }

        return (
            <div id="header-user">
                <Link to="/">
                    <img src={logoOnly} alt="logoOnly" className="logo align-self" width="230" height="100" />
                </Link>
                <div className="header-content">
                    <div className="spacer"></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
})

const mapDispatchToProps = (dispatch) => ({
    actions: {
        reqLogoutUser: bindActionCreators(reqLogoutUser, dispatch),
    }
})

export default connect( mapStateToProps,mapDispatchToProps)(HeaderUser)
