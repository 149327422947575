export const REQ_ACTIVATE_ACCOUNT = 'REQ_ACTIVATE_ACCOUNT'
export const REC_ACTIVATE_ACCOUNT = 'REC_ACTIVATE_ACCOUNT'

export const reqActivateAccount = (data, resource) => {
    return  {
        type: REQ_ACTIVATE_ACCOUNT,
        data,
        resource
    }
}

export const recActivateAccount = data => ({type: REC_ACTIVATE_ACCOUNT, data})


