export const REQ_GET_ALL_INSURANCES = 'REQ_GET_ALL_INSURANCES'
export const REC_GET_ALL_INSURANCES = 'REC_GET_ALL_INSURANCES'

export const REQ_ADD_INSURANCE_THERAPIST = 'REQ_ADD_INSURANCE_THERAPIST'
export const REC_ADD_INSURANCE_THERAPIST = 'REC_ADD_INSURANCE_THERAPIST'

export const REQ_REMOVE_INSURANCE_THERAPIST = 'REQ_REMOVE_INSURANCE_THERAPIST'
export const REC_REMOVE_INSURANCE_THERAPIST = 'REC_REMOVE_INSURANCE_THERAPIST'


export const reqGetAllInsurances = (config) => ({type: REQ_GET_ALL_INSURANCES, config: config})
export const recGetAllInsurances = data => ({type: REC_GET_ALL_INSURANCES, data})

export const reqAddInsurance = (config) => ({type: REQ_ADD_INSURANCE_THERAPIST, config: config})
export const recAddInsurance = data => ({type: REC_ADD_INSURANCE_THERAPIST, data})

export const reqRemoveInsurance = (config) => ({type: REQ_REMOVE_INSURANCE_THERAPIST, config: config})
export const recRemoveInsurance = data => ({type: REC_REMOVE_INSURANCE_THERAPIST, data})

 