import React from 'react'

const ButtonRoundGradient = props => (
    <button
        type="button"
        className={"btn btn-default " + (props.otherClass ? props.otherClass : "")}
        onClick={props.onClick}>{props.text}</button>
);

export default ButtonRoundGradient
