import React from 'react'
import './SidebarAdmin.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog, faUserMd,faUserPlus, faHome,faBlog, faUsersCog, faClipboardList, faStarHalfAlt, faListAlt , faEnvelope, faUserShield} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

class SidebarAdmin extends React.Component {

    state = {
        displaySidebar: true
    }
    
    toggleSidebar = () => {
        this.setState((prevState) => ({
            displaySidebar: !prevState.displaySidebar
        }))
    }

    sidebarVisible = () => (
        this.state.displaySidebar ? '' : 'sidebar-hidden'
    )

    componentWillReceiveProps = (newProps) => {
        
        if (window.innerWidth < 773) {
            this.setState(() => ({displaySidebar: false}))
        }
        
    }

    render = () => (
        
        <div id="admin-sidebar">

            <div className={`sdb-hamburger ${this.state.displaySidebar ? 'hamburger-open' : ''}`} onClick={this.toggleSidebar} >
                    <div className="sd-h-row"></div>
                    <div className="sd-h-row"></div>
                    <div className="sd-h-row"></div>
            </div>

            <ul className={`sidebar-items ${this.sidebarVisible()}`}>
                {/* Dashboard */}
                <Link to="/admin/dashboard">
                    <li className={`sidebar-item ${this.props.page === 'dashboard' ? 'active-item' : ''}`} 
                        data-tip="Dashboard">
                        <FontAwesomeIcon icon={faHome} />
                    </li>
                </Link>
                {/* Profile */}
                <Link to="/admin/profile">
                    <li className={`sidebar-item ${this.props.page === 'profile' ? 'active-item' : ''}`}
                        data-tip="Profile">
                        <FontAwesomeIcon icon={faUserCog} />
                    </li>
                </Link>
                {/* Create Admin */}
                <Link to="/admin/create/admin">
                    <li className={`sidebar-item ${this.props.page === 'create-admin' ? 'active-item' : ''}`}
                        data-tip="Add User">
                        <FontAwesomeIcon icon={faUserPlus} />
                    </li>
                </Link>
                {/* Manage Admins */}
                <Link to="/admin/manage/admins">
                    <li className={`sidebar-item ${this.props.page === 'admins' ? 'active-item' : ''}`}
                        data-tip="Manage Admins">
                        <FontAwesomeIcon icon={faUserShield} />
                    </li>
                </Link>
                {/* Therapists */}
                <Link to="/admin/manage/therapists">
                    <li className={`sidebar-item ${this.props.page === 'therapists' ? 'active-item' : ''}`}
                        data-tip="Manage Therapists">
                        <FontAwesomeIcon icon={faUserMd} />
                    </li>
                </Link>

                {/* Manage Bloggers */}
                <Link to="/admin/manage/bloggers">
                    <li className={`sidebar-item ${this.props.page === 'bloggers' ? 'active-item' : ''}`}
                        data-tip="Manage Bloggers">
                        <FontAwesomeIcon icon={faUsersCog} />
                    </li>
                </Link>

                {/* Specialities */}
                <Link to="/admin/manage/specialities">
                    <li className={`sidebar-item ${this.props.page === 'specialities' ? 'active-item' : ''}`}
                        data-tip="Manage Specialities">
                        <FontAwesomeIcon icon={faClipboardList} />
                    </li>
                </Link>
                
                {/* Insurances */}
                {/* <Link to="/admin/manage/insurances">
                    <li className={`sidebar-item ${this.props.page === 'insurances' ? 'active-item' : ''}`}
                        data-tip="Manage Insurances">
                        <FontAwesomeIcon icon={faFileAlt} />
                    </li>
                </Link> */}
                
                {/* Therapist Reviews */}
                <Link to="/admin/manage/reviews/therapists">
                    <li className={`sidebar-item ${this.props.page === 'reviews-therapists' ? 'active-item' : ''}`}
                        data-tip="Manage Therapist Reviews">
                        <FontAwesomeIcon icon={faStarHalfAlt} />
                    </li>
                </Link>
                {/* Article Reviews */}
                <Link to="/admin/manage/reviews/articles">
                    <li className={`sidebar-item ${this.props.page === 'reviews-articles' ? 'active-item' : ''}`}
                        data-tip="Manage Article Reviews">
                        <FontAwesomeIcon icon={faListAlt} />
                    </li>
                </Link>
                <Link to="/admin/manage/articles">
                    <li className={`sidebar-item ${this.props.page === 'articles' ? 'active-item' : ''}`} 
                        data-tip="Resources">
                        <FontAwesomeIcon icon={faBlog} />
                    </li>
                </Link>
                {/* Support Email */}
                <Link to="/admin/manage/support/email">
                    <li className={`sidebar-item ${this.props.page === 'support-email' ? 'active-item' : ''}`}
                        data-tip="Manage Support Email">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </li>
                </Link>
            </ul>
            <ReactTooltip type="info" place="right" />
        </div>
    )
    
}

export default SidebarAdmin