import React from 'react'
import './SidebarBlogger.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import { faBlog } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

class SidebarBlogger extends React.Component {

    state = {
        displaySidebar: true
    }

    toggleSidebar = () => {
        this.setState((prevState) => ({
            displaySidebar: !prevState.displaySidebar
        }))
    }

    sidebarVisible = () => (
        this.state.displaySidebar ? '' : 'sidebar-hidden'
    )

    componentWillReceiveProps = (newProps) => {
        
        if (window.innerWidth < 773) {
            this.setState(() => ({displaySidebar: false}))
        }
        
    }

    pageList = [
        { url: 'blogger/profile', pageName: 'account', dataTip: 'Dashboard', icon: faUserCircle, roleIds: [4] },
        { url: 'blogger/details', pageName: 'details', dataTip: 'Profile Details', icon: faIdCard, roleIds: [4] },
        { url: 'articles', pageName: 'articles', dataTip: 'Your Resources', icon: faBlog, roleIds: [4]},
    ]

    render () {
        return (
            <div id="therapist-sidebar">
                
                <div className={`sdb-hamburger ${this.state.displaySidebar ? 'hamburger-open' : ''}`} onClick={this.toggleSidebar} >
                        <div className="sd-h-row"></div>
                        <div className="sd-h-row"></div>
                        <div className="sd-h-row"></div>
                </div>

                <ul className={`sidebar-items ${this.sidebarVisible()}`}>

                    <Link to="blogger-profile">
                        <li className={`sidebar-item ${this.props.page === 'account' ? 'active-item' : ''}`} 
                            data-tip="Dashboard">
                            <FontAwesomeIcon icon={faUserCircle} />
                        </li>
                    </Link>

                    <Link to="blogger-articles">
                        <li className={`sidebar-item ${this.props.page === 'articles' ? 'active-item' : ''}`} 
                            data-tip="Your Resources">
                            <FontAwesomeIcon icon={faBlog} />
                        </li>
                    </Link>
                </ul>

                <ReactTooltip type="info" place="right"/>
            </div>
        )
    }
    
}

export default SidebarBlogger