import React from 'react'
// import Rating from 'react-rating'
// import ratingStarFull from '../../../static/images/desktop/rating-star-full.png'
// import ratingStarEmpty from '../../../static/images/desktop/rating-star-empty.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const renderActions = props => {
    
    switch(props.review.status) {
        case 0: 
            return renderAllActions(props)
        
        case 1:
            return renderDeclineAction(props)
        
        case -1:
            return renderAcceptAction(props)
        
        default: console.log("-")
    }
}

const renderAllActions = (props) => (

    <div className="rev-actions-container">
        <FontAwesomeIcon icon={faCheck} 
                         onClick={props.handleAcceptReview.bind(this, props.review.id)}
                         className="accept-icon" 
                         data-tip="Accept" />
                 
        <FontAwesomeIcon icon={faTimes} 
                         onClick={props.handleDeclineReview.bind(this, props.review.id)}
                         className="decline-icon" 
                         data-tip="Decline" />

        <FontAwesomeIcon icon={faTrash} 
                         onClick={props.handleDeleteReview.bind(this, props.review.id)}
                         className="delete-icon" 
                         data-tip="Delete" />
    </div>   
)

const renderDeclineAction = (props) => (
    <div className="rev-actions-container">         
        <FontAwesomeIcon icon={faTimes} 
                         onClick={props.handleDeclineReview.bind(this, props.review.id)}
                         className="decline-icon" 
                         data-tip="Decline" />
    </div>  
    
)

const renderAcceptAction = (props) => (
    <div className="rev-actions-container">         
        <FontAwesomeIcon icon={faCheck} 
                         onClick={props.handleAcceptReview.bind(this, props.review.id)}
                         className="accept-icon" 
                         data-tip="Accept" />
         <FontAwesomeIcon icon={faTrash} 
                         onClick={props.handleDeleteReview.bind(this, props.review.id)}
                         className="delete-icon" 
                         data-tip="Delete" />
    </div>  
)



const ArticleReviewCard = props => (
    <div className="rev-card-manage">
        {/* Content Section */}
        <div className="rev-card-content">
            {/* Title Row */}
            <div className="rev-card-title">
                <div className="from-container">
                    From: &nbsp;
                    <span className="rev-card-value">{props.review.fullName} </span>
                    <span className="rev-card-email">{ props.review.email ? `(${props.review.email})` : '' }</span>
                </div>
                <div className="to-container">
                    Article: &nbsp;
                    <Link to={`/blog/${props.review.articleId}/${props.review.title.toLowerCase().replace(/ /g, "-").replace(/[,?!]/g, "")}`}>
                        <span className="rev-card-value">{props.review.title}</span>
                    </Link>  
                </div>
            </div>
            <div className="rev-card-body">
            {/* Body Row */}
            {props.review.reviewId ?
                (
                <div className="rev-card-review">
                    <div className="review-label">Comment:</div>
                    <div className="review-content">{props.review.comment}</div>
                </div>) : ""}
                {/* Review Section */}
                <div className="rev-card-review">
                    <div className="review-label">{props.review.reviewId ? "Reply:":"Review:"}</div>
                    <div className="review-content">{props.review.reviewId ? props.review.reply : props.review.comment}</div>
                </div>
            </div>

        </div>
        {/* Actions Section */}
        <div className="rev-card-actions">
            {renderActions(props)}
        </div>
    </div>
)

export default ArticleReviewCard