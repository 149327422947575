import React from "react";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";

class NotFound extends React.Component {
  state = { sideDrawerOpen: false };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  render() {

    return (
      <div id="404">
        <HeaderGuestSimple
          menu="404"
          menuVisible={this.state.sideDrawerOpen}
          drawerClickHandler={this.drawerToggleClickHandler}
        />
        <section className="container mt-80">
          <h1 className="page-title">404 - Page Not Found</h1>
        </section>
      </div>
    );
  }
}

export default NotFound;
