export const REQ_FETCH_USER_DETAILS = 'REQ_FETCH_USER_DETAILS'
export const REC_FETCH_USER_DETAILS = 'REC_FETCH_USER_DETAILS'


export const reqFetchUserDetails = (config) => {
    // console.log("Fetch user details action called: ", config)
    return {
        type: REQ_FETCH_USER_DETAILS,
        config: config
    }
}

export const recFetchUserDetails = data => ({type: REC_FETCH_USER_DETAILS, data})
