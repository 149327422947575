import React from "react";
import TherapistCard from "../../components/TherapistCard/TherapistCard";
import RoundInputGradient from "../../components/RoundInputGradient/RoundInputGradient";
import PopupLoader from "../../components/PopupLoader/PopupLoader";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  reqGetTherapistsTopProfiles,
  reqGetTherapistByNameGuest,
} from "./actions";

import "./TherapistProfiles.scss";

class TherapistsList extends React.Component {
  state = {
    page: 1,
    therapists: [],
    hasMore: true,
    resultsTitle: "Top Therapist Profiles",
    pageLoading: true,
  };

  topProfilesUrl = "therapists/top/profiles";
  searchByNameUrl = "therapists/search/name";

  componentDidMount = () => {
    this.scrollToTop();
    this.getTopTherapists();
  };

  componentWillReceiveProps = (newProps) => {
    let apiData = newProps.apiData;

    // console.log("api data specialists: ", apiData)

    if (apiData.topProfiles && apiData.topProfiles.success) {
      let therapists = apiData.topProfiles.therapists;
      let hasMore = true;

      if (therapists.length < 10) {
        hasMore = false;
      }

      this.setState(() => ({
        therapists: this.state.therapists.concat(therapists),
        hasMore: hasMore,
        pageLoading: false,
      }));
    } else if (apiData.therapistsByName && apiData.therapistsByName.success) {
      let therapists = apiData.therapistsByName.therapists,
        resTitle = therapists.length
          ? `Results for "${this.state.searchName}"`
          : `Looks like there are no results for "${this.state.searchName}"`;

      this.setState(() => ({
        therapists: therapists,
        hasMore: false,
        pageLoading: false,
        resultsTitle: resTitle,
      }));
    }
  };

  getTopTherapists = () => {
    this.setState({ pageLoading: true }, () => {
      this.loadTopTherapists();
    });
  };

  scrollToTop = () => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });  
      setTimeout(() => {
        this.setState(() => ({
          pageLoading: false,
        }));
      }, 100);
    }, 200);
  };

  loadTopTherapists = () => {
    this.props.actions.reqGetTherapistsTopProfiles({
      body: {},
      headers: null,
      resource: `${this.topProfilesUrl}?page=${this.state.page}`,
    });
  };

  renderTherapists = () => {
    const items = this.state.therapists.map((t, idx) => (
      <TherapistCard therapist={t} key={idx} />
    ));

    return (
      <div ref={0} className="therapist-list">
        {items}
      </div>
    );
  };

  loadNextPage = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getTopTherapists();
      }
    );
  };

  noMoreData = () => (this.state.hasMore ? "" : "invisible-btn");

  searchTherapistsByName = () => {
    if (!this.state.searchName) return;

    this.setState({ pageLoading: true, therapists: [] }, () => {
      this.performTherapistSearch();
    });
  };

  performTherapistSearch = () => {
    this.props.actions.reqGetTherapistByNameGuest({
      resource: `${this.searchByNameUrl}?fullName=${this.state.searchName}`,
      body: {},
      headers: null,
    });
  };

  nameInputChanged = (ev) => {
    let val = ev.target.value;
    this.setState(() => ({
      searchName: val,
    }));
  };

  render() {
    return (
      <div id="therapist-profiles">
        <PopupLoader display={this.state.pageLoading} />

        {/* Search By Name Section */}
        <div className="search-by-name-section">
          {/* Section Title */}
          <h1 className="page-title">Search By Name</h1>
          <div className="search-by-name-input-container">
            <RoundInputGradient
              className="search-by-name-input"
              onChange={this.nameInputChanged}
              placeholder="Name or Business Name"
              action={this.searchTherapistsByName}
            />
            <div
              className="search-button-container"
              onClick={this.searchTherapistsByName}
            >
              <div className="btn btn-default search-by-name-btn">Search</div>
            </div>
          </div>
        </div>
        <div className="therapist-profiles-content">
          <h1 className="page-title">{this.state.resultsTitle}</h1>
          {this.renderTherapists()}
          <div
            className={`btn btn-default load-more-btn ${this.noMoreData()}`}
            onClick={this.loadNextPage}
          >
            Load More
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.topProfiles,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetTherapistsTopProfiles: bindActionCreators(
        reqGetTherapistsTopProfiles,
        dispatch
      ),
      reqGetTherapistByNameGuest: bindActionCreators(
        reqGetTherapistByNameGuest,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapistsList);
