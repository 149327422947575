import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_SAVE_STRIPE_CUSTOMER, REQ_FETCH_STRIPE_CUSTOMER, REQ_REMOVE_USER_CARD, 
        REQ_LIST_MEMBERSHIP_PLANS, REQ_SUBSCRIBE_USER_TO_PLAN, REQ_CUSTOMER_SWITCH_PLAN,
        REQ_CANCEL_CUSTOMER_SUBSCRIPTION, REQ_BUY_EXTRA_BENEFITS,
        recSaveStripeCustomer, recFetchStripeCustomer, recRemoveUserCard, recListMembershipPlans, 
        recSubscribeUserToPlan, recCustomerSwitchPlan, recCancelCustomerSubscription, recBuyExtraBenefits } from './actions'
import fetchData from '../../../utils/api'
import mergeInUserDetails from '../../../utils/mergeInUserDetails'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'

  function* saveStripeCustomer(action) {
    try {
      
      const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    
     if (data.success) {
       const newUserDetails = {...data};
       delete newUserDetails.success
       const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      //  console.log("merged details: ", mergedUserDetails)
       yield put(recFetchUserDetails(mergedUserDetails))
     }
      yield put(recSaveStripeCustomer(data))
     
    } catch (e) {console.log("Api error: ", e)}
  }

  function* fetchStripeCustomer(action) {
    try {
      const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
      yield put(recFetchStripeCustomer(data))
     
    } catch (e) {console.log("Api error: ", e)}
  }

  function* removeUserCard(action) {
    try {
      const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
      if (data.success) {
        const newUserDetails = {
          cardData: null,
          planData: null
        };
        // delete newUserDetails.success
        const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
        yield put(recFetchUserDetails(mergedUserDetails))
      }
      yield put(recRemoveUserCard(data))
     
    } catch (e) {console.log("Api error: ", e)}
  }

  function* listMembershipPlans(action) {
    try {
      const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
      yield put(recListMembershipPlans(data))
     
    } catch (e) {console.log("Api error: ", e)}
  }

  function* subscribeToPlan(action) {
    try {
      const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
      
      // console.log("data in payment: ", data)

      if (data.success) {
        const newUserDetails = {
          planData: {id: data.planId},
          membershipPlan: data.membershipPlan
        };
        // delete newUserDetails.success
        const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
        yield put(recFetchUserDetails(mergedUserDetails))
      }
      yield put(recSubscribeUserToPlan(data))
     
    } catch (e) {console.log("Api error: ", e)}
  }

  function* buyExtraBenefits(action) {
    try {
      const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
      
      // console.log("data in payment: ", data)

      if (data.success) {
        const newUserDetails = {
          extraLocations: data.paidExtraLocations
        };
        const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
        yield put(recFetchUserDetails(mergedUserDetails))
      }
      yield put(recBuyExtraBenefits(data))
     
    } catch (e) {console.log("Api error: ", e)}
  }

  function* switchPlan(action) {
    try {
      const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
      if (data.success) {
        const newUserDetails = {
          planData: {id: data.planId},
          membershipPlan: data.membershipPlan
        };
        // delete newUserDetails.success
        const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
        yield put(recFetchUserDetails(mergedUserDetails))
      }
      yield put(recCustomerSwitchPlan(data))
     
    } catch (e) {console.log("Api error: ", e)}
  }

  function* cancelSubsscription(action) {
    try {
      const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
      if (data.success) {
        const newUserDetails = {
          planData: null,
          membershipPlan: 'free'
        };
        // delete newUserDetails.success
        const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
        yield put(recFetchUserDetails(mergedUserDetails))
      }
      yield put(recCancelCustomerSubscription(data))
     
    } catch (e) {console.log("Api error: ", e)}
  }



  export default function* therapistManagePayment() {
    yield takeLatest(REQ_SAVE_STRIPE_CUSTOMER, saveStripeCustomer)
    yield takeLatest(REQ_FETCH_STRIPE_CUSTOMER, fetchStripeCustomer)
    yield takeLatest(REQ_REMOVE_USER_CARD, removeUserCard)
    yield takeLatest(REQ_LIST_MEMBERSHIP_PLANS, listMembershipPlans)
    yield takeLatest(REQ_SUBSCRIBE_USER_TO_PLAN, subscribeToPlan)
    yield takeLatest(REQ_CUSTOMER_SWITCH_PLAN, switchPlan)
    yield takeLatest(REQ_CANCEL_CUSTOMER_SUBSCRIPTION, cancelSubsscription)
    yield takeLatest(REQ_BUY_EXTRA_BENEFITS, buyExtraBenefits)
}