import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
// import { faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
require('dotenv').config()
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class ArticleItem  extends React.Component {
    admin = this.props.userDetails;
    state = {
        title: this.props.article.title,
        author: this.props.article.author,
        body: this.props.article.body.replace(/(<([^>]+)>)/ig, "").substring(0, 255),
    };

    

    componentWillReceiveProps = (newProps) => {
        this.setState({
            title: newProps.article.title,
            body: newProps.article.body.replace(/(<([^>]+)>)/ig, "").substring(0, 255),
        })
    };
    escapeHtml = (unsafe) => {
        return unsafe
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };
    render() {
        const dangerousHtmlArticleContent = {
            __html: this.state.body,
        };
        return (
            <li key={this.props.index} className="article-list-item">
                <div className="article-li-info">
                    {/* Thumbnail */}
                    <div className="thumbnail-container">
                        <img src={`${baseBackendUrl}/articleContentImage/${this.props.article.thumbnail_url}`} alt="articleImg" loading="lazy" />
                    </div>
                    {/* Data */}
                    <div className="data-container">
                        <h4 className="article-li-title">{this.state.title || ""}</h4>
                        <div className="article-li-details">{ moment(this.props.article.published).format('LL')}</div>
                        <div className="article-li-details"><p>Submitted by: <span>{this.props.article.authorFirstName? this.props.article.authorFirstName + " " + this.props.article.authorLastName : this.props.article.authorBusinessName}</span></p></div>

                        <div className="article-li-body" dangerouslySetInnerHTML={dangerousHtmlArticleContent}/>
                    </div>

                </div>
                <div className="art-item-actions-container">
                    <div className="article-li-actions mb-3">
                        <FontAwesomeIcon icon={faThumbsUp}
                                         className={`accept-icon ` + (this.props.article.status === 1 ? 'green' : '') }
                                         data-tip="Accept Article"
                                         onClick={this.props.acceptAction.bind(this, this.props.article.id)} />
                        <FontAwesomeIcon icon={faThumbsDown}
                                         className={`decline-icon ` + (this.props.article.status === -1 ? 'red' : '') }
                                         data-tip="Decline Article"
                                         onClick={this.props.declineAction.bind(this, this.props.article.id)} />
                    </div>
                    <div className="article-li-actions">
                        <FontAwesomeIcon icon={faPencilAlt}
                                         className="edit-icon"
                                         data-tip="Edit Article"
                                         onClick={this.props.editAction.bind(this, this.props.article)} />

                        <FontAwesomeIcon icon={faTimesCircle}
                                         className="delete-icon"
                                         data-tip="Delete Article"
                                         onClick={this.props.removeAction.bind(this, this.props.article.id)} />
                    </div>
                </div>
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }

}

export default ArticleItem
