import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_LIST_ADMINS, REQ_SEARCH_ADMINS, REQ_ADMIN_LOCK, REQ_ADMIN_DELETE,
  recListAdmins, recSearchAdmins, recAdminLock, recAdminDelete} from './actions'
import fetchData from '../../../utils/api'




function* listAdmins(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListAdmins(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* searchAdmins(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recSearchAdmins(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* lockUnLockAdmin(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recAdminLock(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* deleteAdmin(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recAdminDelete(data))
   
  } catch (e) {console.log("Api error: ", e)}
}



export default function* manageAdminsSaga() {
    yield takeLatest(REQ_LIST_ADMINS, listAdmins);
    yield takeLatest(REQ_SEARCH_ADMINS, searchAdmins);
    yield takeLatest(REQ_ADMIN_LOCK, lockUnLockAdmin);
    yield takeLatest(REQ_ADMIN_DELETE, deleteAdmin);
    
};