import React from 'react'
import { withRouter } from 'react-router-dom';
import Header from "../HeaderUser/Header";
import './ThankYouContact.scss';
import { Link } from 'react-router-dom';

//const PROFILE_ROUT = '/profile';

const DEFAULT_MESSAGE = "Thank you for being a part of our community! We're excited to have you on our platform and place our services at your disposal.";

function ThankYou(props) {
    let message = DEFAULT_MESSAGE;
    const signedUp = localStorage.getItem('signedUp');
    const {redirectUrl} = props.location.state;

    if (signedUp) {
        props.history.push(redirectUrl)
    }

    if(props.location.state) {
        message = props.location.state.message;
    }

    return (
        <div className='thankYouContainer'>
            <Header/>
            <div className='thankYouContent'>
                <p style={{whiteSpace: "pre-wrap"}}>{message}</p>
                <h2>Where Would You Like To Go?</h2>
                <ul className={"menu" + (props.menuVisible === true ? ' visible' : '')}>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about-us">About</Link></li>
                    <li><Link to="/autism-therapy-types">Therapies</Link></li>
                    <li><Link to="/specialists">Specialists</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                </ul>
            </div>
        </div>
    );
}

export default withRouter(ThankYou);
