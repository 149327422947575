import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_UPDATE_PROFILE, REQ_UPDATE_ADMIN_PASSWORD,
  recUpdateProfile, recUpdateAdminPassword } from './actions'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'
import mergeInUserDetails from '../../../utils/mergeInUserDetails'

import fetchData from '../../../utils/api'


function* updateProfile(action) {
  
  try {
    // console.log("saga called: ", action.headers)
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recUpdateProfile(data))
    
    if (data.success) {
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, action.config.body)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
   
  } catch (e) {
      console.log("Api error: ", e)
   }

}

function* updatePassword(action) {
  
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recUpdateAdminPassword(data))
   
  } catch (e) {
      console.log("Api error: ", e)
   }

}

export default function* adminUpdateProfile() {
    yield takeLatest(REQ_UPDATE_PROFILE, updateProfile);
    yield takeLatest(REQ_UPDATE_ADMIN_PASSWORD, updatePassword);
};