import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import './ManageArticleReviews.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'
import { reqListArticleReviewsManagement, reqListReviewsRepliesManagement, reqToggleArticleReviewStatusManagement, 
    reqToggleReviewReplyStatusManagement, reqDeleteReply, reqDeleteReview } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import ArticleReviewCard from './ArticleReviewCard'
import PopuConfirm from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper from '../MainWrapper/MainWrapper'

const REVIEWS = 'reviews',
      ACTIVE_TAB = 'active-tab',
      INACTIVE_TAB = 'inactive-tab',
      PENDING = 'pending',
      ACCEPTED = 'accepted',
      DECLINED = 'declined',
      LIST_REVIEW_URL = 'admin/article/reviews/list',
      ACCEPT_REVIEW_URL = 'admin/article/review/accept',
      DECLINE_REVIEW_URL = 'admin/article/review/decline',
      DELETE_REVIEW_URL = 'admin/article/review/delete',
      LIST_REPLIES_URL = 'admin/article/replies/list',
      ACCEPT_REPLY_URL = 'admin/article/reply/accept',
      DECLINE_REPLY_URL = 'admin/article/reply/decline',
      DELETE_REPLY_URL = 'admin/article/reply/delete'

class ManageReviewsTherapists extends React.Component {

    state = {
        pageLoading: false,
        filter: PENDING,
        activeTabItem: REVIEWS,
        reviews: [],
        replies: []
    }

    admin = {}
    authHeader = {}


    componentDidMount = () => {

        this.admin = this.props.userDetails;
        this.authHeader = {  
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json" 
        }

        this.loadReviews();
        this.loadReplies();
    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;

        if (apiData.articleRevList && apiData.articleRevList.success) {
            const reviews = apiData.articleRevList.reviews;

            this.setState(() => ({
                reviews: reviews,
                pageLoading: false
            }))
        } else if (apiData.articleRevStatusUpdated && apiData.articleRevStatusUpdated.success) {
            this.loadReviews()
        } else if (apiData.reviewDeleted && apiData.reviewDeleted.success) {
            this.loadReviews()
        } else if (apiData.articleRepliesList && apiData.articleRepliesList.success) {
            const replies = apiData.articleRepliesList.replies;

            this.setState(() => ({
                replies: replies,
                pageLoading: false
            }))
        } else if (apiData.articleReplyStatusUpdated && apiData.articleReplyStatusUpdated.success) {
            this.loadReplies()
        } else if (apiData.replyDeleted && apiData.replyDeleted.success) {
            this.loadReplies()
        }
    }

    filterActive = (filter) => {
        return this.state.filter === filter ? 'active-filter' : '';
    }

    tabItemActive = tabName => tabName === this.state.activeTabItem ? ACTIVE_TAB : INACTIVE_TAB;

    switchTab = tabName => {
        this.setState({
            activeTabItem: tabName
        }, () => { this.state.activeTabItem === REVIEWS? 
        this.loadReviews() : this.loadReplies()
        })
        
      };

    applyFilter = (filter) => {
      
        this.setState({
            filter: filter
        }, () => { this.state.activeTabItem === REVIEWS? 
        this.loadReviews() : this.loadReplies()
        })
    }

    renderTabContent = () =>
    this.state.activeTabItem === REVIEWS
      ? this.renderReviewsTab()
      : this.renderRepliesTab();

    loadReviews = () => {

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqListArticleReviewsManagement({
                body: {},
                headers: this.authHeader,
                resource: `${LIST_REVIEW_URL}?type=${this.state.filter}`
            })
        })
    }

    loadReplies = () => {
        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqListReviewsRepliesManagement({
                body: {},
                headers: this.authHeader,
                resource: `${LIST_REPLIES_URL}?type=${this.state.filter}`
            })
        })
    }

    renderReviewsTab = () => {
        const items = this.state.reviews.map( (r, i) => (
            <ArticleReviewCard 
                key={i} 
                review={r} 
                handleAcceptReview={ this.handleAcceptReview }
                handleDeclineReview={ this.handleDeclineReview }
                handleDeleteReview={ this.handleDeleteReview }
            />
        ))

        const content = items.length ? items : (<div className="no-review-text">{`No ${this.state.filter} reviews at this time.`}</div>)

        return (
            <div className="reviews-container">
                { content }
            </div>
        )

    }

    renderRepliesTab = () => {
        const items = this.state.replies.map( (r, i) => (
            <ArticleReviewCard 
                key={i} 
                review={r} 
                handleAcceptReview={ this.handleAcceptReply }
                handleDeclineReview={ this.handleDeclineReply }
                handleDeleteReview={ this.handleDeleteReply }
            />
        ))

        const content = items.length ? items : (<div className="no-review-text">{`No ${this.state.filter} replies at this time.`}</div>)

        return (
            <div className="reviews-container">
                { content }
            </div>
        )

    }

    handleAcceptReview = (reviewId) => {

        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Publish Review',
            popupText: 'Are you sure you want to accept and publish this review?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmToggleStatus,
            revId: reviewId,
            status: 1
        }))

    }

    handleDeclineReview = (reviewId) => {
        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Decline Review',
            popupText: 'Are you sure you want to decline this review?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmToggleStatus,
            revId: reviewId,
            status: -1
        }))
    }

    handleDeleteReview = (reviewId) => {
        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Delete Review',
            popupText: 'Are you sure you want to delete this Review?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmDeleteReview,
            reviewId: reviewId,
        }))
    }

    confirmToggleStatus = () => {

        this.props.actions.reqToggleArticleReviewStatusManagement({
            body: {
                rId: this.state.revId
            },
            headers: this.authHeader,
            resource: this.state.status === -1 ? DECLINE_REVIEW_URL : ACCEPT_REVIEW_URL
        })
    }

    confirmDeleteReply = () => {
        this.props.actions.reqDeleteReply({
            body: {
                rId: this.state.replyId
            },
            headers: this.authHeader,
            resource: this.deleteReplyUrl
        })
    }

    confirmDeleteReview = () => {
        this.props.actions.reqDeleteReview({
            body: {
                rId: this.state.reviewId
            },
            headers: this.authHeader,
            resource: DELETE_REVIEW_URL
        })
    }

    handleAcceptReply = (replyId) => {

        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Publish Reply',
            popupText: 'Are you sure you want to accept and publish this reply?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmReplyToggleStatus,
            replyId: replyId,
            status: 1
        }))

    }

    handleDeclineReply = (replyId) => {
        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Decline Reply',
            popupText: 'Are you sure you want to decline this reply?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmReplyToggleStatus,
            replyId: replyId,
            status: -1
        }))
    }

    handleDeleteReply = (replyId) => {
        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Delete Reply',
            popupText: 'Are you sure you want to delete this reply?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmDeleteReply,
            replyId: replyId,
        }))
    }

    confirmReplyToggleStatus = () => {

        this.props.actions.reqToggleReviewReplyStatusManagement({
            body: {
                rId: this.state.replyId
            },
            headers: this.authHeader,
            resource: this.state.status === -1 ? DECLINE_REPLY_URL : ACCEPT_REPLY_URL
        })
    }

    confirmDeleteReply = () => {
        this.props.actions.reqDeleteReply({
            body: {
                rId: this.state.replyId
            },
            headers: this.authHeader,
            resource: DELETE_REPLY_URL
        })
    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    render () {
        return (
            <MainWrapper>
                <div id="manage-article-reviews">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup} 
                                 text={this.state.popupText}
                                 title={this.state.popupTitle}
                                 hidePopup={this.hidePopup}
                                 calcelAction={this.hidePopup}
                                 confirmAction={this.state.confirmHandler}/>

                    <HeaderUser/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="reviews-articles"/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row">
                                        {/* Title */}
                                        <h1
                                        className={`page-title tab-item ${this.tabItemActive(
                                            REVIEWS
                                        )}`}
                                        onClick={this.switchTab.bind(this, REVIEWS)}
                                        >
                                        Article Reviews
                                        </h1>
                                        <h1 className="page-title tabs-bar">|</h1>
                                        <h1
                                        className={`page-title tab-item ${this.tabItemActive(
                                            "replies"
                                        )}`}
                                        onClick={this.switchTab.bind(this, "replies")}
                                        >
                                        Reviews Replies
                                        </h1>
                                    </div>
                                    <div className="filter-row">
                                        <div className="filters">
                                            <div 
                                                className={`filter-item ${this.filterActive(PENDING)}`}
                                                onClick={this.applyFilter.bind(this, PENDING)}
                                            >Pending</div>
                                            <div 
                                                className={`filter-item middle-filter ${this.filterActive(ACCEPTED)}`}
                                                onClick={this.applyFilter.bind(this, ACCEPTED)}
                                            >Accepted</div>
                                            <div 
                                                className={`filter-item ${this.filterActive(DECLINED)}`}
                                                onClick={this.applyFilter.bind(this, DECLINED)}
                                            >Declined</div>
                                        </div>
                                    </div>

                                    {this.renderTabContent()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.manageArticleReviewReducer,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqListArticleReviewsManagement: bindActionCreators(reqListArticleReviewsManagement, dispatch),
        reqToggleArticleReviewStatusManagement: bindActionCreators(reqToggleArticleReviewStatusManagement, dispatch),
        reqListReviewsRepliesManagement: bindActionCreators(reqListReviewsRepliesManagement, dispatch),
        reqToggleReviewReplyStatusManagement: bindActionCreators(reqToggleReviewReplyStatusManagement, dispatch),
        reqDeleteReview: bindActionCreators(reqDeleteReview, dispatch),
        reqDeleteReply: bindActionCreators(reqDeleteReply, dispatch),
      }
    };
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(ManageReviewsTherapists)

