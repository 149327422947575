import { REC_CREATE_ADMIN, REC_GET_ALL_SPECIALITIES } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_CREATE_ADMIN:
            return data;
        case REC_GET_ALL_SPECIALITIES:
            return {
                allSpecialities: data
            };
        default:
            return state
    }
}