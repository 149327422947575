import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_POST_REVIEW, REQ_POST_REPLY, REQ_GET_PUBLIC_PROFILE, REQ_GET_PUBLIC_REVIEWS, REQ_GET_PUBLIC_REPLIES, REQ_GET_THERAPIST_LOCATIONS_VISITOR,
         REQ_LOG_PROFILE_VISIT, REQ_REQUEST_CLAIM_LISTING, REQ_FORM_SUBMIT, REQ_REQUEST_NETWORKING, recRequestClaimListing, recFormSubmit, 
         recPostReview, recPostReply, recGetPublicProfile, recGetPublicReviews, recGetPublicReplies, recGetTherapistLocationsVisitor,
         recLogProfileVisit, recRequestNetworking} from './actions'

import fetchData from '../../../utils/api'

function* postReview(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recPostReview(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* postReply(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recPostReply(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* getPublicProfile(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetPublicProfile(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* getPublicReviews(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetPublicReviews(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* getPublicReplies(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetPublicReplies(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* getLocations(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetTherapistLocationsVisitor(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* logProfileVisit(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recLogProfileVisit(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* requestClaimListing(action) {
  
  try {
    //console.log("called saga")
    const data = yield call(fetchData, action.data, action.resource)
    yield put(recRequestClaimListing(data))
   
  } catch (e) {
      //console.log("Api error: ", e)
   }

}

function* requestNetworking(action) {
  
  try {
    //console.log("called saga")
    const data = yield call(fetchData, action.data, action.resource)
    yield put(recRequestNetworking(data))
   
  } catch (e) {
      //console.log("Api error: ", e)
   }

}

function* sendForm(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recFormSubmit(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

export default function* therapistPublicProfile() {
    yield takeLatest(REQ_POST_REVIEW, postReview);
    yield takeLatest(REQ_POST_REPLY, postReply);
    yield takeLatest(REQ_GET_PUBLIC_PROFILE, getPublicProfile);
    yield takeLatest(REQ_GET_PUBLIC_REVIEWS, getPublicReviews);
    yield takeLatest(REQ_GET_PUBLIC_REPLIES, getPublicReplies);
    yield takeLatest(REQ_GET_THERAPIST_LOCATIONS_VISITOR, getLocations);
    yield takeLatest(REQ_LOG_PROFILE_VISIT, logProfileVisit);
    yield takeLatest(REQ_REQUEST_CLAIM_LISTING, requestClaimListing);
    yield takeLatest(REQ_REQUEST_NETWORKING, requestNetworking);
    yield takeLatest(REQ_FORM_SUBMIT, sendForm);
};  