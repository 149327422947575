import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_ARTICLE_GUEST, REQ_GET_SIMILAR_ARTICLES, REQ_SUBMIT_ART_REVIEW, REQ_SUBMIT_ART_REPLY,
  REQ_LIST_ACCEPTED_ARTICLE_REVIEWS, REQ_LIST_ACCEPTED_ARTICLE_REPLIES,
  recGetArticleGuest, recGetSimilarArticles, recSubmitArticleReview, recSubmitArticleReply,
  recListAcceptedArticleReviews, recListAcceptedArticleReplies} from './actions'
import fetchData from '../../utils/api'


function* loadArticle(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetArticleGuest(data))
  } catch (e) {
      console.log("Api error: ", e)
   }
}

function* loadSimilarArticles(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetSimilarArticles(data))
  } catch (e) {
      console.log("Api error: ", e)
   }
}

function* submitArticleReview(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recSubmitArticleReview(data))
  } catch (e) {
      console.log("Api error: ", e)
   }
}

function* submitArticleReply(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recSubmitArticleReply(data))
  } catch (e) {
      console.log("Api error: ", e)
   }
}

function* listAcceptedReviews(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListAcceptedArticleReviews(data))
  } catch (e) {
      console.log("Api error: ", e)
   }
}

function* listAcceptedReplies(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListAcceptedArticleReplies(data))
  } catch (e) {
      console.log("Api error: ", e)
   }
}

export default function* guestArticleSaga() {
    yield takeLatest(REQ_GET_ARTICLE_GUEST, loadArticle);
    yield takeLatest(REQ_GET_SIMILAR_ARTICLES, loadSimilarArticles);
    yield takeLatest(REQ_SUBMIT_ART_REVIEW, submitArticleReview);
    yield takeLatest(REQ_LIST_ACCEPTED_ARTICLE_REVIEWS, listAcceptedReviews);
    yield takeLatest(REQ_SUBMIT_ART_REPLY, submitArticleReply);
    yield takeLatest(REQ_LIST_ACCEPTED_ARTICLE_REPLIES, listAcceptedReplies);
};
