import { call, put, takeLatest } from 'redux-saga/effects'
import fetchData from '../../utils/api'
import { REQ_GET_THERAPISTS_TOP_PROFILES, REQ_GET_THERAPISTS_BY_NAME_GUEST,
  recGetTherapistsTopProfiles, recGetTherapistByNameGuest} from './actions'



function* getTherapistTopProfiles(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetTherapistsTopProfiles(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* getTherapistByName(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetTherapistByNameGuest(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

export default function* therapistTopProfiles() {
    yield takeLatest(REQ_GET_THERAPISTS_TOP_PROFILES, getTherapistTopProfiles);
    yield takeLatest(REQ_GET_THERAPISTS_BY_NAME_GUEST, getTherapistByName);
    
};  