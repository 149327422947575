import React from "react";
import { Link } from "react-router-dom";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import Footer from "../../components/Footer/Footer";
import ArticleCard from "../../components/Blog/ArticleCard";
import { Helmet } from "react-helmet";
import { reqGetArticles, reqSearchArticles } from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import searchImage from "../../static/images/desktop/search_icon_blue.png";
import PopupLoader from "../../components/PopupLoader/PopupLoader";
import Pagination from "../../components/Blog/Pagination";

import "./BlogMain.scss";

require("dotenv").config();
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class BlogMain extends React.Component {
  state = {
    pageLoading: false,
    articles: [],
    currentPage: 1,
    searchInput: "",
  };

  articlesPerPage = 10;
  getArticlesUrl = "blog/articles/all";
  searchArticleUrl = "blog/articles/search";

  componentDidMount = () => {
    this.authHeader = {
      "Content-type": "application/json",
    };
    this.setState({}, () => {
      this.loadArticles();
    });
  };

  componentWillReceiveProps = (newProps) => {
    const apiData = newProps.apiData;

    // console.log("blog main props: ", newProps)

    if (apiData.articles && apiData.articles.success) {

      this.setState(() => ({
        pageLoading: false,
        articles: apiData.articles.articles.filter(article => article.type === "blog"),
      }));
    }

    if (apiData.articleSearch && apiData.articleSearch.success) {
      this.setState(() => ({
        pageLoading: false,
        articles: apiData.articleSearch.articles.filter(article => article.type === "blog"),
      }));
    }

    let url = newProps.location.pathname;

    if (url !== "/blog") {
      this.setState({ currentPage: parseInt(url.slice(-1)) });
    } else {
      this.setState({ currentPage: 1 });
    }
  };

  loadArticles = () => {
    const rConfig = {
      body: {},
      resource: `${this.getArticlesUrl}`,
      headers: null,
    };
    this.props.actions.reqGetArticles(rConfig);
  };

  getArticles = () => {
    this.setState({ pageLoading: true }, () => {
      this.loadArticles();
    });
  };

  searchArticles = () => {
    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqSearchArticles({
          body: {},
          headers: null,
          resource: `${this.searchArticleUrl}?searchTerm=${this.state.searchInput}`,
        });
      }
    );
  };

  searchSubmit = () => {
    if (this.state.searchInput !== "") {
      this.searchArticles();
      this.changePage(1);
    } else {
      this.getArticles();
    }
  };

  filterSubmit = () => {};

  searchInputChange = (ev) => {
    let val = ev.target.value;

    this.setState(() => ({
      searchInput: val,
    }));
  };

  getArticlePermalink = (title) =>
    title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[’,?!]/g, "");

  getArticleSnippet = (article, size = 155) =>
    article.body
      .replace(/<figcaption>([^<]+)<\/figcaption>/gi, "")
      .replace(/(<([^>]+)>)/gi, "")
      .replace("&nbsp;", " ")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .substring(0, size) + "...";

  listArticles = () => {
    const indexOfLastArticle = this.state.currentPage * this.articlesPerPage,
      indexOfFirstArticle = indexOfLastArticle - this.articlesPerPage,
      currentArticles = this.state.articles.slice(
        indexOfFirstArticle,
        indexOfLastArticle
      );

    if (
      typeof this.state.articles === "undefined" ||
      !this.state.articles.length
    ) {
      return (
        <div className="no-articles-result">
          <div className="no-articles-res-text">
            There are currently no articles for this search.
          </div>
        </div>
      );
    }
    let items = [],
      thumblink = currentArticles[0].thumbnail_url
        .toLowerCase()
        .replace(/-/g, " ")
        .replace(/[?!]/g, "")
        .replace(/_/g, "")
        .replace(/[1234567890.]/g, "")
        .replace(/jpeg/g, "")
        .replace(/png/g, "")
        .replace(/jpg/g, ""),
      subItems = [],
      articles = currentArticles,
      publishedDate = new Date(articles[0].published),
      articleID = articles[0].id,
      permalink =
        articles[0].permalink === "" || articles[0].permalink === null
          ? this.getArticlePermalink(articles[0].title)
          : articles[0].permalink;

    items.push(
      <div className="mb-60" key={`item-art-0`}>
        <Link to={`/blog/${articleID}/${permalink}`}>
          <ArticleCard
            className="article--first"
            url={`/blog/${articleID}/${permalink}`}
            image={`${baseBackendUrl}/articleContentImage/${articles[0].thumbnail_url}`}
            tag={`TAG, ` + this.formatDate(publishedDate)}
            buttonText="Read Article"
            title={
              articles[0].card_title === "" || articles[0].card_title === null
                ? articles[0].title
                : articles[0].card_title
            }
            firstArticle={true}
            alt={`${thumblink}`}
            shortContent={this.getArticleSnippet(articles[0])}
          />
        </Link>
      </div>
    );
    for (let i = 1; i < articles.length; i++) {
      let article = articles[i];

      let publishedDate = new Date(article.published);
      let thumblink = article.thumbnail_url
        .toLowerCase()
        .replace(/-/g, " ")
        .replace(/[?!]/g, "")
        .replace(/_/g, "")
        .replace(/[1234567890.]/g, "")
        .replace(/jpeg/g, "")
        .replace(/png/g, "")
        .replace(/jpg/g, "");
      let permalink =
        article.permalink === "" || article.permalink === null
          ? this.getArticlePermalink(article.title)
          : article.permalink;

      subItems.push(
        <div className="col-lg-4 col-sm-6 mb-60" key={`item-sub-art-${i}`}>
          <Link to={`/blog/${article.id}/${permalink}`}>
            <ArticleCard
              // key={`item-sub-art-${i}`}
              image={`${baseBackendUrl}/articleContentImage/${article.thumbnail_url}`}
              url={`/blog/${article.id}/${permalink}`}
              alt={`${thumblink}`}
              tag={`TAG, ` + this.formatDate(publishedDate)}
              buttonText="Read Article"
              title={
                article.card_title === "" || article.card_title === null
                  ? article.title
                  : article.card_title
              }
              firstArticle={false}
              shortContent={this.getArticleSnippet(article, 90)}
            />
          </Link>
        </div>
      );
    }

    items.push(
      <div className="row" key={`uk`}>
        {subItems}
      </div>
    );

    if (!items.length) {
      return (
        <div className="message-row">
          <p>You have no articles written.</p>
        </div>
      );
    }

    return <ul className="articles-list">{items}</ul>;
  };

  changePage = (pageNumber) => {
    if (pageNumber === "prev") {
      this.setState(
        {
          currentPage: this.state.currentPage - 1,
        },
        () => {
          this.props.history.push({
            pathname: `/blog/page-${this.state.currentPage}`,
          });
        }
      );
    }
    if (pageNumber === "next") {
      this.setState(
        {
          currentPage: this.state.currentPage + 1,
        },
        () => {
          this.props.history.push({
            pathname: `/blog/page-${this.state.currentPage}`,
          });
        }
      );
    }
    if (typeof pageNumber === "number") {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.props.history.push({
            pathname: `/blog/page-${this.state.currentPage}`,
          });
        }
      );
    }

    window.scrollTo(0, 600);
  };

  formatDate(date) {
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return (
      month[date.getMonth()] +
      " " +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      ", " +
      date.getFullYear()
    );
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Information About Autism & More | Autism Assistant Blog</title>
          <meta
            name="description"
            content="Our blog aims to bring every type of important information about autism straight to you in an easy to understand manner. We also help you find therapists."
          ></meta>
        </Helmet>
        <PopupLoader display={this.state.pageLoading} />
        <HeaderGuestSimple menu="blog" />
        <div id="blogMainPage" className="container">
          <h2 className="page-title">Autism Assistant Blog</h2>
          <div className="row our-story mb-50 blog-text-ontop">
            <div className="text-section">
              <p>
                Our blog is dedicated to making the most relevant information
                about autism readily available. Whether we're talking about what
                ASD is, or how to help your child through specific situations,
                it is our belief that parents of children on the spectrum should
                always be supported. Finding help should be easy.
              </p>
              <p>
                If you’re a blogger and would like to share your thoughts on our
                websites, you can{" "}
                <a href="https://autismassistant.com/signup">join now</a> with a
                few easy steps.
              </p>
              <p>
                If you’re searching for a therapist, check out our{" "}
                <a href="https://autismassistant.com/specialists">
                  list of specialists
                </a>{" "}
                to find the right one for your child. Alternatively, you can
                read more on the{" "}
                <a href="https://autismassistant.com/autism-therapy-types">
                  types of autism therapies
                </a>{" "}
                first.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-11">
              <div className="article-search--container">
                <div className="article-search--input-background">
                  <input
                    onChange={this.searchInputChange}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.searchSubmit();
                      }
                    }}
                    placeholder="Search Article"
                  />
                  <img
                    src={searchImage}
                    className="search-icon"
                    onClick={this.searchSubmit}
                    alt="search-icon"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-md-1 col-1 d-flex">
							<img src={filterImage} className="filter-icon" onClick={this.filterSubmit} alt="img"/>
						</div> */}
          </div>
          <div className="blog-listing-container">{this.listArticles()}</div>
          <Pagination
            articlesPerPage={this.articlesPerPage}
            totalArticles={this.state.articles.length}
            paginate={this.changePage}
            currentPage={this.state.currentPage}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.mainBlogReducer,
  userDetails: state.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetArticles: bindActionCreators(reqGetArticles, dispatch),
      reqSearchArticles: bindActionCreators(reqSearchArticles, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogMain);
