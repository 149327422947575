import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_ADD_SPECIALITY, 
         REQ_GET_SPECIALITIES, 
         REQ_DELETE_SPECIALITY,
         REQ_UPDATE_SPECIALITY,
         REQ_SEARCH_SPECIALITIES,
         recAddSpeciality, 
         recGetSpecialities,
         recUpdateSpeciality,
         recSearchSpecialities,
         recDeleteSpeciality} from './actions'

import fetchData from '../../../utils/api'


function* addSpeciality(action) {
  //console.log("adding speciality")
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recAddSpeciality(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* getSpecialities(action) {
  // console.log("Getting specialities")
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetSpecialities(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* deleteSpeciality(action) {
  // console.log("Getting specialities")
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recDeleteSpeciality(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* updateSpeciality(action) {
  console.log("Updating specialities")
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recUpdateSpeciality(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* searchSpecialities(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recSearchSpecialities(data))
   
  } catch (e) {console.log("Api error: ", e)}
}


export default function* adminManageSpecialities() {
    yield takeLatest(REQ_ADD_SPECIALITY, addSpeciality);
    yield takeLatest(REQ_GET_SPECIALITIES, getSpecialities);
    yield takeLatest(REQ_DELETE_SPECIALITY, deleteSpeciality);
    yield takeLatest(REQ_UPDATE_SPECIALITY, updateSpeciality);
    yield takeLatest(REQ_SEARCH_SPECIALITIES, searchSpecialities);
};