import React from "react";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import "./AssistanceForAll.scss";

class ThankYouPage extends React.Component {
  state = {
    menu: "",
    searchMethod: "location",
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Assistance For All thank you page | Autism Assistant</title>
          <meta
            name="description"
            content="We connect you directly to the type of therapist that provides the autism spectrum disorder treatment that your child requires. Search our list for free."
          ></meta>
          <meta name="robots" content="noindex"></meta>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
        </Helmet>
        <HeaderGuestSimple menu="ThankYouPage" />
        <section className="container mt-50">
          <h1 className="text-center mt-30 aboutUsHeading">
            Assistance For All
          </h1>
          <div className="row our-story">
            <div className="text-section text-center mt-50">
             <h2 className="thank-you">Thank you for applying to the program</h2>
             <p>Due to the high volume of applications we receive, it may be a few weeks or more before you hear from us. Just know you've been added to our interest list. We have new providers signing up weekly who are generously donating their time to our program and we hope to be able to provide services to as many families as possible.</p>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default ThankYouPage;
