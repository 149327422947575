import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_SPECIALITIES_HOME, REQ_LOG_PAGE_VISIT_HOME, REQ_GET_FEATURED_ARTCILE, REQ_GET_FEATURED_PRODUCTS, REQ_GET_FEATURED_BUSINESSES, REQ_GET_FEATURED_PARTNERS,
         recGetSpecialitiesForHome, recLogPageVisitHome, recGetFeaturedArticle, recGetFeaturedProducts, recGetFeaturedBusinesses, recGetFeaturedPartners} from './actions'

import fetchData from '../../utils/api'

function* getSpecialitiesForHome(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetSpecialitiesForHome(data))
  } catch (e) {console.log("Api error: ", e)}
}

function* logPageVisitHome(action) { 
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recLogPageVisitHome(data))
  } catch (e) {console.log("Api error: ", e)}
}

function* getFeaturedArticle(action) { 
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetFeaturedArticle(data))
  } catch (e) {console.log("Api error: ", e)}
}

function* getFeaturedProducts(action) { 
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetFeaturedProducts(data))
  } catch (e) {console.log("Api error: ", e)}
}

function* getFeaturedBusinesses(action) { 
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetFeaturedBusinesses(data))
  } catch (e) {console.log("Api error: ", e)}
}

function* getFeaturedPartners(action) { 
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetFeaturedPartners(data))
  } catch (e) {console.log("Api error: ", e)}
}

export default function* homeSagas() {
    yield takeLatest(REQ_GET_SPECIALITIES_HOME, getSpecialitiesForHome);
    yield takeLatest(REQ_LOG_PAGE_VISIT_HOME, logPageVisitHome);
    yield takeLatest(REQ_GET_FEATURED_ARTCILE, getFeaturedArticle);
    yield takeLatest(REQ_GET_FEATURED_PRODUCTS, getFeaturedProducts);
    yield takeLatest(REQ_GET_FEATURED_BUSINESSES, getFeaturedBusinesses);
    yield takeLatest(REQ_GET_FEATURED_PARTNERS, getFeaturedPartners);

};