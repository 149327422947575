import React from "react";
import defaultAvatar from "../../static/images/desktop/annonymous-user-icon.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faPhone, faGlobe } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import Rating from "react-rating";
import ratingStarFull from "../../static/images/desktop/rating-star-full.png";
import ratingStarEmpty from "../../static/images/desktop/rating-star-empty.png";
// import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faCheck, faFile } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./TherapistCard.scss";

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class TherapistCard extends React.Component {
  therapist = this.props.therapist;

  renderContactInfo = () => {

    let keys = [
      { label: "phone", icon: faPhone },
      { label: "website", icon: faAddressCard },
    //   { label: "distance", icon: faLocationArrow }
    ];

    let items = keys.map(k => this.renderItemFromKey(k));

    return (
      <div className="contact-info-list">
        {this.therapist.userVerified !== 0 ? (
          items
        ) : (
          <div>
            <span className="like-link">Click here</span> to contact this
            therapist.
          </div>
        )}
      </div>
    );
  };

  renderItemFromKey = k => (
    <div
      className={`contact-info-item ${
        k.label === "distance" && this.props.online === "true"
          ? "hide-distance"
          : ""
      }`}
      key={`k-${k.label}`}
    >
      <FontAwesomeIcon icon={k.icon} />
      <span className="contact-info-value">
        {this.therapist[k.label] !== null ? this.therapist[k.label] : "-"}
      </span>
    </div>
  );

  renderVerifiedFlag = () =>
    this.therapist.userVerified ? (
      <div>
        <div className="therapist-flag">
          <FontAwesomeIcon icon={faCheck} className="green-icon" />
          <div className="therapist-flag-text">Verified Provider</div>
        </div>
        {(this.therapist.specialities &&
          this.therapist.specialities.includes("telemedicine")) ||
        (this.therapist.online && this.therapist.online !== 0) ? (
          <div className="online-flag">
            <FontAwesomeIcon icon={faGlobe} className="green-icon" />
            <div className="therapist-flag-text">Online Therapy</div>
          </div>
        ) : (
          ""
        )}
        {this.props.therapist.acceptsInsurance ? (
      <div className="therapist-flag">
        <FontAwesomeIcon icon={faFile} className="yellow-icon" />
        <div className="therapist-insurance-flag">Accepts Insurance!</div>
        
      </div>
    ) : (
      ""
    )}
      </div>
    ) : (
      ""
    );

  getAvatarUrl = therapist =>
    therapist.avatarUrl
      ? `${baseBackendUrl}/${therapist.avatarUrl}`
      : defaultAvatar;

  render() {
    return (
      <Link to={`/therapist/profile/${this.therapist.id}`} target="blank">
        <li key={this.props.index} className="tc-list-item">
          {/* Avatar Container */}
          <div className="left-col">
            <div className="tc-image-container">
              <img
                src={this.getAvatarUrl(this.therapist)}
                alt="therapist-avatar"
                className="avatar"
              />
            </div>
          </div>
          {/* User Profile Container */}
          <div className="tc-info-container">
            {/* Name & Title */}
            <div className="tc-main-info">
              {this.therapist.firstName === "" ||
              this.therapist.firstName === null ? (
                ""
              ) : (
                <div className="tc-u-name">{`${this.therapist.firstName} ${this.therapist.lastName}`}</div>
              )}
              {this.therapist.businessName === "" ? (
                ""
              ) : (
                <div className="tc-b-name">{this.therapist.businessName}</div>
              )}
            </div>
            {/* Details */}
            <div className="tc-details-info">
              {/* Email */}
              <div className="tr-reviews-row">
                <Rating
                  placeholderRating={this.therapist.avgScore || 0}
                  emptySymbol={
                    <img
                      src={ratingStarEmpty}
                      className="icon"
                      alt="ratingEmpty"
                      width="21" 
                      height="21"
                    />
                  }
                  placeholderSymbol={
                    <img
                      src={ratingStarFull}
                      className="icon"
                      alt="ratingFull"
                      width="21" 
                      height="21"
                    />
                  }
                  readonly
                />
                <span className="tr-reviews-value">{`(${this.therapist.reviewCnt} reviews)`}</span>
              </div>
              {/* Phone */}
              {this.renderContactInfo()}
              <div className={`contact-info-item ${this.props.online === "true" || !this.therapist.distance ? "hide-distance" : ""}`}>
                <FontAwesomeIcon icon={faLocationArrow} />
                <span className="contact-info-value">
                 {this.therapist.distance}
                </span>
    </div>
            </div>
          </div>
          <div className="right-col">
            <div className="flags-row">
              {this.renderVerifiedFlag()}
            </div>
          </div>
          {/* Actions Container */}

          <ReactTooltip type="info" place="right" />
        </li>
      </Link>
    );
  }
}

export default TherapistCard;
