import { REC_GET_ALL_INSURANCES, REC_ADD_INSURANCE_THERAPIST, REC_REMOVE_INSURANCE_THERAPIST } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_ALL_INSURANCES:
            return {
                allInsurances: data
            };
        
        case REC_ADD_INSURANCE_THERAPIST:
            return {
                addInsurance: data
            };

        case REC_REMOVE_INSURANCE_THERAPIST:
            return {
                removeInsurance: data
            };

        default:
            return state
    }
}