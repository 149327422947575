import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_TOP_LOCATIONS, REQ_GET_TOP_THERAPISTS,
         recGetTopLocations, recGetTopTherapists} from './actions'

import fetchData from '../../utils/api'

function* getTopLocations(action) {
  
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetTopLocations(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* getTopTherapists(action) {
  
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetTopTherapists(data))
   
  } catch (e) {console.log("Api error: ", e)}

}


export default function* homeSagas() {
    yield takeLatest(REQ_GET_TOP_LOCATIONS, getTopLocations);
    yield takeLatest(REQ_GET_TOP_THERAPISTS, getTopTherapists);
};