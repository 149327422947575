import { REC_GET_THERAPIST_ARTICLES, REC_ADD_THERAPIST_ARTICLE, 
    REC_UPDATE_THERAPIST_ARTICLE, REC_REMOVE_THERAPIST_ARTICLE,
    REC_UPLOAD_ARTICLE_THUMBNAIL } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_THERAPIST_ARTICLES:
            return {
                allArticles: data
            };
        
        case REC_ADD_THERAPIST_ARTICLE:
            return {
                addArticle: data
            };

        case REC_UPDATE_THERAPIST_ARTICLE:
            return {
                updateArticle: data
            };

        case REC_REMOVE_THERAPIST_ARTICLE:
            return {
                removeArticle: data
            };

        case REC_UPLOAD_ARTICLE_THUMBNAIL:
            return {
                thumbnailUploaded: data
            };

        default:
            return state
    }
}