import { REC_ADD_LOCATION, REC_GET_ALL_LOCATIONS, REC_DEL_LOCATION } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_ADD_LOCATION:
            return {
                locAdded: data
            };
        
        case REC_GET_ALL_LOCATIONS:
            return {
                therapistLocations: data
            };
        
        case REC_DEL_LOCATION:
            return {
                locDeleted: data
            };

        default:
            return state
    }
}