import React from 'react'
import './ArticleTags.scss'
import ArticleTag from './ArticleTag'

const ArticleTags = props => {

    if (!props.tags) return '';

    let items = props.tags.split(',').map( (t, idx) => (
        <ArticleTag tag={t} key={`art-tag-dis-${idx}`}/>
    ))


    return (
        <div className="art-tags-section">
            <div className="art-tags-sec-title">Tags</div>
            <div className="article-tags-container">
                {items}
            </div>
        </div>
    )
}

export default ArticleTags