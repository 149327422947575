import React from "react";
import HeaderUser from "../../../components/HeaderUser/HeaderUser";
import SidebarAdmin from "../../../components/SidebarAdmin/SidebarAdmin";
import "./CreateAdmin.scss";
import ButtonRoundGradient from "../../../components/ButtonRoundGradient/ButtonRoundGradient";
import { reqCreateAdmin, reqGetAllSpecialities } from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// noinspection ES6CheckImport
import { Redirect } from "react-router-dom";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import MainWrapper from "../MainWrapper/MainWrapper";
import PopupConfirm from "../../../components/PopupConfirm/PopupConfirm";
import AttachedSpItem from "../../Therapist/Specialities/AttachedSpItem";
import SpecialityList from "../../../components/Header/SpecialityList";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import AttachedLocationItem from "../../Therapist/Locations/AttachedLocationItem";
import uploadImage from "../../../utils/uploadImage";

const ADMIN = "admin",
  PROVIDER = "provider";

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class AdminCreateAdmin extends React.Component {
  state = {
    userName: '',
    profileImage: "",
    hoverOnImage: false,
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    title: "",
    phone: "",
    bio: "",
    website: "",
    errors: [],
    redirectUrl: "",
    redirect: false,
    pageLoading: false,
    successResponse: "",
    selectedRole: 3,
    signupForm: PROVIDER,
    allSpecialities: [],
    attachedSpecialities: [],
    displayedSpecialities: [],
    specialitiesOpen: false,
    selectedSpecialityError: false,
    searchSpecialitiesTerm: "",
    therapistLocations: [],
    placeholderText: "",
    locationID: 1,
    profileImgPreview: ''
  };

  allSpecialitiesUrl = "specialities/list";
  submitUrl = "admin/create/user";
  uploadThumbnailUrl = 'user/profile/set/image';
  admin = {};
  authHeaders = {};

  componentDidMount = () => {

    this.admin = this.props.userDetails;
    this.authHeaders = {
      Authorization: `Bearer ${this.admin.token}`,
      "Content-type": "application/json",
    };

    this.loadAllSpecialities();
  };

  componentWillReceiveProps = (newProps) => {
    const apiData = newProps.apiData;
    let errorMessage = "";
    let newState = {};

    if (apiData.allSpecialities && apiData.allSpecialities.specialities) {
      this.setState(() => ({
        pageLoading: false,
        allSpecialities: apiData.allSpecialities.specialities,
        displayedSpecialities: apiData.allSpecialities.specialities,
      }));
    } else if (apiData.error) {
      if (apiData.display) {
        errorMessage = apiData.error;
      } else {
        errorMessage = "An error occurred";
      }

      newState = {
        errorMessage: errorMessage,
        pageLoading: false,
      };
    } else if (apiData.success) {
      newState = {
        profileImage: "",
        hoverOnImage: false,
        userName: "",
        firstName: "",
        lastName: "",
        businessName: "",
        email: "",
        title: "",
        website: "",
        phone: "",
        bio: "",
        profileImgPreview: "",
        allSpecialities: [],
        attachedSpecialities: [],
        displayedSpecialities: [],
        therapistLocations: [],
        errors: [],
        redirectUrl: "",
        redirect: false,
        pageLoading: false,
        successResponse: apiData.message,
      };
    }

    this.setState(() => newState);
  };

  loadAllSpecialities = () => {
    this.setState(
      {
        pageLoading: true,
      },
      () => {
        const rConfig = {
          body: {},
          resource: this.allSpecialitiesUrl,
          headers: null,
        };
        this.props.actions.reqGetAllSpecialities(rConfig);
      }
    );
  };

  hidePopup = () => {
    this.setState( () => ({showPopup: false, pageLoading: false}))
}

  getAvailableSpecialities = (allSpecialities, attachedSpecialities) => {
    return allSpecialities.filter(
      (s) => attachedSpecialities.filter((ats) => ats.id === s.id).length === 0
    );
  };

  handleChooseProfileImg = ev => {
    let imageFile = ev.target.files[0];
    let previewFile = URL.createObjectURL(imageFile);

    this.setState(() => ({
      profileImgFile: imageFile,
      profileImgPreview: previewFile
    }));
  };

  coverVisible = () => {
    return this.state.hoverOnImage ? "flex-item" : "hidden-item";
  };

  displayCover = () => {
    this.setState(() => ({ hoverOnImage: true }));
  };

  hideCover = () => {
    this.setState(() => ({ hoverOnImage: false }));
  };

  renderAvatar = () => {
    let imageContent = (
        <div className="profile-img-sub-container">
          <div className="add-photo-btn"> + </div>
          <div className="add-photo-text-container">
            <span className="white-text"> Add Photo </span>
          </div>
        </div>
    );

    if (this.state.profileImgPreview) {
      imageContent = (
          <div className="avatar-sub-container">
            <img src={this.state.profileImgPreview} alt="profileImg" />
            <div className="avatar-cover"> CHANGE </div>
          </div>
      );
    } else if (this.state.profileImageName) {
      imageContent = (
          <div className="avatar-sub-container">
            <img
                src={`${baseBackendUrl}/${this.state.profileImageName}`}
                alt="profileImg"
            />
            <div className="avatar-cover"> CHANGE </div>
          </div>
      );
    }

    return (
        <div
            className="avatar-container"
            onClick={() => this.selectProfilePhoto.click()}
        >
          {imageContent}
        </div>
    );
  };

  toggleSpecialityList = () => {
    this.setState(() => ({
      specialitiesOpen: !this.state.specialitiesOpen,
    }));
  };

  hideSpecialityList = () => {
    this.setState(() => ({
      specialitiesOpen: false,
    }));
  };

  suggestSpecialities = (event) => {
    const term = event.target.value;
    const suggestedSpecialities = this.state.allSpecialities.filter(
      (s) => s.name.toLowerCase().indexOf(term.toLowerCase()) > -1
    );

    this.setState(() => ({
      searchSpecialitiesTerm: term,
      suggestedSpecialities: suggestedSpecialities,
      suggestOpen: true,
    }));
  };

  inputHasErrors = (inputName) => {
    const errors = this.state.errors.filter((err) => err.input === inputName);
    return errors.length ? "input-error" : "";
  };

  handleChange = (event) => {
    let inputName = event.target.name,
      value = event.target.value;

    // console.log("inputName: ", inputName)

    this.setState({
      [inputName]: value,
    });
  };

  validateForm = () => {
    let formValid = true;

    if (this.state.email === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "email" }],
        errorMessage: "Email is mandatory",
        pageLoading: false,
      }));
    } else if (!/@/.test(this.state.email)) {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "email" }],
        errorMessage: "Not a valid email",
        pageLoading: false,
      }));
    } else if (this.state.userName === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "userName" }],
        errorMessage: "User Name is mandatory",
        pageLoading: false,
      }));
    }

    return formValid;
  };

  handleSubmit = () => {
    this.setState(
        {
          errors: [],
          errorMessage: "",
          pageLoading: true,
          // check: false,
        },
        () => {
          if (!this.validateForm()) return;

          this.uploadAvatar().then( res => {
            let { url: fileName } = res.data
            this.createUser(fileName)
          }).catch((error) => console.log(error))
        }
    );
  };

  uploadAvatar = () => {

    let profileImg = this.state.profileImgFile,
        data = new FormData();

    data.append('profileImage', profileImg)

    return uploadImage(this.uploadThumbnailUrl, data);
  }

  createUser = (profileImageName = null) => {

    const rBody = {
      userName: this.state.userName,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      businessName: this.state.businessName,
      phone: this.state.phone,
      website: this.state.website,
      bio: this.state.bio,
      title: this.state.title,
      profileImageName,
      roleId: this.state.selectedRole,
      specialities: this.state.attachedSpecialities,
      locations: this.state.therapistLocations,
    };

    const headers = this.authHeaders;

    this.props.actions.reqCreateAdmin(rBody, this.submitUrl, headers);
  }

  handleRemoveSpeciality = (spId) => {
    this.setState(() => ({
      showPopup: true,
      removeId: spId,
      popupText: "Confirm removing this from your specialities?",
      confirmAction: this.confirmRemove
    }));
  };

  confirmRemove = () => {
    for (let i = 0; i < this.state.attachedSpecialities.length; i++) {
      const speciality = this.state.attachedSpecialities[i];
      if (speciality.id === this.state.removeId) {
        this.setState(() => ({
          showPopup: false
        }))
        this.state.attachedSpecialities.splice(i,1)
      }
    }
  }

  renderAttachedSpecialities = () => {
    const items = [];

    for (let i = 0; i < this.state.attachedSpecialities.length; i++) {
      const speciality = this.state.attachedSpecialities[i];

      items.push(
        <AttachedSpItem
          index={speciality.id}
          key={speciality.id}
          speciality={speciality}
          removeAction={this.handleRemoveSpeciality}
        />
      );
    }

    return items;
  };

  renderSpecialityDisplayText = () => {
    let selectedCnt = this.state.displayedSpecialities.filter(
      (s) => s.isChecked
    ).length;
    return selectedCnt ? `(${selectedCnt}) selected` : "Speciality";
  };

  renderSpecialityLabelTooltip = () => {
    return this.state.displayedSpecialities
      .filter((s) => s.isChecked)
      .map((s) => s.name)
      .join(" || ");

  };

  attachSpecialityHandler = (spId) => {
    for (let i = 0; i < this.state.displayedSpecialities.length; i++) {
      let speciality = this.state.displayedSpecialities[i];
      if (speciality.id === spId) {
        this.state.attachedSpecialities.push(speciality);
      }
    }

    this.setState(() => ({
      displayedSpecialities: this.getAvailableSpecialities(
        this.state.allSpecialities,
        this.state.attachedSpecialities
      ),
    }));
  };

  handleSpecialityCheck = (state, speciality) => {
    this.state.displayedSpecialities.map((s) => {
      if (s.id === speciality.id) {
        s["isChecked"] = state;
        this.state.attachedSpecialities.push(s);
      }

      return s;
    });
    this.setState(() => ({
      displayedSpecialities: this.getAvailableSpecialities(
        this.state.allSpecialities,
        this.state.attachedSpecialities
      ),
    }));
  };

  handleRoleChange = (ev) => {
    let roleId = ev.target.value,
      signupForm = "";

    if (roleId === "3") {
      signupForm = PROVIDER;
    } else if (roleId === "1") {
      signupForm = ADMIN;
    }

    this.setState({
      selectedRole: roleId,
      signupForm: signupForm,
    });

    // console.log ("signupForm : ", this.state.signupForm)

    document.getElementById("add-user").reset();
  };

  renderAttachedLocations = () => {
    const items = [];

    for (let i = 0; i < this.state.therapistLocations.length; i++) {
      const location = this.state.therapistLocations[i];
      items.push(
        <AttachedLocationItem
          key={i}
          location={location}
          removeAction={this.removeLocationHandler}
        />
      );
    }

    return items;
  };

  removeLocationHandler = (locId) => {
    this.setState(() => ({
      showPopup: true,
      popupText: "Are you sure you want to remove this location?",
      popupTitle: "Remove Location?",
      confirmAction: this.confirmDeleteLocation,
      deleteId: locId,
    }));
  };

  confirmDeleteLocation = () => {
    for (let i = 0; i < this.state.therapistLocations.length; i++) {
      const location = this.state.therapistLocations[i];
      if (location.id === this.state.deleteId) {
        this.setState(() => ({
          showPopup: false
        }))
        this.state.therapistLocations.splice(i,1)
      }
    }
  }

  addLocation = (place) => {
    geocodeByPlaceId(place.place_id).then((geocode) => {
      const latLng = getLatLng(geocode[0]),
        locationName = this.extractFromPlace("locality", geocode),
        region = this.extractFromPlace("region", geocode);

      latLng.then((response) => {
        const location = {
          id: this.state.locationID,
          address: place.description,
          lat: response.lat,
          lng: response.lng,
          locationName: locationName,
          region: region,
        };

        this.state.therapistLocations.push(location);
        this.setState({locationID : this.state.locationID+1})
      });
    });
  };

  extractFromPlace = (type, placeObj) => {
    let components = placeObj[0].address_components;
    let googleKey = "";

    switch (type) {
      case "locality":
        googleKey = "locality";
        break;

      case "region":
        googleKey = "administrative_area_level_1";
        break;

      default:
        googleKey = "-";
        break;
    }

    let name = "-";

    for (let i = 0; i < components.length; i++) {
      let component = components[i],
        hasKey = component.types.filter((t) => t === googleKey).length;

      if (hasKey) {
        name = component.long_name;
        break;
      }
    }

    return name;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }

    return (
      <MainWrapper logoutRedirect="/admin/signin">
        <div id="admin-create-admin">
          <PopupLoader display={this.state.pageLoading} />
          <PopupConfirm
            display={this.state.showPopup}
            text={this.state.popupText}
            title={this.state.popupTitle}
            hidePopup={this.hidePopup}
            calcelAction={this.hidePopup}
            confirmAction={this.state.confirmAction}
          />

          <HeaderUser adminFullName="John Doe" />
          <div className="page-background">
            {/* Page Content */}
            <div className="page-content">
              {/* Sidebar */}
              <SidebarAdmin page="create-admin" />
              {/* Inner Content */}
              <div className="inner-content-container">
                <div className="inner-content">
                  <div className="top-row">
                    {/* Title */}
                    <h1 className="page-title"> Add New User</h1>
                  </div>
                  <div className="second-row">
                    {/* Left side Content */}
                    <div className="leftside-content">
                      {/* Avatar */}
                      {this.renderAvatar()}
                    </div>
                    {/* RightSide Content */}
                    <div className="rightside-content">
                      <div className="inputs-row role-options-row">
                        <div className="role-options-container">
                          <select
                            className="role-options-select"
                            onChange={this.handleRoleChange}
                          >
                            <option value="3">Provider/Business</option>
                            <option value="1">Admin</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-container">
                        <form id="add-user">
                          <div className="input-row">
                            <input
                              type="text"
                              name="userName"
                              className={`basic-input ${this.inputHasErrors(
                                "userName"
                              )}`}
                              placeholder="User Name*"
                              value={this.state.userName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="input-row">
                            <input
                              type="text"
                              name="firstName"
                              className={`basic-input ${this.inputHasErrors(
                                "firstName"
                              )}`}
                              placeholder="First Name"
                              value={this.state.firstName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="input-row">
                            <input
                              type="text"
                              name="lastName"
                              className={`basic-input ${this.inputHasErrors(
                                "lastName"
                              )}`}
                              placeholder="Last Name"
                              value={this.state.lastName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="input-row">
                            <input
                              type="text"
                              name="businessName"
                              className={`basic-input ${this.inputHasErrors(
                                "businessName"
                              )}`}
                              placeholder="Business Name"
                              value={this.state.businessName}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="input-row">
                            <input
                              type="email"
                              name="email"
                              className={`basic-input ${this.inputHasErrors(
                                "email"
                              )}`}
                              placeholder="Email*"
                              value={this.state.email}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="input-row">
                            <input
                              type="text"
                              name="title"
                              className={`basic-input ${this.inputHasErrors(
                                "title"
                              )}`}
                              placeholder="Title"
                              value={this.state.title}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="input-row">
                            <input
                              type="text"
                              name="phone"
                              className={`basic-input ${this.inputHasErrors(
                                "phone"
                              )}`}
                              placeholder="Phone"
                              value={this.state.phone}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="input-row">
                            <input
                              type="text"
                              name="website"
                              className={`basic-input ${this.inputHasErrors(
                                "website"
                              )}`}
                              placeholder="Website"
                              value={this.state.website}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="input-row textarea-row">
                            <textarea
                              name="bio"
                              className={`basic-input `}
                              placeholder="Bio"
                              value={this.state.bio}
                              onChange={this.handleChange}
                            />
                          </div>
                          {/* Image Input */}
                          <input
                            type="file"
                            id="profilePhotoInput"
                            ref={ref => (this.selectProfilePhoto = ref)}
                            onChange={this.handleChooseProfileImg}
                            style={{ display: "none" }}
                          />

                          
                          <hr/>
                          <div className="selected-specialities">
                            <div className="top-row mb-30">
                              {/* Title */}
                              <h4>Add Specialities</h4>
                            </div>
                            <div className="results-section">
                              {/* Results Title */}
                              <div className="attached-specialities-section">
                                <ul className="attached-specialities-list">
                                  {this.renderAttachedSpecialities()}
                                </ul>
                              </div>
                              <div className="search-select">
                                {/* {this.renderAvailableSpecialities()} */}
                                <SpecialityList
                                  suggestedSpecialities={
                                    this.state.displayedSpecialities
                                  }
                                  // handleBlur={this.hideSpecialityList}
                                  handleSpecialityCheck={
                                    this.handleSpecialityCheck
                                  }
                                  // searchTerm={this.state.searchSpecialitiesTerm}
                                  isOpen={this.state.specialitiesOpen}
                                  closeList={this.hideSpecialityList}
                                  selectedSpecialityError={
                                    this.state.selectedSpecialityError
                                  }
                                  searchSpecialitiesTerm={
                                    this.state.searchSpecialitiesTerm
                                  }
                                  suggestSpecialities={this.suggestSpecialities}
                                  toggleSpecialityList={
                                    this.toggleSpecialityList
                                  }
                                  renderSpecialityDisplayText="Specialities List"
                                  renderSpecialityLabelTooltip={this.renderSpecialityLabelTooltip()}
                                />
                              </div>
                            </div>

                            <hr/>
                          </div>
                          <div id="add-locations">
                            <div className="top-row mb-30">
                              {/* Title */}
                              <h4>Add Location</h4>
                            </div>
                            <div className="attached-locations-section">
                              <ul>{this.renderAttachedLocations()}</ul>
                            </div>
                            <div className="add-section">
                              <div className="add-location-section">
                                <div className="add-input-container">
                                  <div className="location-input-outer">
                                    <div className="location-input-inner">
                                      <GooglePlacesAutocomplete
                                        
                                        initialValue={
                                          this.state.placeholderText
                                        }
                                        className="admin-location-input"
                                        onSelect={this.addLocation}
                                        lazyLoad={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr/>
                          <div>
                            <p className="error-message">
                              {this.state.errorMessage}
                            </p>
                            <p className="success-message">
                              {this.state.successResponse}
                            </p>
                          </div>
                          <div className="submit-row">
                            <ButtonRoundGradient
                              text="Add User"
                              onClick={this.handleSubmit}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.createAdmin,
  userDetails: state.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetAllSpecialities: bindActionCreators(
        reqGetAllSpecialities,
        dispatch
      ),
      reqCreateAdmin: bindActionCreators(reqCreateAdmin, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCreateAdmin);
