import React from 'react'
import Rating from 'react-rating'
import ratingStarFull from '../../../static/images/desktop/rating-star-full.png'
import ratingStarEmpty from '../../../static/images/desktop/rating-star-empty.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";


const ReviewCard = props => (
    <div className="review-card">
        <div className="full-name">
            <span>{ props.review.fullName }</span>
            <span
                className={`arrow-replies ${
                    props.allReviewsWithReplies.includes(props.id)
                    ? " visible"
                    : " hidden"
                }`}
                onClick={props.displayRepliesSection}
                >
                {props.displaySection ? (
                    <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                    <FontAwesomeIcon icon={faAngleDown} />
                )}
            </span>
        </div>
        <div className="rating-container">
            <div>
                <Rating placeholderRating={ props.review.score}
                                        emptySymbol={<img src={ratingStarEmpty} className="icon" alt="ratingEmpty" width="21" height="21"/> }
                                        placeholderSymbol={<img src={ratingStarFull} className="icon" alt="ratingFull" width="21" height="21" />} 
                                        readonly/>
            </div>
            <div className="date-container">{props.review.date}</div>
        </div>
        <div className="review-container">
            <div className="review-content">{ props.review.review }</div>
        </div>
    </div>
)

export default ReviewCard