import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_THERAPIST_ARTICLES, REQ_ADD_THERAPIST_ARTICLE, 
  REQ_UPDATE_THERAPIST_ARTICLE, REQ_REMOVE_THERAPIST_ARTICLE, 
  REQ_UPLOAD_ARTICLE_THUMBNAIL,
  recGetTherapistArticles, recAddTherapistArticles, recUpdateTherapistArticles, 
  recRemoveTherapistArticles, recUploadArticleThumbnail} from './actions'

import fetchData from '../../../utils/api'
import mergeInUserDetails from '../../../utils/mergeInUserDetails'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'


function* getAllArticles(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetTherapistArticles(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* addArticle(action) {
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recAddTherapistArticles(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* updateArticle(action) {
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
  
    if (data.success) {
      const newUserDetails = {...data};
      delete newUserDetails.success
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
    yield put(recUpdateTherapistArticles(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* removeArticle(action) {
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    
    if (data.success) {
      const newUserDetails = {...data};
      delete newUserDetails.success
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
    yield put(recRemoveTherapistArticles(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* uploadThumbnail(action) {
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recUploadArticleThumbnail(data))
   
  } catch (e) {console.log("Api error: ", e)}
}


export default function* therapistManageArticles() {
    yield takeLatest(REQ_GET_THERAPIST_ARTICLES, getAllArticles);
    yield takeLatest(REQ_ADD_THERAPIST_ARTICLE, addArticle);
    yield takeLatest(REQ_UPDATE_THERAPIST_ARTICLE, updateArticle);
    yield takeLatest(REQ_REMOVE_THERAPIST_ARTICLE, removeArticle);
    yield takeLatest(REQ_UPLOAD_ARTICLE_THUMBNAIL, uploadThumbnail);
};  