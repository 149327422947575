import React from 'react'
import './ArticleContent.scss'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import moment from 'moment'
import { Helmet } from "react-helmet"


const renderContent = article => {
    if (!article.body) return;

    return (
        <div>
            <CKEditor
                editor={ ClassicEditor }
                data={article.body}
                disabled={true}
                isReadOnly={true}
                config={{
                    removeButtons: 'Cut,Copy,Paste'
                }}
            />

            <Helmet>
                <script type="text/javascript">
                    {`
                        let articleResizedImages = document.querySelectorAll('.ck-content .image>img');
                        
                        for (var i = 0; i < articleResizedImages.length; ++i) {
                            let item = articleResizedImages[i];  
                            item.height = "100";
                            item.width = "100";
                            item.loading = "lazy";
                        }
                    `}
                </script>
            </Helmet>
        </div>
        
    )
    
}

const ucFirst = (str) => {
    if (!str) return '';
    
    return str.charAt(0).toUpperCase() + str.slice(1)
}

const authroFullName = article => (
    `${article.authorFirstName} ${article.authorLastName} ${article.authorBusinessName}`
)

const formatTimestamp = article => (
    article.published ? moment(article.published).format('LL') : ''
)

const ArticleContent = props => (
    <div id="article-content-container">
        {/* Article Title */}
        <div className="art-con-title-container">
            <h1 className="page-title">{ucFirst((props.article || {}).title)}</h1>
        </div>
        {/* Article Details */}
        {(props.article || {}).type === 'therapy' ? "" : <div className="article-details-container">
          <p className="ad-value">{authroFullName(props.article || {})}</p>
            <p className="ad-value">{formatTimestamp(props.article || {})}</p>
        </div> }
        {/* Article Content */}
        <div className="article-content article-read-only-blg">
            {renderContent(props.article || {})}
        </div>
       
        
    </div>
)

export default ArticleContent
