export const REQ_UPDATE_THERAPIST_DETAILS = 'REQ_UPDATE_THERAPIST_DETAILS'
export const REC_UPDATE_THERAPIST_DETAILS = 'REC_UPDATE_THERAPIST_DETAILS'

export const reqUpdateTherapistDetails = (config) => {
    return {
        type: REQ_UPDATE_THERAPIST_DETAILS,
        config: config
    }
}

export const recUpdateTherapistDetails = data => ({type: REC_UPDATE_THERAPIST_DETAILS, data})


