export const REQ_GET_ARTICLE_GUEST = 'REQ_GET_ARTICLE_GUEST'
export const REC_GET_ARTICLE_GUEST = 'REC_GET_ARTICLE_GUEST'

export const REQ_GET_SIMILAR_ARTICLES = 'REQ_GET_SIMILAR_ARTICLES'
export const REC_GET_SIMILAR_ARTICLES = 'REC_GET_SIMILAR_ARTICLES'

export const REQ_SUBMIT_ART_REVIEW = 'REQ_SUBMIT_ART_REVIEW'
export const REC_SUBMIT_ART_REVIEW = 'REC_SUBMIT_ART_REVIEW'

export const REQ_SUBMIT_ART_REPLY = 'REQ_SUBMIT_ART_REPLY'
export const REC_SUBMIT_ART_REPLY = 'REC_SUBMIT_ART_REPLY'

export const REQ_LIST_ACCEPTED_ARTICLE_REVIEWS = 'REQ_LIST_ACCEPTED_ARTICLE_REVIEWS'
export const REC_LIST_ACCEPTED_ARTICLE_REVIEWS = 'REC_LIST_ACCEPTED_ARTICLE_REVIEWS'

export const REQ_LIST_ACCEPTED_ARTICLE_REPLIES = 'REQ_LIST_ACCEPTED_ARTICLE_REPLIES'
export const REC_LIST_ACCEPTED_ARTICLE_REPLIES = 'REC_LIST_ACCEPTED_ARTICLE_REPLIES'

export const reqGetArticleGuest = (config) => ({type: REQ_GET_ARTICLE_GUEST, config: config})
export const recGetArticleGuest = data => ({type: REC_GET_ARTICLE_GUEST, data})

export const reqGetSimilarArticles = (config) => ({type: REQ_GET_SIMILAR_ARTICLES, config: config})
export const recGetSimilarArticles = data => ({type: REC_GET_SIMILAR_ARTICLES, data})

export const reqSubmitArticleReview = (config) => ({type: REQ_SUBMIT_ART_REVIEW, config: config})
export const recSubmitArticleReview = data => ({type: REC_SUBMIT_ART_REVIEW, data})

export const reqSubmitArticleReply = (config) => ({type: REQ_SUBMIT_ART_REPLY, config: config})
export const recSubmitArticleReply = data => ({type: REC_SUBMIT_ART_REPLY, data})

export const reqListAcceptedArticleReviews = (config) => ({type: REQ_LIST_ACCEPTED_ARTICLE_REVIEWS, config: config})
export const recListAcceptedArticleReviews = data => ({type: REC_LIST_ACCEPTED_ARTICLE_REVIEWS, data})

export const reqListAcceptedArticleReplies = (config) => ({type: REQ_LIST_ACCEPTED_ARTICLE_REPLIES, config: config})
export const recListAcceptedArticleReplies = data => ({type: REC_LIST_ACCEPTED_ARTICLE_REPLIES, data})


