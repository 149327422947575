import React from 'react'
import { stateTwoDigitCode } from '../../../components/StateTwoDigitCode/StateTwoDigitCode'
import GoogleMapReact from 'google-map-react';
import mapMarker from '../../../static/images/desktop/map-marker.png'
import { reqGetTherapistLocationsVisitor } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import RoundLabelGradientBorder from '../../../components/RoundLabelGradientBorder/RoundLabelGradientBorder'
// import { initial } from 'lodash';

const MapParker = ({ text }) => <div><img alt="mapMarker" src={mapMarker} width="55" height="75" />{text}</div>;

class LocationSection extends React.Component {

    state = {
        locations: [],
        selectedLocation: {}
    }
    
    // static defaultProps = {
    //     center: {
    //       lat: 59.95,
    //       lng: 30.33
    //     },
    //     zoom: 11
    //   };
    zoom = 10;

    getLocationsUrl = 'therapist/locations/public';

    componentDidMount = () => {
        this.getTherapistLocations()
    }

    componentWillReceiveProps = (newProps) => {
        // console.log("newProps: ", newProps)
        const apiData = newProps.apiData;

        if (apiData.locVisitor && apiData.locVisitor.success) {
            this.setState(() => ({
                locations: apiData.locVisitor.locations,
                selectedLocation: apiData.locVisitor.locations[0]
            }))
        }
    }

    getTherapistLocations = () => {
        const rConfig = {
            body: {},
            headers: null,
            resource: `${this.getLocationsUrl}?uid=${this.props.therapistId}`
        }

        this.props.actions.reqGetTherapistLocationsVisitor(rConfig)
    }
        
    renderLocations = () => {
        if (!this.state.locations) return;

        const locations = this.state.locations.map( (l, i) => (
            <RoundLabelGradientBorder 
                key={`sp-${i}`} 
                text={l.address.split(',')[0] + ', ' + stateTwoDigitCode(l.region)} 
                className='location-item-small'
                item={l}
                clickHandler={this.updateMap}
            />
            
            ))
            
            return (
            <div className="location-list">
                {locations}
            </div>
        )
    }

    updateMap = (loc) => {
        // console.log("Updating map: ", loc)
        this.setState(() => ({
            selectedLocation: loc
        }))
    }
    
    renderMap = () => {
        if (!this.state.selectedLocation.latitude || !this.state.selectedLocation.longitude) return;

        const loc = this.state.selectedLocation,
              center = {
                  lat: loc.latitude,
                  lng: loc.longitude
              };
        
        return (
            <div className="map-container">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCi54luRONEUHIctIbdrQtva3PPIVkPKjc' }}
                    center={center}
                    defaultZoom={this.zoom}
                    lazyLoad={true}
                    >
                    <MapParker
                        lat={loc.latitude}
                        lng={loc.longitude}
                        text="Location"
                        lazyLoad={true}
                    />
                    
                </GoogleMapReact>
            </div>
        )
    }

    render () {

        if (!this.state.locations.length) return (
            <div></div>
        )

        return (
            <section className="location-data">
                <div className="subsection-title">Locations:</div>
                { this.renderLocations() }
                {this.renderMap()}
            </section>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.publicProfileReducer
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqGetTherapistLocationsVisitor: bindActionCreators(reqGetTherapistLocationsVisitor, dispatch),
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(LocationSection)

