export const REQ_ADD_ARTICLE = 'REQ_ADD_ARTICLE'
export const REC_ADD_ARTICLE = 'REC_ADD_ARTICLE'

export const REQ_GET_ARTICLES = 'REQ_GET_ARTICLES'
export const REC_GET_ARTICLES = 'REC_GET_ARTICLES'

export const REQ_DELETE_ARTICLE = 'REQ_DELETE_ARTICLE'
export const REC_DELETE_ARTICLE = 'REC_DELETE_ARTICLE'

export const REQ_UPDATE_ARTICLE = 'REQ_UPDATE_ARTICLE'
export const REC_UPDATE_ARTICLE = 'REC_UPDATE_ARTICLE'

export const REQ_SEARCH_ARTICLES =  'REQ_SEARCH_ARTICLES'
export const REC_SEARCH_ARTICLES =  'REC_SEARCH_ARTICLES'

export const REQ_ACCEPT_ARTICLE =  'REQ_ACCEPT_ARTICLE'
export const REC_ACCEPT_ARTICLE =  'REC_ACCEPT_ARTICLE'

export const REQ_DECLINE_ARTICLE =  'REQ_DECLINE_ARTICLE'
export const REC_DECLINE_ARTICLE =  'REC_DECLINE_ARTICLE'

export const REQ_UPLOAD_BLOG_THUMBNAIL =  'REQ_UPLOAD_BLOG_THUMBNAIL'
export const REC_UPLOAD_BLOG_THUMBNAIL =  'REC_UPLOAD_BLOG_THUMBNAIL'


export const reqAddArticle = (config) => ({type: REQ_ADD_ARTICLE, config: config})
export const recAddArticle = data => ({type: REC_ADD_ARTICLE, data})

export const reqGetArticles = (config) => ({type: REQ_GET_ARTICLES, config: config})
export const recGetArticles = data => ({type: REC_GET_ARTICLES, data})

export const reqDeleteArticle = (config) => ({type: REQ_DELETE_ARTICLE, config: config})
export const recDeleteArticle = data => ({type: REC_DELETE_ARTICLE, data})

export const reqUpdateArticle = (config) => ({type: REQ_UPDATE_ARTICLE, config: config})
export const recUpdateArticle = data => ({type: REC_UPDATE_ARTICLE, data})

export const reqSearchArticles = (config) => ({type: REQ_SEARCH_ARTICLES, config: config})
export const recSearchArticles = data => ({type: REC_SEARCH_ARTICLES, data})

export const reqAcceptArticle = (config) => ({type: REQ_ACCEPT_ARTICLE, config: config})
export const recAcceptArticle = data => ({type: REC_ACCEPT_ARTICLE, data})

export const reqDeclineArticle = (config) => ({type: REQ_DECLINE_ARTICLE, config: config})
export const recDeclineArticle = data => ({type: REC_DECLINE_ARTICLE, data})

export const reqUploadBlogThumbnail = (config) => ({type: REQ_UPLOAD_BLOG_THUMBNAIL, config: config})
export const recUploadBlogThumbnail = data => ({type: REC_UPLOAD_BLOG_THUMBNAIL, data})
 