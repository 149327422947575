export const REQ_GET_SPECIALITIES_FOR_SEARCH = 'REQ_GET_SPECIALITIES_FOR_SEARCH'
export const REC_GET_SPECIALITIES_FOR_SEARCH = 'REC_GET_SPECIALITIES_FOR_SEARCH'

export const REQ_GET_INSURANCES_FOR_SEARCH = 'REQ_GET_INSURANCES_FOR_SEARCH'
export const REC_GET_INSURANCES_FOR_SEARCH = 'REC_GET_INSURANCES_FOR_SEARCH'

export const REQ_GUEST_SEARCH_THERAPIST = 'REQ_GUEST_SEARCH_THERAPIST'
export const REC_GUEST_SEARCH_THERAPIST = 'REC_GUEST_SEARCH_THERAPIST'

export const REQ_LOG_GUEST_SEARCH = 'REQ_LOG_GUEST_SEARCH'
export const REC_LOG_GUEST_SEARCH = 'REC_LOG_GUEST_SEARCH'

export const reqGetSpecialitiesForSearch = (config) => ({type: REQ_GET_SPECIALITIES_FOR_SEARCH, config: config})
export const recGetSpecialitiesForSearch = data => ({type: REC_GET_SPECIALITIES_FOR_SEARCH, data})

export const reqGetInsurancesForSearch = (config) => ({type: REQ_GET_INSURANCES_FOR_SEARCH, config: config})
export const recGetInsurancesForSearch = data => ({type: REC_GET_INSURANCES_FOR_SEARCH, data})

export const reqGuestSearchTherapist = (config) => ({type: REQ_GUEST_SEARCH_THERAPIST, config: config})
export const recGuestSearchTherapist = data => ({type: REC_GUEST_SEARCH_THERAPIST, data})

export const reqLogGuestSearch = (config) => ({type: REQ_LOG_GUEST_SEARCH, config: config})
export const recLogGuestSearch = data => ({type: REC_LOG_GUEST_SEARCH, data})