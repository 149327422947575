import { createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './sagasRoot'
import reducersRoot from './reducersRoot'

const sagaMiddleware = createSagaMiddleware()

function saveToLocalStorage(state){
   try {
    const serializedState = JSON.stringify(state)
    // console.log("saving serialized state:", serializedState)
    localStorage.setItem('state', serializedState)
    // console.log("saved state to local storage", localStorage.getItem('state'))
   } catch (e) {
       console.log(e)
   }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        // console.log("serializedState: ", serializedState)
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState)
    } catch (e) {
        console.log(e);
        return undefined
    }
}

const persistedState = loadFromLocalStorage()
let store = createStore(
    reducersRoot, 
    persistedState,
    applyMiddleware(sagaMiddleware));

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store

sagaMiddleware.run(rootSaga)
