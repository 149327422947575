import React from 'react'
import './SideDrawer.css'
import loginIcon from '../../static/images/desktop/login_icon.png'
import ButtonRoundGradient from '../ButtonRoundGradient/ButtonRoundGradient'

const sideDrawer = props => (
    <nav className={`side-drawer ${props.className}`}>
        <ul>
            <li className="sidedrawer-nav-item">List Therapists</li>
            <li className="sidedrawer-nav-item">About Us</li>
            <li className="sidedrawer-nav-item">Contact Us</li>
            <li className="sidedrawer-nav-item">Blog</li>
            <li className="sidedrawer-nav-item">
                <div className="loginbutton-container">
                    <img src={loginIcon} className="login-logo" alt="loginIcon"/>
                    <span>Login</span>
                </div>
            </li>
            <li className="sidedrawer-nav-item">
                <div>
                    <ButtonRoundGradient text="Sign Up"/>
                </div>
            </li>
            
        </ul>
    </nav>
)

export default sideDrawer