import {
    REC_ADD_ARTICLE,
    REC_GET_ARTICLES,
    REC_DELETE_ARTICLE,
    REC_UPDATE_ARTICLE,
    REC_SEARCH_ARTICLES,
    REC_ACCEPT_ARTICLE,
    REC_DECLINE_ARTICLE
} from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_ADD_ARTICLE:
            return {
                artCreate: data
            };

        case REC_GET_ARTICLES:
            return {
                articles: data
            };

        case REC_DELETE_ARTICLE:
            return {
                artDeleted: data
            }

        case REC_UPDATE_ARTICLE:
            return {
                insUpdate: data
            }

        case REC_SEARCH_ARTICLES:
            return {
                insSearch: data
            }

        case REC_ACCEPT_ARTICLE:
            return {
                artAccept: data
            }

        case REC_DECLINE_ARTICLE:
            return {
                artDecline: data
            }

        default:
            return state
    }
}
