export const REQ_GET_ARTICLES = 'REQ_GET_ARTICLES'
export const REC_GET_ARTICLES = 'REC_GET_ARTICLES'

export const REQ_SEARCH_ARTICLES_GUEST = 'REQ_SEARCH_ARTICLES_GUEST'
export const REC_SEARCH_ARTICLES_GUEST = 'REC_SEARCH_ARTICLES_GUEST'

export const reqGetArticles = (config) => ({type: REQ_GET_ARTICLES, config: config})
export const recGetArticles = data => ({type: REC_GET_ARTICLES, data})

export const reqSearchArticles = (config) => {
     //console.log("search articles action")
    return {type: REQ_SEARCH_ARTICLES_GUEST, config: config}
}
export const recSearchArticles = data => {
    // console.log("Search articles received action")
    return {type: REC_SEARCH_ARTICLES_GUEST, data}
}
