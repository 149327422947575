import { REC_GET_ARTICLE_GUEST, REC_GET_SIMILAR_ARTICLES, REC_SUBMIT_ART_REVIEW,
    REC_LIST_ACCEPTED_ARTICLE_REVIEWS, REC_LIST_ACCEPTED_ARTICLE_REPLIES, REC_SUBMIT_ART_REPLY } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_ARTICLE_GUEST:
            return { articleLoaded: data }

        case REC_GET_SIMILAR_ARTICLES:
            return { similarArticles: data }
        
        case REC_SUBMIT_ART_REVIEW:
            return { revSubmitted: data }
        
        case REC_SUBMIT_ART_REPLY:
            return { repSubmitted: data }
        
        case REC_LIST_ACCEPTED_ARTICLE_REVIEWS:
            return { acceptedReviews: data }

        case REC_LIST_ACCEPTED_ARTICLE_REPLIES:
            return { acceptedReplies: data }
        
        default:
            return state
    }
}