import React from 'react'
import './CheckboxRound.scss'

class CheckboxRound extends React.Component{
  state = {isChecked: false}

    constructor() {
      super();
      this.state = {isChecked: false};
      this.handleChecked = this.handleChecked.bind(this); // set this, because you need get methods from CheckBox 
    }

    componentWillMount = () => {
      this.setState(() => ({
        isChecked: this.props.isChecked
      }))
    }

    componentWillReceiveProps = (newProps) => {
      this.setState(() => ({
        isChecked: newProps.isChecked
      }))
    }
  
    handleChecked = () => {
      this.setState({isChecked: !this.state.isChecked});
      this.props.handleCheck(this);
    }
  
    render(){
      
      return (
        <div className="container-checkbox-round">
            <div className="round">
                <input type="checkbox" id={this.props.id} onClick= {this.handleChecked} defaultChecked={this.state.isChecked}/>
              <label className="speciality-select-name" htmlFor={this.props.id}>{this.props.specialityName}</label>

            </div>
        </div>
      )
    }
  }
  
 export default CheckboxRound
  