import React    from 'react'
//import { Link } from 'react-router-dom'

import HeaderUser         from '../../../components/HeaderUser/HeaderUser'
import SidebarTherapist   from '../../../components/SidebarTherapist/SidebarTherapist'
import RoundInputGradient from '../../../components/RoundInputGradient/RoundInputGradient'

import InsuranceItem   from './InsuranceItem'
import PopuConfirm     from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper     from '../MainWrapper/MainWrapper'
import AttachedInsItem from './AttachedInsItem'
import MenuStepNavigation from "../../../components/MenuStepNavigation/MenuStepNavigation";

import './Insurances.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'

import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux'
import PopupLoader            from '../../../components/PopupLoader/PopupLoader';

import { reqGetAllInsurances, reqAddInsurance, reqRemoveInsurance } from './actions'


// import ButtonRoundGradient from '../../../components/ButtonRoundGradient'

class TherapistInsurances extends React.Component {

    state = {
        addInsuranceName: '',
        allInsurances: [],
        displayedInsurances: [],
        ownInsurances: [],
        pageLoading: false,
        showPopup: false,
        errorMsg: ''
    }

    therapist = {}
    authHeader = {}
    allInsurancesUrl = 'therapist/insurance/all'
    addInsuranceUrl = 'therapist/insurances/own/add'
    removeInsuranceUrl = 'therapist/insurances/own/remove'
    requiredMemberships = [
        'silver',
        'gold',
        'platinum'
    ]
    

    componentDidMount = () => {

        this.therapist = this.props.userDetails;
        this.authHeader = {  
            Authorization: `Bearer ${this.therapist.token}`,
            "Content-type": "application/json" 
        }

        this.setState(() => ({
            ownInsurances: this.therapist.insurances
        }))

    }


    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;

        if (apiData.addInsurance && apiData.addInsurance.success) {
            // console.log("Insurance Added");
            this.setState(() => ({
                ownInsurances: apiData.addInsurance.insurances,
                pageLoading: false
            }))
        } else if (apiData.removeInsurance && apiData.removeInsurance.success) {
            this.setState(() => ({
                ownInsurances: apiData.removeInsurance.insurances,
                pageLoading: false
            }))
        }
        
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value
        
        this.setState(() => ({
            [inputName]: value
        }))
    }

    filterDisplayedInsurances = () => {
        const searchFilter = this.state.searchInsName.toLowerCase();
        const filteredInsurances = this.state.allInsurances.filter( 
            i => {
                const match = i.name.toLowerCase().indexOf(searchFilter) > -1,
                      notAdded = this.state.attachedInsurances.filter(
                          ati => ati.id === i.id
                        ).length === 0
                
                return match & notAdded
            }
        )
       
        this.setState(() => ({
            displayedInsurances: filteredInsurances
        }))
    }


    renderAvailableInsurances = () => {
        let items = [];
        
        for(let i=0; i< this.state.displayedInsurances.length; i++) {

            let insurance = this.state.displayedInsurances[i]
            
            items.push(
                <InsuranceItem index={insurance.id} 
                                key={insurance.id} 
                                insurance={insurance}
                                attachAction={this.attachInsuranceHandler}/>
            )
        }
        
        if (!items.length) {
            return (
                <div className="message-row">
                    <p>There are no more available insurances to add.</p>
                </div>
            )
        }

        return (
            <ul className="insurances-list">
                {items}
            </ul>
        )

    }

    attachInsuranceHandler = (insId) => {
        const rConfig = {
            body: {
                insurance: insId
            },
            headers: this.authHeader,
            resource: this.addInsuranceUrl,
            userDetails: this.props.userDetails
        }

        this.props.actions.reqAddInsurance(rConfig)
    } 

    handleRemoveInsurance = (insId) => {
        this.setState(() => ({
            showPopup: true,
            removeId: insId,
            popupText: 'Confirm removing this from your insurances?'
        })) 
    }

    confirmRemove = () => {
        const rConfig = {
            body: {
                insuranceId: this.state.removeId
            },
            headers: this.authHeader,
            resource: this.removeInsuranceUrl,
            userDetails: this.props.userDetails
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqRemoveInsurance(rConfig)
        })
    }

    renderOwnInsurances = () => {
        const items = []

        for (let i = 0; i < this.state.ownInsurances.length; i++) {
            const insurance = this.state.ownInsurances[i];
            items.push(
                <AttachedInsItem index={insurance.id}
                                key={insurance.id}
                                insurance={insurance}
                                removeAction={this.handleRemoveInsurance} />
            )
        }

        return items;
    }

    attachSpecialityHandler = (spId) => {
        const rConfig = {
            body: {
                specialities: `${spId}`
            },
            headers: this.authHeader,
            resource: this.addSpecialityUrl,
            userDetails: this.props.userDetails
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqAddSpeciality(rConfig)
        })
        
    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    handleSubmit = () => {
        this.setState({
            errorMsg: ''
        }, () => {
            if (!this.formValid()) return;
            this.addOwnInsurance()
        })
    }

    addOwnInsurance = () => {
        const rConfig = {
            body: {
                insuranceName: this.state.addInsuranceName
            },
            headers: this.authHeader,
            resource: this.addInsuranceUrl,
            userDetails: this.props.userDetails
        }

        this.props.actions.reqAddInsurance(rConfig)
    }

    formValid = () => {
        if (this.state.addInsuranceName !== '') {
            return true;
        } else {
            this.setState({
                errorMsg: 'Insurance Name is mandatory'
            }, () => {
                return false
            })
        }
    }


    hasAccessToContent = () => {
        let cPlan = this.therapist.membershipPlan,
            hasPlan = this.requiredMemberships.filter(m => m === cPlan).length,
            isBeta = this.props.userDetails.betaUser;
        
        return hasPlan || isBeta;
    }

    renderContent = () => (
        this.hasAccessToContent() 
            ? this.renderInsurancesSection()
            : this.renderUnauthrizedSection()
    )

    renderInsurancesSection = () => (
        <div className="inner-content">
            <MenuStepNavigation
                leftLink="/therapist-locations"
                leftText="My Locations"
                rightLink="/articles"
                rightText="Write An Article"
            />
            <div className="top-row">
                {/* Title */}
                <h1 className="page-title">Insurances Accepted By You</h1>
            </div>

            <div className="attached-specialities-section">
                <ul className="attached-specialities-list">
                    {this.renderOwnInsurances()}
                </ul>
                
            </div>

            <div className="add-section">
                
                <h2 className="page-title">Add Insurances</h2>
                <div className="add-row">
                    <RoundInputGradient 
                        placeholder="Insurance Name" 
                        className="ins-add-input"
                        inputName="addInsuranceName"
                        value={this.state.addInsuranceName}
                        onChange={this.handleChange}/>
                    
                    <div 
                        className="btn btn-default add-ins-btn"
                        onClick={this.handleSubmit}
                        >Add</div>
                </div>  
                <div className="error-message">{this.state.errorMsg}</div>
                
            </div>

        </div>
    )

    renderUnauthrizedSection = () => (
        <div className="inner-content">
        <MenuStepNavigation
                leftLink="/therapist-locations"
                leftText="My Locations"
                rightLink="/payment"
                rightText="Choose A Membership Plan"
            />
            <div className="not-authorized-section">
                <div className="top-row">
                    {/* Title */}
                    <h1 className="page-title">Insurances Accepted By You</h1>
                </div>

                <div className="not-authorized-description">
                    You need at least a silver membership plan to manage your insurances
                </div>
            </div>
        </div>
    )

    render () {
        return (
            <MainWrapper page="insurances">
                <div id="therapist-manage-insurances">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup} 
                                 text={this.state.popupText}
                                 title={this.state.popupTitle}
                                 hidePopup={this.hidePopup}
                                 calcelAction={this.hidePopup}
                                 confirmAction={this.confirmRemove}/>

                    <HeaderUser adminFullName="John Doe"/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarTherapist page="insurances" roleId={this.therapist.roleId}/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                { this.renderContent() }
                            </div>
                        </div>
                    </div>
                </div>

            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.therapistManageInsurances,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqGetAllInsurances: bindActionCreators(reqGetAllInsurances, dispatch),
        reqAddInsurance: bindActionCreators(reqAddInsurance, dispatch),
        reqRemoveInsurance: bindActionCreators(reqRemoveInsurance, dispatch)
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(TherapistInsurances)

