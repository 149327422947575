import React from 'react';
import { Link } from 'react-router-dom'
import HeaderGuest from '../../components/Header/HeaderGuest'
import Footer from '../../components/Footer/Footer'
import { connect } from 'react-redux'
import providerProfilesImg from '../../static/images/desktop/provider_profiles_img.png'
import becomeMember from '../../static/images/desktop/become-a-member.png'

import '../Home/Home.scss'
import './LandingPage.scss'

class LandingPage extends React.Component {

    state = {
        sideDrawerOpen: false
    };

    listSpecialitiesUrl = 'specialities/list';

    drawerToggleClickHandler = () => {
        this.setState((prevState) => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen }
        })
    };

    componentWillReceiveProps = (newProps) => {
        let apiData = newProps.apiData;

        if (apiData.featuredArticle && apiData.featuredArticle.success) {
            this.setState(() => ({
                featuredArticle: apiData.featuredArticle.article
            }))
        }

    };

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => {
            return { [inputName]: value }
        })
    };

    render() {

        const landingPageContent = (
            <div>
                <h1>HOW CAN AUTISM ASSISTANT HELP ME?</h1>
                <p>The day you find out that a family member, or even your very child has been placed somewhere on the Autism Spectrum by a specialist, is one of the hardest of your life. This is precisely why this website exists. My name is Emily Swank, and through this online portal, I aim to make your transition into this new reality as bearable as possible. </p>
                <h3>A List of Useful Resources in One Place</h3>
                <p>The first few days after the diagnosis, you can easily become lost in the new “sea” of acronyms. This was definitely <a href='/about-us'>my case</a>. You keep hearing about ABA, OT, PT, and other very confusing terms. At the end of the day, all of this translates into a single thing: many, many phone calls. Phone calls towards government funded programs, phone calls towards your insurance, and especially, phone calls towards therapists.</p>
                <p>That’s where it all becomes very complicated. You do all of your research about what Feeding Therapy, Speech Therapy, Developmental Pediatricians and other associated concepts are. However, when it comes to actually getting in touch with a specialist, you find that they don’t work with your insurance, or they’re too far away, or worse, they’re in fact not the one that your child needs.</p>
                <p>I know how hard this is, because I’ve been in your shoes. As such, the focus of Autism Assistant is to offer you a simple to use database of all kinds of specialists in the field. On our site, you can find the following specialists and treatments, using <a href="#searchFormSpecialist">the form at the top of the page:</a></p>
                <div className="landingList">
                    <ul>
                        <li>Applied Behavioral Analysis (ABA)</li>
                        <li>Developmental Pediatrician</li>
                        <li>Equine Therapy</li>
                        <li>Feeding Therapy</li>
                        <li>Music Therapy</li>
                        <li>Occupational Therapy</li>
                        <li>Pediatric Dentistry</li>
                        <li>Pediatrician</li>
                        <li>Physical Therapy</li>
                        <li>Social Skills</li>
                        <li>Speech Therapy</li>
                    </ul>
                </div>
                <h3>An Inclusive Environment for Learning & Improving</h3>
                <p>Aside from being a tool for you to find the right specialists for your needs, Autism Assistant is also dedicated to helping you with your child’s integration with those around them. Autism isn’t as foreign as it was even five years ago. Today, there are special preschool programs, camps, and even travel accommodations for people on the spectrum.</p>
                <p>We aim to list as many of these as possible, as well as partner with relevant programs, such as K-12 schools, <strong>parent training programs</strong>, after school programs, and more. Additionally, we fully support the employment of people on the spectrum, so our members will have the option to post job openings.</p>
                <div><strong>Get In Touch with Our Community</strong></div>
                <p>If you’d like to search for a therapist straightaway, check our <a href="/specialists">Provider Profiles</a>. Alternatively, head over to <a href="/blog">our blog</a> for information about the specifics of ASD, how to better cope with the diagnosis, and treatment options. </p>
                <p>Want to <a href="/contact">contact us</a> directly? We’ll get back to your questions and comments as soon as possible!</p>
            </div>
        );

        return (
            <div id="landingPage">
                <HeaderGuest
                    menu="home"
                    menuVisible={this.state.sideDrawerOpen}
                    drawerClickHandler={this.drawerToggleClickHandler}
                />
                <section className="container landingPageContainer">
                    {landingPageContent}
                </section>
                <section className="container mt-80 containerLanding">
                    <div className="rowItem">
                        <div className="rowItemTop">
                            <h2>Provider Profiles</h2>
                            <img src={providerProfilesImg} alt="alt" loading="lazy"/>
                        </div>
                        <div className="rowItemBottom">
                            <p>Search for and review Provider profiles to find the right fit for your needs</p>
                            <Link to="specialists">
                                <button className="btn btn-default">See Provider Profiles</button>
                            </Link>
                        </div>
                    </div>

                    <div className="rowItem">
                        <div className="rowItemTop">
                            <h2>Become a Member</h2>
                            <img src={becomeMember} alt="become a member" loading="lazy"/>
                        </div>
                        <div className="rowItemBottom">
                            <p>Do you write blogs about autism and would like to be featured on our site?</p>
                            <Link to="/signup">
                                <button className="btn btn-default become-member--btn">Join Now</button>
                            </Link>
                        </div>
                    </div>
                </section>

                <Footer page="home"/>
            </div>
        )
    }

}

const mapStateToProps = state => ({ apiData: state.homeReducer })

export default connect(mapStateToProps)(LandingPage)
