import React from 'react'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import './Dashboard.scss'
// import { Redirect } from 'react-router-dom'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import MainWrapper from '../MainWrapper/MainWrapper'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { reqGetAllAnalitics, reqGetAllAnaliticPoints } from './actions'
import DataCard from './DataCard'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import moment from 'moment';
import { VictoryChart, VictoryTheme, VictoryArea } from 'victory'

class AdminDashboard extends React.Component {

    state = {
        analitics: {},
        timerange: 'last7',
        filter: {},
        chartName: 'searches',
        searchPoints: [],
        homeVisitPoints: [],
        profileVisitPoints: [],
    };

    admin = {};
    authHeaders = {};
    getAnaliticsUrl = 'analitics/statistics/all';
    getAnaliticPointsUrl = 'analitics/statistics/all/points';

    timeranges = [
        { label: 'Last 7 Days', val: 'last7' },
        { label: 'Last 28 Days', val: 'last28' },
        { label: 'Last 365 Days', val: 'last365' },
        { label: 'From the begining', val: 'all' },
    ];

    componentDidMount = () => {
        this.admin = this.props.userDetails;
        this.authHeaders = {
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json"
        };

        let filter = this.generateTimeFilter(this.state.timerange);

        this.setState({
            filter: filter
        }, () => {
            this.loadAnalitics()
            this.loadAnaliticPoints()
        })

    };

    componentWillReceiveProps = (newProps) => {
        if (newProps.apiData && newProps.apiData.analitics && newProps.apiData.analitics.success) {
            let analitics = newProps.apiData.analitics.analitics;
            this.setState(() => ({
                analitics: analitics,
                pageLoading: false
            }))
        } else if (newProps.apiData && newProps.apiData.analiticPoints && newProps.apiData.analiticPoints.success) {
            this.setState({
                analiticPoints: newProps.apiData.analiticPoints.analitics,
                pageLoading: false
            }, () => { this.processAnaliticPoints() })
        }
    };

    // Process the analitic datapoints and format them so they can be displayed on a chart
    processAnaliticPoints = () => {
        let daysToPast = parseInt(this.state.timerange.replace('last', ''));
        // console.log("daysToPast: ", daysToPast)
        let searchPoints = [],
            homeVisitPoints = [],
            profileVisitPoints = [];

        for (let i = 0; i < daysToPast; i++) {
            let date = null;
            if (i === 0) {
                date = moment(new Date())
            } else {
                date = moment(new Date()).add((-1 * i), 'days')
            }

            let rangeStart = date.startOf('day').valueOf(),
                rangeEnd = date.endOf('day').valueOf(),
                label = date.format('L')
            const { analiticPoints } = this.state;

            let searchesInRange = analiticPoints.searches
                .filter(s => (s.timestamp >= rangeStart && s.timestamp <= rangeEnd)).length;

            let homeVisitsInRange = analiticPoints.homeVisits
                .filter(s => (s.timestamp >= rangeStart && s.timestamp <= rangeEnd)).length;

            let profileVisitsInrange = analiticPoints.profileVisits
                .filter(s => (s.timestamp >= rangeStart && s.timestamp <= rangeEnd)).length;

            searchPoints.push({
                dayNr: daysToPast - i,
                count: searchesInRange,
                dayLabel: label
            });

            homeVisitPoints.push({
                dayNr: daysToPast - i,
                count: homeVisitsInRange,
                dayLabel: label
            });

            profileVisitPoints.push({
                dayNr: daysToPast - i,
                count: profileVisitsInrange,
                dayLabel: label
            })
        }

        this.setState({
            searchPoints: searchPoints,
            homeVisitPoints: homeVisitPoints,
            profileVisitPoints: profileVisitPoints
        })
    };

    loadAnalitics = () => {
        let q = this.generateAnaliticsUrl();
        const rConfig = {
            body: {},
            headers: null,
            resource: `${this.getAnaliticsUrl}${q}`
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqGetAllAnalitics(rConfig)
        })

    }

    loadAnaliticPoints = () => {
        let q = this.generateAnaliticsUrl()
        const rConfig = {
            body: {},
            headers: null,
            resource: `${this.getAnaliticPointsUrl}${q}`
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqGetAllAnaliticPoints(rConfig)
        })
    }

    generateAnaliticsUrl = () => {
        let start = this.state.filter.start,
            end = this.state.filter.end,
            q = (start && end) ? `?start=${start}&end=${end}` : '';

        return q;
    }

    renderCards = () => {

        let cards = Object.keys(this.state.analitics).map((k, i) => (
            <DataCard
                key={i}
                label={k}
                value={this.state.analitics[k]}
            />
        ))

        return (
            <div className="cards-row">
                {cards}
            </div>
        )
    }

    renderCharts = () => {

        let sampleData = []
        let sampleCategories = {}




        switch (this.state.chartName) {
            case "searches":
                sampleData = this.state.searchPoints.map(p => ({ x: p.dayNr, y: p.count }))
                this.state.searchPoints.length < 8 ? sampleCategories = {x: this.state.searchPoints.map(p => (p.dayLabel))} : sampleCategories = {}
                break
            case "profile":
                sampleData = this.state.homeVisitPoints.map(p => ({ x: p.dayNr, y: p.count }))
                this.state.homeVisitPoints.length < 8 ? sampleCategories = {x: this.state.homeVisitPoints.map(p => (p.dayLabel))} : sampleCategories = {}
                break
            case "visits":
                sampleData = this.state.profileVisitPoints.map(p => ({ x: p.dayNr, y: p.count }))
                this.state.profileVisitPoints.length < 8 ? sampleCategories = {x: this.state.profileVisitPoints.map(p => (p.dayLabel))} : sampleCategories = {}
                break
            default:
                sampleData = this.state.searchPoints.map(p => ({ x: p.dayNr, y: p.count }))
                this.state.searchPoints.length < 8 ? sampleCategories = {x: this.state.searchPoints.map(p => (p.dayLabel))} : sampleCategories = {}
                break
        }

        return (
            <div>
                <svg style={{ height: 0 }}>
                    <defs>
                        <linearGradient id="gradiant">
                            <stop offset="0%" stopColor="rgba(252, 211, 45)" />
                            <stop offset="70%" stopColor="rgba(255, 53, 153)" />
                            <stop offset="100%" stopColor="rgba(255, 53, 153)" />
                        </linearGradient>
                    </defs>
                </svg>
                <VictoryChart
                    theme={VictoryTheme.material}
                    width={750}
                    height={300}
                >
                    <VictoryArea

                        style={{
                            data: { fill: "url(#gradiant)", fillOpacity: 0.2, stroke: "url(#gradiant)", strokeWidth: 5 },
                            labels: { fontSize: 9, fill: "#e0e0e0" }
                        }}
                        data = {sampleData}
                        categories = {sampleCategories}
                    />
                </VictoryChart>
            </div>
        )
    }

    handleCharts = (title) => {
        this.setState({ chartName: title })
    }

    handleChange = (e) => {
        let name = e.target.name,
            val = e.target.value,
            newState = { [name]: val }

        if (name === 'timerange' && val !== 'all') {
            let filter = this.generateTimeFilter(val);
            newState.filter = filter
        }

        this.setState(newState, () => {
            this.loadAnalitics()
            this.loadAnaliticPoints()
        });

    }

    generateTimeFilter = (range) => {
        if (range === 'all') {
            return {}
        }

        let minusDays = parseInt(range.replace('last', '')),
            dt = new Date(),
            start = new Date(dt.setDate(new Date().getDate() - minusDays)).getTime(),
            end = new Date().getTime();

        return {
            start: start,
            end: end
        }

    }

    generateRanges = () => {

        let options = this.timeranges.map(r => (
            <option
                key={r.val}
                value={r.val}
            >{r.label}</option>
        ))

        return (
            <div className="timrange-container">
                <select
                    name="timerange"
                    value={this.state.timerange}
                    onChange={this.handleChange}
                >
                    {options}
                </select>
            </div>
        )
    }

    render() {

        return (
            <MainWrapper>
                <div id="admin-dashboard">
                    <PopupLoader display={this.state.pageLoading} />
                    <HeaderUser />
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="admin-dashboard" />
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row title-row">
                                        {/* Title */}
                                        <h1 className="page-title">Analitics Overview</h1>
                                        {this.generateRanges()}
                                    </div>
                                    {this.renderCards()}

                                    <div className="charts-row">
                                        <div className="charts-background">
                                            <div className="charts-names">
                                                <div
                                                    className={this.state.chartName === "searches" ? 'chart-title active' : 'chart-title'}
                                                    onClick={() => this.handleCharts("searches")}>
                                                    <p>Searches</p>
                                                    <p>50</p>
                                                </div>
                                                <div
                                                    className={this.state.chartName === "profile" ? 'chart-title active' : 'chart-title'}
                                                    onClick={() => this.handleCharts("profile")}>
                                                    <p>Click to profile</p>
                                                    <p>100</p>
                                                </div>
                                                <div
                                                    className={this.state.chartName === "visits" ? 'chart-title active' : 'chart-title'}
                                                    onClick={() => this.handleCharts("visits")}>
                                                    <p>Homepage visits</p>
                                                    <p>15</p>
                                                </div>

                                            </div>
                                            <div className="chart">
                                                {this.renderCharts()}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails,
    apiData: state.analitics
})

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            reqGetAllAnalitics: bindActionCreators(reqGetAllAnalitics, dispatch),
            reqGetAllAnaliticPoints: bindActionCreators(reqGetAllAnaliticPoints, dispatch),
        }
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
