import React from 'react'
import './RequestResetPassword.scss'
import bottomRightEllipse from '../../static/images/desktop/reset-pwd-bottom-right-ellipse.png';
import bottomRightShape from '../../static/images/desktop/reset-pwd-bottom-right-shape.png';
import topleftShape from '../../static/images/desktop/reset-pwd-left-shape.png';
import topRightEllipse from '../../static/images/desktop/reset-pwd-right-ellipse.png';
import logoText from '../../static/images/desktop/logo-text-blue.png';
import ButtonRoundGradient from '../../components/ButtonRoundGradient/ButtonRoundGradient';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reqRequestResetPassword } from './actions';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Redirect } from 'react-router-dom'
import PopupLoader from '../../components/PopupLoader/PopupLoader';


class RequestResetPassword extends React.Component {

    state = {
        email: '',
        errors: [],
        redirect: false,
        redirectUrl: '/profile',
        pageLoading: false
    }

    submitUrl = 'resetPassword';

    componentDidMount = () => {

        this.setState( () => ({
            email: '',
            errors: [],
            redirect: false,
            redirectUrl: '/profile',
            pageLoading: false
        }))
        if ((this.props.userDetails || {}).uid) {
            this.setState( () => ({redirect: true}))
        }
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState( () => ({pageLoading: false}))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    }

    handleChange = (event) => {

        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({[inputName]: value}))
    }

    handleSubmit = () => {

        this.setState({
            errors: [],
            pageLoading: true
        }, () => {

            const formValid = this.validateForm()

            if (!formValid) {return;}

            const rBody = {
                email: this.state.email,
            }

            this.props.reqRequestResetPassword(rBody, this.submitUrl);
        })

    }

    validateForm = () => {

        let formValid = true;

        if (this.state.email === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'email',
                    message: 'Email is mandatory'}
                ],
                pageLoading: false
            }))
        }

        return formValid
    }

    displayErrorMessage = () => {
        const errors = this.state.errors;

        return errors.length ? errors[0].message : ''
    }

    displayApiResponse = () => {
        let response = '';
        const apiData = this.props.apiData;

        if (!apiData) return '';

        response = apiData.success ? apiData.message : '';

        return response;
    }

    displayApiError = () => {
        let error = '';
        const apiData = this.props.apiData;

        if (!apiData) return '';

        error = apiData.error ? apiData.display ? apiData.error : 'An error occured' : '';

        return error;
    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to={this.state.redirectUrl} />
            )
        }

        return (
            <div id="reset-password">

                <PopupLoader display={this.state.pageLoading} />

                <div className="page-background">
                    <img src={topleftShape} alt="topLeftShape" className="top-left-shape"/>
                    <img src={topRightEllipse} alt="topLeftShape" className="top-right-ellipse"/>
                    <img src={bottomRightShape} alt="topLeftShape" className="bottom-right-shape"/>
                    <img src={bottomRightEllipse} alt="topLeftShape" className="bottom-right-ellipse"/>
                </div>

                <div className="page-content">
                    <Link to="/">
                        <img src={logoText} alt="logo" className="logo" />
                    </Link>
                    <h1 className="page-title">Request Password Reset</h1>
                    <div className="text-row">
                        <p className="grey-text">Please enter your email below and a link to reset your password will be sent to you</p>
                    </div>
                    <div className="form-container">
                        <form>
                            <div className="input-row">
                                <input type="text"
                                       placeholder="Email"
                                       name="email"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("email")}`}/>
                            </div>

                            <div>
                                <p className="error-message">{this.displayErrorMessage()}</p>
                                <p className="error-message">{this.displayApiError()}</p>
                                <p className="success-message">{this.displayApiResponse()}</p>
                            </div>

                            <div className="reset-row">
                                <ButtonRoundGradient text="Request Password Reset" onClick={this.handleSubmit}/>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    apiData: state.requestResetPassword,
    userDetails: state.userDetails

})

const mapDispatchToProps = dispatch => bindActionCreators({reqRequestResetPassword}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RequestResetPassword)
// export default RequestResetPassword
