import React, { Component } from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement, injectStripe } from 'react-stripe-elements'
import '../Payment/CheckoutForm.scss'
import { reqSaveStripeCustomer, reqRemoveUserCard } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PopupConfirm from '../../../components/PopupConfirm/PopupConfirm'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';

class CheckoutForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      complete: false,
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      userHasCard: false,
      changeCard: false
    }
    this.submit = this.submit.bind(this)
  }

  saveCustomerUrl = "payment/stripe/customer/register"
  removeCardUrl = 'customer/card/remove'
  changeCardUrl = 'customer/card/change'
  authHeader = {}
  therapist = {}

  componentDidMount = () => {

    this.therapist = this.props.userDetails;
    this.authHeader = {
      Authorization: `Bearer ${this.therapist.token}`,
      "Content-type": "application/json"
    }

    if (this.props.userDetails.cardData) {
      this.setState(() => ({
        userHasCard: true
      }))
    }
    // console.log("userDetails: ", this.props.userDetails)
  }

  componentWillReceiveProps = (newProps) => {
    const apiData = newProps.apiData;

    if (apiData.saveStripeCustomer && apiData.saveStripeCustomer.success) {
      this.setState(() => ({
        userHasCard: true,
        pageLoading: false,
        changeCard: false
      }))
    } else if (apiData.saveStripeCustomer && apiData.saveStripeCustomer.error) {
      this.setState(() => ({
        pageLoading: false,
        errorMessage: apiData.saveStripeCustomer.message
      }))
      this.stripeErrorHandler(apiData.saveStripeCustomer.message)
    } else if (apiData.cardRemoved && apiData.cardRemoved.success) {
      this.setState(() => ({
        userHasCard: false,
        pageLoading: false
      }))
    }


  }

  async submit(ev) {

    if (!this.validateCardData()) return;

    this.setState({
      pageLoading: true
    }, async () => {
      let { token } = await this.props.stripe.createToken({ name: "Name" })
      const rConfig = {
        body: {
          stripeToken: token.id
        },
        headers: this.authHeader,
        resource: this.state.changeCard ? this.changeCardUrl : this.saveCustomerUrl,
        userDetails: this.props.userDetails
      }

      this.props.actions.reqSaveStripeCustomer(rConfig);
    })

  }

  stripeErrorHandler = (errorMessage) => {
    
    this.setState(() => ({
      showPopup: true,
      popupText: errorMessage,
      popupTitle: 'Payment error',
      confirmAction: this.hidePopup,
      confirmText: 'Ok',
      cancelText: ' '
    }))
  }

  handleChangeCardDetails = (ev) => {
    let newState = {}
    newState[ev.elementType] = ev.complete

    this.setState(() => (newState))
  }

  validateCardData = () => {

    let formValid = true

    // Card Nr Validation
    if (!this.state.cardNumber) {
      this.setState(() => ({
        errorMsg: 'Card Number is invalid'
      }))
      formValid = false;
    }
    // Expiry Validation
    if (!this.state.cardExpiry) {
      this.setState(() => ({
        errorMsg: 'Card Expiry is invalid'
      }))
      formValid = false;
    }
    // Cvc Validation
    if (!this.state.cardCvc) {
      this.setState(() => ({
        errorMsg: 'Card CVC is invalid'
      }))
      formValid = false;
    }

    if (this.state.cardNumber && this.state.cardExpiry && this.state.cardCvc) {
      this.setState(() => ({
        errorMsg: ''
      }))
      formValid = true;
    }


    return formValid
  }

  handleChangeCard = () => {
    this.setState(() => ({
      changeCard: true
    }))
  }

  handleRemoveCard = () => {
    if (this.therapist.membershipPlan !== 'free') {
      this.setState(() => ({
        showPopup: true,
        popupText: 'Please change plan to Free Plan before removing your card',
        popupTitle: 'Change plan',
        confirmText: "Ok",
        cancelText: " "
      }))
    } else {
      this.setState(() => ({
        showPopup: true,
        popupText: 'Are you sure you want to remove your card? Your active subscription will be cancelled as well',
        popupTitle: 'Remove Card?',
        confirmAction: this.confirmRemoveCard,
        confirmText: "Yes",
        cancelText: "No"
      }))
    }

  }

  confirmRemoveCard = () => {
    //console.log("Confirmed Removing Card");
    let rConfig = {
      body: {},
      headers: this.authHeader,
      resource: this.removeCardUrl,
      userDetails: this.props.userDetails
    }

    this.setState({
      pageLoading: true
    }, () => {
      this.props.actions.reqRemoveUserCard(rConfig)
    })
  }

  hidePopup = () => {
    this.setState(() => ({
      showPopup: false
    }))
  }

  renderCardForm = () => {

    return (
      <div className="checkout-form">
        <p>Please enter your card information</p>
        {/* Card Number */}
        <div className="card-number input-outer">
          <div className="input-inner">
            <CardNumberElement onChange={this.handleChangeCardDetails} />
          </div>
        </div>
        {/* Expiry Date */}
        <div className="expiry input-outer">
          <div className="input-inner">
            <CardExpiryElement onChange={this.handleChangeCardDetails} />
          </div>
        </div>
        {/* CVC */}
        <div className="cvc input-outer">
          <div className="input-inner">
            <CardCvcElement onChange={this.handleChangeCardDetails} />
          </div>
        </div>

        <div className="error-msg-container">{this.state.errorMsg}</div>

        {/* Button */}
        <div className="send-button">
          <button className="btn btn-default btn-smalll" onClick={this.submit}>Save Card</button>
        </div>
      </div>
    )
  }

  renderCardCompleted = () => {

    if (!this.props.userDetails.cardData) return '';

    let el = (
      <div className="card-completed-container">
        <div className="saved-card-border">
          <div className="saved-card-inner">
            <span className="card-type-value">{this.props.userDetails.cardData.brand}</span>
            <span className="card-number-value">{` * * * * * * * * * * * * ${this.props.userDetails.cardData.last4}`}</span>
          </div>
        </div>
        <div className="cc-buttons">
          <button type="button" className="btn btn-default btn-smalll" onClick={this.handleChangeCard}>Change Card</button>
          <button type="button" className="btn btn-default btn-smalll" onClick={this.handleRemoveCard}>Remove Card</button>
        </div>
      </div>
    )

    return el;
  }

  renderFormSection = () => {

    return (this.state.userHasCard && !this.state.changeCard) ? this.renderCardCompleted() : this.renderCardForm()

  }

  render() {
    if (this.state.complete) return <h1>Purchase Complete</h1>

    return (
      <div className="checkout">
        <PopupLoader display={this.state.pageLoading} />
        <PopupConfirm display={this.state.showPopup}
          text={this.state.popupText}
          title={this.state.popupTitle}
          hidePopup={this.hidePopup}
          confirmText={this.state.confirmText}
          cancelText={this.state.cancelText}
          calcelAction={this.hidePopup}
          confirmAction={this.state.confirmAction} />
        {this.renderFormSection()}
      </div>

    );
  }
}

const mapStateToProps = state => ({
  apiData: state.therapistManagePayment,
  userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqSaveStripeCustomer: bindActionCreators(reqSaveStripeCustomer, dispatch),
      reqRemoveUserCard: bindActionCreators(reqRemoveUserCard, dispatch),
    }
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(CheckoutForm))
