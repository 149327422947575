import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_ADD_LOCATION, REQ_GET_ALL_LOCATIONS, REQ_DEL_LOCATION,
  recAddLocation, recGetAllLocations, recDelLocation} from './actions'

import fetchData from '../../../utils/api'
// import mergeInUserDetails from '../../../utils/mergeInUserDetails'
// import { recFetchUserDetails } from '../../../utils/FetchUser/actions'


function* addLocation(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recAddLocation(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* getAllLocations(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetAllLocations(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* deleteLocation(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recDelLocation(data))
   
  } catch (e) {console.log("Api error: ", e)}
}


export default function* manageLocationsSaga() {
    yield takeLatest(REQ_ADD_LOCATION, addLocation);
    yield takeLatest(REQ_GET_ALL_LOCATIONS, getAllLocations);
    yield takeLatest(REQ_DEL_LOCATION, deleteLocation);

};  