import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_ARTICLES, REQ_SEARCH_ARTICLES_GUEST, 
         recGetArticles, recSearchArticles} from './actions'

import fetchData from '../../utils/api'


function* getArticles(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetArticles(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* searchArticles(action) {

  try {
    // console.log("Search articles saga")
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recSearchArticles(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

export default function* adminManageArticles() {
    yield takeLatest(REQ_GET_ARTICLES, getArticles);
    yield takeLatest(REQ_SEARCH_ARTICLES_GUEST, searchArticles);
};