import { REC_LIST_MESSAGES_ADMIN} from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_LIST_MESSAGES_ADMIN:
            if (data.status === 0) {
                return {
                    newSupportMessages: data
                };
            } else {
                return {
                    readSupportMessages: data
                }
            }
            
        
        default:
            return state
    }
}