import { REC_LIST_ADMINS, REC_SEARCH_ADMINS, REC_ADMIN_LOCK, REC_ADMIN_DELETE } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_LIST_ADMINS:
            return {
                adminList: data
            };

        case REC_SEARCH_ADMINS:
            return {
                adminSearch: data
            };

        case REC_ADMIN_LOCK:
            return {
                adminLock: data
            };

        case REC_ADMIN_DELETE:
            return {
                adminDelete: data
            };
        
        default:
            return state
    }
}