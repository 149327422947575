import React from "react";
import HeaderUser from "../../../components/HeaderUser/HeaderUser";
import SidebarTherapist from "../../../components/SidebarTherapist/SidebarTherapist";
import "./Profile.scss";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ButtonRoundGradient from "../../../components/ButtonRoundGradient/ButtonRoundGradient";
import {
  reqUpdateTherapistProfile,
  reqUpdateTherapistPassword,
  reqEmailConfirmed
} from "./actions";
import { reqFetchUserDetails } from "../../../utils/FetchUser/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainWrapper from "../MainWrapper/MainWrapper";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import { Popup } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import uploadImage from "../../../utils/uploadImage";
import { Link } from 'react-router-dom'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Resizer from "react-image-file-resizer";
//import loginIcon from "../../../static/images/desktop/login_icon.png";

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

class TherapistProfile extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    website: "",
    bio: "",
    title: "",
    emailVerified: true,
    phone: "",
    errors: [],
    pageLoading: false,
    activeTabItem: "account",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    profileImageName: "",
    check: true,
    content:"",
    businessName: "",
    membershipPlan: '',
    networking: false
  };

  therapist = {};
  autheHeaders = {};
  submitUrl = "therapist/account/update"
  emailVerifiedUrl = "user/profile/confirm-email"
  uploadProfileImgUrl = 'user/profile/update/image'
  updatePasswordUrl = "user/profile/updatePassword"

  
  componentDidMount = () => {
    this.therapist = this.props.userDetails;
    this.autheHeaders = {
      Authorization: `Bearer ${this.therapist.token}`,
      "Content-type": "application/json"
    };

    this.checkEmailVerified()

    const newState = {
      email: this.therapist.email || "",
      userName: this.therapist.userName || "",
      firstName: this.therapist.firstName || "",
      lastName: this.therapist.lastName || "",
      businessName: this.therapist.businessName ||"",
      website: this.therapist.website || "",
      bio: this.therapist.bio || "",
      phone: this.therapist.phone || "",
      title: this.therapist.title || "",
      profileImageName: this.therapist.profileImageName,
      membershipPlan: this.therapist.membershipPlan,
      emailVerified: this.therapist.active,
      networking: this.therapist.networking ? true : false
    };

    this.setState(() => newState);
    
  };

  componentWillReceiveProps = newProps => {
    let apiData = newProps.apiData;

    // console.log("newProps profile: ", newProps)
    if (apiData.profileUpdated && apiData.profileUpdated.success) {
      this.setState(() => ({
        pageLoading: false
      }));
    } else if (
      apiData.passwordUpdated &&
      apiData.passwordUpdated.error &&
      apiData.passwordUpdated.display
    ) {
      this.setState(() => ({
        errors: [{ input: "currentPassword" }],
        errorMessage: apiData.passwordUpdated.error,
        pageLoading: false
      }));
    } else if (apiData.passwordUpdated && apiData.passwordUpdated.success) {
      this.setState(() => ({
        pageLoading: false,
        succesMessage: "Password Succesfully Updated"
      }));
    } else if (apiData.emailConfirmed) {
      this.setState(() => ({emailVerified : apiData.emailConfirmed.active}))
    }
  };

  handleChange = event => {
    let inputName = event.target.name,
        value = event.target.value;

    this.setState(() => ({
      [inputName]: value,
      // check: false
    }));

  };

  checkboxNetworking = () => {
    this.state.networking ? this.setState({errorMessage:'', networking : false}) : this.setState({errorMessage:'', networking : true})

  }

  handleChangeWeb = event => {
    let inputName = event.target.name,
        value = event.target.value;
    if (value.indexOf('http://') > -1)
    {
      this.setState(() => ({
        check: true,
      }));
    }
    else if (value.indexOf('https://') > -1)
    {
      this.setState(() => ({
        check: true,
      }));
    }
    else {
      this.setState(() => ({
        check: false,
      }));
    }
    this.setState(() => ({
      [inputName]: value,
    }));
  };

  inputHasErrors = inputName => {
    const errors = this.state.errors.filter(err => err.input === inputName);
    return errors.length ? "input-error" : "";
  };

  handleSubmit = () => {

    this.setState(
      {
        errors: [],
        errorMessage: "",
        pageLoading: true,
       // check: false,
      },
      () => {
        if (!this.validateForm()) return;

        if (!this.state.profileImgFile) {
          this.updateProfileData()
          return
      }

      this.uploadProfilePhoto().then( res => {
          let { url: fileName } = res.data
          this.updateProfileData(fileName)
      }).catch((error) => console.log(error))

      }
    );
  };

  updateProfileData = (profileImageName = null) => {
    const rConfig = {
      body: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        businessName: this.state.businessName,
        email: this.state.email,
        website: this.state.website,
        phone: this.state.phone,
        title: this.state.title,
        bio: this.state.bio,
        profileImageName,
        networking: this.state.networking
      },
      headers: this.autheHeaders,
      resource: this.submitUrl,
      userDetails: this.props.userDetails
    };
    
    this.props.actions.reqUpdateTherapistProfile(rConfig);
}

checkEmailVerified = () => {
  const rConfig = {
    body: {},
    headers: this.autheHeaders,
    resource: this.emailVerifiedUrl
  };
  
  this.props.actions.reqEmailConfirmed(rConfig);
};

handleUpdatePassword = () => {
    // console.log("Updating Password");
    this.setState(
      {
        errors: [],
        errorMessage: "",
        pageLoading: true
      },
      () => {
        if (!this.validatePasswordForm()) return;

        this.props.actions.reqUpdateTherapistPassword({
          body: {
            oldPassword: this.state.currentPassword,
            newPassword: this.state.newPassword
          },
          headers: this.autheHeaders,
          resource: this.updatePasswordUrl
        });
      }
    );
  };

  validateForm = () => {
    let formValid = true;

    if (this.state.email === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "email" }],
        errorMessage: "Email is mandatory",
        pageLoading: false
      }));
    } 

    return formValid;
  };
  collapse =()=>{
    this.setState({check: false});
  }
  validatePasswordForm = () => {
    let formValid = true;

    if (this.state.currentPassword === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "currentPassword" }],
        errorMessage: "Current Password is mandatory",
        pageLoading: false
      }));
    } else if (this.state.newPassword === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "newPassword" }],
        errorMessage: "New Password is mandatory",
        pageLoading: false
      }));
    } else if (this.state.confirmPassword === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "confirmPassword" }],
        errorMessage: "Confirm Password is mandatory",
        pageLoading: false
      }));
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "newPassword" }, { input: "confirmPassword" }],
        errorMessage: "New Password and Confirm Password do not match",
        pageLoading: false
      }));
    }

    return formValid;
  };

  handleChooseProfileImg = async ev => {
    let imageFile = ev.target.files[0];
    let previewFile = URL.createObjectURL(imageFile);
    const resizeImg = await resizeFile(imageFile);

    this.setState(() => ({
      profileImgFile: resizeImg,
      profileImgPreview: previewFile
    }));
  };

  uploadProfilePhoto = () => {

    let profileImg = this.state.profileImgFile,
        data = new FormData();

    data.append('profileImage', profileImg)
    let authConfig = {
        headers: {
            Authorization: `Bearer ${this.therapist.token}`
        }
    }

    return uploadImage(this.uploadProfileImgUrl, data, authConfig)
}

  renderAvatar = () => {
    let imageContent = (
      <div className="profile-img-sub-container">
        <div className="add-photo-btn"> + </div>
        <div className="add-photo-text-container">
          <span className="white-text"> Add Photo </span>
        </div>
      </div>
    );

    if (this.state.profileImgPreview) {
      imageContent = (
        <div className="avatar-sub-container">
          <img src={this.state.profileImgPreview} alt="profileImg" />
          <div className="avatar-cover"> CHANGE </div>
        </div>
      );
    } else if (this.state.profileImageName) {
      imageContent = (
        <div className="avatar-sub-container">
          <img
            src={`${baseBackendUrl}/${this.state.profileImageName}`}
            alt="profileImg"
          />
          <div className="avatar-cover"> CHANGE </div>
        </div>
      );
    }

    return (
      <div
        className="avatar-container"
        onClick={e => this.selectProfilePhoto.click()}
      >
        {imageContent}
      </div>
    );
  };

  tabItemActive = tabName =>
    tabName === this.state.activeTabItem ? "active-tab" : "inactive-tab";

  switchTab = tabName => {
    this.setState(() => ({
      activeTabItem: tabName
    }));
  };

  renderTabContent = () =>
    this.state.activeTabItem === "account"
      ? this.renderAccountTab()
      : this.renderPasswordTab();

  renderAccountTab = () => (
    <div className="second-row">
      {/* Profile Form */}
      <div className="leftside-content">
        {/* Avatar */} {this.renderAvatar()} {/* Hidden upload input */}
        <input
          id="profilePhotoInput"
          type="file"
          ref={ref => (this.selectProfilePhoto = ref)}
          style={{ display: "none" }}
          onChange={this.handleChooseProfileImg}
        />
      </div>
      <div className="rightside-content">
        <div className="form-container">
          
          <form>
          {this.state.userName ? (
            <div>
              <p className="user-name">User Name: {this.state.userName}</p>
              {this.state.emailVerified ? '' : <p className="info-text info-text-red"><FontAwesomeIcon icon={faInfoCircle} className="info-icon"/>In order to edit/add any information and for the profile to become public, please confirm your email address. After confirming your email please logout and login again.</p>}
              <p className="info-text"><FontAwesomeIcon icon={faInfoCircle} className="info-icon"/>Your User Name is not publicly visible, but all the information below will be public.</p>
            </div>) : ""}
            <div className={`input-row ${this.state.emailVerified ? "" : "disabled-input"}`} >
              First Name
              <input
                type="text"
                name="firstName"
                className={`basic-input ${this.inputHasErrors("firstName")} ${this.state.membershipPlan === 'free'? "grey-input" : ""}`}
                placeholder="You can leave this field blank"
                value={this.state.firstName}
                onChange={this.handleChange}
                disabled = {this.state.emailVerified ? "" : "disabled"}
              />
            </div>
            <div className={`input-row ${this.state.emailVerified ? "" : "disabled-input"}`}>
              Last Name
              <input
                type="text"
                name="lastName"
                className={`basic-input ${this.inputHasErrors("lastName")} ${this.state.membershipPlan === 'free'? "grey-input" : ""}`}
                placeholder="You can leave this field blank"
                value={this.state.lastName}
                onChange={this.handleChange}
                disabled = {(this.state.emailVerified)? "" : "disabled"}
              />
            </div>
            <div className={`input-row ${this.state.emailVerified ? "" : "disabled-input"}`}>
              Business Name
              <input
                type="text"
                name="businessName"
                className={`basic-input ${this.inputHasErrors("businessName")} ${this.state.membershipPlan === 'free'? "grey-input" : ""}`}
                placeholder="Business Name"
                value={this.state.businessName}
                onChange={this.handleChange}
                disabled = {(this.state.emailVerified)? "" : "disabled"}
              />
            </div>
            <div className="input-row">
              Business Email
              <input
                type="text"
                name="email"
                className={`basic-input ${this.inputHasErrors("email")} ${this.state.membershipPlan === 'free'? "grey-input" : ""}`}
                placeholder="example@domain.com"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>
            {/* Website */}
            <div className={`input-row ${this.state.emailVerified ? "" : "disabled-input"}`}>
              Website
              {/* <FontAwesomeIcon icon={faPager} className="input-icon" /> */}
              <input
                type="text"
                name="website"
                className={`basic-input ${this.state.membershipPlan === 'free'? "grey-input" : ""}`}
                placeholder="https://example.com"
                value={this.state.website}
                onChange={this.handleChangeWeb}
                disabled = {(this.state.emailVerified)? "" : "disabled"}
                //onBlur={ this.collapse }
              />
            </div>
            {/* Phone */}
            <div className={`input-row ${this.state.emailVerified ? "" : "disabled-input"}`}>
              Phone
              {/* <FontAwesomeIcon icon={faPhone} className="input-icon" /> */}
              <input
                type="text"
                name="phone"
                className={`basic-input ${this.inputHasErrors("phone")} ${this.state.membershipPlan === 'free'? "grey-input" : ""}`}
                placeholder="(123) 456-7890"
                value={this.state.phone}
                onChange={this.handleChange}
                disabled = {(this.state.emailVerified)? "" : "disabled"}
              />
            </div>
            {/* Bio */}
            <div className={`input-row textarea-row ${this.state.emailVerified ? "" : "disabled-input"}`}>
              <div> Bio </div>
              {/* <FontAwesomeIcon icon={faAddressCard} className="input-icon" /> */}
              <textarea
                name="bio"
                className={`basic-input ${this.state.membershipPlan === 'free'? "grey-input" : ""}`}
                placeholder="Bio"
                value={this.state.bio}
                onChange={this.handleChange}
                disabled = {(this.state.emailVerified)? "" : "disabled"}
              />
            </div>
            <div className={` ${this.state.emailVerified ? "" : "disabled-input"}` }>
              <input type="checkbox"
                onChange={this.checkboxNetworking}
                className={`networking-checkbox `}
                checked={this.state.networking}
                name="networking"
                disabled={this.state.membershipPlan === 'free'? true : false}
                />
              <span className={`basic-input ${this.state.membershipPlan === 'free'? "grey-input" : ""}`}>Open for Networking</span>
            </div>
            <div>
              <p className="error-message"> {this.state.errorMessage} </p>
            </div>
            <p className={this.state.membershipPlan === 'free' ? 'networking-info' : 'display-none'}><FontAwesomeIcon icon={faInfoCircle} /> You need a paid subscription to use the networking service.</p>

            <div className="info-text">

                <p><FontAwesomeIcon icon={faInfoCircle} /> You can navigate your profile's sections using the menu on the left, or
                step-by-step with the button below.
              </p>
              </div>
            <div className="submit-row">
              <Popup
                  content={this.state.content}
                on="click"
                pinned
                trigger={
                  <ButtonRoundGradient
                    text="Save Changes"
                    otherClass="submit-btn-medium"
                    onClick={this.handleSubmit}
                  />
                }
              />
              <Link to="/specialities">Add Your Specialities <FontAwesomeIcon icon={faAngleRight} /></Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  renderPasswordTab = () => (
    <div className="password-tab">
      <div className="password-form-container">
        {/* Current Password */}
        <div className="input-row">
          <input
            type="password"
            name="currentPassword"
            className={`basic-input ${this.inputHasErrors("currentPassword")}`}
            placeholder="Current Password"
            value={this.state.currentPassword}
            onChange={this.handleChange}
          />
        </div>
        {/* New Password */}
        <div className="input-row">
          <input
            type="password"
            name="newPassword"
            className={`basic-input ${this.inputHasErrors("newPassword")}`}
            placeholder="New Password"
            value={this.state.newPassword}
            onChange={this.handleChange}
          />
        </div>
        {/* Confirm Password */}
        <div className="input-row">
          <input
            type="password"
            name="confirmPassword"
            className={`basic-input ${this.inputHasErrors("confirmPassword")}`}
            placeholder="Confirm Password"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
          />
        </div>
        <div>
          <p className="error-message"> {this.state.errorMessage} </p>
          <p className="success-message"> {this.state.succesMessage} </p>
        </div>
        <div className="submit-row">
          <ButtonRoundGradient
            text="Update Password"
            otherClass="submit-btn-medium"
            onClick={this.handleUpdatePassword}
          />
        </div>
      </div>
    </div>
  );

  render() {
    const therapist = this.props.userDetails;
          
    let progress = 20,
        message="",
        specialities = [],
        insurances = []
    
    if ( therapist.specialities ) {
      specialities = therapist.specialities
    } else { specialities = []}

    if ( therapist.insurances ) {
      insurances = therapist.insurances
    } else { insurances = []}
        
    if ( therapist.membershipPlan !=='free' & insurances.length===0 )
    {message="Choose an Accepted Insurance!"}
    else{progress += 5;}
    if ( therapist.membershipPlan !=='free' ){progress += 10;}
    else{message="Choose a Membership Plan!"}
    if (specialities.length!==0){progress += 5;}
    else{message="Choose a Speciality!"}
    if (therapist.phone) {progress += 15;}
    else {message="Add a Phone Number!"};
    if (therapist.bio) {progress += 15;}
    else{message="Add Your BIO!"};
    if (therapist.profileImg) {progress += 15;}
    else{message="Add a Profile Image!"}
    if (therapist.website) {progress += 15;}
    else{message="Add Your Website!"}

    
    if (this.state.check===false) {this.state.content= "Please modify your website's link in order for it to work properly, and then click Save Changes again"}

    else {this.state.content= "The changes to your account information have been saved. Your profile will be publicly visible once it has been reviewed by an administrator. You can continue adding more information in the meantime."};
    return (
      <MainWrapper page="profile">
        <PopupLoader display={this.state.pageLoading} />
        <div id="therapist-profile">
          <HeaderUser />
          <div className="page-background">
            {/* Page Content */}
            <div className="page-content">
              {/* Sidebar */}
              <SidebarTherapist page="account" roleId={this.therapist.roleId} />
              {/* Inner Content */}
              <div className="inner-content-container">
                <div className="inner-content">
                  <div className="top-row profile-tabs">
                    {/* Title */}
                    <h1
                      className={`page-title tab-item ${this.tabItemActive(
                        "account"
                      )}`}
                      onClick={this.switchTab.bind(this, "account")}
                    >
                      Account Information
                    </h1>
                    <h1 className="page-title tabs-bar"> | </h1>
                    <h1
                      className={`page-title tab-item ${this.tabItemActive(
                        "password"
                      )}`}
                      onClick={this.switchTab.bind(this, "password")}
                    >
                      Change Password
                    </h1>
                  </div>
                  <div className="percent">
                    <p>Your profile is {progress}% complete:</p>
                    <Progress percent={progress}/>
                    <p className="message">{message}</p>
                  </div>
                  {/* <GooglePlacesAutocomplete
                                                                        onSelect={this.findCoordinates}
                                                                    /> */}
                  {/* Leftside Content */} {this.renderTabContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }
}

const mapStateToProps = state => ({
  apiData: state.therapistUpdateProfileReducer,
  userDetails: state.userDetails
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqUpdateTherapistProfile: bindActionCreators(reqUpdateTherapistProfile,dispatch),
      reqFetchUserDetails: bindActionCreators(reqFetchUserDetails, dispatch),
      reqEmailConfirmed: bindActionCreators(reqEmailConfirmed, dispatch),
      reqUpdateTherapistPassword: bindActionCreators(reqUpdateTherapistPassword,dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapistProfile);
