import { REC_GET_ALL_SPECIALITIES, REC_ADD_SPECIALITY_THERAPIST, REC_REMOVE_SPECIALITY_THERAPIST } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_ALL_SPECIALITIES:
            return {
                allSpecialities: data
            };
        
        case REC_ADD_SPECIALITY_THERAPIST:
            return {
                addSpeciality: data
            };

        case REC_REMOVE_SPECIALITY_THERAPIST:
            return {
                removeSpeciality: data
            };

        default:
            return state
    }
}