import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarTherapist from '../../../components/SidebarTherapist/SidebarTherapist'
import './Sponsorship.scss'
// import '../../../components/RoundInputGradient/RoundInputGradient'
import MainWrapper from '../MainWrapper/MainWrapper'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CheckoutForm from '../Payment/CheckoutForm'
import { reqLoadSponsorshipPlans, reqSubscribeToSponsorshipPlan, 
  reqGetSponsorshipSettings, reqCancelSponsorshipPlan, reqUpdateSponsorshipPlan } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PlanCard from './PlanCard'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import PopupConfirm from '../../../components/PopupConfirm/PopupConfirm'
//import { Redirect } from 'react-router-dom'
import MenuStepNavigation from "../../../components/MenuStepNavigation/MenuStepNavigation";
import scrollToComponent from 'react-scroll-to-component';


const stripePk = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

class Sponsorship  extends React.Component {

  therapist = {}
  authHeader = {}
  loadSponsorshipPlansUrl = 'therapist/plans/sponsorship/list'
  subscribeToPlanUrl = 'therapist/plans/sponsorship/register'
  sponsorshipSettingsUrl = 'therapist/plans/sponsorship/settings'
  cancelPlanUrl = 'therapist/plans/sponsorship/cancel'
  updateSponsorshipUrl = 'therapist/plans/sponsorship/update'

  state = {
    plans: [],
    pageLoading: false,
    errorMessage: ''
  }

  componentDidMount = () => {

    this.therapist = this.props.userDetails;
    this.authHeader = {  
        Authorization: `Bearer ${this.therapist.token}`,
        "Content-type": "application/json" 
    }

    if (this.props.userDetails.cardData) {
      this.setState(() => ({
        userHasCard: true
      }))
    }

    this.loadSponsorshipPlans();
    this.loadSponsorshipSettings();
  }

  componentWillReceiveProps = (newProps) => {
    let apiData = newProps.apiData
    // console.log("sponsorship props: ", newProps);
    // Plans Loaded
    if (apiData.sponsorshipPlans && apiData.sponsorshipPlans.success) {
        let plans = apiData.sponsorshipPlans.plans,
            places = apiData.sponsorshipPlans.places;

        let processedPlans = plans.map( p => {
          p['places'] = places.map( p => ({...p}))
          p['budget'] = 0

          return p
        })
        
        this.setState(() => ({
            pageLoading: false,
            plans: processedPlans
            // places: places
        }))
    } else if (apiData.sponsorshipSettings && apiData.sponsorshipSettings.success) {
      let plans = apiData.sponsorshipSettings.plans,
          activePlan = plans[0],
          oldPlans = this.state.plans;
      
      oldPlans.map( op => {
        op['isEdit'] = false
        return op
      })

      if (activePlan) {
        this.setState(() => ({
          noSponsorship: false  
        }))
      }

      this.setState(() => ({
        pageLoading: false,
        activePlan: activePlan
      }))

    } else if (apiData.planCancelled && apiData.planCancelled.success) {
      this.loadSponsorshipSettings();
    } else if (apiData.sponsorshipSubscription && apiData.sponsorshipSubscription.success) {
      this.loadSponsorshipSettings();
    } else if (apiData.sponsorshipUpdated && apiData.sponsorshipUpdated.success) {
      this.loadSponsorshipSettings();
    } else if (apiData.sponsorshipUpdated && apiData.sponsorshipUpdated.error) {
      this.setState(() => ({
        pageLoading: false,
        activePlan: [],
        errorMessage: apiData.sponsorshipUpdated.message
      }))
      this.stripeErrorHandler(apiData.sponsorshipUpdated.message)
    } else if (apiData.sponsorshipSubscription && apiData.sponsorshipSubscription.error) {
      this.setState(() => ({
        pageLoading: false,
        activePlan: [],
        errorMessage: apiData.sponsorshipSubscription.message
      }))
      this.stripeErrorHandler(apiData.sponsorshipSubscription.message)
    } 

  }


  hidePopup = () => {
    this.setState(() => ({
      showPopup: false
    }))
  }

  displayNoCardPopup = () => {
    let popupTitle = 'No Card Saved',
        popupText = 'Please add your card information before subscribing to a plan.',
        confirmText = 'Ok',
        cancelText = " "
    

    this.setState(() => ({
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.hidePopup,
      confirmText: confirmText,
      cancelText: cancelText
    }))
  }

  stripeErrorHandler = (errorMessage) => {
    let popupTitle = 'Payment error',
        popupText = errorMessage,
        confirmText = 'Ok',
        cancelText = " "
    

    this.setState(() => ({
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.hidePopup,
      confirmText: confirmText,
      cancelText: cancelText
    }))
  }

  renderPlans = () => {
    // console.log("plans: ", this.state.activePlan)

    let plans = this.state.plans.map( (p, i) => (
        <PlanCard
            plan={p}
            key={i}
            handleChoosePlan={this.handleChoosePlan}
            handleCheckPlace={this.handleCheckPlanPlace}
            handleBudgetChange={this.handleBudgetChange}
            handleCancelPlan={this.handleCancelPlan}
            handleChangePlan={this.handleChangePlan}
            activePlan={this.state.activePlan}
        />
    ))

    return (
        <div className="plans">
            { plans }
        </div>
    )
  }

  handleCancelPlan = plan => {
    
    if (plan.isEdit) {
      //console.log("plan is edit: ", plan)
      let stPlan = this.state.plans.filter(p => p.id === plan.id)[0];
      stPlan.isEdit = false;

      this.setState(() => ({}))
      return;
    }

    this.setState(() => ({
      showPopup: true,
      popupTitle: 'Cancel Plan?',
      popupText: 'Are you sure you want to cancel this plan?',
      confirmText: 'Yes',
      cancelText: 'No',
      cancelAction: this.hidePopup,
      confirmAction: this.confirmCancelSponsorshipPlan.bind(this, plan)
    }))
  }

  confirmCancelSponsorshipPlan = plan => {
    let rConfig = {
      body:{
        planId: plan.id
      },
      headers: this.authHeader,
      resource: this.cancelPlanUrl,
      userDetails: this.props.userDetails
    }

    this.setState({
      pageLoading: true
    }, () => {
      this.props.actions.reqCancelSponsorshipPlan(rConfig)
    })
  }

  handleChangePlan = plan => {

    if (plan.isEdit) {
      let configValid = this.validateChangePlanConfig(plan)

      this.setState(() => ({
        showPopup: true,
        popupText: configValid.conf.popupText,
        popupTitle: configValid.conf.popupTitle,
        confirmText: configValid.conf.confirmText, 
        cancelText: configValid.conf.cancelText, 
        confirmAction: configValid.conf.confirmAction, 
        cancelAction: configValid.conf.cancelAction, 
      }))

      return;
    }

    let stPlan = this.state.plans.filter(p => p.id === plan.id)[0];
    stPlan['isEdit'] = true;

    this.setState(() => ({}))
  }

  confirmChangePlan = plan => {
    //console.log("confirmed Changing plan: ", plan)

    let rConfig = {
      body: {
        planId: plan.id,
        placeId: plan.places.filter(p => p.isChecked)[0].id,
        amount: plan.budget || 0
      },
      headers: this.authHeader,
      resource: this.updateSponsorshipUrl
    }

    this.setState({
      pageLoading: true
    }, () => {
      this.props.actions.reqUpdateSponsorshipPlan(rConfig)
    })
  }

  validateChangePlanConfig = plan => {
    let hp = plan.places.filter( p => p.isChecked).length,
        popupConfig = {
          popupTitle: hp ? 'Confirm Change?' : 'No Place Selected',
          popupText: this.getChangePlanText(hp, plan),
          confirmText: hp ? 'Yes' : 'Ok',
          cancelText: hp ? 'No' : 'Cancel',
          cancelAction: this.hidePopup,
          confirmAction: hp ? this.confirmChangePlan.bind(this, plan) : this.hidePopup
        }

    return {
      value: hp,
      conf: popupConfig
    }

  }

  getChangePlanText = (hasPlace, plan) => {

    if (!hasPlace) {
      return `Please select a place before submitting changes`
    }

    if (plan.id === 1) {
      return `Confirm changed made and add $${plan.budget} to your balance?`
    } else if (plan.id === 2) {
      return `Confirm changing your budget to $${plan.budget} ?`
    }
  }

  handleBudgetChange = (plan, ev) => {
    let value = ev.target.value,
        statePlan = this.state.plans.filter( p => p.id === plan.id)[0];
    
    statePlan.budget = value;

    this.setState(() => ({}))
  }

  handleChoosePlan = plan => {

    let valid = this.configValid(plan);

    this.setState(() => ({
      showPopup: true,
      popupTitle: valid.popupConfig.title,
      popupText: valid.popupConfig.text,
      confirmText: valid.popupConfig.confirmText,
      confirmAction: valid.popupConfig.confirmAction,
      cancelText: valid.popupConfig.cancelText
    }))

  }

  // Load Sponsorship plans from backend
  loadSponsorshipPlans = () => {
    let rConfig = {
        body:{},
        headers: this.authHeader,
        resource: this.loadSponsorshipPlansUrl
    }

    this.setState({
        pageLoading: true
    }, () => {
        this.props.actions.reqLoadSponsorshipPlans(rConfig)
    })
  }

  // Load Sponsorship settings from backend
  loadSponsorshipSettings = () => {
    let rConfig = {
      body: {},
      headers: this.authHeader,
      resource: this.sponsorshipSettingsUrl
    }

    this.setState({
      pageLoading: true
    }, () => {
      this.props.actions.reqGetSponsorshipSettings(rConfig);
    })
  }

  // Subscribe user to sponsorship plan
  subscribeToPlan = (plan, activePlan) => {
    let rConfig = {
      body: {
        planId: plan.id,
        payedAmount: plan.budget,
        placeId: plan.places.filter(p => p.isChecked)[0].id,
      },
      headers: this.authHeader,
      resource: this.subscribeToPlanUrl,
      userDetails: this.props.userDetails
    }

    this.setState({
      pageLoading: true
    }, () => {
      this.props.actions.reqSubscribeToSponsorshipPlan(rConfig);
      if (activePlan) {
        this.confirmCancelSponsorshipPlan(activePlan)
      }
      
    })

  }

  gotoPayment = () => {
    this.setState(() => ({
      showPopup: false
    }))
    var el = this.refs.wrap;
    scrollToComponent(el ,{duration: 10})
  }

  configValid = plan => {
    
    let placeChoosen = plan.places.filter(p => p.isChecked).length,
        budgetSet = parseInt(plan.budget) > 0,
        hasCard = this.props.userDetails.cardData,
        popupConfig = {},
        response = {}

    // No Card
    if (!hasCard) {
      popupConfig = {
        showPopup: true,
        title: 'No Card Added!',
        text: 'Please save your card under the Payment section in order to subscribe to this plan',
        confirmText: 'Ok',
        confirmAction: this.gotoPayment,
        cancelText: " "
      }

      response = {
        value: false,
        popupConfig: popupConfig
      }

      return response;
    }

    // No place choosen
    if (!placeChoosen) {

      popupConfig = {
        showPopup: true,
        title: 'No Place Choosen!',
        text: 'Please choose a place in order to subscribe to this plan',
        confirmText: 'Ok',
        cancelText: ' ',
        confirmAction: this.hidePopup,
      }

      response = {
        value: false,
        popupConfig: popupConfig
      }

      return response
    }

    // No budget set
    if (!budgetSet) {
      
      popupConfig = {
        showPopup: true,
        title: 'No Budget Set!',
        text: 'Please set a budget in order to subscribe to this plan',
        confirmText: 'Ok',
        cancelText: ' ',
        confirmAction: this.hidePopup,
      }

      response = {
        value: false,
        popupConfig: popupConfig
      }

      return response;
    }

    // Valid
    if (placeChoosen && budgetSet) {

      //console.log("config valid")

      let activePlans = this.state.plans
        .filter(p => p.id === (this.state.activePlan || {}).plan_id),
          hasActivePlan = activePlans.length,
          activePlan = hasActivePlan ? activePlans[0] : null;

      popupConfig = {
        title: 'Confirm Plan?',
        text: `Confirm ${hasActivePlan ? ' switching ' : ' subscribing '} to this plan?`,
        confirmText: 'Yes',
        cancelText: 'No',
        confirmAction: this.subscribeToPlan.bind(this, plan, activePlan),
        cancelAction: this.hidePopup
      }
      
      response = {
        value: true,
        popupConfig: popupConfig
      }
      return response
    }
    

    
  }

  handleCheckPlanPlace = data => {
    // console.log("Place checked: ", data);
    let plan = this.state.plans.filter( p => p.id === data.plan.id)[0];
    
    plan.places.map(p => {
      let checked = p.id === data.place.id
      p['isChecked'] = checked;
      return p
    })
    
    this.state.plans.map( plan => {
      plan.places.map( place => {
        let checked = (place.id === data.place.id && plan.id === data.plan.id)
        place['isChecked'] = checked
        // console.log("Place mapping: ", place)
        return place
      })
      return plan
    })
  
    this.setState(() => ({}))
  }

  render() {

    // if(this.state.redirect) {
    //   return (
    //       <Redirect to={this.state.redirectUrl} />
    //   )
    // }

    return (
      <MainWrapper page="sponsorship">
        <PopupLoader display={this.state.pageLoading} />
        
        <div id="sponsorship">
          <PopupConfirm display={this.state.showPopup} 
                        text={this.state.popupText}
                        title={this.state.popupTitle}
                        hidePopup={this.hidePopup}
                        calcelAction={this.hidePopup}
                        confirmText={this.state.confirmText}
                        cancelText={this.state.cancelText}
                        confirmAction={this.state.confirmAction}/>
          
          <HeaderUser />
          <div className="page-background">
            {/* Page Content */}
            <div className="page-content"> 
              {/* Sidebar */}
              <SidebarTherapist 
                page="sponsorship"  
                roleId={this.therapist.roleId}
              />
              {/* Inner Content */}
              <div className="inner-content-container">
                <div className="inner-content">
                <MenuStepNavigation
                  leftLink="/specialities"
                  leftText="Choose A Membership Plan"
                  rightLink="/profile"
                  rightText="My Account"
              />
                  <div className="top-row">
                    {/* Title */}
                    <h1 className="page-title">Sponsorship Plans</h1>
                  </div>
                  <div className="main-content">
                    
                    <section className="plan-description-section">
                        <span className="plans-description-value">If you would like to guarantee that your business always appears at the top of the search results when you meet the relevant criteria, you may set up a pay per click (PPC) plan. The fixed rate plan will stop when you've reached your monthly allocated budget and the pay as you go will bill you at the end of every month.</span>
                    </section>
                    {this.renderPlans()}
                  </div>
                  <div className="top-row" ref='wrap'>
                      {/* Title */}
                      <h1 className="page-title">Payment Information For Your Services</h1>
                    </div>
                    <div className="stripe-payment-method">
                      <StripeProvider apiKey={stripePk}>
                        <div className="payment-info">
                          <Elements>
                            <CheckoutForm />
                          </Elements>
                        </div>
                      </StripeProvider>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    )
  }

}

const mapStateToProps = state => ({
  apiData: state.sponsorship,
  userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqLoadSponsorshipPlans: bindActionCreators(reqLoadSponsorshipPlans, dispatch),
      reqSubscribeToSponsorshipPlan: bindActionCreators(reqSubscribeToSponsorshipPlan, dispatch),
      reqGetSponsorshipSettings: bindActionCreators(reqGetSponsorshipSettings, dispatch),
      reqCancelSponsorshipPlan: bindActionCreators(reqCancelSponsorshipPlan, dispatch),
      reqUpdateSponsorshipPlan: bindActionCreators(reqUpdateSponsorshipPlan, dispatch),
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sponsorship)

// export default Payment