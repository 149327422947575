import React from 'react'
import { Link } from 'react-router-dom'
import footerLogo  from '../../static/images/desktop/footer_logo_white.png'
import MediaIcon from '../MediaIcon/MediaIcon'
import './Footer.scss'

const socialPlatforms = ['facebook', 'twitter', 'instagram', 'pinterest', 'linkedin', 'youtube'];

const renderAmazonDisclarimer = props => (
    props.page === 'home'
        ? <div className="disclaimer-bottom-affil">Autism Assistant is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com</div>
        : ''
)

const Footer = props => (
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <img className="footer-logo" src={footerLogo} width="100" height="50" alt="footerLogo" loading="lazy"/>
                </div>
                <div className="col-md-3">
                    <ul className="footer-menu">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/signup">Sign Up</Link></li>
                        {/* <li><Link to="/therapists/top/locations">Top Locations</Link></li> */}
                        {/* <li><Link to="/therapists/top/regions">Top Regions</Link></li> */}
                        <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link to="/faq">FAQ</Link></li>
                        <li><Link to="/" aria-label="empty-link"> </Link></li>
                        <li><Link to="/" aria-label="empty-link"> </Link></li>
                        <li><Link to="/" aria-label="empty-link"> </Link></li>
                    </ul>
                </div>
                <div className="col-sm-12 col-md-6 social-icons">
                    <span className="social-media-title">Follow Us on Social Media</span>
                    <div className="iconsContainer">
                        {socialPlatforms.map( (p, idx) => (
                            <MediaIcon
                                label={p}
                                iconSize="2x"
                                circleClass="footer-icon-circle"
                                circleBackground="#ddd"
                                key={`mi-icon-k-${idx}`}
                                lazyLoad={true}
                            />
                        ))
                        }
                    </div>
                </div>
            </div>
            { renderAmazonDisclarimer(props) }
            <div className="text-center copyright">Copyright 2020 | <a href="https://wesrom.com/" rel="noopener nofollow"><u>Design & website development by Wesrom Corporation</u></a></div>
        </div>
    </footer>
);

export default Footer
