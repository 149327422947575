import {
  REC_GET_SPECIALITIES_HOME,
  REC_LOG_PAGE_VISIT_HOME,
  REC_GET_FEATURED_ARTCILE,
  REC_GET_FEATURED_PRODUCTS,
  REC_GET_FEATURED_BUSINESSES,
  REC_GET_FEATURED_PARTNERS,
} from "./actions";

export default (state = {}, { type, data }) => {
  switch (type) {
    case REC_GET_SPECIALITIES_HOME:
      return {
        specialities: data,
      };

    case REC_LOG_PAGE_VISIT_HOME:
      return {
        pageVisit: data,
      };

    case REC_GET_FEATURED_ARTCILE:
      return {
        featuredArticle: data,
      };

    case REC_GET_FEATURED_PRODUCTS:
      return {
        featuredProducts: data,
      };
    case REC_GET_FEATURED_BUSINESSES:
      return {
        featuredBusinesses: data,
      };

    case REC_GET_FEATURED_PARTNERS:
      return {
        featuredPartners: data,
      };

    default:
      return state;
  }
};
