
import React    from 'react'
import { Link } from 'react-router-dom'
import moment   from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes }         from '@fortawesome/free-solid-svg-icons'

import HeaderUser          from '../../../components/HeaderUser/HeaderUser'
import SidebarBlogger    from '../../../components/SidebarBlogger/SidebarBlogger'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import PopupLoader         from '../../../components/PopupLoader/PopupLoader'
import PopuConfirm         from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper         from '../MainWrapper/MainWrapper'
import ArticleItem         from './ArticleItem'
import RoundInputGradient  from '../../../components/RoundInputGradient/RoundInputGradient'
import ArticlePopupForm    from './ArticlePopupForm'
import MenuStepNavigation from "../../../components/MenuStepNavigation/MenuStepNavigation";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import './Articles.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'

import uploadImage from '../../../utils/uploadImage'

import { reqGetTherapistArticles, reqAddTherapistArticles,
    reqUpdateTherapistArticles, reqRemoveTherapistArticles,
    reqUploadArticleThumbnail } from './actions'



class TherapistArticles extends React.Component {

    state = {
        searchArticleTitle: '',
        allArticles: [],
        displayedArticles: [],
        pageLoading: false,
        showPopup: false,
        showArticleForm: false,
        showArticleTitle: '',
        aid: '',
        articleTitle: '',
        articleContent: '',
        articleTags: [],
        articleMetaTitle: '',
        articleMetaDescription: '',
        tagInput: '',
        tagInputError: '',
        articleThumbnailPreview: '',
        articleThumbnailFile: '',
        lastPublised: -1,
        publicStatus: 0,
        articlePublishedDate: '',
        articleType: 'blog',
        articlePermalink: '',
        articleCardTitle: ''
    }

    therapist = {}
    authHeader = {}
    getTherapistArticlesUrl = 'therapist/articles/all'
    addTherapistArticleUrl = 'therapist/article/create'
    updateTherapistArticleUrl = 'therapist/article/update'
    removeTherapistArticleUrl = 'therapist/article/delete'
    uploadThumbnailUrl = 'therapist/article/upload_thumbnail'

    requiredPlans = [
        'platinum'
    ]

    componentDidMount = () => {

        this.therapist = this.props.userDetails;
        this.authHeader = {
            Authorization: `Bearer ${this.therapist.token}`,
            "Content-type": "application/json"
        }
        this.setState({
        }, () => {
            this.loadTherapistArticles()
        })
    }

    loadTherapistArticles = () => {
        this.setState({
            pageLoading: true
        }, () => {
            const rConfig = {
                body: {},
                resource: `${this.getTherapistArticlesUrl}?uid=${this.props.userDetails.uid}`,
                headers: null
            }
            this.props.actions.reqGetTherapistArticles(rConfig)
        })

    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;
        if (apiData.allArticles && apiData.allArticles.success) {
            this.setState(() => ({
                pageLoading: false,
                allArticles: apiData.allArticles.article,
                lastPublised: apiData.allArticles.lastPublised
            }), () => {
                this.filterDisplayedArticles()
            })
        } else if (apiData.addArticle && apiData.addArticle.success) {

            this.setState({
                showArticleForm: false,
            }, () => { this.loadTherapistArticles() })

        } else if (apiData.updateArticle && apiData.updateArticle.success) {
            this.setState({
                pageLoading: false,
                showArticleForm: false,
                allArticles: apiData.updateArticle.articles.data,
            }, () => {
                this.filterDisplayedArticles()
                document.body.classList.remove('overflow-hidden');
            })
        } else if (apiData.removeArticle && apiData.removeArticle.success) {

            this.loadTherapistArticles()
        }
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value

        this.setState({
            [inputName]: value
        }, () => {
            this.filterDisplayedArticles()
        })
    }

    handleAddArticle = (article) => {
        this.setState(() => ({
            showArticleForm: true,
            showArticleTitle: 'Add a new resource',
            aid: '',
            articleTitle: '',
            articleContent: '',
            articleTags: [],
            articleThumbnailPreview: '',
            articleThumbnailUrl: ''
        }))
        document.body.classList.add('overflow-hidden');
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value

        this.setState({
            [inputName]: value
        }, () => {
            this.filterDisplayedArticles()
        })
    }

    handleAddTag = () => {
        if (this.state.tagInput === '') {
            this.setState({tagInputError: 'Tag can\'t be empty'})
            return
        }
        if (this.state.tagInput.match("^[a-zA-Z0-9_ -]+$") === null) {
            this.setState({tagInputError: 'Invalid characters detected! You can use only alphanumeric characters, whitespace, hyphen and underscore'})
            return
        }

        let input = this.state.tagInput;
        input = input.toLowerCase();
        input = input.charAt(0).toUpperCase() + input.slice(1);

        if (this.state.articleTags.includes(input)) {
            this.setState({tagInputError: 'You\'ve already added this tag'})
            return
        }

        this.state.articleTags.push(input);
        this.setState({tagInput: ''})
    }

    handleRemoveTag = (tagIdx) => {
        let newList = [...this.state.articleTags];
        newList.splice(tagIdx, 1)

        this.setState(() => ({
            articleTags: newList
        }))
    }

    handleEditArticle = (article) => {
        this.setState(() => ({
            showArticleForm: true,
            showArticleTitle: `Edit article - ${article.title}`,
            aid: article.id,
            articleTitle: article.title,
            articleContent: article.body,
            articleThumbnailUrl: article.thumbnail_url,
            articleTags: (article.tags || '').split(','),
            articleMetaTitle: article.metaTitle,
            articleMetaDescription: article.metaDescription,
            articlePublishedDate: new Date(article.published),
            articlePermalink: article.permalink,
            articleCardTitle: article.card_title,
            articleType: article.type
        }))
        document.body.classList.add('overflow-hidden');
    }

    handleRemoveArticle = (articleId) => {
        this.setState(() => ({
            showPopup: true,
            removeId: articleId,
            popupText: 'Confirm removing this from your resources?'
        }))
    }

    handleArticleInputChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value
        this.setState({
            [inputName]: value
        })
    }

    submitArticleForm = (publicStatus=0) => {
        this.setState(() => ({
            pageLoading: true
        }))

        let articleFormValid = this.validateArticleForm();

        if (!articleFormValid.value) {
            this.setState(() => ({
                errorMessage: articleFormValid.errorMessage
            }))

            return;
        }

        let resource = this.updateTherapistArticleUrl

        if (this.state.aid === '') {
            resource = this.addTherapistArticleUrl
        }

        if (!this.state.articleThumbnailFile) {
            this.saveArticle(resource, null, publicStatus)
            return;
        }

        this.uploadArticleThumbNail().then(
            res => {
                let { filename: thumbnailName } = res.data.details
                this.saveArticle(resource, thumbnailName, publicStatus)
                
            }
        )


    }

    validateArticleForm = () => {
        if (!this.state.articleTitle) {
            return {
                value: false,
                errorMessage: 'Please add a title to your article'
            }
        }

        if (!this.state.articleThumbnailPreview && !this.state.articleThumbnailUrl) {
            return {
                value: false,
                errorMessage: 'Please select a thumbnail image for your article'
            }
        }

        if (!this.state.articleContent) {
            return {
                value: false,
                errorMessage: 'Please add content to your article'
            }
        }

        return {
            value: true
        }
    }

    escapeHtml = (unsafe) => {
        return unsafe
            //.replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    };

    saveArticle = (resource, thumbnailName = null, publicStatus) => {
        const rConfig = {
            body: {
                articleId: this.state.aid,
                title: this.state.articleTitle,
                body: this.escapeHtml(this.state.articleContent),
                tags: this.state.articleTags.join(','),
                metaTitle: this.state.articleMetaTitle,
                metaDescription: this.state.articleMetaDescription,
                cardTitle: this.state.articleCardTitle,
                permalink: this.state.articlePermalink,
                type: this.state.articleType,
                thumbnailName: thumbnailName,
                public: publicStatus,
                publishedDate: this.state.articlePublishedDate
            },
            headers: this.authHeader,
            resource: resource,
            userDetails: this.props.userDetails
        }

        this.setState({
            showPopup: false
        }, () => {
            if (this.state.aid === '') {
                if (this.state.articleThumbnailFile) {
                    this.uploadArticleThumbNail()
                }
                this.props.actions.reqAddTherapistArticles(rConfig)
            } else {
                this.props.actions.reqUpdateTherapistArticles(rConfig)
            }
        })
    }

    uploadArticleThumbNail = () => {

        let thumbnailFile = this.state.articleThumbnailFile,
            data = new FormData();

        data.append('upload', thumbnailFile)

        return uploadImage(this.uploadThumbnailUrl, data);
    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    hideArticleForm = () => {
        this.setState( () => ({showArticleForm: false}))
        document.body.classList.remove('overflow-hidden');
    }

    confirmRemove = () => {
        const rConfig = {
            body: {
                articleId: this.state.removeId
            },
            headers: this.authHeader,
            resource: this.removeTherapistArticleUrl,
            userDetails: this.props.userDetails
        }

        this.setState({
            pageLoading: true,
            showPopup: false
        }, () => {
            this.props.actions.reqRemoveTherapistArticles(rConfig)
        })
    }

    filterDisplayedArticles = () => {
        const searchFilter = this.state.searchArticleTitle.toLowerCase();
        const filteredArticles = this.state.allArticles.filter(
            i => {
                const match = i.title.toLowerCase().indexOf(searchFilter) > -1

                return match
            }
        )
        this.setState(() => ({
            displayedArticles: filteredArticles,
        }))
    }

    handleChooseThumbnail = (ev) => {

        let thumbnailFile = ev.target.files[0]
        let previewFile = URL.createObjectURL(thumbnailFile)

        this.setState(() => ({
            articleThumbnailFile: thumbnailFile,
            articleThumbnailPreview: previewFile
        }))
    }

    loadTags = () => {
        // console.log("loading tags...", this.state.articleTags)
        let items = []
        for(let i=0; i< this.state.articleTags.length; i++) {
            items.push(
                <div className="article-tag" key={`article-tag-k-${i}`}>
                    <span>{this.state.articleTags[i]}</span>
                    <FontAwesomeIcon icon={faTimes}
                                     className="delete-icon"
                                     data-tip="Delete Tag"
                                     onClick={this.handleRemoveTag.bind(this, i)} />
                </div>
            )
        }

        if (!items.length) {
            return (
                <div className="message-row">
                    <p>This article has no tags.</p>
                </div>
            )
        }

        return (
            <div className="article-tags">
                {items}
            </div>
        )
    }

    loadArticles = () => {
        let items = []
        for(let i=0; i< this.state.displayedArticles.length; i++) {
            let article = this.state.displayedArticles[i]
            items.push(
                <ArticleItem index={article.id}
                             key={article.id}
                             article={article}
                             editAction={this.handleEditArticle}
                             removeAction={this.handleRemoveArticle}/>
            )
        }

        if (!items.length) {
            return (
                <div className="message-row">
                    <p>You have no articles written.</p>
                </div>
            )
        }

        return (
            <ul className="articles-list">
                {items}
            </ul>
        )
    }

    shouldRenderActionButton = () => (
        this.canPublish().value
            ? this.renderAddButton()
            : ''
    )

    renderAddButton = () => (
        <div className="col-md-6 text-right">
            <ButtonRoundGradient text="Add"
                                otherClass="small-gradient-button"
                                onClick={this.handleAddArticle}/>
        </div>
    )

    shouldRenderNoActionMessage = () => (
        this.canPublish().value
            ? ''
            : this.renderNoActionMessage(this.canPublish().reason)
    )

    renderNoActionMessage = (reason) => (
        <div className="no-access-message">
            { reason === 'plan' ? this.renderNoPlanMessage() : this.renderCoolDownMessage() }
        </div>
    )

    renderNoPlanMessage = () => (

        <div className="no-plan-message">
            You need the {this.renderPlanLink()} in order to have access to this feature.
        </div>
    )

    renderPlanLink = () => (
        <Link to="/payment">
            <span>{`${this.requiredPlans[0]} plan`}</span>
        </Link>

    )

    renderCoolDownMessage = () => (
        <div className="cooldown-message">
            You can only publish 1 guest post per month. You can publish your next article starting: <span className="bold-val">{ this.getNextPublishDate() }</span>
        </div>
    )

    getNextPublishDate = () => (
        moment(this.state.lastPublised).add(30, 'days').format('LL')
    )

    canPublish = () => {
        let cPlan = this.therapist.membershipPlan,
            hasPlan = this.requiredPlans.filter( p => p === cPlan).length,
            coolDownPassed = this.coolDownPassed(),
            betaUser = this.props.userDetails.betaUser;

        /*
        * roleId === 4 => user is blogger and can post as many articles as he wants.
        *
        * !hasPlan => user is not subscribed to a plan that will allow him to post articles
        *
        * !coolDownPassed => Currently users ca only post 1 article/month,
        *  if a month has not passed since the created article he will not be able to create a new one
        *
        * if none of the above, it means user is therapist, is subscribet to the necessary plan,
        * and at leas a month has passed since his last article, so he can create a new one
        */
        if (this.props.userDetails.roleId === 4) {
            return {
                value: true
            }
        } else if (!hasPlan && !betaUser) {
            return {
                value: false,
                reason: 'plan'
            }
        } else if (!coolDownPassed) {
            return {
                value: false,
                reason: 'coolDown'
            }
        } else {
            return {
                value: true
            }
        }
    }

    coolDownPassed = () => {

        if (!this.state.lastPublised > 0) {
            return true
        }

        let now = moment(),
            lastPublised = this.state.lastPublised,
            nextDate = moment(lastPublised).add(30, 'days').valueOf()

        return now >= nextDate;
    }

    render () {
        return (
            <MainWrapper page="articles">
                <div id="blogger-manage-articles">
                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup}
                                 text={this.state.popupText}
                                 title={this.state.popupTitle}
                                 hidePopup={this.hidePopup}
                                 calcelAction={this.hidePopup}
                                 confirmAction={this.confirmRemove}/>

                    <HeaderUser adminFullName=""/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarBlogger page="articles" roleId={this.therapist.roleId}/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <MenuStepNavigation
                                        leftLink="/blogger-profile"
                                        leftText="My profile"
                                        rightLink="/"
                                    />

                                    <div className="results-section">
                                        {/* Results Title */}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h2 className="page-title mb-0">Your Resources</h2>
                                            </div>
                                            { this.shouldRenderActionButton() }
                                        </div>

                                        { this.shouldRenderNoActionMessage() }

                                        <div className="search-row">
                                            <RoundInputGradient
                                                placeholder="Filter by Name"
                                                className="article-search-input"
                                                inputName="searchArticleTitle"
                                                value={this.state.searchArticleTitle}
                                                onChange={this.handleChange}/>
                                        </div>
                                        <div className="articles-container">
                                            {this.loadArticles()}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <ArticlePopupForm display={this.state.showArticleForm}
                                      title={this.state.showArticleTitle}
                                      changeInputAction={this.handleArticleInputChange}
                                      addTagAction={this.handleAddTag}
                                      tagInputError={this.state.tagInputError}
                                      tagInput={this.state.tagInput}
                                      loadTags={this.loadTags}
                                      cancelAction={this.hideArticleForm}
                                      articleTitle={this.state.articleTitle}
                                      articleMetaTitle={this.state.articleMetaTitle}
                                      articleMetaDescription={this.state.articleMetaDescription}
                                      articleContent={this.state.articleContent}
                                      submitForm={this.submitArticleForm}
                                      submitDraft={this.submitArticleDraft}
                                      articlePublishedDate={this.state.articlePublishedDate}
                                      thumbnailPreview={this.state.articleThumbnailPreview}
                                      handleChooseThumbnail={this.handleChooseThumbnail}
                                      articleThumbnailUrl={this.state.articleThumbnailUrl}
                                      errorMessage={this.state.errorMessage}/>
                </div>

            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.therapistManageArticle,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            reqGetTherapistArticles: bindActionCreators(reqGetTherapistArticles, dispatch),
            reqAddTherapistArticles: bindActionCreators(reqAddTherapistArticles, dispatch),
            reqUpdateTherapistArticles: bindActionCreators(reqUpdateTherapistArticles, dispatch),
            reqRemoveTherapistArticles: bindActionCreators(reqRemoveTherapistArticles, dispatch),
            reqUploadArticleThumbnail: bindActionCreators(reqUploadArticleThumbnail, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapistArticles)

