import React from "react";

import "./Product.scss";

const ProductCard = (props) => (
  <div
    className={`product-card ${
      typeof props.className !== "undefined" ? props.className : ""
    }`}
  >
    <div className={`product--image-container`}>
      <img src={props.image} className="product--image" alt={props.alt} />
    </div>
    <div className="product--content">
      {/* <div className="product--tag">{props.tag}</div> */}
      {/* <a href={props.url} className="product--title"><span>{props.title}</span></a> */}
      <div className="product-title">{props.title}</div>
      <div className="product--short-content">{props.description}</div>
      <div className="product--price">
        <span>${props.price}</span>
      </div>
      <div className="product--button-container">
        <div
          className="btn btn-default product--button"
        >
          {props.buttonText}
        </div>
      </div>
    </div>
  </div>
);

export default ProductCard;
