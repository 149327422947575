import React from 'react'

const containerStyle = {
    minHeight: '150px',
    width: '350px',
    // margin: `${window.innerHeight/2 - 150}px auto 0`,
    background: '#fff',
    borderRadius: '20px',
    padding: '15px',
    position: 'fixed',
    top: `${window.pageYOffset + (window.innerHeight/2 - 150)}px`,
    left: `${window.innerWidth/2 - 175}px`
}

const titleStyle = {
    textAlign: 'center',
    fontWeight: '600'
}

const textStyle = {
    textAlign: 'center',
    fontSize: '17px',
    display: 'block',
    margin: '15px auto'
}

const optionsCStyle = {
  width: '250px',
  display: 'flex',
  justifyContent: 'space-around',
  margin: '30px auto'
}

const displayNone = {
  display: 'none'
}

const displayBlock = {
  display: 'block'
}


const WideModal = props => (
    <div className="dark-overlay" 
         onClick={props.hidePopup}
         style={{
            display: props.display ? 'block' : 'none',
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            zIndex: '100',
            background: 'rgba(0, 0, 0, 0.5)',
        }}>
        <div className="confirm-container"
             style={containerStyle}>
            <h5 className="pc-title" style={titleStyle}>{props.title}</h5>
            <span className="pc-text" style={textStyle}>{props.text}</span>
            
            <div>{props.children}</div>

            <div className="pc-options-container" style={optionsCStyle}>
                <button type="button"
                        className="btn btn-danger"
                        onClick={props.confirmAction}>{props.confirmText || 'Confirm'}</button>
                <button type="button"
                        style={(props.cancelText===" " ? displayNone : displayBlock)}
                        className="btn btn-primary "
                        onClick={props.calcelAction}>{props.cancelText || 'Cancel'}</button>
            </div>
        </div>
    </div>
);

export default WideModal