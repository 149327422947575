import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import CheckboxRound from '../CheckboxRound/CheckboxRound'
import SpecialitySuggest from './SpecialitySuggest'

// import './SuggestSpecialities.scss'

class SpecialityList extends React.Component {

    state = {
        specialities: this.props.suggestedSpecialities || [],
    }

    componentWillReceiveProps = (newProps) => {
        // console.log("newProps: ", newProps)
        this.setState(() => ({
            specialities: newProps.suggestedSpecialities || [],
        }))
    }

    handleCheckSpeciality = (ev) => {
        const state = !ev.state.isChecked,
              speciality = ev.props.speciality;

        this.props.handleSpecialityCheck(state, speciality)
    }


    renderList = () => {
        const items = [];
        for (let i = 0; i < this.state.specialities.length; i++) {
            const speciality = this.state.specialities[i];
            const item = (
                <li className="suggested-spec-item" key={speciality.id}>
                    <div className="speciality-select-box">
                        <CheckboxRound id={`check-spec-${speciality.id}`}
                                       speciality={speciality}
                                       specialityName={speciality.name}
                                       handleCheck={this.handleCheckSpeciality}
                                       isChecked={ speciality.isChecked || false}/>

                    </div>
                </li>

            )

            items.push(item);
        }

        return (

            <div className="suggested-specs-list">
                {/* <div className="spec-list-close-btn" onClick={this.props.closeList}>
                    <div className="spec-list-close-text spec-list-clos-btn-item">Close</div>
                    <div className="spec-list-close-icon spec-list-clos-btn-item">
                        <FontAwesomeIcon icon={faTimes}/>
                    </div>
                </div> */}
                <ul>
                    {items}
                </ul>
            </div>

        )

    }

    handleClickOutside = () => {
        this.props.closeList()
    }

    render() {
        return (
            <div className={`search-field--bg no-bg-image ${this.props.selectedSpecialityError === true ? "error" : ""}`} >
            <SpecialitySuggest
                      placeholder="ABA Therapist"
                      className="t-search-input autosugest-input"
                      inputName="searchSpecialitiesTerm"
                      value={this.props.searchSpecialitiesTerm}
                      onChange={this.props.suggestSpecialities}
                      toggleSpecialityList={this.props.toggleSpecialityList}
                      displayText={this.props.renderSpecialityDisplayText}
                      tooltip={this.props.renderSpecialityLabelTooltip}
                    />
            <div className={ `suggested-specs-container ${this.props.isOpen ? '' : 'hidden-element'}` } >
                {this.renderList()}
            </div>
            </div>
        )
    }

}

export default enhanceWithClickOutside(SpecialityList)
