import { put, takeLatest } from 'redux-saga/effects'
import { REQ_LOGOUT_USER} from './actions'
import { recFetchUserDetails } from '../../utils/FetchUser/actions'
//import fetchData from '../../utils/api'

function* logoutUser(action) {

  // console.log("saga called")
  
  try {
    //const data = yield call(fetchData, action.data, action.resource)
    // console.log("Signin data", data);
    // console.log("action: ", action)
    // const data = {
    //     logout: true
    // } ;
    yield put(recFetchUserDetails({}))
    //yield put(recLogoutUser(data))
   
  } catch (e) {
      console.log("Api error: ", e)
   }
}


export default function* headerSaga() {
    yield takeLatest(REQ_LOGOUT_USER, logoutUser);
};