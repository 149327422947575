import { REC_POST_REVIEW, REC_POST_REPLY, REC_GET_PUBLIC_PROFILE, REC_GET_PUBLIC_REVIEWS, REC_GET_PUBLIC_REPLIES,REC_GET_THERAPIST_LOCATIONS_VISITOR,
         REC_LOG_PROFILE_VISIT, REC_REQUEST_CLAIM_LISTING, REC_FORM_SUBMIT, REC_REQUEST_NETWORKING } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_POST_REVIEW:
            return {
                review: data
            };

        case REC_POST_REPLY:
            return {
                reply: data
            };

        case REC_GET_PUBLIC_PROFILE:
            return data;

        case REC_GET_PUBLIC_REVIEWS:
            return {
                reviews: data
            };

        case REC_GET_PUBLIC_REPLIES:
            return {
                replies: data
            };
        
        case REC_GET_THERAPIST_LOCATIONS_VISITOR:
            return {
                locVisitor: data
            };

        case REC_LOG_PROFILE_VISIT:
            return {
                profileVisitLog: data
            };

        case REC_REQUEST_CLAIM_LISTING:
            return data;
        
        case REC_REQUEST_NETWORKING:
            return data;
        
        case REC_FORM_SUBMIT:
            return data;

        default:
            return state
    }
}