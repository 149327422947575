import { REC_GET_ALL_ANALITICS, REC_GET_ALL_ANALITIC_POINTS } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_ALL_ANALITICS:
            return {
                analitics: data
            };

        case REC_GET_ALL_ANALITIC_POINTS:
            return {
                analiticPoints: data
            };
        
        default:
            return state
    }
}