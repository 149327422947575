import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_UPDATE_THERAPIST_DETAILS, recUpdateTherapistDetails} from './actions'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'
import fetchData from '../../../utils/api'
import mergeInUserDetails from '../../../utils/mergeInUserDetails'

export const userDetails = (state) => state.userDetails

function* updateDetails(action) {
  
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)

    yield put(recUpdateTherapistDetails(data))
    
    if (data.success) {
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, action.config.body)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
   
  } catch (e) {
      console.log("Api error: ", e)
   }

}


export default function* therapistUpdateDetails() {
    yield takeLatest(REQ_UPDATE_THERAPIST_DETAILS, updateDetails);
};