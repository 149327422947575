import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarTherapist from '../../../components/SidebarTherapist/SidebarTherapist'
import './Locations.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'
import AttachedLocationItem from './AttachedLocationItem'
import { reqAddLocation, reqGetAllLocations, reqDelLocation } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
// import InsuranceItem from './InsuranceItem'
import PopuConfirm from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper from '../MainWrapper/MainWrapper'
// import AttachedInsItem from './AttachedInsItem'
import MenuStepNavigation from "../../../components/MenuStepNavigation/MenuStepNavigation"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

class TherapistLocations extends React.Component {

    state = {
        therapistLocations: [],
        pageLoading: false,
        showPopup: false,
        extraLocations: 0
    }

    therapist = {}
    authHeader = {}
    addLocationUrl = 'therapist/locations/add';
    getLocationsUrl = 'therapist/locations/get';
    deleteLocationUrl = 'therapist/locations/delete';
    requiredPlans = [
        'platinum'
    ]
    maxNumberLocations = { gold : 2, platinum : 3}


    componentDidMount = () => {

        this.therapist = this.props.userDetails;
        this.authHeader = {  
            Authorization: `Bearer ${this.therapist.token}`,
            "Content-type": "application/json" 
        }

        this.getTherapistLocations()

    }

    getTherapistLocations = () => {
        const rConfig = {
            body: {},
            headers: this.authHeader,
            resource: this.getLocationsUrl
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqGetAllLocations(rConfig);
        })
    }
    

    componentWillReceiveProps = (newProps) => {
        // console.log("newProps: ", newProps)
        const apiData = newProps.apiData;

        if (apiData.locAdded && apiData.locAdded.success) {
            this.getTherapistLocations()
        } else if (apiData.therapistLocations && apiData.therapistLocations.success) {
            this.setState(() => ({
                pageLoading: false,
                therapistLocations: apiData.therapistLocations.locations,
                extraLocations: apiData.therapistLocations.extraLocations
            }))
        } else if (apiData.therapistLocations && apiData.therapistLocations.error){
            this.setState(() => ({
                pageLoading: false,
                errorMessage: apiData.therapistLocations.error.code,
              }));
              this.errorHandler(apiData.therapistLocations.error.code)
        } else if (apiData.locDeleted && apiData.locDeleted.success) {
            // console.log("Getting locations")
            this.getTherapistLocations();
        }
        
    }

    // handleChange = (event) => {
    //     let inputName = event.target.name,
    //         value = event.target.value
        
    //     this.setState({
    //         [inputName]: value
    //     }, () => {
    //         this.filterDisplayedInsurances()
    //     })
    // }

    errorHandler = (errorMessage) => {
        
        this.setState(() => ({
          showPopup: true,
          popupText: errorMessage,
          popupTitle: "Error occurred",
          confirmAction: this.hidePopup,
          confirmText: "Ok",
          cancelText: " "
        }));
      };


    hidePopup = () => {
        this.setState( () => ({showPopup: false, pageLoading: false}))
    }

    extractFromPlace = (type, placeObj) => {
        let components = placeObj[0].address_components;
        let googleKey = '';

        switch(type) {
            case 'locality':
                googleKey = 'locality';
                break;
            
            case 'region': 
                googleKey = 'administrative_area_level_1'
                break;

            default:
                googleKey = '-'
                break;
        }

        let name = '-';

        for(let i=0; i< components.length; i++) {
            let component = components[i],
                hasKey = component.types.filter( t => t === googleKey).length;
            
            if (hasKey) {
                name =  component.long_name
                break;
            }
        }

        return name;
    }

    

    addLocation = (place) => {
        // console.log("selected place: ", place);
        geocodeByPlaceId(place.place_id).then( geocode => {
            // console.log("geocode is: ", geocode);
            const latLng = getLatLng(geocode[0]),
                  locationName = this.extractFromPlace('locality', geocode),
                  region = this.extractFromPlace('region', geocode);
            
            // console.log("Location: ", location);
            // console.log("Region: ", region)
            latLng.then( response => {
                //console.log("Place: ", place)
                const location = {
                    address: place.description,
                    lat: response.lat,
                    lng: response.lng,
                    locationName: locationName,
                    region: region
                }

                // console.log("Location: ", location)

                const rConfig = {
                    body: {
                        location: location
                    },
                    headers: this.authHeader,
                    resource: this.addLocationUrl
                }

                this.setState({
                    pageLoading: true
                }, () => {
                    this.props.actions.reqAddLocation(rConfig)
                })
                
            })
        })
    }

    renderAttachedLocations = () => {

        let locations = this.state.therapistLocations.map( (l, i) => (
            <AttachedLocationItem 
                key={i}
                location={l}
                removeAction={this.removeLocationHandler}
            />
        ))

        return (
            <ul className="attached-location-list">
                {locations}
            </ul>
        )
    }

    removeLocationHandler = (locId) => {
        console.log("Removing location w id: ", locId);
        this.setState(() => ({
            showPopup: true,
            popupText: 'Are you sure you want to remove this location?',
            popupTitle: 'Remove Location?',
            confirmAction: this.confirmDeleteLocation,
            deleteId: locId
        }))
    }

    confirmDeleteLocation = () => {
        //console.log("Confirmed deleting location: ", this.state.deleteId)
        const rConfig = {
            body: {
                locId: this.state.deleteId
            },
            headers: this.authHeader,
            resource: this.deleteLocationUrl
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqDelLocation(rConfig)
        })
    }


    hasAccess = () => {
        let cPlan = this.therapist.membershipPlan,
            addedLocations = (this.state.therapistLocations || []),
            // hasReqPlan = this.requiredPlans.filter(p => p === cPlan).length,
            isBetaUser = this.props.userDetails.betaUser,
            availableLocations
        
        //console.log("cPlan: ", cPlan)
        //console.log("addedLocations: ", addedLocations)
        //console.log("hasReqPlan: ", hasReqPlan)

        switch(cPlan) {
            case 'platinum':
                availableLocations = this.maxNumberLocations.platinum + this.state.extraLocations - addedLocations.length
                break;
            case 'gold':
                availableLocations = this.maxNumberLocations.gold + this.state.extraLocations - addedLocations.length
                break;
            case 'executive':
                availableLocations = addedLocations.length + 1
                break;
            default:
                availableLocations = this.state.extraLocations + 1 - addedLocations.length
        }
        
        
        if (availableLocations > 0 || isBetaUser) {
            return true
        } else {
            return false
        }

        // if (!addedLocations.length) {
        //     return true
        // } else if (addedLocations.length > 1 && hasReqPlan) {
        //     return true
        // } else {
        //     return false
        // }
    }

    renderAddSection = () => {
        let cPlan = this.therapist.membershipPlan,
            addedLocations = (this.state.therapistLocations || []),
            availableLocations
        
        switch(cPlan) {
            case 'platinum':
                availableLocations = this.maxNumberLocations.platinum + this.state.extraLocations - addedLocations.length
                break;
            case 'gold':
                availableLocations = this.maxNumberLocations.gold + this.state.extraLocations - addedLocations.length
                break;
            case 'executive':
                availableLocations = addedLocations.length + 1
                break;
            default:
                availableLocations = this.state.extraLocations + 1 - addedLocations.length
        }

    return (
        <div className="add-location-section">
            <h3 className="loc-subtitle">Add Location</h3>
            <div className="add-input-container">
                <div className="location-input-outer">
                    <div className="location-input-inner">
                        <GooglePlacesAutocomplete
                            initialValue={''}
                            className="location-input"
                            onSelect={this.addLocation}
                            lazyLoad={true}
                        />
                    </div>
                </div>
            
            {availableLocations > 0 ?  
                <h5 className="mt-20">Available number of locations: <span className="bold-val">{this.therapist.membershipPlan === "executive" ? (<strong>Unlimited</strong>) : availableLocations}</span></h5>
                : ''}
            </div>
        </div>
        )
    }

    renderNoAccessSection = () => (
        <div className="no-access-section">
            <h3 className="loc-subtitle">Add Location</h3>
            {this.therapist.membershipPlan === "free" ? (
                <div className="no-access-desc">You reached your maximum number of locations for your plan, you need at least <strong>Silver plan</strong> in order to add more location. You can chage your plan <a href="/payment">here! </a></div>
            ) : (
                <div className="no-access-desc">You reached your maximum number of locations for your plan, you need to <a href="/payment">buy more locations</a> in order to add addresses</div>)}
            
        </div>
    )

    renderAddContent = () => (
        this.hasAccess() 
            ? this.renderAddSection()
            : this.renderNoAccessSection()
    )

    render () {
        return (
            <MainWrapper page="therapist-locations">
                <div id="therapist-manage-locations">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup} 
                                 text={this.state.popupText}
                                 title={this.state.popupTitle}
                                 hidePopup={this.hidePopup}
                                 calcelAction={this.hidePopup}
                                 confirmAction={this.state.confirmAction}
                                 confirmText={this.state.confirmText}
                                 cancelText={this.state.cancelText}
                                 />

                    <HeaderUser adminFullName="John Doe"/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarTherapist page="therapist-locations" roleId={this.therapist.roleId}/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                <MenuStepNavigation
                                    leftLink="/specialities"
                                    leftText="My Specialities"
                                    rightLink="/insurances"
                                    rightText="Add Accepted Insurances"
                                />
                                    <div className="top-row">
                                        {/* Title */}
                                        <h1 className="page-title">Locations For Your Services</h1>
                                    </div>
                                    <div className="attached-locations-section">
                                        <h3 className="loc-subtitle">Attached Locations</h3>
                                    </div>
                                    <div className="attached-locations-section">
                                        {this.renderAttachedLocations()}
                                    </div>
                                    <div className="add-section">
                                        { this.renderAddContent() }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.manageLocations,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqAddLocation: bindActionCreators(reqAddLocation, dispatch),
        reqGetAllLocations: bindActionCreators(reqGetAllLocations, dispatch),
        reqDelLocation: bindActionCreators(reqDelLocation, dispatch),
      }
    };
  }

  
  
export default connect(mapStateToProps, mapDispatchToProps)(TherapistLocations)

