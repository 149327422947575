export const REQ_GET_TOP_LOCATIONS = 'REQ_GET_TOP_LOCATIONS'
export const REC_GET_TOP_LOCATIONS = 'REC_GET_TOP_LOCATIONS'

export const REQ_GET_TOP_THERAPISTS = 'REQ_GET_TOP_THERAPISTS'
export const REC_GET_TOP_THERAPISTS = 'REC_GET_TOP_THERAPISTS'

export const reqGetTopLocations = (config) => ({type: REQ_GET_TOP_LOCATIONS, config: config})
export const recGetTopLocations = data => ({type: REC_GET_TOP_LOCATIONS, data})

export const reqGetTopTherapists = (config) => ({type: REQ_GET_TOP_THERAPISTS, config: config})
export const recGetTopTherapists = data => ({type: REC_GET_TOP_THERAPISTS, data})