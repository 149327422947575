import React from 'react'
// import { Link } from 'react-router-dom'

// import ButtonRoundGradient from '../../components/ButtonRoundGradient/ButtonRoundGradient'
import './ArticleCard.scss'

const isFirstArticle = props => (
    props.firstArticle ? '' : 'art-small-thb'
)

const ArticleCard = props => (
    <div className={`article-card ${typeof props.className !== "undefined" ? props.className : "" }`}>
        <div className={`article--image-container ${isFirstArticle(props)}`}>
            <img src={props.image} className="article--image" alt={props.alt} loading="lazy" />
        </div>
        <div className="article--content">
            {/* <div className="article--tag">{props.tag}</div> */}
            {/* <a href={props.url} className="article--title"><span>{props.title}</span></a> */}
            <div className="article-title">{props.title}</div>
            <div className="article--short-content">{props.shortContent}</div>
            <div className="article--button-container">
              <div className="btn btn-default article--button">{props.buttonText}</div>
            </div>
        </div>
    </div>
);

export default ArticleCard
