import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import './ManageAdmins.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'
import RoundInputGradient from '../../../components/RoundInputGradient/RoundInputGradient';
import ButtonRoundGradientWIcon from '../../../components/ButtonRoundGradientWIcon/ButtonRoundGradientWicon'
import searchIcon from '../../../static/images/desktop/search_icon_white.png'
import { reqListAdmins, reqSearchAdmins, reqAdminLock, reqAdminDelete } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import AdminItem from './AdminItem'
import PopuConfirm from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper from '../MainWrapper/MainWrapper'

class ManageAdmins extends React.Component {

    state = {
        searchAdminTerm: '',
        pageLoading: false,
        showPopup: false,
        successMessage: '',
        deleteId: '',
        admins: [],
        errors: [],
        listPage: 1,
        lockId: 0,
        lockValue: false,
        popupText: '',
        popupTitle: '',
        confirmHandler: null,
    }

    admin = {}
    listUrl = 'admin/list/admins'
    deleteUrl = 'admin/delete/admin'
    lockUrl = 'admin/ban/admin'
    unlockUrl = 'admin/unlock/admin'
    searchUrl = 'admin/search/admin'
    authHeader = {}

    componentDidMount = () => {

        this.admin = this.props.userDetails;
        this.authHeader = {
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json"
        }

        this.loadAdmins();

    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;

        if (apiData.adminList && apiData.adminList.admins) {
            this.setState(() => ({
                pageLoading: false,
                admins: apiData.adminList.admins
            }))
        } else if (apiData.adminSearch && apiData.adminSearch.results) {
            this.setState(() => ({
                pageLoading: false,
                admins: apiData.adminSearch.results
            }))
        } else if (apiData.adminLock && apiData.adminLock.success) {
            this.loadAdmins()
        }


    }

    loadAdmins = () => {
        const reqConfig = {
            body: {},
            resource: this.listUrl,
            headers: this.authHeader
        }
        this.props.actions.reqListAdmins(reqConfig)
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({[inputName]: value}))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length;
    }

    getInpuErrorMessage = (inputName) => {
        const message = this.state.errors.filter(err => err.input === inputName)
                                        .map( err => err.message) || []

        return message[0] || '';
    }


    renderAdmins = () => {
        const ownId = this.props.userDetails.id
        let adminItems = [];

        for(let i=0; i< this.state.admins.length; i++) {

            let admin = this.state.admins[i]

            adminItems.push(
                <AdminItem index={admin.id}
                           key={admin.id}
                           ownId={ownId}
                           admin={admin}
                           handleLocking={this.handleAdminLocking}
                           handleDelete={this.handleAdminDelete}/>
            )
        }

        return (
            <ul className="admin-list">
                {adminItems}
            </ul>
        )

    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    handleAdminLocking = (data) => {
        const lockText = 'Are you sure you want to lock this admin account? This admin will not be able to login untill you unlock the account!',
              unlockText = 'Are you sure you want to unlock this account? This admin will be able to login again'

        this.setState(() => ({
            showPopup: true,
            lockId: data.id,
            lockValue: data.lock,
            popupTitle: 'Lock Admin?',
            confirmHandler: this.confirmAdminLock,
            popupText: data.lock ? lockText : unlockText
        }))
    }

    handleAdminDelete = (id) => {
        const deleteText = 'Are you sure you want to delete this admin account? This action is not reversible!'

        this.setState(() => ({
            showPopup: true,
            deleteId: id,
            popupTitle: 'Delete Admin?',
            confirmHandler: this.confirmAdminDelete,
            popupText: deleteText
        }))
    }

    confirmAdminLock = () => {
        const rConfig = {
            body: {
                uid: this.state.lockId
            },
            headers: this.authHeader,
            resource: this.state.lockValue ? this.lockUrl : this.unlockUrl
        }

        this.props.actions.reqAdminLock(rConfig)
    }

    confirmAdminDelete = () => {
        const rConfig = {
            body: {
                uid: this.state.deleteId
            },
            headers: this.authHeader,
            resource: this.deleteUrl
        }

        this.props.actions.reqAdminDelete(rConfig)
    }

    searchAdmins = () => {

        this.setState({
            pageLoading: true
        }, () => {


            const config = {
                body: {},
                headers: this.authHeader,
                resource: `${this.searchUrl}?search=${this.state.searchAdminTerm}`
            }
            // console.log("config: ", config)
            if (this.state.searchAdminTerm !== '') {
                this.props.actions.reqSearchAdmins(config)
            } else {
                this.loadAdmins()
            }

        })

    }

    render () {
        return (
            <MainWrapper>
                <div id="manage-admins">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup}
                                 text={this.state.popupText}
                                 title={this.state.popupTitle}
                                 hidePopup={this.hidePopup}
                                 calcelAction={this.hidePopup}
                                 confirmAction={this.state.confirmHandler}/>

                    <HeaderUser/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="admins"/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row">
                                        {/* Title */}
                                        <h1 className="page-title">Manage Admin Accounts</h1>
                                    </div>

                                    <div className="search-row">
                                        {/* Leftside Content */}
                                        <div className="leftside-content">
                                            <RoundInputGradient
                                                placeholder="Email/Name"
                                                className="sp-search-input"
                                                inputName="searchAdminTerm"
                                                value={this.state.searchAdminTerm}
                                                onChange={this.handleChange}/>
                                        </div>
                                        {/* RightSide Content */}
                                        <div className="rightside-content">
                                            <ButtonRoundGradientWIcon text="Search"
                                                                    otherClass="small-gradient-button"
                                                                    icon={searchIcon}
                                                                    onClick={this.searchAdmins}/>
                                        </div>
                                    </div>

                                    <div className="results-section">
                                        {/* Results Title */}
                                        <h2 className="page-title">Admins</h2>
                                        <div className="admins-container">
                                            {this.renderAdmins()}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.manageAdmins,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqListAdmins: bindActionCreators(reqListAdmins, dispatch),
        reqSearchAdmins: bindActionCreators(reqSearchAdmins, dispatch),
        reqAdminLock: bindActionCreators(reqAdminLock, dispatch),
        reqAdminDelete: bindActionCreators(reqAdminDelete, dispatch)
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdmins)
