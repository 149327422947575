import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import './ManageInsurances.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'
import RoundInputGradient from '../../../components/RoundInputGradient/RoundInputGradient';
import ButtonRoundGradientWIcon from '../../../components/ButtonRoundGradientWIcon/ButtonRoundGradientWicon'
import searchIcon from '../../../static/images/desktop/search_icon_white.png'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import { reqAddInsurance, reqGetInsurances, reqDeleteInsurance, reqUpdateInsurance, reqSearchInsurances } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import InsuranceItem from './InsuranceItem'
import PopuConfirm from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper from '../MainWrapper/MainWrapper'

class AdminManageInsurances extends React.Component {

    state = {
        searchInsName: '',
        addInsName: '',
        pageLoading: false,
        showPopup: false,
        successMessage: '',
        deleteId: '',
        insurances: [],
        errors: [],
    }

    admin = {}
    addInsUrl = 'admin/insurances/new'
    listInsUrl = 'admin/insurances/list'
    deleteInsUrl = 'admin/insurance/delete'
    updateUrl = 'admin/insurance/update'
    searchUrl = 'admin/insurances/search'
    authHeader = {}

    componentDidMount = () => {

        this.admin = this.props.userDetails;
        this.authHeader = {
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json"
        }

        const reqConfig = {
            body: {},
            resource: this.listInsUrl,
            headers: this.authHeader
        }

        // Load Insurances
        this.props.actions.reqGetInsurances(reqConfig);

    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;

        if (apiData.addInsurance && apiData.addInsurance.success) {
            this.setState( {
                pageLoading: false,
                successMessage: 'Insurance Succesfully Added',
                addInsName: '',
            }, () => {
                this.loadInsurances()
            })

        } else if (apiData.insurances) {
            this.setState(() => ({
                insurances: (apiData.insurances.insurances || []).reverse()
            }))
        } else if (apiData.insDeleted && apiData.insDeleted.success) {
            this.setState({pageLoading: false}, () => {
                this.loadInsurances()
            })
        } else if (apiData.insUpdate && apiData.insUpdate.success) {
            this.setState({pageLoading: false}, () => {
                this.loadInsurances()
            })
        } else if (apiData.insSearch) {
            this.setState( () => ({
                pageLoading: false,
                insurances: apiData.insSearch.insurances
            }))
        }

    }

    loadInsurances = () => {
        const reqConfig = {
            body: {},
            resource: this.listInsUrl,
            headers: this.authHeader
        }
        this.props.actions.reqGetInsurances(reqConfig)
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({[inputName]: value}))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length;
    }

    getInpuErrorMessage = (inputName) => {
        const message = this.state.errors.filter(err => err.input === inputName)
                                        .map( err => err.message) || []

        return message[0] || '';
    }

    addInsurance = () => {

        this.setState({
            errors: []
        }, () => {
            let formValid = true;

            // Validate fields for creating speciality
            if (this.state.addInsName === '') {
                formValid = false;
                this.setState( () => ({
                    errors: [
                        {
                            input: 'addInsName',
                            message: 'Insurance Name is mandatory'
                        }
                    ]
                }))
            }

            if (!formValid) return;

            const config = {
                body: {
                    name: this.state.addInsName,
                },
                headers: this.authHeader,
                resource: this.addInsUrl
            }

            this.setState({
                pageLoading: true
            }, () => {
                this.props.actions.reqAddInsurance(config)
            })


        })

    }


    renderInsurances = () => {
        let items = [];

        for(let i=0; i< this.state.insurances.length; i++) {

            let insurance = this.state.insurances[i]

            items.push(
                <InsuranceItem index={insurance.id}
                                key={insurance.id}
                                speciality={insurance}
                                deleteAction={this.deleteInsurance}
                                updateAction={this.updateInsurance}/>
            )
        }

        return (
            <ul className="specialities-list">
                {items}
            </ul>
        )

    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

    deleteInsurance = (id) => {
        this.setState(() => ({
            deleteId: id,
            showPopup: true
        }))
    }

    confirmDelete = () => {
        const rConfig = {
            body: {
                insuranceId: this.state.deleteId
            },
            resource: this.deleteInsUrl,
            headers: this.authHeader
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqDeleteInsurance(rConfig)
        })

    }

    updateInsurance = (insurance) => {

        const rConfig = {
            body: {
                insuranceId: insurance.id,
                insuranceName: insurance.name,
            },
            headers: this.authHeader,
            resource: this.updateUrl
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqUpdateInsurance(rConfig)
        })
    }

    searchInsurances = () => {

        this.setState({
            pageLoading: true
        }, () => {


            const config = {
                body: {
                    insuranceName: this.state.searchInsName
                },
                headers: this.authHeader,
                resource: this.searchUrl
            }
            this.props.actions.reqSearchInsurances(config)
        })

    }

    render () {
        return (
            <MainWrapper logoutRedirect="/admin/signin">
                <div id="admin-manage-insurances">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup}
                                hidePopup={this.hidePopup}
                                calcelAction={this.hidePopup}
                                confirmAction={this.confirmDelete}/>

                    <HeaderUser adminFullName="John Doe"/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="insurances"/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                    <div className="top-row">
                                        {/* Title */}
                                        <h1 className="page-title">Manage Available Insurances</h1>
                                    </div>

                                    <div className="search-row">
                                        {/* Leftside Content */}
                                        <div className="leftside-content">
                                            <RoundInputGradient
                                                placeholder="Insurance Name"
                                                className="sp-search-input"
                                                inputName="searchInsName"
                                                value={this.state.searchInsName}
                                                onChange={this.handleChange}/>
                                        </div>
                                        {/* RightSide Content */}
                                        <div className="rightside-content">
                                            <ButtonRoundGradientWIcon text="Search"
                                                                    otherClass="small-gradient-button"
                                                                    icon={searchIcon}
                                                                    onClick={this.searchInsurances}/>
                                        </div>
                                    </div>

                                    <div className="add-row">
                                        {/* Leftside Content */}
                                        <div className="leftside-content">
                                            <RoundInputGradient
                                                placeholder="Insurance Name"
                                                className={`sp-search-input`}
                                                invalid={this.inputHasErrors('addInsName')}
                                                inputName="addInsName"
                                                value={this.state.addInsName}
                                                onChange={this.handleChange}/>

                                            <div className="message-span-container">
                                                <span className="error-message">{this.getInpuErrorMessage('addInsName')}</span>
                                            </div>

                                        </div>
                                        {/* RightSide Content */}
                                        <div className="rightside-content">
                                            <ButtonRoundGradient text="Add"
                                                                otherClass="small-gradient-button"
                                                                onClick={this.addInsurance}/>
                                        </div>
                                    </div>

                                    <div className="results-section">
                                        {/* Results Title */}
                                        <h2 className="page-title">Results</h2>
                                        <div className="specialities-container">
                                            {this.renderInsurances()}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.adminManageInsurances,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqAddInsurance: bindActionCreators(reqAddInsurance, dispatch),
        reqGetInsurances: bindActionCreators(reqGetInsurances, dispatch),
        reqDeleteInsurance: bindActionCreators(reqDeleteInsurance, dispatch),
        reqUpdateInsurance: bindActionCreators(reqUpdateInsurance, dispatch),
        reqSearchInsurances: bindActionCreators(reqSearchInsurances, dispatch)
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(AdminManageInsurances)
