import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reqGetTopTherapists } from "../actions";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Footer from "../../../components/Footer/Footer";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import TherapistCard from "../../../components/TherapistCard/TherapistCard";
import "./TherapistsByLocation.scss";
import HeaderGuestSimple from "../../../components/Header/HeaderGuestSimple";
import { Link } from "react-router-dom";
import ButtonRoundGradient from "../../../components/ButtonRoundGradient/ButtonRoundGradient";

class TherapistsByLocation extends React.Component {
  state = {
    sideDrawerOpen: false,
    pageLoading: false,
    locationType: "locations",
    locationName: "",
    region: "",
    selectedLocation: {},
  };

  listTherapistsUrl = "seo/therapist/list";

  drawerToggleClickHandler = () => {
    this.setState((prevState) => ({
      sideDrawerOpen: !prevState.sideDrawerOpen,
    }));
  };

  componentDidMount = () => {
    let { locationType, locationName, region } = this.props.match.params;
    this.setState(
      {
        locationType,
        locationName,
        region,
      },
      () => {
        this.getCoordinates();
      }
    );
  };

  componentWillReceiveProps = (newProps) => {
    let apiData = newProps.apiData;

    // console.log("apidata: ", apiData)

    if (apiData.topTherapists && apiData.topTherapists.success) {
      let { data: therapists } = apiData.topTherapists;

      this.setState(() => ({
        pageLoading: false,
        therapists: therapists,
      }));
    }
  };

  loadTherapists = () => {
    this.setState({ pageLoading: true }, () => {
      this.props.actions.reqGetTopTherapists({
        body: {},
        headers: null,
        resource: this.getListUrl(),
      });
    });
  };

  getListUrl = () =>
    `${this.listTherapistsUrl}?locationType=${this.state.locationType}&location=${this.state.locationName}&lat=${this.state.selectedLocation.lat}&lng=${this.state.selectedLocation.lng}`;

  getCoordinates = () => {
    geocodeByAddress(`${this.state.locationName}, ${this.state.region}`).then(
      (geocode) => {
        const latLng = getLatLng(geocode[0]);
        latLng.then((response) => {
          const location = {
            lat: response.lat,
            lng: response.lng,
          };

          this.setState(() => ({
            selectedLocation: location,
          }));

          this.loadTherapists();
        });
      }
    );
  };

  renderTherapists = () => {
    let therapists = (this.state.therapists || []).map((t, i) => (
      <TherapistCard key={i} therapist={t} />
    ));

    return (
      <div className="top-therapists-list">
        {therapists}
        <div className="more-therapists">
          <Link to="/specialists">
            <ButtonRoundGradient text={"See More Specialists"} />
          </Link>
        </div>
      </div>
    );
  };

  render() {
    // eslint-disable-next-line
    let sideDrawer = null;

    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer />;
    }

    return (
      <div>
        <div id="therapists-by-locations">
          {/* Loader */}
          <PopupLoader display={this.state.pageLoading} />

          {/* Header */}
          <HeaderGuestSimple
            menu=""
            menuVisible={this.state.sideDrawerOpen}
            drawerClickHandler={this.drawerToggleClickHandler}
          />

          {/* Main Container */}
          <div className="therapists-by-loc-container">
            <h2 className="page-title">{`Top Therapists in ${this.state.locationName}`}</h2>
            <div className="mt-50 mb-30"></div>
            {/* List Container */}
            <div className="therapists-list-container">
              {this.renderTherapists()}
            </div>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.topLocations,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetTopTherapists: bindActionCreators(reqGetTopTherapists, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TherapistsByLocation);
