import React from 'react'

import HeaderGuest               from '../../components/Header/HeaderGuest'
import Footer                    from '../../components/Footer/Footer'
import SideDrawer                from '../../components/SideDrawer/SideDrawer'
import TravelCard                from '../../components/Travel/Travel'

import TravelPicture             from '../../static/images/desktop/travel-picture.png'
import travelSampleImage1        from '../../static/images/desktop/travel-sample-image-1.png'

import './Travel.scss'


class Travel extends React.Component {

  state = {
    sideDrawerOpen: false
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen}
    })
  }

  render() {
    // eslint-disable-next-line
    let sideDrawer = null;

    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer/>
    }

    return (
    <div>
        <HeaderGuest menu="" menuVisible={this.state.sideDrawerOpen} drawerClickHandler={this.drawerToggleClickHandler}/>

        <div id="travelPage" className="container">
            <div className="text-center row mb-60">
                <img src={TravelPicture} className="travel-image" alt="alt"/>
            </div>
            <h2 className="text-center">Travel</h2>
            <div className="row">
                <div className="col-lg-4 col-sm-6 mb-30">
                    <TravelCard 
                      className="travel-card"
                      image={travelSampleImage1}
                      url="/"
                      tag="Tag - tag"
                      buttonText="View Details"
                      title="Featured Hotel"
                    />
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                    <TravelCard
                      className="travel-card"
                      image={travelSampleImage1}
                      url="/"
                      tag="Tag - tag"
                      buttonText="View Details"
                      title="Featured Restaurant"
                    />
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                    <TravelCard
                      className="travel-card"
                      image={travelSampleImage1}
                      url="/"
                      tag="Tag - tag"
                      buttonText="View Details"
                      title="Featured Resort"
                    />
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                    <TravelCard
                      className="travel-card"
                      image={travelSampleImage1}
                      url="/"
                      tag="Tag - tag"
                      buttonText="View Details"
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
                    />
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                    <TravelCard
                      className="travel-card"
                      image={travelSampleImage1}
                      url="/"
                      tag="Tag - tag"
                      buttonText="View Details"
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
                    />
                </div>
                <div className="col-lg-4 col-sm-6 mb-30">
                    <TravelCard
                      className="travel-card"
                      image={travelSampleImage1}
                      url="/"
                      tag="Tag - tag"
                      buttonText="View Details"
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
                    />
                </div>
            </div>
        </div>

        <Footer />
    </div>
    )
  }

}



export default Travel