import { REC_LIST_THERAPISTS_ADMIN, REC_LIST_ALL_SPEC_ADMIN_SEARCH, REC_SEARCH_THERAPISTS_ADMIN, 
         REC_THERAPISTS_LOCK_UNLOCK, REC_THERAPISTS_DELETE, REC_THERAPISTS_TOGGLE_BETA_STATUS, 
         REC_THERAPISTS_TOGGLE_PUBLISH_STATUS, REC_SEARCH_POSITION } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_LIST_THERAPISTS_ADMIN:
            return {
                therapistList: data
            };
        
        case REC_LIST_ALL_SPEC_ADMIN_SEARCH:
            return {
                specialitiesList: data
            };
        
        case REC_SEARCH_THERAPISTS_ADMIN:
            return {
                therapistSearch: data
            };
        
        case REC_THERAPISTS_LOCK_UNLOCK:
            return {
                therapistLockUnLock: data
            };

        case REC_THERAPISTS_DELETE:
            return {
                therapistDelete: data
            };

        case REC_THERAPISTS_TOGGLE_BETA_STATUS:
            return {
                therapistBetaStatus: data
            };

        case REC_THERAPISTS_TOGGLE_PUBLISH_STATUS:
            return {
                therapistPublishStatus: data
            };

        case REC_SEARCH_POSITION:
            return {
                therapistSearchPosition: data
            };
        
        default:
            return state
    }
}