import { REC_GET_SPECIALITIES_FOR_SEARCH, REC_GET_INSURANCES_FOR_SEARCH, REC_GUEST_SEARCH_THERAPIST
         , REC_LOG_GUEST_SEARCH } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_SPECIALITIES_FOR_SEARCH:
            return {
                searchFormSpecialities: data
            };

        case REC_GET_INSURANCES_FOR_SEARCH:
            return {
                searchFormInsurances: data
            };

        case REC_GUEST_SEARCH_THERAPIST:
            return {
                guestSearchTherapists: data
            };

        case REC_LOG_GUEST_SEARCH:
            return {
                logGuestSearch: data
            };

        default:
            return state
    }
}