import React from "react";
import ButtonRoundGradientWicon from "../../components/ButtonRoundGradientWIcon/ButtonRoundGradientWicon";
import searchIconWhite from "../../static/images/desktop/search_icon_white.png";
import RoundInputWLabel from "../../components/RoundInputWLabel/RoundInputWLabel";
import SpecialityList from "./SpecialityList";
import LocationSuggest from "./LocationSuggest";
import InsuranceList from "./InsuranceList";
import PopupLoader from "../../components/PopupLoader/PopupLoader";
import TherapistCard from "../TherapistCard/TherapistCard";
import ButtonRoundGradient from "../../components/ButtonRoundGradient/ButtonRoundGradient";
import { Link } from "react-router-dom";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import RoundDropdown from "../../components/RoundDropdown/RoundDropdown";
import {
  reqGetSpecialitiesForSearch,
  reqGetInsurancesForSearch,
  reqGuestSearchTherapist,
  reqLogGuestSearch,
} from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TherapistsList from "../../containers/TherapistProfiles/TherapistsList";
import "./SearchForm.scss";

class SearchForm extends React.Component {
  state = {
    address: "",
    specialities: [],
    selectedSpeciality: "",
    insurances: [],
    selectedInsurance: "",
    addressError: false,
    selectedSpecialityError: false,
    selectedInsuranceError: false,
    searchSpecialitiesTerm: "",
    searchInsuranceTerm: "",
    allSpecialities: [],
    allInsurances: [],
    suggestedSpecialities: [],
    suggestOpen: false,
    insSuggestOpen: false,
    selectedLocation: {},
    therapists: [],
    pageLoading: false,
    showTherapistsList: true,
    displayNoResults: false,
    specialitiesOpen: false,
    distanceOptionsOpen: false,
    sponsoredTherapists: [],
    clientData: {},
  };

  rangeOptions = [
    { label: "5 mi", value: 5 },
    { label: "10 mi", value: 10 },
    { label: "20 mi", value: 20 },
    { label: "50 mi", value: 50 },
  ];

  listSpecialitiesUrl = "specialities/list";
  listInsurancesUrl = "insurances/list";
  searchTherapistUrl = "therapists/search";
  logSearchUrl = "analitics/search/log";

  componentDidMount = () => {
    const reqConfigSpec = {
      body: {},
      resource: this.listSpecialitiesUrl,
      headers: null,
    };
    // Load Specialities
    this.props.actions.reqGetSpecialitiesForSearch(reqConfigSpec);

    const reqConfigIns = {
      body: {},
      resource: this.listInsurancesUrl,
      headers: null,
    };
    // Load Insurances
    this.props.actions.reqGetInsurancesForSearch(reqConfigIns);

    fetch("https://geolocation-db.com/json/")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            clientData: result,
          });
        },
        (error) => {
          this.setState({
            error,
          });
        }
      );
  };

  componentWillReceiveProps = (newProps) => {
    const apiData = newProps.apiData;
    // console.log("mewProps search form: ", newProps)

    if (
      (newProps.menu === "public-profile" ||
        newProps.menu === "specialists" ||
        newProps.menu === "home") &&
      newProps.menu !== this.props.menu
    ) {
      this.setState(() => ({
        therapists: [],
        sponsoredTherapists: [],
      }));
      return;
    }

    // console.log("page change: ", newProps.menu !== this.props.menu)

    if (
      apiData.searchFormSpecialities &&
      apiData.searchFormSpecialities.specialities !== undefined
    ) {
      let specialities = apiData.searchFormSpecialities.specialities;

      for (var i = 0; i < specialities.length; i++) {
        if (specialities[i].id === 32) {
          specialities.splice(i, 1);
        }
      }

      this.setState(() => ({
        allSpecialities: specialities.sort((a, b) =>
          a.name > b.name ? 1 : -1
        ),
      }));
    }

    if (
      apiData.searchFormInsurances &&
      apiData.searchFormInsurances.insurances !== undefined
    ) {
      let insurances = apiData.searchFormInsurances.insurances;

      this.setState(() => ({
        allInsurances: insurances.sort((a, b) => (a.name > b.name ? 1 : -1)),
      }));
    }

    if (
      apiData.guestSearchTherapists &&
      apiData.guestSearchTherapists.success
    ) {
      let therapists = apiData.guestSearchTherapists.therapists,
        sponsoredTherapists = apiData.guestSearchTherapists.sponsoredTherapists;

      // console.log("sponsoredTherapists: ",sponsoredTherapists)
      // console.log("therapists: ",therapists)

      if (therapists.length || sponsoredTherapists.length) {
        this.setState(() => ({
          therapists: therapists,
          sponsoredTherapists: sponsoredTherapists,
          pageLoading: false,
          showTherapistsList: false,
          selectedLocation: {},
          address: ''
        }));
      } else {
        this.setState(() => ({
          displayNoResults: true,
          pageLoading: false,
          showTherapistsList: true
        }));
      }
    }
  };

  handleChange = (value) => {
    if (value !== "") {
      this.setState({ selectedInsuranceError: false });
      this.setState({ selectedSpecialityError: false });
      this.setState({ addressError: false });
    }
  };

  changeAddress = (event) => {
    this.handleChange(event.target.value);
    this.setState({ address: event.target.value });
  };

  changeSpeciality = (event) => {
    this.handleChange(event.target.value);
    this.setState({ selectedSpeciality: event.target.value });
  };

  changeInsurance = (event) => {
    let value = event.target.value;
    this.handleChange(value);
    this.setState({ selectedInsurance: value });
  };

  selectSpeciality = (value) => {
    this.handleChange(value);
    this.setState({ selectedSpeciality: value });
  };

  selectInsurance = (value) => {
    this.handleChange(value);
    this.setState({ selectedInsurance: value });
  };

  submitSearchForm = (event) => {
    const formValid = this.validateSearchForm();
    if (formValid === false) {
      // console.log("Form not valid!");
      return;
    }
    this.setState({
      therapists: [],
      sponsoredTherapists: [],
    });

    this.searchTherapists();
    // console.log("Form is valid")
  };

  searchTherapists = () => {
    let specialities = this.state.allSpecialities.filter((s) => s.isChecked),
      location = this.state.selectedLocation,
      // insurance = this.state.selectedInsurance,
      spIds = specialities.map((s) => s.id).join(","),
      insurance = this.state.selectedInsurance
        ? this.state.selectedInsurance
        : "",
      loc = {
        lat: location.lat,
        lng: location.lng,
        address: location.address,
      };

    // console.log("Search - Specialities: ", specialities);
    // console.log("Search - Insurance: ", insurance);
    // console.log("Search - Location: ", location);
    // console.log("Search - Loc: ", loc);

    const rConfig = {
      body: {
        specialities: spIds,
        location: loc,
        insurance: insurance,
        clientData: this.state.clientData,
        range: this.state.rangeValue,
      },
      headers: null,
      resource: this.searchTherapistUrl,
    };

    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqGuestSearchTherapist(rConfig);
        this.logGuestSearch(rConfig);
      }
    );
  };

  logGuestSearch = (config) => {
    // console.log("Logging search w config: ", config)
    let sBody = config.body,
      specialities = sBody.specialities.split(","),
      loc = sBody.location;

    const rConfig = {
      body: {
        specialities: specialities,
        location: loc,
      },
      headers: null,
      resource: this.logSearchUrl,
    };

    this.props.actions.reqLogGuestSearch(rConfig);
  };

  validateSearchForm = () => {
    let formValid = true;

    if (!this.specialitySelected()) {
      formValid = false;
      this.setState({ selectedSpecialityError: true });
    } else {
      this.setState(() => ({
        selectedSpecialityError: false,
      }));
    }
    if (!this.locationSelected()) {
      formValid = false;
      this.setState({ addressError: true });
    } else {
      this.setState(() => ({
        addressError: false,
      }));
    }

    return formValid;
  };

  specialitySelected = () => {
    return this.state.allSpecialities.filter((s) => s.isChecked).length > 0;
  };

  locationSelected = () => {
    let selectedSpecialities = this.state.allSpecialities.filter(
      (s) => s.isChecked)

    let hasElement = (array, element) => {
      let elementPrezent = false;

      for (let i = 0; i < array.length; i++) {
        if (array[i].name === element) {
          elementPrezent = true;
        }
      }
      return elementPrezent;
    }
    
    if (
      selectedSpecialities.length === 1 &&
      hasElement(selectedSpecialities, "Autism Friendly Products")
    ) {
      
      return true;
    } else {
      if (this.state.selectedLocation.lat !== undefined) {
        return true;
      } else return false;
    }
  };
  // Handle blur on multiselect-autocomplete inputs
  // handleBlurAutoSuggest = (stateKey) => {

  //   let newState = {};
  //   newState[stateKey] = ''
  //   this.setState(() => (newState))
  // }

  // Suggest Specialities based on search term
  suggestSpecialities = (event) => {
    const term = event.target.value;
    const suggestedSpecialities = this.state.allSpecialities.filter(
      (s) => s.name.toLowerCase().indexOf(term.toLowerCase()) > -1
    );

    this.setState(() => ({
      searchSpecialitiesTerm: term,
      suggestedSpecialities: suggestedSpecialities,
      suggestOpen: true,
    }));
  };

  // Suggest insurances based on search term
  suggestInsurances = (event) => {
    const term = event.target.value;
    const suggestedInsurances = this.state.allInsurances.filter(
      (s) => s.name.toLowerCase().indexOf(term.toLowerCase()) > -1
    );

    this.setState(() => ({
      searchInsuranceTerm: term,
      suggestedInsurances: suggestedInsurances,
      insSuggestOpen: true,
    }));
  };

  // On Speciality Checked
  handleSpecialityCheck = (state, speciality) => {
    const allSpecsUpdated = this.state.allSpecialities.map((s) => {
      if (s.id === speciality.id) {
        s["isChecked"] = state;
      }

      return s;
    });
    this.setState(() => ({
      allSpecialities: allSpecsUpdated,
    }));
  };

  handleInsuranceSelect = (insurance) => {
    this.setState(() => ({
      searchInsuranceTerm: insurance.name,
      insSuggestOpen: false,
      selectedInsurance: insurance,
    }));
  };

  handleLocationSelected = (place) => {
    geocodeByPlaceId(place.place_id).then((geocode) => {
      const latLng = getLatLng(geocode[0]);
      latLng.then((response) => {
        const location = {
          address: place.description,
          lat: response.lat,
          lng: response.lng,
        };

        this.setState(() => ({
          selectedLocation: location,
        }));
        // console.log("Location: ", location)
      });
    });
  };

  renderInsuranceList = () => {
    if (!this.state.insSuggestOpen) return;
    return (
      <InsuranceList
        isOpen={this.state.insSuggestOpen}
        suggestedInsurances={this.state.suggestedInsurances}
        handleBlur={this.handleBlurAutoSuggest.bind(
          this,
          "searchInsuranceTerm"
        )}
        handleSelectInsurance={this.handleInsuranceSelect}
      />
    );
  };

  renderTherapists = () => {
    if (!this.state.therapists.length) return this.renderNoResults();

    let items = this.state.therapists.map((t, i) => (
      <TherapistCard
        therapist={t}
        key={t.id}
        handleViewProfileGuest={this.handleViewProfileGuest}
      />
    ));
    return <div className="guest-search-therapist-list">{items}</div>;
  };

  renderSponsoredTherapists = () => {
    let items = this.state.sponsoredTherapists.map((t, i) => (
      <TherapistCard
        therapist={t}
        key={t.id}
        handleViewProfileGuest={this.handleViewProfileGuest}
      />
    ));
    return (
      <div
        className={`sponsored-therapist-list ${
          items.length ? "sponsored-therapist-back" : ""
        }`}
      >
        {items}
      </div>
    );
  };

  renderNoResults = () => {
    if (!this.state.displayNoResults) {
      return "";
    }

    return (
      <div className="no-results-container">
        <div className="no-results-text">
          {/* <h3>It appears there are no providers that meet your criteria</h3> */}
          <h4>
            It appears there are no providers that meet your criteria. While we
            are currently building our database, we would be happy to manually
            get you the details of the type of therapist that you require. Just
            contact us with the form below.
          </h4>
          <div className="small-btn-container">
            <Link to="/contact">
              <ButtonRoundGradient text="Contact Us" otherClass="small-btn" />
            </Link>
          </div>
        </div>
      </div>
    );
  };

  handleViewProfileGuest = (therapist) => {
    // console.log("View profile of: ", therapist);
  };

  toggleSpecialityList = () => {
    this.setState(() => ({
      specialitiesOpen: !this.state.specialitiesOpen,
    }));
  };

  toggleDistanceOptions = () => {
    this.setState(() => ({
      distanceOptionsOpen: !this.state.distanceOptionsOpen,
    }));
  };

  hideDistanceOptions = () => {
    this.setState(() => ({
      distanceOptionsOpen: false,
    }));
  };

  rangeChoosen = (range) => {
    // console.log("Range choosen: ", range)
    let rangeObj = this.rangeOptions.filter((r) => r.value === range)[0];

    this.setState(() => ({
      rangeValue: rangeObj.value,
      rangeLabel: rangeObj.label,
      distanceOptionsOpen: false,
    }));
  };

  hideSpecialityList = () => {
    this.setState(() => ({
      specialitiesOpen: false,
    }));
  };

  renderSpecialityDisplayText = () => {
    let selectedCnt = this.state.allSpecialities.filter((s) => s.isChecked)
      .length;
    return selectedCnt ? `(${selectedCnt}) selected` : "Speciality";
  };

  renderSpecialityLabelTooltip = () => {
    let selectedText = this.state.allSpecialities
      .filter((s) => s.isChecked)
      .map((s) => s.name)
      .join(" || ");

    return selectedText;
  };

  render() {
    const descriptionClass = `formDescription ${this.props.menu}`;

    return (
      <div>
        <div className="search-form-container">
          <PopupLoader display={this.state.pageLoading} />
          <p className={descriptionClass} id="searchForm">
            Find Autism Specialists Near Me
          </p>
          <form id="location-search-form">
            <div className="search-panel row">
              {/* Speciality multiselect-autosuggest List */}
              <SpecialityList
                suggestedSpecialities={this.state.allSpecialities}
                // handleBlur={this.hideSpecialityList}
                handleSpecialityCheck={this.handleSpecialityCheck}
                // searchTerm={this.state.searchSpecialitiesTerm}
                isOpen={this.state.specialitiesOpen}
                closeList={this.hideSpecialityList}
                selectedSpecialityError={this.state.selectedSpecialityError}
                searchSpecialitiesTerm={this.state.searchSpecialitiesTerm}
                suggestSpecialities={this.suggestSpecialities}
                toggleSpecialityList={this.toggleSpecialityList}
                renderSpecialityDisplayText={this.renderSpecialityDisplayText()}
                renderSpecialityLabelTooltip={this.renderSpecialityLabelTooltip()}
              />
              <div
                className={`search-field--bg no-bg-image ${
                  this.state.addressError === true ? "error" : ""
                }`}
              >
                {/* Location AutoSuggest */}
                <LocationSuggest
                  placeholder="San Francisco"
                  locationSelected={this.handleLocationSelected}
                />
              </div>
              <div
                className={`search-field--bg no-bg-image ${
                  this.state.selectedInsuranceError === true ? "error" : ""
                }`}
              >
                {/* Insurance Search Input */}
                <RoundInputWLabel
                  label="Insurance"
                  placeholder="Insurance"
                  onChange={this.changeInsurance}
                />
              </div>

              <div
                className={`search-field--bg no-bg-image ${
                  this.state.selectedInsuranceError === true ? "error" : ""
                }`}
              >
                {/* Distance Input */}
                <RoundDropdown
                  options={this.rangeOptions}
                  placeholder="Distance"
                  label="Range"
                  toggleList={this.toggleDistanceOptions}
                  open={this.state.distanceOptionsOpen}
                  hideDistanceOptions={this.hideDistanceOptions}
                  optionChoosen={this.rangeChoosen}
                  selected={{
                    value: this.state.rangeValue,
                    label: this.state.rangeLabel,
                  }}
                />
              </div>
              <div className="col-md-6 search-field--button-container">
                <ButtonRoundGradientWicon
                  text="Search"
                  otherClass="location-search-button"
                  icon={searchIconWhite}
                  onClick={this.submitSearchForm}
                />
              </div>
              <div
                className={`search-field--select ${
                  this.state.selectedSpecialityError === true ||
                  this.state.addressError === true
                    ? "error"
                    : ""
                }`}
              >
                <p>
                  *Please select at least one Speciality from the list, as well
                  as an Address
                </p>
              </div>
            </div>
          </form>
          {this.state.sponsoredTherapists.length ? (
            <h3 className="mt-50">Sponsored search results</h3>
          ) : (
            ""
          )}
          {this.renderSponsoredTherapists()}

          {this.renderTherapists()}
        </div>
        {this.props.menu === "specialists" && this.state.showTherapistsList ? (
          <TherapistsList />
        ) : (
          ""
        )}
      </div>
    );
  }
}

SearchForm.propTypes = {
  menu: PropTypes.string,
};

SearchForm.defaultProps = {
  menu: "",
};
const mapStateToProps = (state) => ({ apiData: state.searchFormReducer });

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetSpecialitiesForSearch: bindActionCreators(
        reqGetSpecialitiesForSearch,
        dispatch
      ),
      reqGetInsurancesForSearch: bindActionCreators(
        reqGetInsurancesForSearch,
        dispatch
      ),
      reqGuestSearchTherapist: bindActionCreators(
        reqGuestSearchTherapist,
        dispatch
      ),
      reqLogGuestSearch: bindActionCreators(reqLogGuestSearch, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
