import { REC_UPDATE_PROFILE, REC_UPDATE_ADMIN_PASSWORD } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_UPDATE_PROFILE:
            return { profileUpdated: data};

        case REC_UPDATE_ADMIN_PASSWORD:
            return { passwordUpdated: data};

        default:
            return state
    }
}