import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_LIST_ARTICLE_REVIEWS_MANAGEMENT, REQ_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT, REQ_LIST_REVIEWS_REPLIES_MANAGEMENT,
         REQ_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT, REQ_DELETE_REVIEW, REQ_DELETE_REPLY, 
         recToggleReviewReplyStatusManagement, recListArticleReviewsManagement, recListReviewsRepliesManagement, 
         recToggleArticleReviewStatusManagement, recDeleteReply, recDeleteReview} from './actions'
import fetchData from '../../../utils/api'

function* listReviews(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListArticleReviewsManagement(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* toggleStatus(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recToggleArticleReviewStatusManagement(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* listReplies(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListReviewsRepliesManagement(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* toggleReplyStatus(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recToggleReviewReplyStatusManagement(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* deleteReview(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recDeleteReview(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* deleteReply(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recDeleteReply(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

export default function* manageTherapistReviews() {
    yield takeLatest(REQ_LIST_ARTICLE_REVIEWS_MANAGEMENT, listReviews);
    yield takeLatest(REQ_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT, toggleStatus);
    yield takeLatest(REQ_LIST_REVIEWS_REPLIES_MANAGEMENT, listReplies);
    yield takeLatest(REQ_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT, toggleReplyStatus);
    yield takeLatest(REQ_DELETE_REVIEW, deleteReview);
    yield takeLatest(REQ_DELETE_REPLY, deleteReply);
};