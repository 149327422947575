import React from 'react'
import './RoundLabelGradientBorder.scss'

const RoundLabelGradientBorder = (props) => (
    
    <div className={`rlb-item ${props.className}`} onClick={ props.clickHandler ? props.clickHandler.bind(this, props.item) : null}>
        <div className="sp-at-outer">
            <div className="sp-at-info">
                <h4 className="sp-at-name" >{props.text}</h4>
            </div>
        </div>
    </div> 
)

export default RoundLabelGradientBorder