import React from 'react'
import './Signin.scss'
import leftShape from '../../../static/images/desktop/login-leftside-shape.png'
import bottomShape from '../../../static/images/desktop/login-bottom-shape.png'
import topRightEllipse from '../../../static/images/desktop/login-top-righ-ellipse.png'
import bottomEllipse from '../../../static/images/desktop/login-bottom-ellipse.png'
import logoTextBlue from '../../../static/images/desktop/logo-text-blue.png'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
// import CheckboxRound from '../../../components/CheckboxRound/CheckboxRound'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reqSignupTherapist, reqActivateUserAccount } from './actions';
import { reqFetchUserDetails } from '../../../utils/FetchUser/actions'
import { Redirect, Link } from 'react-router-dom'

class Signin extends React.Component {

    state = {
        email: '',
        userName: '',
        password: '',
        errors: [],
        redirectUrl: '/profile',
        redirect: false,
        errorMessage: ''
    };

    confirmUser = (token, email) => {
        const apiUrl = 'activate-account/';
        const rBody = {
            token,
            email
        };

        this.props.actions.reqActivateUserAccount(rBody, apiUrl);
    };

    componentDidMount = () => {
        // If therapist user is logged in, redirect to profile page
        const {token, email} = this.props.match.params;

        if (token && email) {
            this.confirmUser(token, email);
        }

        const user = this.props.userDetails;

        if (user.roleId === 3) {
            this.setState( () => ({ redirectUrl: '/profile' }))
            this.setState( () => ({ redirect: true}))
        }
        else if (user.roleId === 4) {
            this.setState( () => ({ redirectUrl: '/blogger-profile'}))
            this.setState( () => ({ redirect: true}))
        }
        else if (user.roleId === 1) {
            this.setState( () => ({ redirectUrl: '/admin'}))
            this.setState( () => ({ redirect: true}))
        }
        else if (user.roleId === 2) {
            this.setState( () => ({ redirectUrl: '/admin'}))
            this.setState( () => ({ redirect: true}))
        }
    };

    // Display error message if any, else if user returned, redirect to profile
    componentWillReceiveProps = (newProps) => {

        // console.log("newProps signin: ", newProps)

        const apiData = newProps.apiData,
            redirect = apiData.user || false,
            err = apiData.error ? apiData.display ? apiData.error : 'An error occured' : '';

        let newState = {
            redirect: redirect,
            errorMessage: err
        }

        this.setState(() => (newState))

    };

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    };

    handleChange = (event) => {

        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({
            [inputName]: value
        }))
    };

    handleSubmit = () => {
        this.setState({
            errors: [],
            errorMessage: ''
        }, () => {

            if (!this.validateForm()) return;

            const rBody = {
                userName: this.state.email,
                email: this.state.email,
                password: this.state.password,
                redirectUrl: this.state.redirectUrl
            }

            this.props.actions.reqSignupTherapist(rBody, 'login');
        })
    }

    handleCheck = () => (console.log("checked"))

    validateForm = () => {

        let formValid = true;

        if (this.state.email === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'email'}
                ],
                errorMessage: 'Email or User name is mandatory'
            }))
        } else if (this.state.password === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'password'}
                ],
                errorMessage: 'Password is mandatory'
            }))
        }

        return formValid

    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to={this.state.redirectUrl} />
            )
        }

        return(
            <div id="signin">
                <div className="page-background">
                    <img src={leftShape} className="bck-left-shape" alt="leftShape"/>
                    <img src={topRightEllipse} className="bck-right-top-ellipse" alt="rightTopEllipse"/>
                    <img src={bottomShape} className="bck-bottom-shape" alt="bottomShape"/>
                    <img src={bottomEllipse} className="bck-bottom-ellipse" alt="bottomShape"/>
                </div>

                <div className="page-content">
                    <Link to="/">
                        <img src={logoTextBlue} className="logo" alt="logo" />
                    </Link>
                    <h1 className="page-title">Welcome Back to Autism Assistant, Please Log In to Your Account.</h1>
                    <div className="form-container">
                        <form>
                            <div className="input-row">
                                <input type="text"
                                       placeholder="User name or Email"
                                       name="email"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("email")}`}/>
                            </div>
                            <div className="input-row">
                                <input type="password"
                                       placeholder="Password"
                                       name="password"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("password")}`}/>
                            </div>

                            <div className="remember-me-row">
                                {/* <CheckboxRound id="rmb-me" handleCheck={this.handleCheck}/> */}
                                <input type="checkbox" className="sign-in-checkbox" id="rememberme_checkbox"></input>
                                <label htmlFor="rememberme_checkbox"><span className="grey-text">Remember Me</span></label>
                            </div>

                            <div className="forgot-password-row">
                                <Link to='/request-reset-password'>
                                    <span className="grey-text underline-text">Forgot Password</span>
                                </Link>
                            </div>

                            <div>
                                <p className="error-message">{this.state.errorMessage}</p>
                            </div>

                            <div className="login-row">
                                <ButtonRoundGradient text="Log In" onClick={this.handleSubmit} />
                            </div>
                        </form>
                    </div>

                    <div className="signin-row">
                        <span className="grey-text">New to Autism Assistant?</span>
                        <Link to="/signup">
                            <span className="link-text">&nbsp; Sign up</span>
                        </Link> <span className="grey-text">now!</span>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.signin,
    userDetails: state.userDetails
});

function mapDispatchToProps(dispatch) {

    return {
        actions: {
            reqSignupTherapist: bindActionCreators(reqSignupTherapist, dispatch),
            reqFetchUserDetails: bindActionCreators(reqFetchUserDetails, dispatch),
            reqActivateUserAccount: bindActionCreators(reqActivateUserAccount, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin)
