import React        from 'react'
import { connect }  from 'react-redux'
import { Link }     from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reqGetTopLocations } from '../actions'
import { stateTwoDigitCode } from '../../../components/StateTwoDigitCode/StateTwoDigitCode'
import Footer      from '../../../components/Footer/Footer'
import { Helmet } from "react-helmet";
import SideDrawer  from '../../../components/SideDrawer/SideDrawer'
import HeaderGuest from '../../../components/Header/HeaderGuest'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import './TherapistsTopLocations.scss'

class TherapistsTopLocations extends React.Component {

  state = {
    sideDrawerOpen: false,
    pageLoading: false,
    locationType: 'locations'
  }

  lostLocationsUrl = 'seo/therapist/locations'

  drawerToggleClickHandler = () => {
    this.setState((prevState) => ({sideDrawerOpen: !prevState.sideDrawerOpen}))
  }

  componentDidMount = () => {

    let { locationType } = this.props.match.params

    this.setState({
        locationType: locationType.replace('s', '')
    }, () => { this.loadTopLocations() })

  }

  componentWillReceiveProps = (newProps) => {
    let apiData = newProps.apiData,
        { locationType } = newProps.match.params;

    // console.log("locatins: ", apiData)

    if (locationType.replace('s', '') !== this.state.locationType) {
        this.setState({
            locationType: locationType.replace('s', '')
        }, () => { this.loadTopLocations() })
    } else if (apiData.topLocations && apiData.topLocations.success) {
        let { data: locations} = apiData.topLocations

        this.setState(() => ({
            pageLoading: false,
            locations: locations
        }))
    }
  }

  loadTopLocations = () => {
    this.setState({pageLoading: true},
        () => { this.props.actions.reqGetTopLocations({
            body: {},
            headers: null,
            resource: `${this.lostLocationsUrl}?locationType=${this.state.locationType}`
        })
    })
  }

  // Render Top Locations List
  renderTopLocations = () => (
    <div className="loc-location-list">
        { (this.state.locations || []).map(
            (loc, idx) => 
            (loc[this.state.locationType]==='-'? '' :
                <Link to={`/top/therapists/list/${this.state.locationType}/${loc[this.state.locationType]}/${loc.region}`} key={idx} className="top-loc-item">
                    <div>{ `${loc[this.state.locationType]},${stateTwoDigitCode(loc.region)}`}</div>
                </Link>
            )
        )}
    </div>
  )

  render() {
    // eslint-disable-next-line
    let sideDrawer = null;

    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer/>
    }

    return (
      <div id="therapist-top-locations">
      {this.state.locationType === 'location' ? (
        <Helmet>
          <title>
          Find Autism Therapists Near Your Location For Free | Autism Assistant{" "}
          </title>
          <meta
            name="description"
            content='We place at your disposal a database of providers in or near your location. Find the autism therapies your child needs today, for free (ABA, OT, ST, etc).'
          ></meta>
        </Helmet>
       ) : (
        <Helmet>
          <title>
          Search Our Database For Autism Therapists In Your Region | Autism Assistant{" "}
          </title>
          <meta
            name="description"
            content='Find the autism therapists your child needs (ABA, OT, ST, etc). Match them with your insurance. See all of their contact information and get in touch now.'
          ></meta>
        </Helmet>
       )}
      
        {/* Loader */}
        <PopupLoader display={this.state.pageLoading} />

        {/* Header */}
        <HeaderGuest menu="" menuVisible={this.state.sideDrawerOpen} drawerClickHandler={this.drawerToggleClickHandler}/>

        {/* Main Container */}
        <div className="top-loc-container">
            <h2 className="page-title">{this.state.locationType === 'location' ? "Top Therapist Locations" : "Top Therapist Regions"}</h2>

            {/* List Container */}
            <div className="loc-list-container">
                { this.renderTopLocations() }
            </div>

        </div>

        {/* Footer */}
        <Footer />
      </div>
    )
  }

}

const mapStateToProps = state => ({
    apiData: state.topLocations
})

function mapDispatchToProps(dispatch) {
  return {
    actions: {
        reqGetTopLocations: bindActionCreators(reqGetTopLocations, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapistsTopLocations)
