import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_ALL_INSURANCES, REQ_ADD_INSURANCE_THERAPIST, REQ_REMOVE_INSURANCE_THERAPIST,
  recGetAllInsurances, recAddInsurance, recRemoveInsurance} from './actions'

import fetchData from '../../../utils/api'
import mergeInUserDetails from '../../../utils/mergeInUserDetails'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'


function* getAllInsurances(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetAllInsurances(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* addInsurance(action) {
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
  
    if (data.success) {
      const newUserDetails = {...data};
      delete newUserDetails.success
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
    yield put(recAddInsurance(data))
   
  } catch (e) {console.log("Api error: ", e)}
}

function* removeInsurance(action) {
  try {
    
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    
    if (data.success) {
      const newUserDetails = {...data};
      delete newUserDetails.success
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
    yield put(recRemoveInsurance(data))
   
  } catch (e) {console.log("Api error: ", e)}
}


export default function* therapistManageInsurances() {
    yield takeLatest(REQ_GET_ALL_INSURANCES, getAllInsurances);
    yield takeLatest(REQ_ADD_INSURANCE_THERAPIST, addInsurance);
    yield takeLatest(REQ_REMOVE_INSURANCE_THERAPIST, removeInsurance);
};  