import React from "react";
import ButtonRoundGradient from "../../../components/ButtonRoundGradient/ButtonRoundGradient";
import Rating from "react-rating";
import ratingStarFull from "../../../static/images/desktop/rating-star-full.png";
import ratingStarEmpty from "../../../static/images/desktop/rating-star-empty.png";
import defaultImage from "../../../static/images/desktop/annonymous-user-icon.jpg";
import WideModal from "../../../components/Modal/WideModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faCheck,
  faEnvelope,
  faIdCard,
  faGlobe,
  faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";
import { reqGetPublicProfile, reqRequestClaimListing, reqRequestNetworking } from "./actions";
import PopupConfirm from "../../../components/PopupConfirm/PopupConfirm";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import RoundLabelGradientBorder from "../../../components/RoundLabelGradientBorder/RoundLabelGradientBorder";
import { Redirect } from "react-router-dom";
import ContactForm from "./ContactForm";

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class ProfileSection extends React.Component {
  state = {
    fullName: "",
    email: "",
    businessName: "",
    phone: "",
    title: "",
    website: "",
    networking: true,
    avgScore: 0,
    revCnt: 0,
    specialities: [],
    insurances: [],
    showModal: false,
    errors: [],
    pageLoading: false,
    message: "",
    inputEmail: "",
    redirectUrl: "/",
  };

  getProfileUrl = "therapist/profile/public";
  claimListingUrl = "therapist/profile/claim";
  networkingRequestUrl = "therapist/networking/request";

  componentWillMount = () => {
    const rConfig = {
      body: {},
      headers: null,
      resource: `${this.getProfileUrl}?tid=${this.props.therapistId}`,
      bio: "",
    };

    this.setState(() => ({
      redirectUrl: `/therapist/profile/${this.props.therapistId}`,
    }));

    this.props.actions.reqGetPublicProfile(rConfig);
  };

  componentWillReceiveProps = (newProps) => {
    const apiData = newProps.apiData;

    // console.log("newProps: ", newProps)

    if (apiData.success && apiData.profile) {
      const profile = apiData.profile;
      let telemedicineCheck = false;

      profile.specialities.map((speciality) => {
        if (speciality.name.includes("telemedicine")) {
          telemedicineCheck = true;
        }
      });

      this.setState(() => ({
        fullName: profile.fullName,
        revCnt: profile.score.cnt,
        avgScore: profile.score.avgScore,
        bio: profile.bio || "",
        specialities: profile.specialities || [],
        insurances: profile.insurances || [],
        email: profile.email,
        phone: profile.phone || "",
        businessName: profile.businessName || "",
        website: profile.website || "",
        avatarUrl: profile.avatarUrl,
        membershipPlan: profile.membershipPlan,
        telemedicine: telemedicineCheck,
        networking: profile.networking
      }));
    } else if (apiData.success && apiData.message === "Listing claimed") {
      this.setState(() => ({
        showModal: false,
        showPopup: true,
        popupText: "We sent an email with instructions. Thank you!",
        popupTitle: "Confirmation message",
        confirmAction: this.hidePopup,
        confirmText: "Ok",
        cancelText: " ",
        pageLoading: false
      }));
    } else if (apiData.success && apiData.message === "Networking request sent!") {
      this.setState(() => ({
        showNetworkingModal: false,
        showPopup: true,
        popupText: "Networking request sent!",
        popupTitle: "Networking request",
        confirmAction: this.hidePopup,
        confirmText: "Ok",
        cancelText: " ",
        pageLoading: false
      }));
    }
  };

  handleClaimListing = () => {
    this.setState(() => ({
      showModal: true,
    }));
  };

  handleNetworkingRequest = () => {
    this.setState(() => ({
      showNetworkingModal: true,
    }));
  };

  hidePopup = () => {
    this.setState(() => ({
      showPopup: false,
    }));
  };

  handleChange = (event) => {
    let inputName = event.target.name,
      value = event.target.value;

    this.setState(() => ({ [inputName]: value }));
  };

  handleSubmit = () => {

    this.setState(
      {
        errors: [],
        pageLoading: true
      },
      () => {
        const formValid = this.validateForm();

        if (!formValid) {
          return;
        }

        const rBody = {
          email: this.state.inputEmail,
          uid: this.props.therapistId,
        };

        this.props.actions.reqRequestClaimListing(rBody, this.claimListingUrl);
      }
    );
  };

  handleNetworkingSubmit = () => {

    this.setState(
      {
        errors: [],
        pageLoading: true
      },
      () => {
        const formValid = this.validateForm();

        if (!formValid) {
          return;
        }

        const rBody = {
          email: this.state.inputEmail,
          message: this.state.networkingMessage,
          therapistEmail: this.state.email,
          uid: this.props.therapistId,
        };

        this.props.actions.reqRequestNetworking(rBody, this.networkingRequestUrl);
      }
    );
  };

  validateForm = () => {
    let formValid = true;

    if (this.state.inputEmail === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "email", message: "Email is mandatory" }],
        pageLoading: false,
      }));
    }

    return formValid;
  };

  displayErrorMessage = () => {
    const errors = this.state.errors;

    return errors.length ? errors[0].message : "";
  };

  inputHasErrors = (inputName) => {
    const errors = this.state.errors.filter((err) => err.input === inputName);
    return errors.length ? "input-error" : "";
  };

  closeModalHandler = () => {
    this.setState(() => ({
      showModal: false,
      showNetworkingModal: false
    }));
  };

  renderSpecialities = () => {
    if (!this.state.specialities.length) return;

    const specialities = this.state.specialities.map((s, i) => (
      <RoundLabelGradientBorder key={`sp-${i}`} text={s.name} />
    ));

    return (
      <div className="specialities-section">
        <div className="subsection-title">Specialities:</div>
        <div className="specialities-list">{specialities}</div>
      </div>
    );
  };

  renderInsurances = () => {
    if (!this.state.insurances.length) return;

    const insurances = this.state.insurances.map((s, i) => (
      <RoundLabelGradientBorder key={`ins-${i}`} text={s.name} />
    ));

    return (
      <div className="insurances-row">
        <div className="subsection-title">Accepted Insurances:</div>
        <div className="specialities-list">{insurances}</div>
      </div>
    );
  };

  renderContactDetails = () => {
    const details = [];
    let website = "";

    if (
      this.state.website.includes("https://") ||
      this.state.website.includes("http://")
    ) {
      website = this.state.website;
    } else {
      website = "https://" + this.state.website;
    }

    details.push(
      <div className="email-row" key="dr-1">
        <FontAwesomeIcon icon={faEnvelope} />
        <a href={"mailto:" + this.state.email}>
          <span className="cd-value">
            {this.state.email ? this.state.email : "-"}
          </span>
        </a>
      </div>
    );

    details.push(
      <div className="phone-row" key="dr-2">
        <FontAwesomeIcon icon={faPhone} />
        <a href={"tel:" + this.state.phone}>
          <span className="cd-value">
            {this.state.phone ? this.state.phone : "-"}
          </span>
        </a>
      </div>
    );

    details.push(
      <div className="web-row" key="dr-3">
        <FontAwesomeIcon icon={faIdCard} />
        <a href={website} target="_blank" rel="noopener noreferrer nofollow">
          <span className="cd-value">
            {this.state.website ? this.state.website : "-"}
          </span>
        </a>
      </div>
    );

    return (
      <div className="contact-details-section">
        <div className="subsection-title">Contact Details:</div>
        {details}
      </div>
    );
  };

  getAvatarUrl = () =>
    this.state.avatarUrl
      ? `${baseBackendUrl}/${this.state.avatarUrl}`
      : defaultImage;

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }

    let titleTextLength = this.state.businessName.length + this.state.fullName.length

    return (
      <section className="profile-details">
        <PopupLoader display={this.state.pageLoading} />
        <PopupConfirm
          display={this.state.showPopup}
          text={this.state.popupText}
          title={this.state.popupTitle}
          hidePopup={this.hidePopup}
          calcelAction={this.hidePopup}
          confirmText={this.state.confirmText}
          cancelText={this.state.cancelText}
          confirmAction={this.state.confirmAction}
        ></PopupConfirm>
        <WideModal
          display={this.state.showModal}
          close={this.closeModalHandler}
          title={"Claim your listing"}
          text={"Please enter your business email address:"}
          confirmAction={this.handleSubmit}
          calcelAction={this.closeModalHandler}
        >
          <div className="input-row modal-imput">
            <input
              type="text"
              placeholder="Email"
              name="inputEmail"
              onChange={this.handleChange}
              className={`basic-input ${this.inputHasErrors("email")}`}
            />
          </div>
          <p className="error-message">{this.displayErrorMessage()}</p>
        </WideModal>
        <WideModal
          display={this.state.showNetworkingModal}
          close={this.closeModalHandler}
          title={"Open for Networking"}
          text={"Please enter your business email address and a message, we will get back at soon as posible:"}
          confirmAction={this.handleNetworkingSubmit}
          calcelAction={this.closeModalHandler}
        >
          <div className="input-row modal-imput">
            <input
              type="text"
              placeholder="Email"
              name="inputEmail"
              onChange={this.handleChange}
              className={`basic-input ${this.inputHasErrors("email")}`}
            />
            <input
              type="text"
              placeholder="Message"
              name="networkingMessage"
              onChange={this.handleChange}
              className={`basic-input ${this.inputHasErrors("message")}`}
            />
          </div>
          <p className="error-message">{this.displayErrorMessage()}</p>
        </WideModal>
        <div className="title-row page-title">
          {this.state.fullName === " " ? (
            ""
          ) : (
            <h3 className={`profile-title ${titleTextLength > 50 ? "profile-title-long" : '' }`}>{this.state.fullName}</h3>
          )}
          {this.state.businessName === "" ? (
            ""
          ) : (
            <h3 className={`profile-title ${titleTextLength > 50 ? "profile-title-long" : '' }`}>{this.state.businessName}</h3>
          )}
        </div>
        <div className="profile-section">
          {/* Leftside Content */}
          <div className="leftside-content">
            {/* Avatar */}
            <div className="avatar-container">
              <img src={this.getAvatarUrl()} alt="defaultAvatar" width="150" height="150" />
            </div>
            {this.state.membershipPlan === null ||
            this.state.membershipPlan === "free" ? (
              ""
            ) : (
              <div className="therapist-flag">
                <div className="therapist-flag-text">Verified Provider</div>
                <FontAwesomeIcon icon={faCheck} className="green-icon" />
              </div>
            )}
            {this.state.telemedicine &&
            this.state.membershipPlan !== null &&
            this.state.membershipPlan !== "free" ? (
              <div className="online-flag">
                <div className="therapist-flag-text">Online Therapy</div>
                <FontAwesomeIcon icon={faGlobe} className="green-icon" />
              </div>
            ) : (
              ""
            )}
          </div>
          {/* Rightside Content */}
          <div className="rightside-content">
            {/* Title */}

            {/* Review */}
            <div className="review-row">
              <Rating
                placeholderRating={this.state.avgScore}
                emptySymbol={
                  <img
                    src={ratingStarEmpty}
                    className="icon"
                    alt="ratingEmpty"
                    width="21" 
                    height="21"
                  />
                }
                placeholderSymbol={
                  <img src={ratingStarFull} className="icon" alt="ratingFull" width="21" height="21" />
                }
                readonly
              />
              <span className="review-data">{this.state.revCnt} reviews</span>
            </div>
            {/* Bio */}
            {this.state.bio ? <div className="subsection-title">Description:</div> : ''}
            <div className="bio-section">
              
              <span className="bio-data">{this.state.bio}</span>
            </div>

            {this.state.networking ? (
              <div className="networkin-subsection">
              <div className="subsection-title"><FontAwesomeIcon icon={faProjectDiagram} /> Networking</div>
              <span className="bio-data">We are open to networking. If you want to get in touch with us, use <button onClick={this.handleNetworkingRequest}>this form</button>!</span>
                
              </div>
            ) : (
              ""
            )}
            {/* Specialities */}
            {this.renderSpecialities()}

            {/* Insurances */}
            {this.renderInsurances()}

            {this.state.bio === "" ? (
              <div className="claim-button">
                <ButtonRoundGradient
                  text={"Claim This Listing"}
                  onClick={this.handleClaimListing}
                />
              </div>
            ) : (
              ""
            )}

            {/* Contact Details */}
            {this.state.membershipPlan === null ||
            this.state.membershipPlan === "free" ? (
              <ContactForm therapistId={this.props.therapistId} />
            ) : (
              this.renderContactDetails()
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.publicProfileReducer,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetPublicProfile: bindActionCreators(reqGetPublicProfile, dispatch),
      reqRequestClaimListing: bindActionCreators(reqRequestClaimListing, dispatch),
      reqRequestNetworking: bindActionCreators(reqRequestNetworking, dispatch)
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSection);
// export default ProfileSection
