import React from 'react'
import './SimilarArticles.scss'
import SimilarArticleCard from './SimilarArticleCard'
require('dotenv').config()
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

const renderSimilarArticles = articles => (
    <div className="sim-article-list">
        { articles.map( (a, idx) => (
            <SimilarArticleCard 
                key={`sim-art-k-${idx}`} 
                article={a}
                url={`/blog/${a.id}/${a.title.toLowerCase().replace(/ /g, "-").replace(/[’,?!]/g, "")}`}
                thumbnail={`${baseBackendUrl}/articleContentImage/${a.thumbnail_url}`}/>
        )) }
    </div>
)

const renderTitle = props => {
    if (!props.articles || !props.articles.length) {
        return ''
    }

    return 'Similar Articles'
}

const SimilarArticles = props => (

    <div id="similar-articles-section">
        {/* Section title */}
        <div className="s-art-title">{ renderTitle(props) }</div>
        {/* Content */}
        <div className="sim-articles-container">
            {renderSimilarArticles(props.articles || [])}
        </div>
    </div>
    
)

export default SimilarArticles