import React from 'react'
import './SidebarTherapist.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faHome } from '@fortawesome/free-solid-svg-icons'
//import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { faUserMd } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'
import { faBlog } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'

class SidebarTherapist extends React.Component {

    state = {
        displaySidebar: true
    }

    toggleSidebar = () => {
        this.setState((prevState) => ({
            displaySidebar: !prevState.displaySidebar
        }))
    }

    sidebarVisible = () => (
        this.state.displaySidebar ? '' : 'sidebar-hidden'
    )

    componentWillReceiveProps = () => {
        
        if (window.innerWidth < 773) {
            this.setState(() => ({displaySidebar: false}))
        }
                
    }

    pageList = [
        { url: 'profile', pageName: 'account', dataTip: 'Account Information', icon: faIdCard, roleIds: [3, 4] },
        { url: 'specialities', pageName: 'specialities', dataTip: 'Specialities', icon: faUserMd, roleIds: [3] },
        { url: 'therapist-locations', pageName: 'therapist-locations', dataTip: 'Locations', icon: faMapMarker, roleIds: [3] },
        { url: 'insurances', pageName: 'insurances', dataTip: 'Insurances', icon: faFileAlt, roleIds: [3] },
        { url: 'articles', pageName: 'articles', dataTip: 'Your Resources', icon: faBlog, roleIds: [3, 4] },
        { url: 'payment', pageName: 'payment', dataTip: 'Membership Plans', icon: faMoneyBillAlt, roleIds: [3] },
        { url: 'sponsorship', pageName: 'sponsorship', dataTip: 'Sponsorship System', icon: faMoneyBillWave, roleIds: [3] },
    ]

    renderPages = () => {
        let membershipPlan = this.props.userDetails.membershipPlan,
            sponsorshipPlan = this.props.userDetails.sponsorshipPlanId

        // console.log("this.props.userDetails: ", this.props.userDetails)

        let pages = this.pageList
            .filter(p => p.roleIds.filter(r => r === this.props.roleId).length > 0)
            .map( (p, pIdx) => (
            <Link to={`/${p.url}`} key={pIdx}>
                <li className={`sidebar-item ${this.props.page === p.pageName ? 'active-item' : ''}`} 
                    data-tip={p.dataTip}>
                    <FontAwesomeIcon icon={p.icon} />
                    <span className={`dot-position ${(p.pageName==='sponsorship' && !sponsorshipPlan) || (p.pageName==='payment' && (membershipPlan==="free" || membershipPlan===null)) ? "red-dot": ""}`}></span>
                </li>
            </Link>
        ))

        return (
            <ul className={`sidebar-items ${this.sidebarVisible()}`}>
                { pages }
            </ul>
        )
    }

    render () {
        
        return (
            <div id="therapist-sidebar">
                
                <div className={`sdb-hamburger ${this.state.displaySidebar ? 'hamburger-open' : ''}`} onClick={this.toggleSidebar} >
                        <div className="sd-h-row"></div>
                        <div className="sd-h-row"></div>
                        <div className="sd-h-row"></div>
                </div>

                {/* <ul className={`sidebar-items ${this.sidebarVisible()}`}>

                    <Link to="/profile">
                        <li className={`sidebar-item ${this.props.page === 'account' ? 'active-item' : ''}`} 
                            data-tip="Dashboard">
                            <FontAwesomeIcon icon={faUserCircle} />
                        </li>
                    </Link>
                    <Link to="/specialities">
                        <li className={`sidebar-item ${this.props.page === 'specialities' ? 'active-item' : ''}`} 
                            data-tip="Specialities">
                            <FontAwesomeIcon icon={faUserMd} />
                        </li>
                    </Link>
                    <Link to="/insurances">
                        <li className={`sidebar-item ${this.props.page === 'insurances' ? 'active-item' : ''}`} 
                            data-tip="Insurances">
                            <FontAwesomeIcon icon={faFileAlt} />
                        </li>
                    </Link>
                    <Link to="/articles">
                        <li className={`sidebar-item ${this.props.page === 'articles' ? 'active-item' : ''}`} 
                            data-tip="Your Articles">
                            <FontAwesomeIcon icon={faBlog} />
                        </li>
                    </Link>
                    <Link to="/details">
                        <li className={`sidebar-item ${this.props.page === 'details' ? 'active-item' : ''}`} 
                            data-tip="Profile Details">
                            <FontAwesomeIcon icon={faIdCard} />
                        </li>
                    </Link>
                    <Link to="/therapist-locations">
                        <li className={`sidebar-item ${this.props.page === 'therapist-locations' ? 'active-item' : ''}`} 
                            data-tip="Locations">
                            <FontAwesomeIcon icon={faMapMarker} />
                        </li>
                    </Link>
                    <Link to="/payment">
                        <li className={`sidebar-item ${this.props.page === 'payment' ? 'active-item' : ''}`} 
                            data-tip="Membership Plans">
                            <FontAwesomeIcon icon={faMoneyBillAlt} />
                        </li>
                    </Link>
                    <Link to="/sponsorship">
                        <li className={`sidebar-item ${this.props.page === 'sponsorship' ? 'active-item' : ''}`} 
                            data-tip="Sponsorship System">
                            <FontAwesomeIcon icon={faMoneyBillWave} />
                        </li>
                    </Link>
                </ul> */}

                { this.renderPages() }
                <ReactTooltip type="info" place="right" />
            </div>
        )
    }
    
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
});

export default connect(mapStateToProps)(SidebarTherapist)