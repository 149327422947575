import React from "react";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import { connect } from "react-redux";
import PopupLoader from "../../components/PopupLoader/PopupLoader";
import ArticleContent from "./ArticleContent/ArticleContent";
import Footer from "../../components/Footer/Footer";
import { reqGetArticleGuest } from "./actions";
import { bindActionCreators } from "redux";
import ArticleVerifiedProviders from "./ArticleVerifiedProviders";
import "./Article.scss";
import { Helmet } from "react-helmet";

class TherapyArticle extends React.Component {
  
  state = {
    pageLoading: false,
    authorDetails: {}
  };

  getArticleUrl = "blog/article/view";

  componentWillMount = _ => {
    let { id: articleId } = this.props.match.params;
    this.setState(
      {
        pageLoading: true,
        articleId: articleId
      },
      () => {
        this.loadArticle(articleId);
      }
    );
  };

  componentWillReceiveProps = newProps => {
    const apiData = newProps.apiData;
    let { id: articleId } = newProps.match.params;

    // console.log("new Props: ", apiData.articleLoaded.article.data.tags)

    if (articleId !== this.state.articleId) {
      // console.log("Reload Article");
      this.setState(
        {
          pageLoading: true,
          articleId: articleId
        },
        () => {
          this.loadArticle(articleId);
        }
      );
    } else if (apiData.articleLoaded && apiData.articleLoaded.success) {
      this.setState(() => ({
        pageLoading: false,
        article: apiData.articleLoaded.article.data,
        authorDetails: apiData.articleLoaded.authorDetails,
        therapyName: apiData.articleLoaded.article.data.tags
      }));
    }
  };

  loadArticle = id => {
    this.props.actions.reqGetArticleGuest({
      body: {},
      headers: null,
      resource: `${this.getArticleUrl}?articleId=${id}`
    });

  };

  render = _ => {

    let metaTitle = "",
      metaDescription = "";

    if (this.state && this.state.article) {
      metaTitle = this.state.article.metaTitle;
      metaDescription = this.state.article.metaDescription;
    }
    
    return (
      <div>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <div id="article">
          {/* Loader */}
          <PopupLoader display={this.state.pageLoading} />

          {/* Header */}
          <HeaderGuestSimple menu="article" />
          <section className="container">
            {/* Content */}
            <div id="article-container" className="content">
              <ArticleContent article={this.state.article} />
            </div>
            
            {/* Tags */}

            {/* Reviews */}
            <ArticleVerifiedProviders speciality={this.state.therapyName} />
            
          </section>
        </div>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = state => ({
  apiData: state.guestArticleReducer
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetArticleGuest: bindActionCreators(reqGetArticleGuest, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapyArticle);
// export default Article
