import React from 'react'
import HeaderGuestSimple from '../../components/Header/HeaderGuestSimple'
import Footer from '../../components/Footer/Footer'
import SideDrawer from '../../components/SideDrawer/SideDrawer'
import SearchForm from '../../components/Header/SearchForm'
import { Helmet } from 'react-helmet';
import './TherapyTypes.scss'
import { Redirect } from 'react-router-dom'
import ArticleCard from '../../components/Blog/ArticleCard'
import { reqGetArticles, reqSearchArticles } from '../Blog/actions'
import { bindActionCreators } from 'redux';
import therapyImage from '../../static/images/desktop/autism-therapy-types.png'
import { connect } from 'react-redux'
import PopupLoader from "../../components/PopupLoader/PopupLoader";
import Radio from '../../components/Radio/Radio'
import SearchFormOnline from '../../components/Header/SearchFormOnline'
import { Link } from "react-router-dom";

require('dotenv').config()
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;


class TherapyTypes extends React.Component {

  state = {
    pageLoading: false,
    articles: [],
    currentPage: 1,
    searchMethod: 'location',
    redirect: false,
    redirectUrl: ''
  };
  
  articlesPerPage = 11;
  getArticlesUrl = "blog/articles/all";
  searchArticleUrl = "blog/articles/search";

  componentDidMount = () => {
    this.authHeader = {
      "Content-type": "application/json"
    };
    this.setState({}, () => {
      this.loadArticles();
    });
  };

  componentWillReceiveProps = newProps => {
    const apiData = newProps.apiData

    if (apiData.articles && apiData.articles.success) {
      let articles = apiData.articles.articles,
          articlesArray = []

      for (let i = 0; i < articles.length; i++) {
      
        if (articles[i].type === 'therapy') {
          articlesArray.push(articles[i])
        }}

        this.setState(() => ({
          pageLoading: false,
          articles: articlesArray
        }));
      }

    if (apiData.articleSearch && apiData.articleSearch.success) {
      this.setState(() => ({
        pageLoading: false,
        articles: apiData.articleSearch.articles,
      }));
    }
  };

  radioClickHandler = (event) => {
    let radioValue = event.target.dataset.value
    this.setState(() => {
        return {searchMethod: radioValue}
    })
  }

  loadArticles = () => {
    const rConfig = {
      body: {},
      resource: `${this.getArticlesUrl}`,
      headers: null
    };
    this.props.actions.reqGetArticles(rConfig);
  };

  getArticles = () => {
    this.setState({ pageLoading: true }, () => {
      this.loadArticles();
    });
  };

  searchArticles = () => {
    this.setState(
      {
        pageLoading: true
      },
      () => {
        this.props.actions.reqSearchArticles({
          body: {},
          headers: null,
          resource: `${this.searchArticleUrl}?searchTerm=${this.state.searchInput}`
        });
      }
    );
  };

  searchSubmit = () => {
    this.searchArticles();
  };

  filterSubmit = () => {};

  searchInputChange = ev => {
    let val = ev.target.value;

    this.setState(() => ({
      searchInput: val
    }));
  };

  

  openArticle = (url) => {
    this.props.history.push({pathname: url})
    
    // this.setState({redirect: true, redirectUrl: url}, () => {
    //   this.props.history.push({pathname: url})
      
    // })
  }

  getArticlePermalink = title =>
    title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[’,?!]/g, "");

  getArticleSnippet = (article, size = 155) =>
    article.body
      .replace(/<figcaption>([^<]+)<\/figcaption>/gi, "")
      .replace(/(<([^>]+)>)/gi, "")
      .replace("&nbsp;", " ")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .substring(0, size) + "...";

  listArticles = () => {
    const indexOfLastArticle = this.state.currentPage * this.articlesPerPage,
          indexOfFirstArticle = indexOfLastArticle - this.articlesPerPage,
          currentArticles = this.state.articles.slice(indexOfFirstArticle, indexOfLastArticle);
    if (
      typeof this.state.articles === "undefined" ||
      !this.state.articles.length
    ) {
      return (
        <div className="no-articles-result">
          <div className="no-articles-res-text">
            There are currently no articles for this search.
          </div>
        </div>
      );
    }
    let items = [],
      subItems = [],
      articles = currentArticles
    
    for (let i = 0; i < articles.length; i++) {
      
      let article = articles[i]

      let publishedDate = new Date(article.published);
      let thumblink = article.thumbnail_url
        .toLowerCase()
        .replace(/-/g, " ")
        .replace(/[?!]/g, "")
        .replace(/_/g, "")
        .replace(/[1234567890.]/g, "")
        .replace(/jpeg/g, "")
        .replace(/png/g, "")
        .replace(/jpg/g, "");
      let permalink = article.permalink === null ? this.getArticlePermalink(article.title) : article.permalink;

      subItems.push(
        <div className="col-lg-4 col-sm-6 mb-60" key={`item-sub-art-${i}`} >
          <Link to={`/autism-therapy-types/${article.id}/${permalink}`}>
            <ArticleCard
              // key={`item-sub-art-${i}`}
              image={`${baseBackendUrl}/articleContentImage/${article.thumbnail_url}`}
              url={`/blog/${article.id}/${permalink}`}
              alt={`${thumblink}`}
              tag={`TAG, ` + this.formatDate(publishedDate)}
              buttonText="Read Article"
              title={article.card_title === null ? article.title : article.card_title}
              firstArticle={false}
              shortContent={this.getArticleSnippet(article, 90)}
              lazyLoad={true}
            />
          </Link>
        </div>
      );
    
    }

    items.push(
      <div className="row" key={`uk`}>
        {subItems}
      </div>
    );

    if (!items.length) {
      return (
        <div className="message-row">
          <p>You have no articles written.</p>
        </div>
      );
    }

    return <ul className="articles-list">{items}</ul>;
  };

  changePage = (pageNumber) => {
      if ( pageNumber === "prev" ) {
        this.setState(() => ({
            currentPage: this.state.currentPage -1
        }));
      }
      if ( pageNumber === "next" ) {
        this.setState(() => ({
            currentPage: this.state.currentPage +1
        }));
      }
      if (typeof pageNumber === "number") {
        this.setState(() => ({
            currentPage: pageNumber
        }));}
      window.scrollTo(0,600)
  }

  formatDate(date) {
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    return (
      month[date.getMonth()] +
      " " +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      ", " +
      date.getFullYear()
    );
  }
    
  render () {
    // eslint-disable-next-line
    let sideDrawer = null;

    if (this.state.sideDrawerOpen) {
        sideDrawer = <SideDrawer/>
    }

    if (this.state.redirect) {
      return (
          <Redirect to={this.state.redirectUrl} />
      )
    }

      return (
              <div>
              <Helmet>
                  <title>How Can The Various Autism Therapy Types Help My Child? | Autism Assistant</title>
                  <meta name="description" content="Find out more about the autism therapy types that can help your child, and then check for a specific provider in your area with our free to use tool. "></meta>
              </Helmet>
              <PopupLoader display={this.state.pageLoading} />
              <HeaderGuestSimple menu="therapies" />
              <section className="container mt-30">
                  <h1 className="text-center aboutUsHeading">How Can The Various Autism Therapy Types Help My Child?</h1>
                  <div className="row our-story-therapy">
                      <div className="text-section-therapy">
                          <img src={therapyImage} alt="autism therapy types"/>
                          <p>The day your child receives an autism spectrum diagnosis, things in your world are going to change.
                              This is precisely why our website exists. My name is Emily Swank, and through this online portal,
                              I aim to make your transition into this new reality as easy as possible.
                              We have a list of autism therapy types readily available for you, as well as a
                              free tool with which you can search for providers, because finding help should be easy.</p>
                          <h2>Behavioral Autism Therapies For Your Child</h2>
                          <p>The first few days after the diagnosis, you can easily become lost in the new “sea” of acronyms.
                              This was definitely <a href='/about-us'> my case </a>. You keep hearing about ABA, OT, PT, and other very confusing terms.
                              At the end of the day, all of this translates into a single thing: <strong> many, many phone calls </strong>.
                              Phone calls towards government funded programs, phone calls towards your insurance, and especially,
                              phone calls towards therapists.</p>
                          <p>That’s where it all becomes very complicated. You do all of your research about what Feeding Therapy, Speech Therapy,
                              Developmental Pediatricians and other associated concepts are. However, when it comes to actually
                              getting in touch with a specialist, you find that they don’t work with your insurance, or they’re
                              too far away, or worse, they’re in fact not the one that your child needs.</p>
                          <p>I know how hard this is, because I’ve been in your shoes. As such, the focus of Autism Assistant is to offer you <strong> a simple to use database of all kinds of behavioral autism therapies</strong>.
                              On our site, you can both search for providers, and find details about the exact type of treatment that your child needs in the articles below.</p>
                          <h2>Get In Touch With Our Community</h2>
                          <p>Having information about diverse autism therapy types readily accessible in a user friendly
                              space is important for us, but we don’t stop there!</p>
                          <p>If you’d like to search for a particular therapist, check our <a href= '/specialists'>Provider Profiles. </a>
                              Alternatively, head over to our <a href= '/blog'> blog </a> for information about the specifics of ASD, how to help your child,
                              and  how to better understand the diagnosis yourself.</p>
                          <p>Want to <a href= '/contact'>contact us</a> directly? We’ll get back to your questions and comments as soon as possible!</p>
                      </div>
                  </div>
                  <div className="search-radio--row mt-50">
                          <Radio id="searchMethodLocation"
                                  name="comm-method"
                                  dataValue="location"
                                  isChecked={this.state.searchMethod === 'location'}
                                  className=""
                                  click={this.radioClickHandler}
                                  text="Search by location" />
                          <Radio id="searchMethodOnline"
                                  name="comm-method"
                                  dataValue="online"
                                  isChecked={this.state.searchMethod === 'online'}
                                  className=""
                                  click={this.radioClickHandler}
                                  text="Online therapy (telemedicine)"/>
                      </div>
                      {this.state.searchMethod === 'location' ? <SearchForm  /> : <SearchFormOnline  /> }
                      
                  <div className="blog-listing-container">
                      <h2 >11 Types of Treatment For Autism</h2>
                      {this.listArticles()}
                  </div>
              </section>
              <Footer/>
          </div>
      )
  }

}
const mapStateToProps = state => ({
    apiData: state.mainBlogReducer,
    userDetails: state.userDetails
});

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            reqGetArticles: bindActionCreators(reqGetArticles, dispatch),
            reqSearchArticles: bindActionCreators(reqSearchArticles, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (TherapyTypes)
