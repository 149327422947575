export const REQ_UPDATE_PROFILE = 'REQ_UPDATE_PROFILE'
export const REC_UPDATE_PROFILE = 'REC_UPDATE_PROFILE'

export const REQ_UPDATE_ADMIN_PASSWORD = 'REQ_UPDATE_ADMIN_PASSWORD'
export const REC_UPDATE_ADMIN_PASSWORD = 'REC_UPDATE_ADMIN_PASSWORD'

export const reqUpdateProfile = (config) => ({type: REQ_UPDATE_PROFILE, config: config})
export const recUpdateProfile = data => ({type: REC_UPDATE_PROFILE, data})

export const reqUpdateAdminPassword = (config) => ({type: REQ_UPDATE_ADMIN_PASSWORD, config: config})
export const recUpdateAdminPassword = data => ({type: REC_UPDATE_ADMIN_PASSWORD, data})


