import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt }     from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle }   from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp }      from '@fortawesome/free-solid-svg-icons'
import { faThumbsDown }    from '@fortawesome/free-solid-svg-icons'
import { faClock }         from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash }      from '@fortawesome/free-solid-svg-icons'
import ReactTooltip        from 'react-tooltip'
import moment              from 'moment'
require('dotenv').config()
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;


class ArticleItem  extends React.Component {

    state = {
        title: this.props.article.title,
        body: this.props.article.body.replace(/(<([^>]+)>)/ig, "").substring(0, 255),
    }

    componentWillReceiveProps = (newProps) => {
        this.setState({
            title: newProps.article.title,
            body: newProps.article.body.replace(/(<([^>]+)>)/ig, "").substring(0, 255),
        })
    }

    getStatusIcon = article => {

        if (article.public === 0) {
            return faEyeSlash
        } else {
            switch (article.status) {
                case 1:
                    return faThumbsUp
                case -1:
                    return faThumbsDown
                default:
                    return faClock
            }
        }
    }

    getStatusText = article => {

        if (article.public === 0) {
            return 'Private'
        } else {
            switch (article.status) {
                case 1:
                    return 'Article Accepted'
                case -1:
                    return 'Article Declined'
                default:
                    return 'Article Pending'
            }
        }
    }

    getArticleIconClass = article => {
        if (article.public === 0) {
            return ''
        } else {
            switch (article.status) {
                case 1:
                    return 'accept-icon'
                case -1:
                    return 'decline-icon'
                default:
                    return 'pending-icon'
            }
        }
    }

    render() {
        return (
            <li key={this.props.index} className="article-list-item">
                <div className="article-li-info">
                    {/* Thumbnail */}
                    <div className="thumbnail-container">
                        <img src={`${baseBackendUrl}/articleContentImage/${this.props.article.thumbnail_url}`} alt="articmeImg" loading="lazy"/>
                    </div>
                    {/* Data */}
                    <div className="data-container">
                        <h4 className="article-li-title">{this.state.title || ""}</h4>
                        <div className="article-li-details">{ moment(this.props.article.published).format('LL')}</div>
                        <div className="article-li-body" dangerouslySetInnerHTML={{__html: this.state.body}} />
                    </div>

                </div>
                <div className="article-li-actions" >
                    <FontAwesomeIcon icon={this.getStatusIcon(this.props.article)}
                                     className={this.getArticleIconClass(this.props.article)}
                                     data-tip={this.getStatusText(this.props.article)} />
                    <FontAwesomeIcon icon={faPencilAlt}
                                     className="edit-icon"
                                     data-tip="Edit Article"
                                     onClick={this.props.editAction.bind(this, this.props.article)} />

                    <FontAwesomeIcon icon={faTimesCircle}
                                     className="delete-icon"
                                     data-tip="Delete Article"
                                     onClick={this.props.removeAction.bind(this, this.props.article.id)} />
                </div>
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }

}

export default ArticleItem
