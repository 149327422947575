import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_SPECIALITIES_FOR_SEARCH,
         REQ_GET_INSURANCES_FOR_SEARCH,
         REQ_GUEST_SEARCH_THERAPIST,
         REQ_LOG_GUEST_SEARCH,
         recGuestSearchTherapist,
         recGetSpecialitiesForSearch,
         recGetInsurancesForSearch,
         recLogGuestSearch} from './actions'

import fetchData from '../../utils/api'

function* getSpecialitiesForSearch(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetSpecialitiesForSearch(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* getInsurancesForSearch(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetInsurancesForSearch(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* guestSearchTherapists(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recGuestSearchTherapist(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* logGuestSearch(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recLogGuestSearch(data))
   
  } catch (e) {console.log("Api error: ", e)}

}


export default function* searchFormSagas() {
    yield takeLatest(REQ_GET_SPECIALITIES_FOR_SEARCH, getSpecialitiesForSearch);
    yield takeLatest(REQ_GET_INSURANCES_FOR_SEARCH, getInsurancesForSearch);
    yield takeLatest(REQ_GUEST_SEARCH_THERAPIST, guestSearchTherapists);
    yield takeLatest(REQ_LOG_GUEST_SEARCH, logGuestSearch);
};