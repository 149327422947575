import { REC_LIST_ARTICLE_REVIEWS_MANAGEMENT, REC_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT,
    REC_LIST_REVIEWS_REPLIES_MANAGEMENT, REC_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT, REC_DELETE_REPLY, REC_DELETE_REVIEW } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_LIST_ARTICLE_REVIEWS_MANAGEMENT:
            return {
                articleRevList: data
            };

        case REC_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT:
            return {
                articleRevStatusUpdated: data
            };

        case REC_LIST_REVIEWS_REPLIES_MANAGEMENT:
            return {
                articleRepliesList: data
            };

        case REC_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT:
            return {
                articleReplyStatusUpdated: data
            };

        case REC_DELETE_REVIEW:
            return {
                reviewDeleted: data
            };

        case REC_DELETE_REPLY:
            return {
                replyDeleted: data
            };
        
        default:
            return state
    }
}