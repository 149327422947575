import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { reqGetTherapistsTopProfiles } from "../TherapistProfiles/actions";
import { connect } from "react-redux";
import TherapistCard from "../../components/TherapistCard/TherapistCard";
import { reqGetPublicProfile } from "../Therapist/PublicProfile/actions";
import { Link } from "react-router-dom";
import ButtonRoundGradient from "../../components/ButtonRoundGradient/ButtonRoundGradient";

function ArticleVerifiedProviders(props) {
  const topProfilesUrl = "therapists/top/profiles";
  //const getProfileUrl = "therapist/profile/public";

  let apiData = props.apiData,
    goldProviders = [],
    platinumProviders = [];

  let [therapists, setTherapists] = useState(
    ((apiData || {}).topProfiles || {}).therapists || []
  );
  let speciality = props.speciality ? props.speciality.toLowerCase() : ""
  const [pageLoading, setPageLoading] = useState(true);

  // console.log("speciality in verified:", speciality)

  useEffect(() => {
    getTopTherapists();
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   props.actions.reqGetPublicProfile({
  //     body: {},
  //     headers: null,
  //     resource: `${getProfileUrl}?tid=${props.therapistId}`
  //   });
  // }, []);

  const getTopTherapists = () => {
    setPageLoading(true);
    props.actions.reqGetTherapistsTopProfiles({
      body: { specialityId: 21 },
      headers: null,
      resource: `${topProfilesUrl}`
    });
  };

  useEffect(() => {
    if (apiData.topProfiles && apiData.topProfiles.success) {
      const therapistsLoad = apiData.topProfiles.therapists;
      setPageLoading(false);
      setTherapists([therapists].concat(therapistsLoad));
    }
    window.scrollTo(0, 0);
  }, []);

  const FilterTherapists = () => {
    let showP = false;
    // console.log("therapists: ", therapists);
    therapists.map(therapist => {
      if (therapist.specialities) {
        let specialities = therapist.specialities? therapist.specialities.toLowerCase() : ''

        if (specialities.includes(speciality)) {
          if (therapist.userVerified === 2) {
            goldProviders.push(therapist);
          }
          if (therapist.userVerified === 3) {
            platinumProviders.push(therapist);
          }
        }
      }
    });

    if (goldProviders.length || platinumProviders.length) {
      showP = true;
    }
    return (
      <div>
        <p style={{ display: showP ? "block" : "none" }}>
          If you’re looking for a board certified specialist, we’ve prepared a
          list of our verified providers below.{" "}
        </p>
        <div>
          {platinumProviders.length ? (
            <h2>Our Platinum Verified Providers</h2>
          ) : (
            ""
          )}
          <div className="therapists-type">
            {platinumProviders.map((t, idx) => {
              return <TherapistCard therapist={t} key={idx} />;
            })}
          </div>
          {goldProviders.length ? <h2>Our Gold Verified Providers</h2> : ""}
          <div className="therapists-type">
            {goldProviders.map((t, idx) => {
              return <TherapistCard therapist={t} key={idx} />;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="member-title">{FilterTherapists()}</div>
      <div className="button-container">
        <Link to="/specialists">
          <ButtonRoundGradient text="Go To All Autism Therapists" />
        </Link>
      </div>
    </div>
  );
}
const mapStateToProps = state => ({
  apiData: state.topProfiles,
  apiDat: state.publicProfileReducer
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetTherapistsTopProfiles: bindActionCreators(
        reqGetTherapistsTopProfiles,
        dispatch
      ),
      reqGetPublicProfile: bindActionCreators(reqGetPublicProfile, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleVerifiedProviders);
