export const REQ_GET_SUPPERT_EMAIL = 'REQ_GET_SUPPERT_EMAIL'
export const REC_GET_SUPPERT_EMAIL = 'REC_GET_SUPPERT_EMAIL'

export const REQ_UPDATE_SUPPORT_EMAIL = 'REQ_UPDATE_SUPPORT_EMAIL'
export const REC_UPDATE_SUPPORT_EMAIL = 'REC_UPDATE_SUPPORT_EMAIL'

export const reqGetSupportEmail = (config) => ({type: REQ_GET_SUPPERT_EMAIL, config: config})
export const recGetSupportEmail = data => ({type: REC_GET_SUPPERT_EMAIL, data})

export const reqUpdateSupportEmail = (config) => ({type: REQ_UPDATE_SUPPORT_EMAIL, config: config})
export const recUpdateSupportEmail = data => ({type: REC_UPDATE_SUPPORT_EMAIL, data})

