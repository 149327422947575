import React        from 'react'
import { connect }  from 'react-redux'
import { bindActionCreators } from 'redux'
import { reqGetTopTherapists } from '../actions'
import Footer      from '../../../components/Footer/Footer'
import SideDrawer  from '../../../components/SideDrawer/SideDrawer'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import TherapistCard from '../../../components/TherapistCard/TherapistCard'
import './TherapistsByLocation.scss'
import {Helmet} from "react-helmet";
import HeaderGuestSimple from "../../../components/Header/HeaderGuestSimple";
import {Link} from "react-router-dom";
import ButtonRoundGradient from "../../../components/ButtonRoundGradient/ButtonRoundGradient";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

class TherapistsByLocation2 extends React.Component {

    state = {
        sideDrawerOpen: false,
        pageLoading: false,
        locationType: 'locations',
        locationName: '',
        region:'',
        selectedLocation: {}
    }

    listTherapistsUrl = 'seo/therapist/list'

    drawerToggleClickHandler = () => {
        this.setState((prevState) => ({sideDrawerOpen: !prevState.sideDrawerOpen}))
    }

    componentDidMount = () => {

        let { locationType, locationName } = this.props.match.params
        this.setState({
            locationType,
            locationName
        }, () => { this.getCoordinates() })

    }

    componentWillReceiveProps = (newProps) => {
        let apiData = newProps.apiData

        // console.log("apidata: ", apiData)

        if (apiData.topTherapists && apiData.topTherapists.success) {
            let { data: therapists } = apiData.topTherapists

            this.setState(() => ({
                pageLoading: false,
                therapists: therapists
            }))

        }
    }

    loadTherapists = () => {
        this.setState({pageLoading: true}, () => {
            this.props.actions.reqGetTopTherapists({
                body: {},
                headers: null,
                resource: this.getListUrl()
            })
        })
    }

    getListUrl = () => (
        `${this.listTherapistsUrl}?locationType=${this.state.locationType}&location=${this.state.locationName}&lat=${this.state.selectedLocation.lat}&lng=${this.state.selectedLocation.lng}`
    )

    getCoordinates = () => {
        geocodeByAddress(`${this.state.locationName}, ${this.state.region}`).then((geocode) => {
          const latLng = getLatLng(geocode[0]);
          latLng.then((response) => {
            const location = {
              lat: response.lat,
              lng: response.lng,
            };
    
            this.setState(() => ({
              selectedLocation: location,
            }));
    
            this.loadTherapists()
          });
        });
      }

    renderTherapists = () => {

        let therapists = (this.state.therapists || []).map( (t, i) => (
            <TherapistCard key={i} therapist={t} />
        ))
        if(therapists.length<4){
            return (
                <div className="top-therapists-list">
                    { therapists }
                    <div className="more-therapists">
                        <Link to="/specialists">
                            <ButtonRoundGradient text={"See More Specialists"} />
                        </Link>
                    </div>
                </div>

            )
        }
        else return(
            <div className="top-therapists-list">
                <div className="more-therapists">
                    <Link to="/specialists">
                        <ButtonRoundGradient text={"See More Specialists"} />
                    </Link>
                </div>
                { therapists }

            </div>
        )
    }


    render() {
        // eslint-disable-next-line
        let sideDrawer = null;

        if (this.state.sideDrawerOpen) {
            sideDrawer = <SideDrawer/>
        }

        return (
            <div>
                <Helmet>
                    <meta name="robot" content="noindex"></meta>
                    <meta name="googlebot" content="noindex"></meta>
                </Helmet>
                <div id="therapists-by-locations">

                    {/* Loader */}
                    <PopupLoader display={this.state.pageLoading} />

                    {/* Header */}
                    <HeaderGuestSimple menu="" menuVisible={this.state.sideDrawerOpen} drawerClickHandler={this.drawerToggleClickHandler}/>

                    {/* Main Container */}
                    <div className="therapists-by-loc-container">
                        <h2 className="page-title">{`Top Therapists in ${this.state.locationName}`}</h2>
                        <div className="mt-50 mb-30">
                            <p>Looking for autism specialists in your area, or even for teletherapy? At Autism Assistant, we’re dedicated to placing any and all quality resources at your disposal, for no cost! Why? Because we believe that finding help should be easy.</p>
                            <p>Additionally, we also know how difficult it can be to find a provider which not only accepts your insurance, but also connects with your child. As such, on our platform, you can search for autism specialists for free; no hidden costs, no search amount limit.
                            </p>
                            <p>We have autism therapists listed for the following therapies and specialities:</p>
                            <ul>
                                <li>Applied Behavioral Analysis (ABA)</li>
                                <li>Developmental Pediatrician</li>
                                <li>Equine Therapy</li>
                                <li>Feeding Therapy</li>
                                <li>Music Therapy</li>
                                <li>Occupational Therapy</li>
                                <li>Pediatric Dentistry</li>
                                <li>Pediatrician</li>
                                <li>Physical Therapy</li>
                                <li>Social Skills</li>
                                <li>Speech Therapy</li>
                                <li>Autism coaching/Parent training</li>
                            </ul>
                        </div>
                        {/* List Container */}
                        <div className="therapists-list-container">
                            { this.renderTherapists() }
                        </div>

                    </div>

                    {/* Footer */}
                    <Footer />
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    apiData: state.topLocations
})

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            reqGetTopTherapists: bindActionCreators(reqGetTopTherapists, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapistsByLocation2)
