import React from 'react'
// import './SpecialitySuggest.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

const renderTooltipContainer = props => (
    props.tooltip !== '' ?
        (<ReactTooltip type="info" place="right"/>)
        : ''
);

const SpecialitySuggest = (props) => (
    <div id="specs-autosuggest">
        <div className="item-suggest-outer" onClick={props.toggleSpecialityList}>
            <div className="item-suggest-inner" >
                <div className="item-suggest-label">Find</div>
                <div className="speciality-placeholder">
                    <div
                        className="speciality-placeholder-text"
                        data-tip={props.tooltip}
                        >{props.displayText || 'Speciality'}</div>
                    <div className="speciality-drop-arrow">
                        <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                </div>
            </div>
        </div>
        {renderTooltipContainer(props)}
    </div>

);

export default SpecialitySuggest
