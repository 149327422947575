import React from 'react'
import defaultAvatar from '../../../static/images/desktop/annonymous-user-icon.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
// import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class AdminItem  extends React.Component {

    admin = this.props.admin

    getLockValue = () => {
        return this.props.admin.banned ? faLock : faLockOpen
    }

    renderActions = () => {
        if (this.admin.id === this.props.ownId) return;
        return (
            <div className="ai-actions-container">
                    <FontAwesomeIcon icon={this.getLockValue()} 
                                            onClick={this.props.handleLocking.bind(this, {id: this.props.admin.id, lock: !this.props.admin.banned})}
                                            className="edit-icon" 
                                            data-tip={`${this.props.admin.banned ? 'Unlock' : 'Lock'}`} />
                    <FontAwesomeIcon icon={faTrashAlt} 
                                        className="delete-icon" 
                                        data-tip="Delete" 
                                        onClick={this.props.handleDelete.bind(this, this.props.admin.id)}/>
            </div>
        )
    }

    renderAvatarUrl = () => (
        this.admin.avatarUrl 
            ? `${baseBackendUrl}/${this.admin.avatarUrl}`
            : defaultAvatar
    )


    render() {
        return (
            <li key={this.props.index} className="ai-list-item">
                {/* Avatar Container */}
                <div className="ai-image-container">
                    <img src={this.renderAvatarUrl()} alt='admin-avatar' />
                </div>
                {/* User Profile Container */}
                <div className="ai-info-container">
                    {/* Name & Title */}
                    <div className="ai-main-info">
                        <span className="ai-u-name">{`${this.admin.firstName} ${this.admin.lastName} ${this.props.ownId === this.admin.id ? '(You)' : ''}, `}</span>
                        <span className="ai-u-title">&nbsp;{ ` ${this.admin.title || 'Admin'}` }</span>
                    </div>
                    {/* Details */}
                    <div className="ai-details-info">
                        {/* Email */}
                        <div className="ai-detail-container">
                            <span className="ai-detail-label">Email:</span>
                            <span className="ai-detail-value">{ this.admin.email }</span>
                        </div>
                        {/* Phone */}
                        <div className="ai-detail-container">
                            <span className="ai-detail-label">Phone:</span>
                            <span className="ai-detail-value">{ this.admin.phone || '-' }</span>
                        </div>
                        
                    </div>
                </div>
                {/* Actions Container */}
                {this.renderActions()}
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }
    
}

export default AdminItem