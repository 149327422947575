import React from 'react'
import './ProfileIcon.scss'
import avatar from '../../static/images/desktop/admin-avatar.png'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reqLogoutUser } from './actions';
const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;


class ProfileIcon extends React.Component {

    state = {
        displayOptions: false,
        userNameShor: '',
        redirect: false,
        redirectUrl: '',
        profileUrl: '',
        profileImageUrl: '',
        logoutUrl: '',
        logout: false
    }

    user = {}

    componentDidMount = () => {
        this.updateStateBasedOnProps(this.props);
    }

    generateProfileImageUrl = (user) => (
        user.profileImageName
            ? `${baseBackendUrl}/${user.profileImageName}`
            : ''
    )

    updateStateBasedOnProps = (props) => {
        this.user = props.userDetails || {};
        let fullName = `${this.user.firstName} ${this.user.lastName}`,
            businessName = this.user.businessName,
            nameShort = '',
            nameShortAll = ''

        if(fullName!=="null null" && fullName.length > 4){
            nameShortAll = fullName.split(' ').map(part => part.substring(0, 1)).join('')
            nameShort = nameShortAll[0] + nameShortAll[nameShortAll.length-1];
        } else if(businessName!==""){
            nameShortAll = businessName.split(' ').map(part => part.substring(0, 1)).join('')
            nameShort = nameShortAll[0] + nameShortAll[nameShortAll.length-1];
        } else {
            nameShort = this.user.userName.substring(0, 1)
        }

        let logoutUrl = '',
            profileUrl = '',
            avatarUrl = this.generateProfileImageUrl(this.user)

        if (!this.user.token) return ;
        // Configure header links based on user role
        if (this.user.roleId === 1) {
            logoutUrl = '/admin/signin';
            profileUrl = '/admin/profile';
        } else if (this.user.roleId === 3) {
            logoutUrl = '/signin';
            profileUrl = '/profile';
        }
        else if (this.user.roleId === 4) {
            logoutUrl = '/signin';
            profileUrl = '/blogger-profile';
        }

        this.setState( () => ({
            userNameShor: nameShort,
            logoutUrl: logoutUrl,
            profileUrl: profileUrl,
            avatarUrl: avatarUrl,
            displayOptions: props.displayOptions
        }))
    }

    componentWillReceiveProps = (newProps) => {
        this.updateStateBasedOnProps(newProps);
    }

    logoutUser = () => {
        this.props.actions.reqLogoutUser({})
        localStorage.removeItem('signedUp');
        this.setState(() => ({
            logout: true
        }))
    }

    toggle = () => {
        this.setState( () => ({displayOptions: !this.state.displayOptions}));
    }

    getUserAvatar = () => (
        this.state.avatarUrl ? this.state.avatarUrl : avatar
    )

    render () {
        if (this.state.logout) {
            return (
                <Redirect to={this.state.logoutUrl} />
            )
        }
        return (
            <div id="icon-user">
                <div className="header-content">
                    <div className="spacer"></div>
                        <div className="avatar-container" >
                            <div className="name-container link-text">{this.state.userNameShor}</div>
                            <div className="avatar-img-container">
                                <img src={this.getUserAvatar()} alt="avatar" />
                            </div>
                            <div className={ `aac-options-container ${this.state.displayOptions ? 'visible-item' : 'hidden-item'}` }>
                                <ul className="aac-options">
                                    <li className="aac-option">
                                    <Link to={this.state.profileUrl}><span className="link-text">My Profile</span></Link>
                                    </li>
                                    <li className="aac-option" onClick={this.logoutUser}>
                                        <span className="link-text">Log Out</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
})

const mapDispatchToProps = (dispatch) => ({
    actions: {
        reqLogoutUser: bindActionCreators(reqLogoutUser, dispatch),
    }
})

export default connect( mapStateToProps,mapDispatchToProps)(ProfileIcon)
