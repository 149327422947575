import { REC_LIST_BLOGGERS_ADMIN, REC_SEARCH_BLOGGERS_ADMIN, 
         REC_BLOGGERS_LOCK_UNLOCK, REC_BLOGGERS_DELETE } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_LIST_BLOGGERS_ADMIN:
            return {
                bloggerList: data
            };
        
        case REC_SEARCH_BLOGGERS_ADMIN:
            return {
                bloggerSearch: data
            };
        
        case REC_BLOGGERS_LOCK_UNLOCK:
            return {
                bloggerLockUnLock: data
            };

        case REC_BLOGGERS_DELETE:
            return {
                bloggerDelete: data
            };
        
        default:
            return state
    }
}