import React from 'react'
import ButtonRoundGradient from '../ButtonRoundGradient/ButtonRoundGradient'
import loginIcon from '../../static/images/desktop/login_icon.png'
import headerLogoWhite from '../../static/images/desktop/header_logo_white.png'
import headerBackground from '../../static/images/desktop/header-background_missized.png'
import logoTextBlue from '../../static/images/desktop/logo-text-blue.png'
import './HeaderGuest.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import ProfileIcon from "../../components/HeaderUser/ProfileIcon";
import SearchForm from './SearchForm'
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import { Link } from 'react-router-dom'
import Radio from '../../components/Radio/Radio'
import SearchFormOnline from './SearchFormOnline'

class HeaderGuest extends React.Component {

    state = {
        menuVisible: false,
        profileIconMenu: false,
        showSubMenu: false,
        userId: null,
        menu: '',
        searchMethod: 'location'
    }

    componentDidMount = () => {
    const userDetails = this.props.userDetails

    if (userDetails.uid) {
        this.setState(() => ({
            userId: userDetails.uid
        }))
    } else if (userDetails.id) {
        this.setState(() => ({
            userId: userDetails.id
        }))
    } 

    this.setState(() => ({
        menu: this.props.menu
    }))
    }

    componentWillReceiveProps = (newProps) => {
        const userDetails = newProps.userDetails

        if (userDetails) {
            this.setState(() => ({
                userId: userDetails.uid
            }))
        } else {
            this.setState(() => ({
                userId: userDetails.id
            }))
        }

        this.setState(() => ({
            menu: newProps.menu
        }))

    }

    displaySubMenu = () => {
        this.setState( () => ({showSubMenu: true}))
    }

    hideSubMenu = () => {
        this.setState( () => ({showSubMenu: false}))
    }

    displayOptions = () => {
        this.setState( () => ({profileIconMenu: true}))
    }

    hideOptions = () => {
        this.setState( () => ({profileIconMenu: false}))
    }

    closeMobileMenu = () => {
        this.setState(() => ({
            menuVisible: false
        }));
    }
     
    toggle = () => {
        this.setState(() => ({
            menuVisible: !this.state.menuVisible
        }));
    }

    radioClickHandler = (event) => {
        let radioValue = event.target.dataset.value
        this.setState(() => {
            return {searchMethod: radioValue}
        })
    }

   

    render() {
        const { menu, menuVisible, profileIconMenu, showSubMenu} = this.state;
        
        return (
            <div>
                <header className={"" + (menu === 'home' ? " header header-home" : "header")}>
                    {menu !== 'home' && (
                        <img className="bg-image" src={headerBackground} alt="bigImage" width="1847" height="1200" loading="lazy"/>
                    )}
                    <div className="container">
                        <div className="d-flex align-items-center flex-wrap">
                            {menu !== 'home' && (
                                <Link to="/" className="logo" ><img src={headerLogoWhite} alt="hlgWhite" width="230" height="50" loading="lazy"/></Link>
                            )}
                            <ul className={"menu" + (menuVisible ? ' visible' : '')} >
                                <li className="close-mobile-menu" onClick={this.closeMobileMenu}>
                                X Close
                                </li>

                                { menu!=='home'&&(
                                    <li><Link to="/">Home</Link></li>)
                                }
                                <li><Link to="/about-us">About</Link></li>
                                <li className="sub-menu-link" onMouseEnter={this.displaySubMenu} onMouseLeave={this.hideSubMenu}>
                                    <div className="sub-menu-name">Resources <FontAwesomeIcon icon={faAngleDown} /></div>
                                    <ul className={`sub-menu ${showSubMenu ? "show-sub-menu" : "" }`}>
                                        <li className="sub-menu-item"><Link to="/autism-assistance-for-all">Assistance For All Program</Link></li>
                                        <li className="sub-menu-item"><Link to="/autism-therapy-types">Types of Autism Therapies</Link></li>
                                        <li className="sub-menu-item"><Link to="/online-therapies">Telemedicine Options</Link></li>
                                        <li className="sub-menu-item"><Link to="/products">Autism Friendly Products</Link></li>
                                        <li className="sub-menu-item"><Link to="/faq">FAQ</Link></li>
                                    </ul>
                                </li>
                                <li><a  href="/specialists">Specialists</a></li>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                                {this.state.userId && (
                                <li className="greeting" onMouseEnter={this.displayOptions} onMouseLeave={this.hideOptions}>
                                <ProfileIcon displayOptions={profileIconMenu}/></li>)}
                                {!this.state.userId && (
                                    <li>
                                        <Link to="/signin">
                                            <img src={loginIcon} width="22" height="22" alt="alt" loading="lazy"/> Login
                                        </Link></li>)}
                                {menu === 'home' && !this.state.userId &&(
                                    <li><Link to="/signup"><ButtonRoundGradient text="Sign Up" /></Link></li>
                                )}
                            </ul>
                        </div>

                        <DrawerToggleButton className="burger-menu" click={this.toggle}/>
                        {menu === 'home' && (
                            <div className="home-logo">
                                <img src={logoTextBlue} width="260" height="148" alt="alt" loading="lazy"/>
                            </div>
                        )}
                        {menu === 'home' && (
                            <div className="home-title">
                                <h1>You’ve received an autism diagnosis, now what?</h1>
                                <span className="hp-section-text">Autism Assistant is here to help you find all the providers you need, not only for therapy, but for all aspects of your life.</span>
                            </div>
                        )}
                        <div className={`"search-radio--row" ${menu === 'home' ? '' : 'search-white-text'}`}>
                            <Radio id="searchMethodLocation"
                                    name="comm-method"
                                    dataValue="location"
                                    isChecked={this.state.searchMethod === 'location'}
                                    className=""
                                    click={this.radioClickHandler}
                                    text="Search by location" />
                            <Radio id="searchMethodOnline"
                                    name="comm-method"
                                    dataValue="online"
                                    isChecked={this.state.searchMethod === 'online'}
                                    className=""
                                    click={this.radioClickHandler}
                                    text="Online therapy (telemedicine)"/>
                        </div>
                        {this.state.searchMethod === 'location' ? <SearchForm menu={menu} /> : <SearchFormOnline menu={menu} /> }
                        
                    </div>
                </header>
    </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
  })

export default connect(mapStateToProps)(HeaderGuest)