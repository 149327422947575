import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class SpecialityItem  extends React.Component {

    state = {
        name: this.props.speciality.name,
        description: this.props.speciality.description
    }

    render() {
        return (
            <li key={this.props.index} className="sp-list-item">
                <div className="sp-li-info">
                    <h4 className="sp-li-name" >{this.state.name}</h4>
                    <div className="sp-li-description">{this.state.description}</div>
                </div>
                <div className="sp-li-actions" >
                    <FontAwesomeIcon icon={faPlus} 
                                     className="add-icon" 
                                     data-tip="Add to your specialities" 
                                     onClick={this.props.attachAction.bind(this, this.props.speciality.id)}/>
                </div>
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }
    
}

export default SpecialityItem