import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_ACTIVATE_ACCOUNT, recActivateAccount} from './actions'

import fetchData from '../../../utils/api'


function* activateAccount(action) {
  
  try {
    // console.log("saga called")
    const data = yield call(fetchData, action.data, action.resource)
    yield put(recActivateAccount(data))
   
  } catch (e) {
      console.log("Api error: ", e)
   }

}


export default function* adminActivateAccountSaga() {
    yield takeLatest(REQ_ACTIVATE_ACCOUNT, activateAccount);
};