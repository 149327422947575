export const REQ_REQUEST_RESET_PASSWORD = 'REQ_REQUEST_RESET_PASSWORD'
export const REC_REQUEST_RESET_PASSWORD = 'REC_REQUEST_RESET_PASSWORD'

export const reqRequestResetPassword = (data, resource) => {
    //console.log("called action")
    return {
        type: REQ_REQUEST_RESET_PASSWORD, 
        data, 
        resource
    }
}

export const recRequestResetPassword = data => ({type: REC_REQUEST_RESET_PASSWORD, data})


