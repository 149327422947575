export const REQ_LIST_ARTICLE_REVIEWS_MANAGEMENT = 'REQ_LIST_ARTICLE_REVIEWS_MANAGEMENT'
export const REC_LIST_ARTICLE_REVIEWS_MANAGEMENT = 'REC_LIST_ARTICLE_REVIEWS_MANAGEMENT'

export const REQ_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT = 'REQ_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT'
export const REC_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT = 'REC_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT'

export const REQ_LIST_REVIEWS_REPLIES_MANAGEMENT = 'REQ_LIST_REVIEWS_REPLIES_MANAGEMENT'
export const REC_LIST_REVIEWS_REPLIES_MANAGEMENT = 'REC_LIST_REVIEWS_REPLIES_MANAGEMENT'

export const REQ_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT = 'REQ_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT'
export const REC_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT = 'REC_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT'

export const REQ_DELETE_REVIEW = 'REQ_DELETE_REVIEW'
export const REC_DELETE_REVIEW = 'REC_DELETE_REVIEW'

export const REQ_DELETE_REPLY = 'REQ_DELETE_REPLY'
export const REC_DELETE_REPLY = 'REC_DELETE_REPLY'

export const reqListArticleReviewsManagement = (config) => ({type: REQ_LIST_ARTICLE_REVIEWS_MANAGEMENT, config: config})
export const recListArticleReviewsManagement = data => ({type: REC_LIST_ARTICLE_REVIEWS_MANAGEMENT, data})

export const reqToggleArticleReviewStatusManagement = (config) => ({type: REQ_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT, config: config})
export const recToggleArticleReviewStatusManagement = data => ({type: REC_TOGGLE_ARTICLE_REVIEW_STATUS_MANAGEMENT, data})

export const reqListReviewsRepliesManagement = (config) => ({type: REQ_LIST_REVIEWS_REPLIES_MANAGEMENT, config: config})
export const recListReviewsRepliesManagement = data => ({type: REC_LIST_REVIEWS_REPLIES_MANAGEMENT, data})

export const reqToggleReviewReplyStatusManagement = (config) => ({type: REQ_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT, config: config})
export const recToggleReviewReplyStatusManagement = data => ({type: REC_TOGGLE_REVIEW_REPLY_STATUS_MANAGEMENT, data})

export const reqDeleteReview = (config) => ({type: REQ_DELETE_REVIEW, config: config})
export const recDeleteReview = data => ({type: REC_DELETE_REVIEW, data})

export const reqDeleteReply = (config) => ({type: REQ_DELETE_REPLY, config: config})
export const recDeleteReply = data => ({type: REC_DELETE_REPLY, data})
