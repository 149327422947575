import React from 'react'
// import ReactTooltip from 'react-tooltip'

import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;


const containerStyle = {
    width: '98%',
    maxWidth: '1195px',
    background: '#fff',
    borderRadius: '20px',
    color: '#111',
    padding: '10px 30px 10px 0',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    maxHeight: '94vh',
    height: '100%',
}

const overflowContainer = {
    height: '100%',
    padding: '0 0 0 30px',
    overflowY: 'scroll',
    width: '100%',
}

const titleStyle = {
    textAlign: 'center',
    fontWeight: '600'
}

// const textStyle = {
//     textAlign: 'center',
//     fontSize: '17px',
//     display: 'block',
//     margin: '15px auto'
// }

const optionsCStyle = {
    width: '150px',
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0 auto'
}

const validationError = {
    color: '#dc3545',
    fontSize: '14px',
    lineHeight: '1.2'
}


const renderThumbnail = props => {
    let previewUrl = props.thumbnailPreview;

    let el = (
        <div className="art-thb-container">
            <img src={previewUrl} alt="articleThumbnail" />
        </div>
    );

    if (!previewUrl) {
        if (!props.articleThumbnailUrl) return '';

        let thbUrl = `${baseBackendUrl}/articleContentImage/${props.articleThumbnailUrl}`
        el = (
            <div className="art-thb-container">
                <img src={thbUrl} alt="articleThumbnail" loading="lazy" />
            </div>
        );

        return el;
    }

    return el;
}


class ImageUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                this._initRequest();
                this._initListeners( resolve, reject, file );
                this._sendRequest( file );
            } ) );
    }

    // Aborts the upload process.
    abort() {
        if ( this.xhr ) {
            this.xhr.abort();
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        xhr.open( 'POST', `${baseBackendUrl}/api/therapist/article/upload_image`, true );
        xhr.responseType = 'json';
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners( resolve, reject, file ) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${ file.name }.`;

        xhr.addEventListener( 'error', () => reject( genericErrorText ) );
        xhr.addEventListener( 'abort', () => reject() );
        xhr.addEventListener( 'load', () => {
            const response = xhr.response;

            if ( !response || response.error ) {
                return reject( response && response.error ? response.error.message : genericErrorText );
            }

            resolve( {
                default: response.url
            } );
        } );

        if ( xhr.upload ) {
            xhr.upload.addEventListener( 'progress', evt => {
                if ( evt.lengthComputable ) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            } );
        }
    }

    // Prepares the data and sends the request.
    _sendRequest( file ) {
        // Prepare the form data.
        const data = new FormData();

        data.append( 'upload', file );
        this.xhr.send( data );
    }
}

function imageUploadAdapterFunction( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new ImageUploadAdapter( loader );
    };
}

const editorConfiguration = {
    extraPlugins: [ imageUploadAdapterFunction ]
};

class ArticlePopupForm  extends React.Component {

    render() {
        return (
            <div className="dark-overlay"
                 style={{
                    display: this.props.display ? 'block' : 'none',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: '100',
                    background: 'rgba(0, 0, 0, 0.5)',
                }}>
                <div className="confirm-container"
                     style={containerStyle}>
                    <div className="overflow-container"
                         style={overflowContainer}>
                        <h5 className="pc-title" style={titleStyle}>{this.props.title}</h5>

                        <div className="mb-4">
                            <label>Resource type</label>
                            <select name="articleType"
                                   className="form-control type-input-width"
                                   value={this.props.articleType}
                                   onChange={this.props.changeSelectAction}>
                                   <option value="blog" defaultValue={this.props.articleType==='blog'? true : false}>Blog</option>
                                   <option value="therapy" defaultValue={this.props.articleType==='therapy'? true : false}>Therapy</option>
                                   <option value="product" defaultValue={this.props.articleType==='product'? true : false}>Product</option>
                                   <option value="event" defaultValue={this.props.articleType==='event'? true : false}>Event</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label>Title</label>
                            <input name="articleTitle"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.articleTitle}
                                   onChange={this.props.changeInputAction} />
                        </div>
                        
                        <div className="article-thumbnail-row">
                            <label>Thumbnail Image</label>
                            {/* ThumbnailImage */}
                            { renderThumbnail(this.props) }
                            {/* Upload Btn */}
                            <div
                                className="btn btn-default add-thumbnail-btn"
                                onClick={(e) => this.selectArtThumbnail.click()}
                            >{ this.props.thumbnailPreview ? 'Change' : 'Upload'}</div>
                            {/* Hidden upload input */}
                            <input
                                id="arThbImg"
                                type="file"
                                ref={(ref) => this.selectArtThumbnail = ref}
                                style={{ display: 'none' }}
                                onChange={this.props.handleChooseThumbnail}
                            />

                        </div>
                        {this.props.articleType!=='product' ?
                        (<div className="mb-4">
                            <label>Content</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={this.props.articleContent}
                                config={editorConfiguration}
                                onInit={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    let alteredEvent = event;
                                    alteredEvent.target = {
                                        name: 'articleContent',
                                        value: data
                                    };
                                    this.props.changeInputAction(alteredEvent)
                                } }
                                onBlur={ editor => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ editor => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        </div>) : ""}

                        <div className="mb-4">
                            <label>Tags</label>
                            {this.props.loadTags()}
                            <div className="row">
                                <div className="col-md-6">
                                    <input name="tagInput"
                                           className="form-control"
                                           type="text"
                                           value={this.props.tagInput}
                                           onChange={this.props.changeInputAction} />
                                    <span style={validationError}>{this.props.tagInputError}</span>
                                </div>
                                <div className="col-md-4">
                                    <button type="button"
                                            className="btn btn-primary ml-3"
                                            onClick={this.props.addTagAction}>{'Add Tag'}</button>
                                </div>
                            </div>
                        </div>

                        
                        {this.props.articleType!=='product' ? (
                        <div>
                        <div className="mb-4">
                            <label>Meta Title</label>
                            <input name="articleMetaTitle"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.articleMetaTitle}
                                   onChange={this.props.changeInputAction} />
                        </div>

                        <div className="mb-4">
                            <label>Meta Description</label>
                            <input name="articleMetaDescription"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.articleMetaDescription}
                                   onChange={this.props.changeInputAction} />
                        </div>

                        <div className="mb-4">
                            <label>Canonical Link</label>
                            <input name="articleCanonical"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.articleCanonical}
                                   onChange={this.props.changeInputAction} />
                        </div>

                        <div className="mb-4">
                            <label>Schema Markup</label>
                            <textarea name="articleSchema"
                                   className="form-control input-width"
                                   value={this.props.articleSchema}
                                   onChange={this.props.changeInputAction}
                                   rows="4" cols="50" />
                        </div>

                        <div className="mb-4">
                            <label>Card Title</label>
                            <input name="articleCardTitle"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.articleCardTitle}
                                   onChange={this.props.changeInputAction} />
                        </div>

                        <div className="mb-4">
                            <label>Permalink</label>
                            <input name="articlePermalink"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.articlePermalink}
                                   onChange={this.props.changeInputAction} />
                        </div>
                        </div>) : 
                        
                         
                        (<div>
                        <div className="mb-4">
                            <label>Description</label>
                            <textarea name="resourceDescription"
                                   className="form-control input-width textarea-row"
                                   rows="5"
                                   type="text"
                                   value={this.props.resourceDescription}
                                   onChange={this.props.changeInputAction} />
                        </div>
                            
                        <div className="mb-4">
                            <label>Price</label>
                            <input name="resourcePrice"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.resourcePrice}
                                   onChange={this.props.changeInputAction} />
                        </div>

                        <div className="mb-4">
                            <label>Product Link</label>
                            <input name="resourceLink"
                                   className="form-control input-width"
                                   type="text"
                                   value={this.props.resourceLink}
                                   onChange={this.props.changeInputAction} />
                        </div>
                        </div>)
                        }
                        <div className="mb-4 published-date">
                            <label>Published Date</label>
                            <input name="articlePublishedDate"
                                   className="form-control"
                                   type="date"
                                   value={this.props.articlePublishedDate}
                                   onChange={this.props.changeInputAction} />
                        </div>

                        <div className="error-message">{this.props.errorMessage}</div>

                        <div className="pc-options-container" style={optionsCStyle}>
                            <button type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={this.props.submitForm}>{this.props.confirmText || 'Submit'}</button>
                            <button type="button"
                                    className="btn btn-danger ml-3"
                                    onClick={this.props.cancelAction}>{this.props.cancelText || 'Cancel'}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ArticlePopupForm
