import { REC_UPDATE_THERAPIST_PROFILE, REC_UPDATE_THERAPIST_PASSWORD, REC_EMAIL_CONFIRMED } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_UPDATE_THERAPIST_PROFILE:
            return {profileUpdated: data};

        case REC_UPDATE_THERAPIST_PASSWORD:
            return {passwordUpdated: data};
        
        case REC_EMAIL_CONFIRMED:
            return { emailConfirmed: data};

        default:
            return state
    }
}