import React from "react";
import ButtonRoundGradient from "../../components/ButtonRoundGradient/ButtonRoundGradient";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import Footer from "../../components/Footer/Footer";
import SearchForm from "../../components/Header/SearchForm";
import { Link } from 'react-router-dom'
// import TeamMember from '../../components/TeamMember/TeamMember'
// import teamMember1 from '../../static/images/desktop/team-member-1.png'
// import teamMember2 from '../../static/images/desktop/team-member-2.png'
// import sampleImage2 from '../../static/images/desktop/sample-image-2.png'
import aboutUsImage from "../../static/images/desktop/autism-spectrum-disorder-treatment.png";
import { Helmet } from "react-helmet";
import "./AboutUs.scss";
import Radio from "../../components/Radio/Radio";
import SearchFormOnline from "../../components/Header/SearchFormOnline";

class AboutUs extends React.Component {
  state = {
    menu: "",
    searchMethod: "location",
  };

  radioClickHandler = (event) => {
    let radioValue = event.target.dataset.value;
    this.setState(() => {
      return { searchMethod: radioValue };
    });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Autism Spectrum Disorder Treatment | Autism Assistant</title>
          <meta
            name="description"
            content="We connect you directly to the type of therapist that provides the autism spectrum disorder treatment that your child requires. Search our list for free."
          ></meta>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
        </Helmet>
        <HeaderGuestSimple menu="aboutUs" />
        <section className="container mt-30">
          <h1 className="text-center aboutUsHeading">
            Helping You Find The Right Autism Spectrum Disorder Treatment
          </h1>
          <div className="row our-story">
            <div className="text-section">
              <img
                src={aboutUsImage}
                alt="autism spectrum disorder treatment"
                className="about-img"
                loading="lazy"
              />
              <h2>What Truly Matters to Us</h2>
              <p>
                My name is Emily Swank, founder of Autism Assistant. My goal is
                to connect you with all the specialists your child needs for
                autism spectrum disorder treatment. A new autism diagnosis can
                be hard to navigate, and we want to make life a little easier by
                helping you find the resources your child needs. That's why I
                created this website, because finding help should be easy.
              </p>
              <h2>Raising a Child With Autism</h2>
              <p>
                I am the proud mother of three children, two of which are on the
                spectrum. When my oldest son first received his diagnosis, we
                were a little blindsided. We knew he wasn’t hitting his
                milestones in reference to talking. He also had some behavioral
                issues, but we really didn’t think the evaluation would end with
                him receiving an{" "}
                <a href="/blog/66/what-is-autism-spectrum-disorder">
                  autism spectrum disorder
                </a>{" "}
                diagnosis.
              </p>
              <p>
                The next thing we knew, everyone was throwing all sorts of
                special therapy initials and acronyms at us. I found myself
                drowning in a sea of new and overwhelming information, like the
                following:
              </p>
              <p>
                “It’s very important that you get him into ABA as soon as
                possible. He will also need an SLP, OT, PT, and possibly feeding
                therapy. There are government funded programs through the DDD
                (Arizona) but that’s only if he qualifies and you want to call
                your insurance and see exactly what they cover and verify
                coverage with providers.”
              </p>
              <h2>We’re here to Facilitate Access to Professional Help</h2>
              <p>
                In my case, I found plenty of websites that explained what every
                type of autism spectrum disorder treatment is and why they’re
                important. However, there wasn’t a single website that would
                actually help me find all the providers my child needed, in one
                convenient site.
              </p>
              <p>
                I had to manually search out each type of therapist near me and
                then call each and every one to find out if they’d take my
                insurance. Seeing as how our developmental pediatrician
                recommended we find therapists for ABA, speech, OT, PT, music,
                and oh, let’s not forget a parent training course, I ended up{" "}
                <strong>spending hours making 50+ phone calls</strong>.
              </p>
              <p>
                <strong>It took several days</strong> for me to just track down
                therapists in my area that were contracted with our insurance.
                Throughout the whole process, I kept saying to myself that the
                process shouldn’t be this hard. Raising a child with autism can
                be difficult at times, and there should be something out there
                that makes the beginning stages a little easier on the parents.
              </p>
              <p>
                This is when the idea for Autism Assistant was born. I wanted to
                create a website where you could come to find any type of
                provider you needed for your ASD child with a few clicks.
                Finding help should be easy.
              </p>

              <div className="search-radio-about">

              <div className="search-radio--row">
                <Radio
                  id="searchMethodLocation"
                  name="comm-method"
                  dataValue="location"
                  isChecked={this.state.searchMethod === "location"}
                  className=""
                  click={this.radioClickHandler}
                  text="Search by location"
                />
                <Radio
                  id="searchMethodOnline"
                  name="comm-method"
                  dataValue="online"
                  isChecked={this.state.searchMethod === "online"}
                  className=""
                  click={this.radioClickHandler}
                  text="Online therapy (telemedicine)"
                />
              </div>
              {this.state.searchMethod === "location" ? (
                <SearchForm />
              ) : (
                <SearchFormOnline />
              )}
              </div>

              <h2 className="schedule-call-h2">Do you need personalized assistance directly from Emily?</h2>
              <p className="schedule-call-p">
                Navigating a new autism diagnosis can be overwhelming. Learning
                how to find the best providers, work with insurance, and get the
                most from government programs is just the tip of the iceberg.
                But, as Emily says, "finding help should be easy". Schedule a
                free 10 minute phone call with her to discuss how she can help
                you one on one.
              </p>

              <a href="https://calendly.com/autismassistant/one-on-one-w-emily" target="_blank" rel="nofollow noopener noreferrer">
                <ButtonRoundGradient text="Schedule a Free Call" otherClass="schedule-call" />
            </a>
            </div>
            {/* <div className="image-section">
                    <img src={aboutUsImage} alt="alt"/>
                </div> */}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default AboutUs;
