import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faHandPointUp } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import './DataCard.scss'


const getIcon = (label) => {
    switch(label) {
        case 'searches': 
            return faSearch
        
        case 'totalClients':
            return faUsers
        
        case 'clickToProfile':
            return faHandPointUp
        
        case 'homepageVisits':
            return faHome;

        default:
            return null;
    }
}

const addStyleClases = (element, label) => {
    
    switch(label) {
        case 'searches': 
            return `dc-pink-color ${element === 'icon-container' ? 'dc-pink-background' : ''}`;
        
        case 'totalClients': 
            return `dc-blue-color ${element === 'icon-container' ? 'dc-blue-background' : ''}`;
        
        case 'clickToProfile': 
            return `dc-purple-color ${element === 'icon-container' ? 'dc-purple-background' : ''}`;
        
        case 'homepageVisits': 
            return `dc-orange-color ${element === 'icon-container' ? 'dc-orange-background' : ''}`;
        
        default:
            return '';

    }

}


const DataCard = props => (
    <div className="data-card">
        <div className="dc-icon-row">
            <div className={`dc-icon-container ${addStyleClases('icon-container', props.label)}`}>
                <FontAwesomeIcon icon={getIcon(props.label)} />
            </div>
        </div>
        <div className="dc-value-row">
            <span className={`dc-value ${addStyleClases('value', props.label)}`}>{props.value}</span>
        </div>
        <div className="dc-label-row">
            <span className="dc-label">{_.startCase(props.label)}</span>
        </div>
    </div>
)

export default DataCard