export const REQ_ADD_SPECIALITY = 'REQ_ADD_SPECIALITY'
export const REC_ADD_SPECIALITY = 'REC_ADD_SPECIALITY'

export const REQ_GET_SPECIALITIES = 'REQ_GET_SPECIALITIES'
export const REC_GET_SPECIALITIES = 'REC_GET_SPECIALITIES'

export const REQ_DELETE_SPECIALITY = 'REQ_DELETE_SPECIALITY'
export const REC_DELETE_SPECIALITY = 'REC_DELETE_SPECIALITY'

export const REQ_UPDATE_SPECIALITY = 'REQ_UPDATE_SPECIALITY'
export const REC_UPDATE_SPECIALITY = 'REC_UPDATE_SPECIALITY'

export const REQ_SEARCH_SPECIALITIES=  'REQ_SEARCH_SPECIALITIES'
export const REC_SEARCH_SPECIALITIES=  'REC_SEARCH_SPECIALITIES'


export const reqAddSpeciality = (config) => ({type: REQ_ADD_SPECIALITY, config: config})
export const recAddSpeciality = data => ({type: REC_ADD_SPECIALITY, data})

export const reqGetSpecialities = (config) => ({type: REQ_GET_SPECIALITIES, config: config})
export const recGetSpecialities = data => ({type: REC_GET_SPECIALITIES, data})

export const reqDeleteSpeciality = (config) => ({type: REQ_DELETE_SPECIALITY, config: config})
export const recDeleteSpeciality = data => ({type: REC_DELETE_SPECIALITY, data})

export const reqUpdateSpeciality = (config) => ({type: REQ_UPDATE_SPECIALITY, config: config})
export const recUpdateSpeciality = data => ({type: REC_UPDATE_SPECIALITY, data})

export const reqSearchSpecialities = (config) => ({type: REQ_SEARCH_SPECIALITIES, config: config})
export const recSearchSpecialities = data => ({type: REC_SEARCH_SPECIALITIES, data})
 