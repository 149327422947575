export const REQ_GET_ALL_ANALITICS = 'REQ_GET_ALL_ANALITICS'
export const REC_GET_ALL_ANALITICS = 'REC_GET_ALL_ANALITICS'

export const REQ_GET_ALL_ANALITIC_POINTS = 'REQ_GET_ALL_ANALITIC_POINTS'
export const REC_GET_ALL_ANALITIC_POINTS = 'REC_GET_ALL_ANALITIC_POINTS'

export const reqGetAllAnalitics = (config) => ({type: REQ_GET_ALL_ANALITICS, config: config})
export const recGetAllAnalitics = data => ({type: REC_GET_ALL_ANALITICS, data})

export const reqGetAllAnaliticPoints = (config) => ({type: REQ_GET_ALL_ANALITIC_POINTS, config: config})
export const recGetAllAnaliticPoints = data => ({type: REC_GET_ALL_ANALITIC_POINTS, data})


