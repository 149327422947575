import React from 'react';

const Pagination = ({ articlesPerPage, totalArticles, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalArticles / articlesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className='pagination'>
        {currentPage === 1 ? "" :( 
        <li className='page-item'>
          <div className='page-link' onClick={() => paginate("prev")}>
            Previous
          </div>
        </li>) }
        {pageNumbers.map(number => (
          <li key={number} className="page-item">
            <div onClick={() => paginate(number)} className={`page-link ${currentPage === number ? "current-page" : "" }`}>
              {number}
            </div>
          </li>
        ))}
        {currentPage ===pageNumbers.length ? "":
        <li className='page-item'>
          <div className='page-link' onClick={() => paginate("next")}>
            Next
          </div>
        </li>}
      </ul>
    </nav>
  );
};

export default Pagination;