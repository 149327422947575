import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_UPDATE_THERAPIST_PROFILE, REQ_UPDATE_THERAPIST_PASSWORD, REQ_EMAIL_CONFIRMED, 
  recEmailConfirmed, recUpdateTherapistProfile, recUpdateTherapistPassword} from './actions'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'
import fetchData from '../../../utils/api'
import mergeInUserDetails from '../../../utils/mergeInUserDetails'

export const userDetails = (state) => state.userDetails

function* updateProfile(action) {
  
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recUpdateTherapistProfile(data))
    
    if (data.success) {
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, action.config.body)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
   
  } catch (e) {
      console.log("Api error: ", e)
   }

}

function* updatePassword(action) {
  
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recUpdateTherapistPassword(data))   
  } catch (e) {
      console.log("Api error: ", e)
   }

}

function* confirmEmail(action) {
  
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'get')
    yield put(recEmailConfirmed(data))

  } catch (e) {
      console.log("Api error: ", e)
   }

}

export default function* therapistUpdateProfile() {
    yield takeLatest(REQ_UPDATE_THERAPIST_PROFILE, updateProfile);
    yield takeLatest(REQ_UPDATE_THERAPIST_PASSWORD, updatePassword);
    yield takeLatest(REQ_EMAIL_CONFIRMED, confirmEmail);
};