import React from 'react'
import logoTextBlue from '../../../static/images/desktop/logo-text-blue.png'
import peopleGroup from '../../../static/images/desktop/people-group.png'
import leftEllipse from '../../../static/images/desktop/signup-left-ellipse.png'
import rightEllipse from '../../../static/images/desktop/signup-right-ellipse.png';
import bottomShape from '../../../static/images/desktop/signup-bottom-shape.png';
import { Popup } from "semantic-ui-react";
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reqSignupTherapist } from './actions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Redirect } from 'react-router-dom';
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import './Signup.scss'

const SUCCESS_MESSAGE = "Thank you for signing up to Autism Assistant! We have sent a confirmation link to your email address.\n" +
    "\n"+
    "Please click on that link in order to complete the sign-up process.",
      BLOGGER = 'blogger',
      PROVIDER = 'provider';

class Signup extends React.Component {

    state = {
        firstName: '',
        lastName: '',
        businessWebsite: '',
        businessName: '',
        userName:'',
        password: '',
        email: '',
        agreeTC: false,
        confirmPassword: '',
        errors: [],
        redirect: false,
        redirectUrl: '',
        roleID: 3,
        roleOptionsOpen: false,
        signupForm: PROVIDER,
        pageLoading: false,
        selectedRole: 3
    };

    // roleOptions = [
    //     { label: 'Therapist/Company', value: 3 },
    //     { label: 'Blogger', value: 4 }
    // ];

    submitUrl = 'register';

    // componentDidMount = () => {
    //     const user = this.props.userDetails;

    //     if (user.roleId === 3) {
    //         this.setState(() => ({ redirectUrl: user.membershipPlan === null ? '/payment-first' : '/profile' }))
    //         this.setState(() => ({ redirect: true }))
    //         localStorage.setItem('signedUp', 'true');
    //     } else if (user.roleId === 4) {
    //         this.setState(() => ({ redirectUrl: '/blogger-profile' }))
    //         this.setState(() => ({ redirect: true }))
    //         localStorage.setItem('signedUp', 'true');
    //     } else if (user.roleId === 1) {
    //         this.setState(() => ({ redirectUrl: '/admin' }))
    //         this.setState(() => ({ redirect: true }))
    //         localStorage.setItem('signedUp', 'true');
    //     } else if (user.roleId === 2) {
    //         this.setState(() => ({ redirectUrl: '/admin' }))
    //         this.setState(() => ({ redirect: true }))
    //         localStorage.setItem('signedUp', 'true');
    //     }

    // };

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData,
            err = apiData.error ? apiData.display ? apiData.error : 'An error occured' : '',
            redirect = apiData.user ? apiData.user.token || false : false;

        // console.log("props in signup: ", newProps)

        this.setState(() => ({
            errorMessage: err,
            redirect: redirect,
            pageLoading: false
        }))

        if (apiData.user) {
            if (apiData.user.roleId === 3) {
                this.setState(() => ({ redirectUrl: apiData.user.membershipPlan === null ? '/payment-first' : '/profile' }))
                this.setState(() => ({ redirect: true , pageLoading: false}))
                localStorage.setItem('signedUp', 'true');
            } else {
                this.props.history.push({
                    pathname: '/thank-you',
                    state: {
                        message: SUCCESS_MESSAGE,
                        redirectUrl: this.state.redirectUrl
                    }
                });
            }
        }

    };

    handleChange = (event) => {

        let inputName = event.target.name,
            value = event.target.value

        this.setState({errorMessage:'', errors: []})

        this.setState( { [inputName]: value })
    };

    checkboxAgreeTC = () => {
        this.state.agreeTC ? this.setState({errorMessage:'', agreeTC : false}) : this.setState({errorMessage:'', agreeTC : true})
    }

    handleSubmit = async () => {

        this.setState({ errors: [], pageLoading: true }, async () => {

            const formValid = this.validateForm();

            if (!formValid) {
                this.setState({ pageLoading: false })
                return;
            }

            const rBody = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                userName: this.state.userName,
                businessName: this.state.businessName,
                businessWebsite: this.state.businessWebsite,
                password: this.state.password,
                roleId: this.state.selectedRole,
                redirectUrl: this.state.redirectUrl,
            };

            this.props.reqSignupTherapist(rBody, this.submitUrl);
        })

    }

    validateForm = () => {

        let formValid = true;

        if (this.state.firstName === '' && this.state.signupForm === BLOGGER) {
            formValid = false;
            this.setState(() => ({
                errors: [
                    { input: 'firstName' }
                ],
                errorMessage: 'First name is mandatory'
            }))
        } else if (this.state.lastName === '' && this.state.signupForm === BLOGGER) {
            formValid = false;
            this.setState(() => ({
                errors: [
                    { input: 'lastName' }
                ],
                errorMessage: 'Last name is mandatory'
            }))
        } else if (this.state.userName === '') {
            formValid = false;
            this.setState(() => ({
                errors: [
                    { input: 'userName' }
                ],
                errorMessage: 'User name is mandatory'
            }))
        } else if (this.state.email === '') {
            formValid = false;
            this.setState(() => ({
                errors: [
                    { input: 'email' }
                ],
                errorMessage: 'Email is mandatory'
            }))
        } else if (this.state.businessName === '' && this.state.signupForm === PROVIDER) {
            formValid = false;
            this.setState(() => ({
                errors: [
                    { input: 'businessName' }
                ],
                errorMessage: 'Business Name is mandatory'
            }))
        }else if (this.state.password === '') {
            formValid = false;
            this.setState(() => ({
                errors: [
                    { input: 'password' }
                ],
                errorMessage: 'Password is mandatory'
            }))
        } else if (this.state.confirmPassword === '') {
            formValid = false;
            this.setState(() => ({
                errors: [
                    { input: 'confirmPassword' }
                ],
                errorMessage: 'Confirm Password is mandatory'
            }))
        } else if (this.state.password !== this.state.confirmPassword) {
            formValid = false;
            this.setState(() => ({
                errors: [
                    {
                        input: 'password',
                    },
                    {
                        input: 'confirmPassword'
                    }
                ],
                errorMessage: 'Password and Confirm Password do not match'
            }))
        } else if (!this.state.agreeTC) {
            formValid = false;
            this.setState(() => ({errors: [
                { input: 'agreeTC' }
            ],errorMessage: 'Please agree to Terms and Conditions first'}))
        }

        return formValid

    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)

        return errors.length ? 'input-error' : ''
    }

    displayErrorMessage = () => {
        const errors = this.state.errors;

        return errors.length ? errors[0].message : ''
    }

    displayApiError = () => {
        let error = '';
        const apiData = this.props.apiData;

        if (!apiData) return '';

        error = apiData.error ? apiData.display ? apiData.error : 'An error occured' : '';

        return error;
    }

    handleRoleChange = (ev) => {
        let roleId = ev.target.value,
            signupForm = ''

        if ( roleId === '3' ) {
            signupForm = PROVIDER
        } else if ( roleId === '4' ) {
            signupForm = BLOGGER
        }

        this.setState({
            selectedRole: roleId,
            signupForm:  signupForm
        })

        document.getElementById("signup-form").reset();
    }

    // getRedirectRoute = () => {
    //     const loggedIn = localStorage.getItem('loggedIn');
    //     return loggedIn ? this.state.redirectUrl : '/thank-you';
    // };

    providerForm = () => {
        return (
        <div>
            {/* business name & email */}
            <div className="inputs-row">
            <input type="text"
                       placeholder="Business Name*"
                       name="businessName"
                       onChange={this.handleChange}
                       className={`basic-input ${this.inputHasErrors("businessName")}`}/>

            <input type="text"
                       placeholder="Business Email*"
                       name="email"
                       onChange={this.handleChange}
                       className={`basic-input ${this.inputHasErrors("email")}`}/>
            </div>
            {/* User name & website */}
            <div className="inputs-row">
            <Popup
                content="This will be the ID you log in with. It is not publicly visible."
                on="click"
                pinned
                trigger={
                    <input type="text"
                        placeholder="User name*"
                        name="userName"
                        onChange={this.handleChange}
                        className={`basic-input ${this.inputHasErrors("userName")}`}/>
                }
            />
            <input type="text"
                    placeholder="Business Website"
                    name="businessWebsite"
                    onChange={this.handleChange}
                    className="basic-input"/>
            </div>
        </div>
        )
    }

    bloggerForm = () => {
        return (
            <div>
            {/* First name & Last name */}
            <div className="inputs-row">
            <input type="text"
                       placeholder="First Name*"
                       name="firstName"
                       onChange={this.handleChange}
                       className={`basic-input ${this.inputHasErrors("firstName")}`}/>

            <input type="text"
                       placeholder="Last Name*"
                       name="lastName"
                       onChange={this.handleChange}
                       className={`basic-input ${this.inputHasErrors("lastName")}`}/>
            </div>
            {/* User name & email */}
            <div className="inputs-row">
            <Popup
                content="This will be the ID you log in with. It is not publicly visible."
                on="click"
                pinned
                trigger={
                    <input type="text"
                        placeholder="User name*"
                        name="userName"
                        onChange={this.handleChange}
                        className={`basic-input ${this.inputHasErrors("userName")}`}/>
                }
            />
            <input type="text"
                    placeholder="Email*"
                    name="email"
                    // value={this.state.email}
                    onChange={this.handleChange}
                    className={`basic-input ${this.inputHasErrors("email")}`}/>
            </div>
            </div>
        )
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: this.state.redirectUrl,
                        state: {
                            message: SUCCESS_MESSAGE,
                            redirectUrl: this.state.redirectUrl
                        }
                    }}
                />
            )
        }

        return (
            <div id="signup">
                <PopupLoader display={this.state.pageLoading} />

                <div className="page-background">
                    <img src={leftEllipse} className="bck-left-ellipse" alt="leftEllipse"/>
                    <img src={rightEllipse} className="bck-right-ellipse" alt="roghtEllipse"/>
                    <img src={bottomShape} className="bck-bottom-shape" alt="bottomShape"/>
                </div>

                <div className="page-content">
                    {/* Leftside Content */}
                    <div className="leftside-panel half-panel">
                        <Link to="/">
                            <img src={logoTextBlue} className="logo" alt="logo"/>
                        </Link>
                        <img src={peopleGroup} className="people-group" alt="people"/>
                    </div>
                    {/* Rightside Content */}
                    <div className="rightside-panel half-panel">
                        <h1 className="page-title">Welcome to Autism Assistant</h1>
                        <p className="basic-paragraph">Sign up for an account and create your profile so your potential
                            clients can find you on our site.</p>

                        <div className="inputs-row role-options-row">
                            <div className="role-options-container">
                                <select className="role-options-select" onChange={this.handleRoleChange}>
                                    <option value="3">Provider/Business</option>
                                    <option value="4">Blogger</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-container">
                            <form id="signup-form">

                                {this.state.signupForm === PROVIDER ? this.providerForm() : this.bloggerForm()}
                                {/* Password & Confirm Password */}
                                <div className="inputs-row">
                                    <input type="password"
                                           placeholder="Password*"
                                           name="password"
                                           onChange={this.handleChange}
                                           className={`basic-input ${this.inputHasErrors("password")}`}/>
                                    <input type="password"
                                           placeholder="Confirm Password*"
                                           name="confirmPassword"
                                           onChange={this.handleChange}
                                           className={`basic-input ${this.inputHasErrors("confirmPassword")}`}/>
                                </div>
                                <input type="checkbox"
                                       onChange={this.checkboxAgreeTC}
                                       className={`sign-in-checkbox ${this.inputHasErrors("confirmPassword")}`}
                                       name="agreeTC"/>
                                <span className="grey-text">I agree to the <Link to="/terms-and-conditions">Terms and Conditions</Link></span>

                                {/* User Role */}

                            </form>
                            <div>
                                <p className="error-message">{this.state.errorMessage}</p>
                                {/* <p className="error-message">{this.displayApiError()}</p> */}
                            </div>
                            <div className="signup-row">
                                <ButtonRoundGradient text="Sign Up" onClick={this.handleSubmit}/>
                            </div>
                            <div className="login-row">
                                <p className="classic-text">Already have an account?</p>
                                <Link to="/signin">
                                    <p className="link-text">Log In.</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    apiData: state.signup,
    userDetails: state.userDetails
});

const mapDispatchToProps = dispatch => bindActionCreators({ reqSignupTherapist }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
