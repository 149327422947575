import React from 'react'

import './ButtonRoundGradientWicon.scss'

const ButtonRoundGradientWicon = props => (
    <button type="button" 
            className={`btn btn-default search-field--button ${props.otherClass}`}
            onClick={props.onClick}>
        <span>{props.text}</span>
        <img src={props.icon} width="28" heigh="28" alt="alt"/>
    </button>
);

export default ButtonRoundGradientWicon