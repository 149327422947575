export const REQ_GET_SPECIALITIES_HOME = 'REQ_GET_SPECIALITIES_HOME'
export const REC_GET_SPECIALITIES_HOME = 'REC_GET_SPECIALITIES_HOME'

export const REQ_LOG_PAGE_VISIT_HOME = 'REQ_LOG_PAGE_VISIT_HOME'
export const REC_LOG_PAGE_VISIT_HOME = 'REC_LOG_PAGE_VISIT_HOME'

export const REQ_GET_FEATURED_ARTCILE = 'REQ_GET_FEATURED_ARTCILE'
export const REC_GET_FEATURED_ARTCILE = 'REC_GET_FEATURED_ARTCILE'

export const REQ_GET_FEATURED_PRODUCTS = 'REQ_GET_FEATURED_PRODUCTS'
export const REC_GET_FEATURED_PRODUCTS = 'REC_GET_FEATURED_PRODUCTS'

export const REQ_GET_FEATURED_BUSINESSES = 'REQ_GET_FEATURED_BUSINESSES'
export const REC_GET_FEATURED_BUSINESSES = 'REC_GET_FEATURED_BUSINESSES'

export const REQ_GET_FEATURED_PARTNERS = 'REQ_GET_FEATURED_PARTNERS'
export const REC_GET_FEATURED_PARTNERS = 'REC_GET_FEATURED_PARTNERS'

export const reqGetSpecialitiesForHome = (config) => ({type: REQ_GET_SPECIALITIES_HOME, config: config})
export const recGetSpecialitiesForHome = data => ({type: REC_GET_SPECIALITIES_HOME, data})

export const reqLogPageVisitHome = (config) => ({type: REQ_LOG_PAGE_VISIT_HOME, config: config})
export const recLogPageVisitHome = data => ({type: REC_LOG_PAGE_VISIT_HOME, data})

export const reqGetFeaturedArticle = (config) => ({type: REQ_GET_FEATURED_ARTCILE, config: config})
export const recGetFeaturedArticle = data => ({type: REC_GET_FEATURED_ARTCILE, data})

export const reqGetFeaturedProducts = (config) => ({type: REQ_GET_FEATURED_PRODUCTS, config: config})
export const recGetFeaturedProducts = data => ({type: REC_GET_FEATURED_PRODUCTS, data})

export const reqGetFeaturedBusinesses = (config) => ({type: REQ_GET_FEATURED_BUSINESSES, config: config})
export const recGetFeaturedBusinesses = data => ({type: REC_GET_FEATURED_BUSINESSES, data})

export const reqGetFeaturedPartners = (config) => ({type: REQ_GET_FEATURED_PARTNERS, config: config})
export const recGetFeaturedPartners = data => ({type: REC_GET_FEATURED_PARTNERS, data})
