import { REC_GET_ARTICLES , REC_SEARCH_ARTICLES_GUEST} from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        
        case REC_GET_ARTICLES:
            return {
                articles: data
            };

        case REC_SEARCH_ARTICLES_GUEST:
            return {
                articleSearch: data
            };

        default:
            return state
    }
}