import React from "react";
import HeaderUser from "../../../components/HeaderUser/HeaderUser";
import SidebarAdmin from "../../../components/SidebarAdmin/SidebarAdmin";
import "./Profile.scss";
import ButtonRoundGradient from "../../../components/ButtonRoundGradient/ButtonRoundGradient";
import { reqUpdateProfile, reqUpdateAdminPassword } from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MainWrapper from "../MainWrapper/MainWrapper";
import uploadImage from "../../../utils/uploadImage";
import { Popup } from "semantic-ui-react";

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class AdminProfile extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    phone: "",
    bio: "",
    errors: [],
    activeTabItem: "account",
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  admin = {};
  authHeaders = {};
  submitUrl = "user/profile/update";
  uploadProfileImgUrl = "user/profile/update/image";
  updatePasswordUrl = "user/profile/updatePassword";

  componentDidMount = () => {
    this.admin = this.props.userDetails;

    // console.log("profi le user details: " , this.props)
    this.authHeaders = {
      Authorization: `Bearer ${this.admin.token}`,
      "Content-type": "application/json"
    };

    const profileState = {
      firstName: this.admin.firstName,
      lastName: this.admin.lastName,
      email: this.admin.email,
      title: this.admin.title || "",
      phone: this.admin.phone || "",
      bio: this.admin.bio || "",
      profileImageName: this.admin.profileImageName
    };

    this.setState(() => profileState);
  };

  componentWillReceiveProps = newProps => {
    //console.log("newProps: ", newProps);
    let apiData = newProps.apiData;

    if (
      apiData.passwordUpdated &&
      apiData.passwordUpdated.error &&
      apiData.passwordUpdated.display
    ) {
      this.setState(() => ({
        errors: [{ input: "currentPassword" }],
        errorMessage: apiData.passwordUpdated.error,
        pageLoading: false
      }));
    } else if (apiData.passwordUpdated && apiData.passwordUpdated.success) {
      this.setState(() => ({
        pageLoading: false,
        succesMessage: "Password Succesfully Updated"
      }));
    }
  };

  handleChange = event => {
    let inputName = event.target.name,
      value = event.target.value;

    this.setState(() => ({ [inputName]: value }));
  };

  inputHasErrors = inputName => {
    const errors = this.state.errors.filter(err => err.input === inputName);
    return errors.length ? "input-error" : "";
  };

  handleSubmit = () => {
    this.setState(
      {
        errors: [],
        errorMessage: ""
      },
      () => {
        if (!this.validateForm()) return;

        if (!this.state.profileImgFile) {
          this.updateProfileData();
          return;
        }

        this.uploadProfilePhoto().then(res => {
          let { url: fileName } = res.data;
          this.updateProfileData(fileName);
        });
      }
    );
  };

  handleUpdatePassword = () => {
    // console.log("Updating Password");
    this.setState(
      {
        errors: [],
        errorMessage: "",
        pageLoading: true
      },
      () => {
        if (!this.validatePasswordForm()) return;

        this.props.actions.reqUpdateAdminPassword({
          body: {
            oldPassword: this.state.currentPassword,
            newPassword: this.state.newPassword
          },
          headers: this.authHeaders,
          resource: this.updatePasswordUrl
        });
      }
    );
  };

  updateProfileData = (profileImageName = null) => {
    const rConfig = {
      body: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        bio: this.state.bio,
        title: this.state.title,
        profileImageName: profileImageName
      },
      headers: this.authHeaders,
      resource: this.submitUrl,
      userDetails: this.props.userDetails
    };

    this.props.actions.reqUpdateProfile(rConfig);
  };

  uploadProfilePhoto = () => {
    let profileImg = this.state.profileImgFile,
      data = new FormData();
    
    data.append("profileImage", profileImg);
    //console.log("This admin: ", this.admin);
    let authConfig = {
      headers: {
        Authorization: `Bearer ${this.admin.token}`
      }
    };

    return uploadImage(this.uploadProfileImgUrl, data, authConfig);
  };

  validateForm = () => {
    let formValid = true;

    if (this.state.email === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "email" }],
        errorMessage: "Email is mandatory"
      }));
    } else if (this.state.firstName === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "firstName" }],
        errorMessage: "First Name is mandatory"
      }));
    } else if (this.state.lastName === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "lastName" }],
        errorMessage: "Last Name is mandatory"
      }));
    } else if (this.state.title === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "title" }],
        errorMessage: "Title is mandatory"
      }));
    } else if (this.state.phone === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "phone" }],
        errorMessage: "Phone is mandatory"
      }));
    }

    return formValid;
  };

  validatePasswordForm = () => {
    let formValid = true;

    if (this.state.currentPassword === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "currentPassword" }],
        errorMessage: "Current Password is mandatory",
        pageLoading: false
      }));
    } else if (this.state.newPassword === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "newPassword" }],
        errorMessage: "New Password is mandatory",
        pageLoading: false
      }));
    } else if (this.state.confirmPassword === "") {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "confirmPassword" }],
        errorMessage: "Confirm Password is mandatory",
        pageLoading: false
      }));
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      formValid = false;
      this.setState(() => ({
        errors: [{ input: "newPassword" }, { input: "confirmPassword" }],
        errorMessage: "New Password and Confirm Password do not match",
        pageLoading: false
      }));
    }

    return formValid;
  };

  handleChooseProfileImg = ev => {
    let imageFile = ev.target.files[0];
    let previewFile = URL.createObjectURL(imageFile);

    this.setState(() => ({
      profileImgFile: imageFile,
      profileImgPreview: previewFile
    }));
  };

  tabItemActive = tabName => tabName === this.state.activeTabItem ? "" : "inactive-tab";

  switchTab = tabName => {
    this.setState(() => ({
      activeTabItem: tabName
    }));
  };

  renderTabContent = () =>
    this.state.activeTabItem === "account"
      ? this.renderAccountTab()
      : this.renderPasswordTab();

  renderAccountTab = () => (
    <div className="second-row">
      {/* Leftside Content */}
      <div className="leftside-content">
        {/* Avatar */}
        {this.renderAvatar()}
        {/* Hidden upload input */}
        <input
          id="profilePhotoInput"
          type="file"
          ref={ref => (this.selectProfilePhoto = ref)}
          style={{ display: "none" }}
          onChange={this.handleChooseProfileImg}
        />
      </div>
      {/* RightSide Content */}
      <div className="rightside-content">
        <div className="form-container">
          {/* Profile Form */}
          <form>
            <div className="input-row">
              <input
                type="text"
                name="firstName"
                className={`basic-input ${this.inputHasErrors("firstName")}`}
                placeholder="First Name"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
            </div>
            <div className="input-row">
              <input
                type="text"
                name="lastName"
                className={`basic-input ${this.inputHasErrors("lastName")}`}
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
            </div>
            <div className="input-row">
              <input
                type="text"
                name="email"
                className={`basic-input ${this.inputHasErrors("email")}`}
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>
            <div className="input-row">
              <input
                type="text"
                name="title"
                className={`basic-input ${this.inputHasErrors("title")}`}
                placeholder="Title"
                value={this.state.title}
                onChange={this.handleChange}
              />
            </div>
            <div className="input-row">
              <input
                type="text"
                name="phone"
                className={`basic-input ${this.inputHasErrors("phone")}`}
                placeholder="Phone"
                onChange={this.handleChange}
                value={this.state.phone}
              />
            </div>

            {/* Bio */}
            <div className="input-row textarea-row">
              <textarea
                name="bio"
                className={`basic-input`}
                placeholder="Bio"
                value={this.state.bio}
                onChange={this.handleChange}
              />
            </div>

            <div>
              <p className="error-message">{this.state.errorMessage}</p>
            </div>

            <div className="submit-row">
            <Popup
                content="The changes to your account information have been saved."
                on="click"
                pinned
                trigger={
                  <ButtonRoundGradient
                    text="Save Changes"
                    otherClass="submit-btn-medium"
                    onClick={this.handleSubmit}
                  />
                }
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  renderPasswordTab = () => (
    <div className="password-tab">
      <div className="password-form-container">
        {/* Current Password */}
        <div className="input-row">
          <input
            type="password"
            name="currentPassword"
            className={`basic-input ${this.inputHasErrors("currentPassword")}`}
            placeholder="Current Password"
            value={this.state.currentPassword}
            onChange={this.handleChange}
          />
        </div>
        {/* New Password */}
        <div className="input-row">
          <input
            type="password"
            name="newPassword"
            className={`basic-input ${this.inputHasErrors("newPassword")}`}
            placeholder="New Password"
            value={this.state.newPassword}
            onChange={this.handleChange}
          />
        </div>
        {/* Confirm Password */}
        <div className="input-row">
          <input
            type="password"
            name="confirmPassword"
            className={`basic-input ${this.inputHasErrors("confirmPassword")}`}
            placeholder="Confirm Password"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
          />
        </div>

        <div>
          <p className="error-message">{this.state.errorMessage}</p>
          <p className="success-message">{this.state.succesMessage}</p>
        </div>

        <div className="submit-row">
          <ButtonRoundGradient
            text="Update Password"
            otherClass="submit-btn-medium"
            onClick={this.handleUpdatePassword}
          />
        </div>
      </div>
    </div>
  );

  renderAvatar = () => {
    let imageContent = (
      <div className="profile-img-sub-container">
        <div className="add-photo-btn">+</div>
        <div className="add-photo-text-container">
          <span className="white-text">Add Photo</span>
        </div>
      </div>
    );

    if (this.state.profileImgPreview) {
      imageContent = (
        <div className="avatar-sub-container">
          <img src={this.state.profileImgPreview} alt="profileImg" />
          <div className="avatar-cover">CHANGE</div>
        </div>
      );
    } else if (this.state.profileImageName) {
      imageContent = (
        <div className="avatar-sub-container">
          <img
            src={`${baseBackendUrl}/${this.state.profileImageName}`}
            alt="profileImg"
          />
          <div className="avatar-cover">CHANGE</div>
        </div>
      );
    }
    //console.log("This state: ", this.state);
    return (
      <div
        className="avatar-container"
        onClick={e => this.selectProfilePhoto.click()}
      >
        {imageContent}
      </div>
    );
  };

  render() {
    return (
      <MainWrapper>
        <div id="admin-profile">
          <HeaderUser logoutRedirect="/admin/signin" userRole="admin" />
          <div className="page-background">
            {/* Page Content */}
            <div className="page-content">
              {/* Sidebar */}
              <SidebarAdmin page="profile" />
              {/* Inner Content */}
              <div className="inner-content-container">
                <div className="inner-content">
                  <div className="top-row profile-tabs">
                    {/* Title */}
                    <h1
                      className={`page-title tab-item ${this.tabItemActive(
                        "account"
                      )}`}
                      onClick={this.switchTab.bind(this, "account")}
                    >
                      Account Information
                    </h1>
                    <h1 className="page-title tabs-bar">|</h1>
                    <h1
                      className={`page-title tab-item ${this.tabItemActive(
                        "password"
                      )}`}
                      onClick={this.switchTab.bind(this, "password")}
                    >
                      Password
                    </h1>
                  </div>

                  {this.renderTabContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }
}

const mapStateToProps = state => ({
  apiData: state.adminUpdateProfile,
  userDetails: state.userDetails
});
// reqUpdateAdminPassword
// const mapDispatchToProps = dispatch => bindActionCreators({reqUpdateProfile}, dispatch)

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqUpdateProfile: bindActionCreators(reqUpdateProfile, dispatch),
      // reqFetchUserDetails: bindActionCreators(reqFetchUserDetails, dispatch),
      reqUpdateAdminPassword: bindActionCreators(
        reqUpdateAdminPassword,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);
