import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_MESSAGE_CONTACT, recMessageContact} from './actions'
import fetchData from '../../utils/api'


export const userDetails = (state) => state.userDetails

function* sendMessage(action) {

  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recMessageContact(data))
  } catch (e) {
      console.log("Api error: ", e)
   }

}


export default function* contactSagas() {
    yield takeLatest(REQ_MESSAGE_CONTACT, sendMessage);
};
