import { REC_FETCH_USER_DETAILS } from './actions';
// import store from '../../store'

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_FETCH_USER_DETAILS:
            return data.user || {};
        default:
            return state
    }
}