import React from "react";
import HeaderUser from "../../../components/HeaderUser/HeaderUser";
import SidebarTherapist from "../../../components/SidebarTherapist/SidebarTherapist";
import "./Payment.scss";
import "../../../components/RoundInputGradient/RoundInputGradient";
import MainWrapper from "../MainWrapper/MainWrapper";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";
import {
  reqListMembershipPlans,
  reqSubscribeUserToPlan,
  reqCustomerSwitchPlan,
  reqCancelCustomerSubscription,
  reqBuyExtraBenefits,
} from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PlanCard from "./PlanCard";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import PopupConfirm from "../../../components/PopupConfirm/PopupConfirm";
import MenuStepNavigation from "../../../components/MenuStepNavigation/MenuStepNavigation";
import scrollToComponent from "react-scroll-to-component";
import NumericInputWithButtons from "../../../components/NumericInputWithButtons/NumericInputWithButtons";

const stripePk = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

class Payment extends React.Component {
  therapist = {};
  authHeader = {};
  loadPlansUrl = "payment/plans/list";
  subscribeToPlanUrl = "customer/membership/register";
  switchPlanUrl = "customer/membership/change";
  cancelSubscriptionUrl = "customer/membership/cancel";
  buyExtraBenefitsUrl = "customer/membership/extra/buy"

  state = {
    plans: [],
    pageLoading: false,
    extraBenefitsNumber: 0,
    totalCost: 0,
    therapistMembershipPlan: ''
  };

  componentDidMount = () => {
    this.therapist = this.props.userDetails;
    this.authHeader = {
      Authorization: `Bearer ${this.therapist.token}`,
      "Content-type": "application/json",
    };

    this.setState(() => ({
      therapistMembershipPlan: this.therapist.membershipPlan,
    }));
    // console.log("therapist in didmount: ", this.therapist);

    if (this.props.userDetails.cardData) {
      this.setState(() => ({
        userHasCard: true,
      }));
    }
    this.loadMemberShipPlans();
  };

  componentWillReceiveProps = (newProps) => {
    let apiData = newProps.apiData;

    if (apiData.membershipPlans && apiData.membershipPlans.success) {
      // let userPlan = this.props.userDetails.planData;
      // userPlanId = userPlan ? userPlan.id : null

      let plans = apiData.membershipPlans.plans.map((p) => ({
        id: p.id,
        name: p.nickname,
        price: p.amount / 100,
        benefits: p.metadata.Benefits.split("#"),
      }));

      let freePlan = {
        id: 0,
        name: "Free Plan",
        price: 0,
        benefits: [
          "Picture, Bio",
          "Specialities, Location",
          "Contact form without public contact information",
        ],
      };

      plans.push(freePlan);

      this.setState(() => ({
        plans: plans,
      }));
    } else if (apiData.switchPlan && apiData.switchPlan.success) {
      this.setState(() => ({
        pageLoading: false,
        therapistMembershipPlan: apiData.switchPlan.membershipPlan,
        extraBenefitsNumber: 0,
        totalCost: 0
      }));
    } else if (apiData.switchPlan && apiData.switchPlan.error) {
      this.setState(() => ({
        pageLoading: false,
        errorMessage: apiData.switchPlan.message,
      }));
      this.stripeErrorHandler(apiData.switchPlan.message);
    } else if (apiData.cancelPlan && apiData.cancelPlan.success) {
      this.setState(() => ({
        pageLoading: false,
        therapistMembershipPlan: 'free',
        extraBenefitsNumber: 0,
        totalCost: 0
      }));
    } else if (apiData.subscribeToPlan && apiData.subscribeToPlan.success) {
      this.setState(() => ({
        pageLoading: false,
        therapistMembershipPlan: apiData.subscribeToPlan.membershipPlan,
        extraBenefitsNumber: 0,
        totalCost: 0
      }));
    } else if (apiData.subscribeToPlan && apiData.subscribeToPlan.error) {
      this.setState(() => ({
        pageLoading: false,
        errorMessage: apiData.subscribeToPlan.message,
      }));
      this.stripeErrorHandler(apiData.subscribeToPlan.message);
    } else if (apiData.buyExtraBenefits && apiData.buyExtraBenefits.success) {
      this.setState(() => ({
        pageLoading: false,
        extraBenefitsNumber: 0,
        totalCost: 0
      }));
    } else if (apiData.buyExtraBenefits && apiData.buyExtraBenefits.error) {
      this.setState(() => ({
        pageLoading: false,
        errorMessage: apiData.buyExtraBenefits.message,
      }));
      this.stripeErrorHandler(apiData.buyExtraBenefits.message);
    }
  };

  stripeErrorHandler = (errorMessage) => {
    let popupTitle = "Payment error",
      popupText = errorMessage,
      confirmText = "Ok",
      cancelText = " ";

    this.setState(() => ({
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.hidePopup,
      confirmText: confirmText,
      cancelText: cancelText,
    }));
  };

  loadMemberShipPlans = () => {
    let rConfig = {
      body: {},
      headers: this.authHeader,
      resource: this.loadPlansUrl,
    };

    this.props.actions.reqListMembershipPlans(rConfig);
  };

  hidePopup = () => {
    this.setState(() => ({
      showPopup: false,
    }));
    var el = this.refs.wrap;
    if (!this.therapist.cardData) {
      scrollToComponent(el, { duration: 10 });
    }
  };

  handleChoosePlan = (planId) => {
    if (!this.props.userDetails.cardData) {
      this.displayNoCardPopup();
      return;
    }

    let popupTitle =
      planId === 0 ? "Cancel Subscription?" : "Subscribe To Plan?";

    let popupText =
      planId === 0
        ? "Confirm cancelling current subscription?"
        : "Confirm Subscribing to this plan?";

    this.setState(() => ({
      choosenPlan: planId,
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.confirmChoosePlan,
      confirmText: null,
      cancelText: null,
    }));
  };

  displayNoCardPopup = () => {
    let popupTitle = "No Card Saved",
      popupText =
        "Please add your card information before subscribing to a plan.",
      confirmText = "Ok",
      cancelText = " ";

    this.setState(() => ({
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.hidePopup,
      confirmText: confirmText,
      cancelText: cancelText,
    }));
  };

  confirmChoosePlan = () => {
    if (this.state.choosenPlan !== 0) {
      this.choosePayedPlan();
    } else {
      this.cancelSubscription();
    }
  };

  cancelSubscription = () => {
    const rConfig = {
      body: {},
      headers: this.authHeader,
      resource: this.cancelSubscriptionUrl,
      userDetails: this.props.userDetails,
    };

    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqCancelCustomerSubscription(rConfig);
      }
    );
  };

  choosePayedPlan = () => {
    const rConfig = {
      body: {
        planId: this.state.choosenPlan,
      },
      headers: this.authHeader,
      resource: this.props.userDetails.planData
        ? this.switchPlanUrl
        : this.subscribeToPlanUrl,
      userDetails: this.props.userDetails,
    };

    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.userDetails.planData
          ? this.props.actions.reqCustomerSwitchPlan(rConfig)
          : this.props.actions.reqSubscribeUserToPlan(rConfig);
      }
    );
  };

  buyExtraBenefits = () => {
    const rConfig = {
      body: {totalCost: this.state.totalCost,
              extraBenefitsNumber: this.state.extraBenefitsNumber,
              extraBenefitsName: 'locations'},
      headers: this.authHeader,
      resource: this.buyExtraBenefitsUrl,
      userDetails: this.props.userDetails,
    };

    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqBuyExtraBenefits(rConfig);
      }
    );
  }

  isPlanActive = (p) => {
    //console.log("User details: ",this.props.userDetails)
    let planData = this.props.userDetails.planData,
      planId = planData ? planData.id : null;

    let active = false;

    if ((planId && p.id === planId) || (!planId && p.id === 0)) {
      active = true;
    }

    return active;
  };

  renderPlans = () => {
    let plans = this.state.plans.map((p, idx) => {
      p.active = this.isPlanActive(p);
      return (
        <PlanCard plan={p} handleChoosePlan={this.handleChoosePlan} key={idx} />
      );
    });

    // let freePlan = {
    //   id: 0,
    //   name: 'Free Plan',
    //   price: 0,
    //   benefits: [
    //     'Picture, Bio, Specialities & Contact Info',
    //     'Link to your website',
    //     'Contact form on public profile'
    //   ]
    // }

    // let freePanCard = (
    //   <PlanCard
    //     plan={freePlan}
    //     handleChoosePlan={this.handleChoosePlan}
    //     key='free-plan'
    //   />
    // )

    // plans.push(freePanCard)

    return <div className="plans-container">{plans}</div>;
  };

  handleChange = (event) => {
    let inputName = event.target.name,
        value = event.target.value;

    if (inputName === "-") {
      if (this.state.extraBenefitsNumber <= 0) {
        this.setState(this.calculateTotalCost(), () => ({
          extraBenefitsNumber: this.state.extraBenefitsNumber,
        }));
      } else {
        this.setState(
          { extraBenefitsNumber: this.state.extraBenefitsNumber - 1 },
          () => {
            this.calculateTotalCost();
          }
        );
      }
    } else if (inputName === "+") {
      this.setState(
        { extraBenefitsNumber: this.state.extraBenefitsNumber + 1 },
        () => {
          this.calculateTotalCost();
        }
      );
    } else if (inputName === "quantity") {
      this.setState(
        { extraBenefitsNumber: value },
        () => {
          this.calculateTotalCost();
        }
      );
    }
  };

  calculateTotalCost = () => {

    switch (this.state.therapistMembershipPlan) {
      case "free":
        this.setState({ totalCost: this.state.extraBenefitsNumber * 10 });
        break;

      case "silver":
        this.setState({ totalCost: this.state.extraBenefitsNumber * 10 });
        break;
      case "gold":
        this.setState({ totalCost: this.state.extraBenefitsNumber * 20 });
        break;
      case "platinum":
        this.setState({ totalCost: this.state.extraBenefitsNumber * 30 });
        break;

      default:
        this.setState({ totalCost: 0 });
        break;
    }
  };

  handleExtraBenefitsPay = () => {
    if (this.state.totalCost === 0) {
      
      return;
    }

    let popupTitle = "Buy Extra Benefits",
        popupText = `When you click Ok you will be charged ${this.state.totalCost} $ for ${this.state.extraBenefitsNumber} additional locations`
        

    this.setState(() => ({
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.buyExtraBenefits,
      confirmText: "Ok",
      cancelText: "Cancel",
    }));
  };

  render() {
    return (
      <MainWrapper page="payment">
        <PopupLoader display={this.state.pageLoading} />

        <div id="payment">
          <PopupConfirm
            display={this.state.showPopup}
            text={this.state.popupText}
            title={this.state.popupTitle}
            hidePopup={this.hidePopup}
            calcelAction={this.hidePopup}
            confirmText={this.state.confirmText}
            cancelText={this.state.cancelText}
            confirmAction={this.state.confirmAction}
          />
          <HeaderUser adminFullName="John Doe" />
          <div className="page-background">
            {/* Page Content */}
            <div className="page-content">
              {/* Sidebar */}
              <SidebarTherapist page="payment" roleId={this.therapist.roleId} />
              {/* Inner Content */}
              <div className="inner-content-container">
                <div className="inner-content">
                  <MenuStepNavigation
                    leftLink="/articles"
                    leftText="Your Resources"
                    rightLink="/sponsorship"
                    rightText="Sponsorship Plans"
                  />
                  <div className="main-content">
                    {/* Membership Plans */}
                    <h1 className="title column">Choose Membership Plan</h1>
                    <div className="plans">{this.renderPlans()}</div>
                    
                    {this.therapist.membershipPlan === "free" ? "" : 
                    (<div>
                      <h1 className="page-title">Buy Extra Benefits</h1>
                      <NumericInputWithButtons
                      lable="Aditional locations"
                      handleChange={this.handleChange}
                      extraBenefitsNumber={this.state.extraBenefitsNumber}
                      totalCost={this.state.totalCost}
                      handleExtrasPay={this.handleExtraBenefitsPay}
                    />
                    </div>)
                    }
                    
                    {/* Card Information */}
                    <div className="top-row" ref="wrap">
                      {/* Title */}
                      <h1 className="page-title">
                        Payment Information For Your Services
                      </h1>
                    </div>
                    <div className="stripe-payment-method">
                      <StripeProvider apiKey={stripePk}>
                        <div className="payment-info">
                          <Elements>
                            <CheckoutForm />
                          </Elements>
                        </div>
                      </StripeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.therapistManagePayment,
  userDetails: state.userDetails,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqListMembershipPlans: bindActionCreators(
        reqListMembershipPlans,
        dispatch
      ),
      reqSubscribeUserToPlan: bindActionCreators(
        reqSubscribeUserToPlan,
        dispatch
      ),
      reqCustomerSwitchPlan: bindActionCreators(
        reqCustomerSwitchPlan,
        dispatch
      ),
      reqCancelCustomerSubscription: bindActionCreators(
        reqCancelCustomerSubscription,
        dispatch
      ),
      reqBuyExtraBenefits: bindActionCreators(
        reqBuyExtraBenefits,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);

// export default Payment
