import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class InsuranceItem  extends React.Component {

    state = {
        editing: false,
        name: this.props.speciality.name,
        description: this.props.speciality.description
    }

    enableEditing = () => {
        this.setState( () => ({editing: true}))
    }

    handleChange = (event) => {
        let inputName = event.target.name,
            value = event.target.value
        
        this.setState(() => ({[inputName]: value}))
    }


    fireUpdate = () => {
        this.props.updateAction({
            id: this.props.speciality.id,
            name: this.state.name,
            description: this.state.description
        })

        this.setState(() => ({
            editing: false
        }))
    }

    renderToggableAction = () => {

        let button = <FontAwesomeIcon icon={faEdit} 
                                      className="edit-icon"     
                                      data-tip="Edit"
                                      onClick={this.enableEditing}/>

        if (this.state.editing) {
            button = <FontAwesomeIcon icon={faCheck} 
                                      className="update-icon" 
                                      data-tip="Update"
                                      onClick={this.fireUpdate}/>
        } 
        return button
    }

    renderSPTitle = () => {
        let title = <h4 className="sp-li-name" >{this.state.name}</h4>

        if (this.state.editing) {
            title = <input className="basic-input edit-sp-name" 
                           name="name"
                           onChange={this.handleChange}
                           value={this.state.name} />
        }

        return title
    }


    render() {
        return (
            <li key={this.props.index} className="sp-list-item">
                <div className="sp-li-info">
                    {this.renderSPTitle()}
                </div>
                <div className="sp-li-actions" >
                    {this.renderToggableAction()}
                    <FontAwesomeIcon icon={faTrashAlt} 
                                     className="delete-icon" 
                                     data-tip="Delete" 
                                     onClick={this.props.deleteAction.bind(this, this.props.speciality.id)}/>
                </div>
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }
    
}

export default InsuranceItem