import React from "react";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import "./TherapistProfiles.scss";
import HeaderGuest from "../../components/Header/HeaderGuest";

class TherapistProfiles extends React.Component {
  render() {
    return (
      <div id="therapist-profiles">
        <Helmet>
          <title>Autism Therapists For Your Child | Autism Assistant</title>
          <meta
            name="description"
            content="Our website is dedicated to helping you find the right autism providers that you require, nation-wide. Search our whole list for free."
          ></meta>
        </Helmet>
        {/* Header */}
        <HeaderGuest menu="specialists" />
        {/* Footer */}
        <Footer />
      </div>
    );
  }
}

export default TherapistProfiles;
