import React from 'react'
import HeaderUser from '../../../components/HeaderUser/HeaderUser'
import SidebarAdmin from '../../../components/SidebarAdmin/SidebarAdmin'
import './ReviewsTherapist.scss'
import '../../../components/RoundInputGradient/RoundInputGradient'
import { reqListReviewsManagement, reqListRepliesManagement, reqToggleReviewStatusManagement, reqToggleReplyStatusManagement } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import PopupLoader from '../../../components/PopupLoader/PopupLoader';
import ReviewCardManage from './ReviewCardManage'
import PopuConfirm from '../../../components/PopupConfirm/PopupConfirm'
import MainWrapper from '../MainWrapper/MainWrapper'

class ManageReviewsTherapists extends React.Component {

    state = {
        pageLoading: false,
        activeTabItem: 'reviews',
        filter: 'pending',
        reviews: [],
        replies: []
    }

    admin = {}
    listReviewsUrl = 'admin/reviews/list'
    acceptReviewUrl = 'admin/review/accept'
    declineReviewUrl = 'admin/review/decline'
    listRepliesUrl = 'admin/replies/list'
    acceptReplyUrl = 'admin/reply/accept'
    declineReplyUrl = 'admin/reply/decline'
    authHeader = {}

    componentDidMount = () => {

        this.admin = this.props.userDetails;
        this.authHeader = {  
            Authorization: `Bearer ${this.admin.token}`,
            "Content-type": "application/json" 
        }

        this.loadReviews();
        this.loadReplies();
    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;
        
        if (apiData.lrManagement && apiData.lrManagement.success) {
            const reviews = apiData.lrManagement.reviews;

            this.setState(() => ({
                reviews: reviews,
                pageLoading: false
            }))
        } else if (apiData.revStatusUpdated && apiData.revStatusUpdated.success) {
            this.loadReviews()
        }

        if (apiData.listRepliesManagement && apiData.listRepliesManagement.success) {
            const replies = apiData.listRepliesManagement.replies;

            this.setState(() => ({
                replies: replies,
                pageLoading: false
            }))
        } else if (apiData.repStatusUpdated && apiData.repStatusUpdated.success) {
            this.loadReplies()
        }
        
        
    }

    tabItemActive = tabName => tabName === this.state.activeTabItem ? 'active-tab' : 'inactive-tab';

    switchTab = tabName => {
        this.setState({
            activeTabItem: tabName
        }, () => { this.state.activeTabItem === 'reviews'? 
        this.loadReviews() : this.loadReplies()
        })
        
      };

    filterActive = (filter) => {
        return this.state.filter === filter ? 'active-filter' : '';
    }

    applyFilter = (filter) => {
        this.setState({
            filter: filter
        }, () => { this.state.activeTabItem === 'reviews'? 
        this.loadReviews() : this.loadReplies()
        })
    }

    renderTabContent = () =>
    this.state.activeTabItem === 'reviews'
      ? this.renderReviewsTab()
      : this.renderRepliesTab();

    loadReviews = () => {
        const rConfig = {
            body: {},
            headers: this.authHeader,
            resource: `${this.listReviewsUrl}?type=${this.state.filter}`
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqListReviewsManagement(rConfig)
        })
    }

    loadReplies = () => {
        const rConfig = {
            body: {},
            headers: this.authHeader,
            resource: `${this.listRepliesUrl}?type=${this.state.filter}`
        }

        this.setState({
            pageLoading: true
        }, () => {
            this.props.actions.reqListRepliesManagement(rConfig)
        })
    }

    renderReviewsTab = () => {
        //console.log("State reviews: ", this.state.reviews);

        const items = this.state.reviews.map( (r, i) => (
            <ReviewCardManage 
                key={i} 
                review={r} 
                handleAcceptReview={ this.handleAcceptReview }
                handleDeclineReview={ this.handleDeclineReview }
            />
        ))

        const content = items.length ? items : (<div className="no-review-text">{`No ${this.state.filter} reviews at this time.`}</div>)

        return (
            <div className="reviews-container">
                { content }
            </div>
        )

    }

    renderRepliesTab = () => {
        //console.log("State reviews: ", this.state.reviews);

        const items = this.state.replies.map( (r, i) => (
            <ReviewCardManage 
                key={i} 
                review={r} 
                handleAcceptReview={ this.handleAcceptReply }
                handleDeclineReview={ this.handleDeclineReply }
            />
        ))

        const content = items.length ? items : (<div className="no-review-text">{`No ${this.state.filter} replies at this time.`}</div>)

        return (
            <div className="reviews-container">
                { content }
            </div>
        )

    }

    handleAcceptReview = (reviewId) => {
        //console.log("Accepting Review: ", reviewId);

        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Publish Review',
            popupText: 'Are you sure you want to accept and publish this review?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmToggleRevStatus,
            revId: reviewId,
            status: 1
        }))

    }

    handleDeclineReview = (reviewId) => {
        //console.log("Declining Review: ", reviewId);
        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Decline Review',
            popupText: 'Are you sure you want to decline this review?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmToggleRevStatus,
            revId: reviewId,
            status: -1
        }))
    }

    handleAcceptReply = (replyId) => {
        //console.log("Accepting Review: ", replyId);

        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Publish Review',
            popupText: 'Are you sure you want to accept and publish this reply?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmToggleRepStatus,
            repId: replyId,
            status: 1
        }))

    }

    handleDeclineReply = (replyId) => {
        //console.log("Declining Review: ", replyId);
        this.setState(() => ({
            showPopup: true,
            popupTitle: 'Decline Review',
            popupText: 'Are you sure you want to decline this reply?',
            calcelAction: this.hidePopup,
            confirmHandler: this.confirmToggleRepStatus,
            repId: replyId,
            status: -1
        }))
    }

    confirmToggleRevStatus = () => {
        //console.log("Accept confirmed: ", this.state.acceptId)
        const rConfig = {
            body: {
                rId: this.state.revId
            },
            headers: this.authHeader,
            resource: this.state.status === -1 ? this.declineReviewUrl : this.acceptReviewUrl
        }

        this.props.actions.reqToggleReviewStatusManagement(rConfig)

    }

    confirmToggleRepStatus = () => {
        //console.log("Accept confirmed: ", this.state.acceptId)
        const rConfig = {
            body: {
                replyId: this.state.repId
            },
            headers: this.authHeader,
            resource: this.state.status === -1 ? this.declineReplyUrl : this.acceptReplyUrl
        }

        this.props.actions.reqToggleReplyStatusManagement(rConfig)

    }

    hidePopup = () => {
        this.setState( () => ({showPopup: false}))
    }

   
    render () {
        return (
            <MainWrapper>
                <div id="manage-therapist-reviews">

                    <PopupLoader display={this.state.pageLoading} />
                    <PopuConfirm display={this.state.showPopup} 
                                 text={this.state.popupText}
                                 title={this.state.popupTitle}
                                 hidePopup={this.hidePopup}
                                 calcelAction={this.hidePopup}
                                 confirmAction={this.state.confirmHandler}/>

                    <HeaderUser/>
                    <div className="page-background">
                        {/* Page Content */}
                        <div className="page-content">
                            {/* Sidebar */}
                            <SidebarAdmin page="reviews-therapists"/>
                            {/* Inner Content */}
                            <div className="inner-content-container">
                                <div className="inner-content">
                                <div className="top-row">
                                        {/* Title */}
                                        <h1
                                        className={`page-title tab-item ${this.tabItemActive('reviews')}`}
                                        onClick={this.switchTab.bind(this, 'reviews')}
                                        >
                                        Therapist Reviews
                                        </h1>
                                        <h1 className="page-title tabs-bar">|</h1>
                                        <h1
                                        className={`page-title tab-item ${this.tabItemActive(
                                            "replies"
                                        )}`}
                                        onClick={this.switchTab.bind(this, "replies")}
                                        >
                                        Reviews Replies
                                        </h1>
                                    </div>
                                    
                                    <div className="filter-row">
                                        <div className="filters">
                                            <div 
                                                className={`filter-item ${this.filterActive('pending')}`}
                                                onClick={this.applyFilter.bind(this, 'pending')}
                                            >Pending</div>
                                            <div 
                                                className={`filter-item middle-filter ${this.filterActive('accepted')}`}
                                                onClick={this.applyFilter.bind(this, 'accepted')}
                                            >Accepted</div>
                                            <div 
                                                className={`filter-item ${this.filterActive('declined')}`}
                                                onClick={this.applyFilter.bind(this, 'declined')}
                                            >Declined</div>
                                        </div>
                                    </div>

                                    
                                        { this.renderTabContent() }
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainWrapper>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.manageTherapistReviews,
    userDetails: state.userDetails
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqListReviewsManagement: bindActionCreators(reqListReviewsManagement, dispatch),
        reqToggleReviewStatusManagement: bindActionCreators(reqToggleReviewStatusManagement, dispatch),
        reqListRepliesManagement: bindActionCreators(reqListRepliesManagement, dispatch),
        reqToggleReplyStatusManagement: bindActionCreators(reqToggleReplyStatusManagement, dispatch),
      }
    };
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(ManageReviewsTherapists)

