import React from 'react'
import './RoundInputGradient.scss'
import ReactTooltip from 'react-tooltip'

const renderTooltipContainer = props => (
    props.tooltip !== '' ?
        (<ReactTooltip type="info" place="right"/>)
        : ''
);

const RoundInputGradient = props => (
    <div className={`round-input-gradient ${props.className}`}>
        
        <div className={`input-gradient-border-container ${props.invalid ? 'invalid-input' : ''}`}>
            <div className="input-gradient-inner-container" data-tip={props.tooltip}>
                <input type="text" 
                       placeholder={props.placeholder}
                       name={props.inputName}
                       value={props.value}
                       onChange={props.onChange}
                       onKeyPress={event => {
                if (event.key === 'Enter') {
                  props.action()
                }}}
                       onBlur={props.onBlur}/>
            </div>
        </div>
        {renderTooltipContainer(props)}
    </div>
);

export default RoundInputGradient
