export const REQ_SIGNIN_THERAPIST = 'REQ_SIGNIN_THERAPIST';
export const REC_SIGNIN_THERAPIST = 'REC_SIGNIN_THERAPIST';
export const REQ_FETCH_USER_DETAILS = 'REQ_FETCH_USER_DETAILS';
export const REC_FETCH_USER_DETAILS = 'REC_FETCH_USER_DETAILS';
export const REQ_ACTIVATE_USER_ACCOUNT = 'REQ_ACTIVATE_USER_ACCOUNT';
export const REC_ACTIVATE_USER_ACCOUNT = 'REC_ACTIVATE_USER_ACCOUNT';

export const reqSignupTherapist = (data, resource) => {
    // console.log("action called")
    return {
        type: REQ_SIGNIN_THERAPIST,
        data,
        resource
    }
}

export const recSigninTherapist = data => ({type: REC_SIGNIN_THERAPIST, data})

export const reqFetchUserDetails = (data, resource) => {
    return {
        type: REQ_FETCH_USER_DETAILS,
        data,
        resource
    }
}

export const recFetchUserDetails = data => ({type: REC_FETCH_USER_DETAILS, data})



export const reqActivateUserAccount = (data, resource) => {
    return  {
        type: REQ_ACTIVATE_USER_ACCOUNT,
        data,
        resource
    }
};

export const recActivateUserAccount = data => ({type: REC_ACTIVATE_USER_ACCOUNT, data})
