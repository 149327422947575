import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_SIGNIN_THERAPIST, recSigninTherapist} from './actions'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'

import fetchData from '../../../utils/api'


function* signinTherapist(action) {

  try {
    const data = yield call(fetchData, action.data, action.resource)
    
    if (data.user) {
      // console.log("Signin data", data);
      yield put(recFetchUserDetails(data))
    }
    // console.log("Signin therapist", data);
    yield put(recSigninTherapist(data))
   
  } catch (e) {
      console.log("Api error: ", e)
   }
}


export default function* signupSaga() {
    yield takeLatest(REQ_SIGNIN_THERAPIST, signinTherapist);
};
