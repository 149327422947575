import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_LOAD_SPONSORSHIP_PLANS, REQ_SUBSCRIBE_TO_SPONSORSHIP_PLAN, REQ_GET_SPONSORSHIP_SETTINGS, 
    REQ_CANCEL_SPONSORSHIP_PLAN, REQ_UPDATE_SPONSORSHIP_PLAN,
    recLoadSponsorshipPlans, recSubscribeToSponsorshipPlan, recGetSponsorshipSettings, 
    recCancelSponsorshipPlan, recUpdateSponsorshipPlan} from './actions'

import fetchData from '../../../utils/api'
import mergeInUserDetails from '../../../utils/mergeInUserDetails'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'

function* loadSponsorshipPlans(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recLoadSponsorshipPlans(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* subscribeToSponsorshipPlan(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    
    if (data.success) {
      const newUserDetails = {
        sponsorshipPlanId: action.config.body.planId,
      };
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
      yield put(recSubscribeToSponsorshipPlan(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* getSponsorshipSettings(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetSponsorshipSettings(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* cancelPlan(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    
    if (data.success) {
      const newUserDetails = {
        sponsorshipPlanId: null,
      };
      const mergedUserDetails = mergeInUserDetails(action.config.userDetails, newUserDetails)
      yield put(recFetchUserDetails(mergedUserDetails))
    }
    yield put(recCancelSponsorshipPlan(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

function* updateSponsorshipPlan(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recUpdateSponsorshipPlan(data))
  } 
  catch (e) {console.log("Api error: ", e)}
}

export default function* sponsorshipSagas() {
    yield takeLatest(REQ_LOAD_SPONSORSHIP_PLANS, loadSponsorshipPlans);
    yield takeLatest(REQ_SUBSCRIBE_TO_SPONSORSHIP_PLAN, subscribeToSponsorshipPlan);
    yield takeLatest(REQ_GET_SPONSORSHIP_SETTINGS, getSponsorshipSettings);
    yield takeLatest(REQ_CANCEL_SPONSORSHIP_PLAN, cancelPlan);
    yield takeLatest(REQ_UPDATE_SPONSORSHIP_PLAN, updateSponsorshipPlan);
};  