import React from 'react'
import defaultAvatar from '../../../static/images/desktop/avatar-default-picture.png';


const renderAvatar = props => {
    // console.log("AvatarUrl: ", avatarUrl)
    if (! (props.author || {}).avatarUrl) {
        return <img src={defaultAvatar} alt="avatar" loading="lazy" />
    }

    return <img src={props.avatarUrl} alt="avatar" loading="lazy" />

}

const AboutAuthorSection = props => (
    <div className="about-author-section">
        {/* Title */}
        <div className="section-title">About The Author</div>

        {/* Details Container */}
        <div className="authro-details-container">
            
            {/* Avatar Container */}
            <div className="author-av-container">
                <div className="authro-av-card">
                    { renderAvatar(props) }
                </div>
            </div>

            {/* Info Container */}
            <div className="author-info-container">

                {/* Full Name */}
                <div className="author-full-name">
                    { `${props.author.firstName} ${props.author.lastName}` }
                </div>

                {/* Bio */}
                <div className="author-bio">
                    { props.author.bio }
                </div>
            </div>
        </div>
    </div>
)

export default AboutAuthorSection