import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

class MainWrapper extends React.Component {

    state = {
        redirect: false,
        redirectUrl: '/admin/signin'
    }

    user = {}

    componentDidMount = () => {
        
        this.user = this.props.userDetails || false;

        if (!(this.user.roleId === 1)) {
            this.setState(() => ({
                redirect: true
            }))
        }
    }

    render() {

        if(this.state.redirect) {
            return (
                <Redirect to={this.state.redirectUrl} />
            )
        }

        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.userDetails
})

export default connect(mapStateToProps, null)(MainWrapper)