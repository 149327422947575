import { all }   from 'redux-saga/effects'
import seoSagas  from './containers/SEO/seoSagas'
import homeSagas from './containers/Home/homeSagas'

import signupSaga from './containers/Therapist/Signup/signupSagas'
import signinSaga from './containers/Therapist/Signin/signinSagas'
import confirmUserAccountSagas from './containers/Therapist/Signin/confirmUserAccountSagas'
import headerSaga from './components/HeaderUser/headerSagas'

import mainBlogSaga from './containers/Blog/blogSagas'
import contactSagas from './containers/Contact/contactSagas'

import dashboardSagas  from './containers/Admin/Dashboard/dashboardSagas'
import searchFormSagas from './components/Header/searchFormSagas'
import adminSigninSaga from './containers/Admin/Signin/signinSagas'
import createAdminSaga from './containers/Admin/CreateAdmin/createAdminSagas'

import supportEmailSaga   from './containers/Admin/Support/supportSagas'
import manageAdminsSaga   from './containers/Admin/ManageAdmins/manageAdminsSagas'
import sponsorshipSagas   from './containers/Therapist/Sponsorship/sponsorshipSagas'
import guestArticleSaga   from './containers/Article/articleSagas'
import resetPasswordSaga  from './containers/ResetPassword/resetPasswordSagas'
import adminUpdateProfile from './containers/Admin/Profile/updateProfileSagas'

import manageMessagesAdmin from './containers/Admin/SupportMessages/supportMessagesSagas'
import manageLocationsSaga from './containers/Therapist/Locations/manageLocationsSagas'
import manageArticlesSagas from './containers/Admin/ManageBlog/manageArticlesSagas'

import manageTherapistsSaga   from './containers/Admin/ManageTherapists/manageTherapistsSagas'
import manageBloggersSaga     from './containers/Admin/ManageBloggers/manageBloggersSagas'
import adminManageInsurances  from './containers/Admin/ManageInsurances/manageInsurancesSagas'
import therapistUpdateDetails from './containers/Therapist/Details/detailsSagas'
import therapistUpdateProfile from './containers/Therapist/Profile/updateTherapistProfileSagas'
import therapistPublicProfile from './containers/Therapist/PublicProfile/publicProfileSagas'
import manageTherapistReviews from './containers/Admin/ReviewsTherapist/reviewsTherapistSagas'
import therapistManagePayment from './containers/Therapist/Payment/paymentSagas'

import therapistManageArticles  from './containers/Therapist/Blog/articlesSagas'
import adminManageSpecialities  from './containers/Admin/ManageSpecialities/manageSpecialitiesSagas'
import requestResetPasswordSaga from './containers/RequestResetPassword/requestResetPasswordSagas'
import adminActivateAccountSaga from './containers/Admin/ActivateAccount/activateAccountSagas'
import therapistTopProfilesSaga from './containers/TherapistProfiles/therapistProfilesSagas'

import therapistManageInsurances   from './containers/Therapist/Insurances/insurancesSagas'
import manageArticleReviewsSagas   from './containers/Admin/ManageArticleReviews/ManageArticleReviewsSagas'
import therapistManageSpecialities from './containers/Therapist/Specialities/manageSpecialitiesSagas'


export default function* rootSaga () {
  yield all ([
    seoSagas(),
    homeSagas(),
    signupSaga(),
    signinSaga(),
    headerSaga(),
    mainBlogSaga(),
    contactSagas(),
    dashboardSagas(),
    adminSigninSaga(),
    createAdminSaga(),
    searchFormSagas(),
    sponsorshipSagas(),
    guestArticleSaga(),
    supportEmailSaga(),
    manageAdminsSaga(),
    resetPasswordSaga(),
    adminUpdateProfile(),
    manageArticlesSagas(),
    manageLocationsSaga(),
    manageMessagesAdmin(),
    manageTherapistsSaga(),
    manageBloggersSaga(),
    adminManageInsurances(),
    therapistManagePayment(),
    therapistPublicProfile(),
    manageTherapistReviews(),
    therapistUpdateProfile(),
    therapistUpdateDetails(),
    adminManageSpecialities(),
    therapistManageArticles(),
    therapistTopProfilesSaga(),
    requestResetPasswordSaga(),
    adminActivateAccountSaga(),
    therapistManageInsurances(),
    manageArticleReviewsSagas(),
    therapistManageSpecialities(),
    confirmUserAccountSagas(),
  ]);
}
