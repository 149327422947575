import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_ADD_ARTICLE,
         REQ_GET_ARTICLES,
         REQ_DELETE_ARTICLE,
         REQ_UPDATE_ARTICLE,
         REQ_SEARCH_ARTICLES,
         REQ_ACCEPT_ARTICLE,
         REQ_DECLINE_ARTICLE,
         recAddArticle,
         recGetArticles,
         recUpdateArticle,
         recSearchArticles,
         recDeleteArticle,
         recAcceptArticle,
         recDeclineArticle} from './actions'

import fetchData from '../../../utils/api'


function* addArticle(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST');
    yield put(recAddArticle(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* getArticles(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET');
    yield put(recGetArticles(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* deleteArticle(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST');
    yield put(recDeleteArticle(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* updateArticle(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recUpdateArticle(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* searchArticles(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recSearchArticles(data))

  } catch (e) {console.log("Api error: ", e)}
}

function* acceptArticle(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recAcceptArticle(data))

  } catch (e) {console.log("Api error: ", e)}
}

function* declineArticle(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recDeclineArticle(data))

  } catch (e) {console.log("Api error: ", e)}
}


export default function* adminManageArticles() {
    yield takeLatest(REQ_ADD_ARTICLE, addArticle);
    yield takeLatest(REQ_GET_ARTICLES, getArticles);
    yield takeLatest(REQ_DELETE_ARTICLE, deleteArticle);
    yield takeLatest(REQ_UPDATE_ARTICLE, updateArticle);
    yield takeLatest(REQ_SEARCH_ARTICLES, searchArticles);
    yield takeLatest(REQ_ACCEPT_ARTICLE, acceptArticle);
    yield takeLatest(REQ_DECLINE_ARTICLE, declineArticle);
};
