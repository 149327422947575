import React from 'react'

const inputHasErrors = (inputName, formErrorField) => (inputName === formErrorField ? 'basic-input-err' : '')

const ReviewFormSection = props => (
    <div className="art-rev-form-container">
        {/* First Row */}
        <div className="rev-f-row">
            {/* Full Name Input */}
            <div className="rev-f-half-input-container">
                <input
                    name={props.inputNames.name}
                    type="text"
                    className={`basic-input ${inputHasErrors(props.inputNames.name, props.formErrorField)}`}
                    placeholder="Name*"
                    onChange={props.handleChange}
                    value={props.revFullName}
                />
            </div>
            {/* Email Input */}
            <div className="rev-f-half-input-container">
                <input
                    name={props.inputNames.email}
                    type="text"
                    className={`basic-input ${inputHasErrors(props.inputNames.email, props.formErrorField)}`}
                    placeholder="Email*"
                    onChange={props.handleChange}
                    value={props.revEmail}
                />
            </div>
        </div>
        {/* Second Row */}
        <div className="rev-f-row">
            {/* Comment Input */}
            <div className="rev-f-full-input-container">
                <input
                    name={props.inputNames.comment}
                    type="text"
                    className={`basic-input ${inputHasErrors(props.inputNames.comment, props.formErrorField)}`}
                    placeholder={props.placeholder}
                    onChange={props.handleChange}
                    value={props.revComment}
                />
            </div>
        </div>
        <div className="ar-form-response-row">
            <span className="error-message">{props.formError}</span>
            <span className="success-message">{props.successMessage}</span>
        </div>
        {/* Submit Row */}
        <div className="rev-f-submit-row">
            <div
                className="btn btn-default art-rev-submit-btn"
                onClick={props.submitPost}>{props.buttonName}</div>
        </div>
    </div>
)

export default ReviewFormSection
