import React from 'react'
import './ResetPassword.scss'
import bottomRightEllipse from '../../static/images/desktop/reset-pwd-bottom-right-ellipse.png';
import bottomRightShape from '../../static/images/desktop/reset-pwd-bottom-right-shape.png';
import topleftShape from '../../static/images/desktop/reset-pwd-left-shape.png';
import topRightEllipse from '../../static/images/desktop/reset-pwd-right-ellipse.png';
import logoText from '../../static/images/desktop/logo-text-blue.png';
import ButtonRoundGradient from '../../components/ButtonRoundGradient/ButtonRoundGradient';
import { Link, Redirect } from 'react-router-dom';
import PopupLoader from '../../components/PopupLoader/PopupLoader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reqResetPassword } from './actions';

class ResetPassword extends React.Component {

    state = {
        redirect: false,
        redirectUrl: '/profile',
        pageLoading: false,
        password: '',
        confirmPassword: '',
        errors: [],
        passwordReset: false
    }

    submitUrl = 'updatePassword';

    componentDidMount = () => {
        if ( (this.props.userDetails || {}).uid ) {
            this.setState( () => ({redirect: true}))
        }
        // if (loggedInUser) {
        //     this.setState( () => ({redirect: true}))
        // }
    }

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;
        let errorMessage = '';
        let newState = {};

        if (apiData.error) {
            if (apiData.display) {
                errorMessage = apiData.error
            } else {
                errorMessage = 'An error occured'
            }

            newState = {
                errorMessage: errorMessage,
                pageLoading: false
            }
        } else if (apiData.success) {
            newState = {
                pageLoading: false,
                passwordReset: true
            }
        }

        this.setState( () => (newState))

    }

    handleChange = (event) => {

        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({[inputName]: value}))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    }

    handleSubmit = () => {

        this.setState({
            errors: [],
            pageLoading: true
        }, () => {

            const formValid = this.validateForm()

            if (!formValid) {return;}

            const rBody = {
                password: this.state.password,
                token: this.props.match.params.token,
                uid: this.props.match.params.uid
            }

            this.props.reqResetPassword(rBody, this.submitUrl);
        })

    }

    validateForm = () => {

        let formValid = true;

        if (this.state.password === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'password',
                    message: 'Password is mandatory'}
                ],
                pageLoading: false
            }))
        } else if (this.state.confirmPassword === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'confirmPassword',
                    message: 'Confirm Password is mandatory'}
                ],
                pageLoading: false
            }))
        } else if (this.state.confirmPassword !== this.state.password) {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'confirmPassword',
                    message: 'Confirm Password and Password fields are different'},
                    {
                        input: 'password'
                    }
                ],
                pageLoading: false
            }))
        }

        return formValid
    }

    displayErrorMessage = () => {
        const errors = this.state.errors;

        return errors.length ? errors[0].message : ''
    }

    displayApiResponse = () => {
        let response = '';
        const apiData = this.props.apiData;

        if (!apiData) return '';

        response = apiData.success ? apiData.message : '';

        return response;
    }

    displayApiError = () => {
        let error = '';
        const apiData = this.props.apiData;

        if (!apiData) return '';

        error = apiData.error ? apiData.display ? apiData.error : 'An error occured' : '';

        return error;
    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to={this.state.redirectUrl} />
            )
        }

        if (this.state.passwordReset) {
            return (
                <div id="reset-password">
                    <div className="page-background">
                        <img src={topleftShape} alt="topLeftShape" className="top-left-shape"/>
                        <img src={topRightEllipse} alt="topLeftShape" className="top-right-ellipse"/>
                        <img src={bottomRightShape} alt="topLeftShape" className="bottom-right-shape"/>
                        <img src={bottomRightEllipse} alt="topLeftShape" className="bottom-right-ellipse"/>
                    </div>
                    <div className="reset-success">
                        <span className="grey-text">You can now </span>
                        <Link to="/signin">
                            <span className="link-text">Login</span>
                        </Link>
                        <span className="grey-text"> with your new credentials</span>
                    </div>
                </div>
            )
        }

        return (
            <div id="reset-password">

                <PopupLoader display={this.state.pageLoading} />

                <div className="page-background">
                    <img src={topleftShape} alt="topLeftShape" className="top-left-shape"/>
                    <img src={topRightEllipse} alt="topLeftShape" className="top-right-ellipse"/>
                    <img src={bottomRightShape} alt="topLeftShape" className="bottom-right-shape"/>
                    <img src={bottomRightEllipse} alt="topLeftShape" className="bottom-right-ellipse"/>
                </div>

                <div className="page-content">
                    <Link to="/" >
                        <img src={logoText} alt="logo" className="logo" />
                    </Link>
                    <h1 className="page-title">Reset Password</h1>
                    <div className="form-container">
                        <form>
                            <div className="input-row">
                                <input type="password"
                                       placeholder="Password"
                                       name="password"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("password")}`}/>
                            </div>

                            <div className="input-row">
                                <input type="password"
                                       placeholder="Confirm Password"
                                       name="confirmPassword"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("confirmPassword")}`}/>
                            </div>

                            <div>
                                <p className="error-message">{this.displayErrorMessage()}</p>
                                <p className="error-message">{this.displayApiError()}</p>
                                <p className="success-message">{this.displayApiResponse()}</p>
                            </div>

                            <div className="reset-row">
                                <ButtonRoundGradient text="Reset Password" onClick={this.handleSubmit}/>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    apiData: state.resetPassword,
    userDetails: state.userDetails
})

const mapDispatchToProps = dispatch => bindActionCreators({reqResetPassword}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
