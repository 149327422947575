import React from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import './LocationSuggest.scss'
import 'react-google-places-autocomplete/dist/assets/index.css';
 

const LocationSuggest = (props) => (
    <div id="location-autosuggest">
        <div className="item-suggest-outer">
            <div className="loc-suggest-inner">
                <div className="item-suggest-label">Near</div>
                <GooglePlacesAutocomplete
                    initialValue={''}
                    className="location-input"
                    onSelect={props.locationSelected}
                    autocompletionRequest={{
      componentRestrictions: {
        country: ['us'],
      }
    }}
                    lazyLoad={true} />
            </div>
        </div>
    </div>
)

export default LocationSuggest
