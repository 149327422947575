import React from "react";

import "./Modal.scss";

const containerStyle = {
  minHeight: "150px",
  width: "320px",
  // margin: `${window.innerHeight/2 - 150}px auto 0`,
  borderRadius: "20px",
  padding: "15px",
  position: "fixed",
  // top: `${window.pageYOffset + (window.innerHeight / 2 - 340)}px`,
  // left: `${window.innerWidth / 2 - 160}px`
  left: "0",
  right: "0",
  marginTop: "5%",
  marginLeft: "auto",
  marginRight: "auto",
  zIndex: "100"
};

const Modal = props => {
  return (
    <div>
      <div
        className="modal-wrapper"
        style={{
          display: props.display ? "block" : "none",
          
        }}
      >
        <div style={containerStyle}>{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
