import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import './RoundDropdown.scss'
import enhanceWithClickOutside from 'react-click-outside'

class RoundDropdown extends React.Component {

    renderRdOptions = props => {
       
        return (
            <div className="rd-option-list">
                {props.options.map( (opt, idx) => (
                    <div 
                        className="rd-option-item"
                        key={`rd-opt-${idx}`}
                        onClick={props.optionChoosen.bind(this, opt.value)}
                    >
                        <div className="rd-op-text">{opt.label}</div>    
                    </div>
                ))}
            </div>
            
        )
    }
    
    renderInnerContent = props => {
        let label = props.label
    
        if (label) {
            return (
                <div className="rd-input-placeholder" onClick={props.toggleList}>
                    <div className="rd-label">{label}</div>
                    <div className="rd-input-placeholder-text">{this.rendePlaceHolder(props)}</div>
                    <div className="rd-arrow">
                        <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="rd-input-placeholder">
                    <div className="rd-input-placeholder-text">{this.rendePlaceHolder(props)}</div>
                    <div className="rd-arrow">
                        <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                </div>
            )
        }
    }
   
    rendePlaceHolder = props => (
        props.selected.label ? props.selected.label : props.placeholder
    )

    getListStatus = props => (
        props.open ? 'rd-options-open' : 'rd-options-hidden'
    )

    handleClickOutside = () => {
        this.props.hideDistanceOptions()
    }
   
    render () {
        return (
            <div className="rd-container">
                <div className="rd-input-container">
                    <div className="rd-input-outer">
                        <div className="rd-input-inner">
                            {this.renderInnerContent(this.props)}
                        </div>
                    </div>
                </div>
        
                <div className={this.getListStatus(this.props)}>
                    {this.renderRdOptions(this.props)}
                </div>
            </div>
        )
    }
}

export default enhanceWithClickOutside (RoundDropdown)