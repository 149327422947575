import { REC_GET_TOP_LOCATIONS, REC_GET_TOP_THERAPISTS } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_TOP_LOCATIONS:
            return {
                topLocations: data
            };

        case REC_GET_TOP_THERAPISTS:
            return {
                topTherapists: data
            };

        default:
            return state
    }
}