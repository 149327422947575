import { REC_LOGOUT_USER } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_LOGOUT_USER:
            return data;
        default:
            return state
    }
}