import React from 'react'

import Footer from '../../components/Footer/Footer'
import SideDrawer from '../../components/SideDrawer/SideDrawer'
// import TeamMember                from '../../components/TeamMember/TeamMember'
// import teamMember1              from '../../static/images/desktop/team-member-1.png'
// import teamMember2              from '../../static/images/desktop/team-member-2.png'
// import sampleImage2              from '../../static/images/desktop/sample-image-2.png'

import './TopTherapist.scss'
import HeaderGuestSimple from '../../components/Header/HeaderGuestSimple'


class TopTherapist extends React.Component {


    render() {
        // eslint-disable-next-line
        let sideDrawer = null;

        if (this.state.sideDrawerOpen) {
            sideDrawer = <SideDrawer/>
        }

        return (
            <div>
                <HeaderGuestSimple menu="top-therapist"/>
                <section className="container mt-30">
                    <h1 className="text-center aboutUsHeading">Top Therapist Locations</h1>

                </section>

                <Footer/>
            </div>
        )
    }

}

export default TopTherapist
