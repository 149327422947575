import React from 'react'
//import { Link } from 'react-router-dom'
import { Redirect }  from 'react-router-dom';
import HeaderGuestSimple    from '../../components/Header/HeaderGuestSimple'
import Footer         from '../../components/Footer/Footer'
import Radio          from '../../components/Radio/Radio'
import PopupLoader from '../../components/PopupLoader/PopupLoader'
import { Helmet } from 'react-helmet';
import { reqMessageContact } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import './Contact.scss'


class Contact extends React.Component {

    state = {
        commMethod: 'email',
        name: '',
        email: '',
        phone: '',
        message: '',
        errors: [],
        redirect: false,
        redirectUrl: '/profile',
        pageLoading: false,
        successResponse: '',
        phoneText:'Phone',
        emailText:'Email',
    }

    submitUrl = 'contact/message/save'

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData

        if (apiData.success) {
            this.setState(() => ({
                name: '',
                email: '',
                phone: '',
                message: '',
                pageLoading: false,
                redirect: false,
                successResponse: 'Message succefully submitted'
            }))
        }
    };

    getRedirectRoute = () => {
        return '/thank-you';
    };

    handleChange = (event) => {
        // this.setState({value: event.target.value});
        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => {
            return {[inputName]: value}
        })
    }

    radioClickHandler = (event) => {
        let radioValue = event.target.dataset.value
        this.setState(() => {
            return {commMethod: radioValue}
        })
        if(radioValue==='email'){
            this.setState( () => ({emailText: 'Email*'}))
            this.setState( () => ({phoneText: 'Phone'}))
        } else if (radioValue==='phone'){
            this.setState( () => ({emailText: 'Email'}))
            this.setState( () => ({phoneText: 'Phone*'}))
    }}

    handleSubmit = () => {
        this.setState({errors: []}, () => {
            const formValid = this.validateForm()
            if (!formValid) {
                return;
            }

            const rConfig = {
                body:{
                    email: this.state.email,
                    name: this.state.name,
                    phone: this.state.phone,
                    message: this.state.message,
                    contactMethod: this.state.commMethod
                },
                headers: null,
                resource: this.submitUrl
            }

            this.setState({
                pageLoading: true,
                redirect: true
            }, () => {
                this.props.actions.reqMessageContact(rConfig)
            })

            // console.log("Calling action");
        })
    }


    validateForm = () => {

        let formValid = true;

        if (this.state.name === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'name'}
                ],
                errorMessage: 'Name is mandatory'
            }))
        } else if (this.state.email === '' && this.state.commMethod==='email') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'email'}
                ],
                errorMessage: 'Email is mandatory'
            }))
        } else if (this.state.message === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'message'}
                ],
                errorMessage: 'Message is mandatory'
            }))
        }
        else if (this.state.phone === '' && this.state.commMethod==='phone') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'phone'}
                ],
                errorMessage: 'Phone is mandatory',
            }))
        }
        return formValid
    }
    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)

        return errors.length ? 'input-error' : ''
    }

    render() {
        if (this.state.redirect) {
            return(
                <Redirect
                    to={{
                        pathname: this.getRedirectRoute(),
                        state: {
                            message: "Thank you for contacting us. We'll get back to you as soon as possible!"
                        }
                    }}
                />
            )
        }
        return (
            <div id="contact-us-page">
                <Helmet>
                    <title>Contact Us | Autism Assistant</title>
                    <meta name="description" content="Submit any ASD or otherwise questions you have for us. We’ll get back to you as fast as possible via either email or by phone, as you prefer. "></meta>
                </Helmet>
                <HeaderGuestSimple menu="contactUs"/>

                <section className="container mt-80">

                    <h2 className="header-contact">Send Us a Message</h2>
                    <form className="form">
                        <PopupLoader display={this.state.pageLoading} />
                        <div className="row">
                            <div className="col-md-5">
                                <input type="text"
                                       id="name"
                                       name="name"
                                       placeholder="Name*"
                                       onChange={this.handleChange}
                                       value={this.state.name}
                                       className={`custom-input ${this.inputHasErrors("name")}`} />
                            </div>
                            <div className="offset-md-1 col-md-5">
                                <input type="text"
                                       id="email"
                                       name="email"
                                       placeholder={this.state.emailText}
                                       onChange={this.handleChange}
                                       value={this.state.email}
                                       className={`custom-input ${this.inputHasErrors("email")}`} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <input type="text"
                                       id="phone"
                                       name="phone"
                                       placeholder={this.state.phoneText}
                                       onChange={this.handleChange}
                                       value={this.state.phone}
                                       className={`custom-input ${this.inputHasErrors("phone")}`} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-purple mb-30">Preferred method of communication<span className="star">*</span></div>
                                <div className="custom-radio--row">
                                    <Radio id="commMethodEmail"
                                           name="comm-method"
                                           dataValue="email"
                                           isChecked={this.state.commMethod === 'email'}
                                           className=""
                                           click={this.radioClickHandler}
                                           text="Email" />
                                    <Radio id="commMethodPhone"
                                           name="comm-method"
                                           dataValue="phone"
                                           isChecked={this.state.commMethod === 'phone'}
                                           className=""
                                           click={this.radioClickHandler}
                                           text="Phone"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-11">
                        <textarea id="message"
                                  name="message"
                                  className={`custom-input custom-textarea ${this.inputHasErrors("message")}`}
                                  rows="1"
                                  onChange={this.handleChange}
                                  value={this.state.message}
                                  placeholder="Write your Message*"></textarea>
                            </div>
                        </div>
                    </form>
                    <div>
                        <p className="error-message">{this.state.errorMessage}</p>
                    </div>
                    <div>
                        <p className="success-message">{this.state.successResponse}</p>
                    </div>
                    <div className="contact-submit-row">
                        <button onClick={this.handleSubmit} className="btn btn-default btn-small">Submit</button>
                    </div>
                </section>

                <Footer />
            </div>
        )
    }

}

const mapStateToProps = state => ({
    apiData: state.contactReducer
})

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            reqMessageContact: bindActionCreators(reqMessageContact, dispatch),
        }
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Contact)

