import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const MenuStepNavigation = props => (
  <div className="menu-navigation">
    <div className="navigate-links">
      <div className="position-left">
        <FontAwesomeIcon icon={faAngleLeft} className={(props.leftLink ? "" : "icon-left")}/>
        <Link
          to={props.leftLink}
          className={
            "navigate-left" + (props.otherClass ? props.otherClass : "")
          }
        >
          {props.leftText}
        </Link>
      </div>
      <div className="position-right">
        <Link
          to={props.rightLink}
          className={
            "navigate-right" + (props.otherClass ? props.otherClass : "")
          }
        >
          {props.rightText}
        </Link>
        <FontAwesomeIcon className="iconright" icon={faAngleRight} />
      </div>
    </div>
    <div className="info-text">
      <FontAwesomeIcon icon={faInfoCircle} />
        {props.leftLink === '/blogger-profile'?<p>
                You can navigate your profile's sections using the menu on the left, or with the button above.
      </p>:
            <p>
                You can navigate your profile's sections using the menu on the left, or
                step-by-step with the buttons above.
            </p>
        }
    </div>
  </div>
);

export default MenuStepNavigation;
