import { REC_SIGNIN_ADMIN } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_SIGNIN_ADMIN:
            return data;
        default:
            return state
    }
}