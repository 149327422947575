import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import CheckboxRound from '../../../components/CheckboxRound/CheckboxRound'

class SuggestSpecialities extends React.Component {

    state = {
        specialities: this.props.suggestedSpecialities,
        isOpen: false
    }
    
    componentWillReceiveProps = (newProps) => {
        // console.log("newProps: ", newProps)
        this.setState(() => ({
            specialities: newProps.suggestedSpecialities,
            isOpen: newProps.searchTerm !== ''
        }))
    }

    handleCheckSpeciality = (ev) => {
        const state = !ev.state.isChecked,
              speciality = ev.props.speciality;
            //   console.log("Spec checked: ", ev)
         
        this.props.handleSpecialityCheck(state, speciality)
    }

    renderList = () => {
        // console.log("this items: ", this.items)
        const items = [];
        for (let i = 0; i < this.state.specialities.length; i++) {
            const speciality = this.state.specialities[i];
            // console.log("speciality: ", speciality)

            const item = (
                <li className="suggested-spec-item" key={speciality.id}>
                    <div className="speciality-select-box">
                        <CheckboxRound id={`check-spec-${speciality.id}`}
                                       speciality={speciality}
                                       handleCheck={this.handleCheckSpeciality}
                                       isChecked={ speciality.isChecked || false}/>
                    </div>

                    <div className="speciality-select-name">{speciality.name}</div>
                </li>
                
            )

            items.push(item);
        }

        return (
            <ul className="suggested-specs-list">
                {items}
            </ul>
        )

    }

    handleClickOutside = () => {
        this.props.handleBlur()
        this.toggle();
    }
     
    toggle = () => {
        this.setState(() => ({
            isOpen: false
        }));
    }

    render() {
        return (
                <div className={ `suggested-specs-container ${this.state.isOpen ? '' : 'hidden-element'}` } >
                {this.renderList()} 
            </div>
        )
    }

}

export default enhanceWithClickOutside(SuggestSpecialities)