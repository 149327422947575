export const REQ_SIGNIN_ADMIN = 'REQ_SIGNIN_ADMIN'
export const REC_SIGNIN_ADMIN = 'REC_SIGNIN_ADMIN'

export const reqSigninAdmin = (config) => {
    return {
        type: REQ_SIGNIN_ADMIN,
        config: config
    }
}

export const recSigninAdmin = data => ({type: REC_SIGNIN_ADMIN, data})


