export const  REQ_LOGOUT_USER  = 'REQ_LOGOUT_USER'
export const  REC_LOGOUT_USER  = 'REC_LOGOUT_USER'

// export const reqLogoutUser = data => ({type: REC_FETCH_USER_DETAILS, data})

export const reqLogoutUser = (data) => {
    return {
        type: REQ_LOGOUT_USER,
        data
    }
}

export const recLogoutUser = data => ({type: REC_LOGOUT_USER, data})
