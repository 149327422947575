export const REQ_GET_THERAPIST_ARTICLES = 'REQ_GET_THERAPIST_ARTICLES'
export const REC_GET_THERAPIST_ARTICLES = 'REC_GET_THERAPIST_ARTICLES'

export const REQ_ADD_THERAPIST_ARTICLE = 'REQ_ADD_THERAPIST_ARTICLE'
export const REC_ADD_THERAPIST_ARTICLE = 'REC_ADD_THERAPIST_ARTICLE'

export const REQ_UPDATE_THERAPIST_ARTICLE = 'REQ_UPDATE_THERAPIST_ARTICLE'
export const REC_UPDATE_THERAPIST_ARTICLE = 'REC_UPDATE_THERAPIST_ARTICLE'

export const REQ_REMOVE_THERAPIST_ARTICLE = 'REQ_REMOVE_THERAPIST_ARTICLE'
export const REC_REMOVE_THERAPIST_ARTICLE = 'REC_REMOVE_THERAPIST_ARTICLE'

export const REQ_UPLOAD_ARTICLE_THUMBNAIL = 'REQ_UPLOAD_ARTICLE_THUMBNAIL'
export const REC_UPLOAD_ARTICLE_THUMBNAIL = 'REC_UPLOAD_ARTICLE_THUMBNAIL'


export const reqGetTherapistArticles = (config) => ({type: REQ_GET_THERAPIST_ARTICLES, config: config})
export const recGetTherapistArticles = data => ({type: REC_GET_THERAPIST_ARTICLES, data})

export const reqAddTherapistArticles = (config) => ({type: REQ_ADD_THERAPIST_ARTICLE, config: config})
export const recAddTherapistArticles = data => ({type: REC_ADD_THERAPIST_ARTICLE, data})

export const reqUpdateTherapistArticles = (config) => ({type: REQ_UPDATE_THERAPIST_ARTICLE, config: config})
export const recUpdateTherapistArticles = data => ({type: REC_UPDATE_THERAPIST_ARTICLE, data})

export const reqRemoveTherapistArticles = (config) => ({type: REQ_REMOVE_THERAPIST_ARTICLE, config: config})
export const recRemoveTherapistArticles = data => ({type: REC_REMOVE_THERAPIST_ARTICLE, data})

export const reqUploadArticleThumbnail = (config) => ({type: REQ_UPLOAD_ARTICLE_THUMBNAIL, config: config})
export const recUploadArticleThumbnail = data => ({type: REC_UPLOAD_ARTICLE_THUMBNAIL, data})

 