export const REQ_MESSAGE_CONTACT = 'REQ_MESSAGE_CONTACT'
export const REC_MESSAGE_CONTACT = 'REC_MESSAGE_CONTACT'

export const reqMessageContact = (config) => {
    // console.log("Action called", config)
    return {
        type: REQ_MESSAGE_CONTACT, 
        config: config
    }
}

export const recMessageContact = data => {
   // console.log("received in actions: ", data)
    return {type: REC_MESSAGE_CONTACT, data}
}


