export const REQ_LIST_BLOGGERS_ADMIN = 'REQ_LIST_BLOGGERS_ADMIN'
export const REC_LIST_BLOGGERS_ADMIN = 'REC_LIST_BLOGGERS_ADMIN'

export const REQ_SEARCH_BLOGGERS_ADMIN = 'REQ_SEARCH_BLOGGERS_ADMIN'
export const REC_SEARCH_BLOGGERS_ADMIN = 'REC_SEARCH_BLOGGERS_ADMIN'

export const REQ_BLOGGERS_LOCK_UNLOCK = 'REQ_BLOGGERS_LOCK_UNLOCK'
export const REC_BLOGGERS_LOCK_UNLOCK = 'REC_BLOGGERS_LOCK_UNLOCK'

export const REQ_BLOGGERS_DELETE = 'REQ_BLOGGERS_DELETE'
export const REC_BLOGGERS_DELETE = 'REC_BLOGGERS_DELETE'

export const reqListBloggers = (config) => ({type: REQ_LIST_BLOGGERS_ADMIN, config: config})
export const recListBloggers = data => ({type: REC_LIST_BLOGGERS_ADMIN, data})

export const reqSearchBloggers = (config) => ({type: REQ_SEARCH_BLOGGERS_ADMIN, config: config})
export const recSearchBloggers = data => ({type: REC_SEARCH_BLOGGERS_ADMIN, data})

export const reqBloggerLockUnlock = (config) => ({type: REQ_BLOGGERS_LOCK_UNLOCK, config: config})
export const recBloggerLockUnlock = data => ({type: REC_BLOGGERS_LOCK_UNLOCK, data})

export const reqDeleteBlogger = (config) => ({type: REQ_BLOGGERS_DELETE, config: config})
export const recDeleteBlogger = data => ({type: REC_BLOGGERS_DELETE, data})
