import React from 'react'
import Rating from 'react-rating'
import ratingStarFull from '../../../static/images/desktop/rating-star-full.png'
import ratingStarEmpty from '../../../static/images/desktop/rating-star-empty.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const renderActions = props => {
    
    switch(props.review.status) {
        case 0: 
            return renderBothActions(props)
        
        case 1:
            return renderDeclineAction(props)
        
        case -1:
            return renderAcceptAction(props)
        
        default: console.log("-")
    }
}

const renderBothActions = (props) => (

    <div className="rev-actions-container">
        <FontAwesomeIcon icon={faCheck} 
                         onClick={props.handleAcceptReview.bind(this, props.review.rId)}
                         className="accept-icon" 
                         data-tip="Accept" />
                 
        <FontAwesomeIcon icon={faTimes} 
                         onClick={props.handleDeclineReview.bind(this, props.review.rId)}
                         className="decline-icon" 
                         data-tip="Decline" />
    </div>   
)

const renderDeclineAction = (props) => (
    <div className="rev-actions-container">         
        <FontAwesomeIcon icon={faTimes} 
                         onClick={props.handleDeclineReview.bind(this, props.review.rId)}
                         className="decline-icon" 
                         data-tip="Decline" />
    </div>  
)

const renderAcceptAction = (props) => (
    <div className="rev-actions-container">         
        <FontAwesomeIcon icon={faCheck} 
                         onClick={props.handleAcceptReview.bind(this, props.review.rId)}
                         className="accept-icon" 
                         data-tip="Accept" />
    </div>  
)

const ReviewCardManage = props => (
    <div className="rev-card-manage">
        {/* Content Section */}
        <div className="rev-card-content">
            {/* Title Row */}
            <div className="rev-card-title">
                <div className="from-container">
                    From: &nbsp;
                    <span className="rev-card-value">{props.review.rFullName}</span>
                    <span className="rev-card-email">{ props.review.email ? `(${props.review.email})` : '' }</span>
                </div>
                <div className="to-container">
                    &nbsp; To: &nbsp;
                    <span className="rev-card-value">{props.review.therapistFirstName} {props.review.therapistLastName}</span>
                </div>
            </div>

            {/* Body Row */}
            <div className="rev-card-body">
                {/* Score Section */}
                
                    
                    {props.review.score || props.review.score===0 ? (
                        <div className="rev-card-rating">
                        <div className="rating-label">Rating: </div>
                    <Rating placeholderRating={props.review.score}
                                    emptySymbol={<img src={ratingStarEmpty} className="icon" alt="ratingEmpty" width="21" height="21"/> }
                                    placeholderSymbol={<img src={ratingStarFull} className="icon" alt="ratingFull" width="21" height="21" />} 
                                    readonly/>
                                    </div>
                ) : ''}
                
                {/* Review Section */}
                <div className="rev-card-review">
                    <div className="review-label">{props.review.text? 'Review:' : 'Reply:'}</div>
                    <div className="review-content">{props.review.text || props.review.reply}</div>
                </div>
            </div>

        </div>
        {/* Actions Section */}
        <div className="rev-card-actions">
            {renderActions(props)}
        </div>
    </div>
)

export default ReviewCardManage