import React from 'react'
import { Link } from 'react-router-dom'

import ButtonRoundGradient from '../../components/ButtonRoundGradient/ButtonRoundGradient'
import './Blog.scss'

const Blog = props => (

    <div className="row blog-listing">
        <div className="col-md-6 f-art-thb-container">
            <img className="blog-listing--image" src={props.image} alt="blogImg" loading="lazy"/>
        </div>
        <div className="col-md-6 blog-listing--right">
            <div className="blog-listing--date">{props.date}</div>
            <Link to={props.url} className="blog-listing--title">{props.title}</Link>
            <div className="blog-feat-snippet">{props.snippet}</div>
            <Link to={props.url} className="blog-listing--title fb-read-button">
                <ButtonRoundGradient text="Read More" otherClass="btn-small" />
            </Link>

        </div>
    </div>
);

export default Blog
