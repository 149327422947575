import React from "react";
import HeaderUser from "../../../components/HeaderUser/HeaderUser";
import "./PaymentFirst.scss";
import "../../../components/RoundInputGradient/RoundInputGradient";
import MainWrapper from "../MainWrapper/MainWrapper";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./CheckoutForm";
import {
  reqListMembershipPlans,
  reqSubscribeUserToPlan,
  reqCustomerSwitchPlan,
  reqCancelCustomerSubscription
} from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PlanCard from "./PlanCard";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import PopupConfirm from "../../../components/PopupConfirm/PopupConfirm";
import Modal from "../../../components/Modal/Modal";
import scrollToComponent from "react-scroll-to-component";
import { Redirect } from "react-router-dom";

const stripePk = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

class PaymentFirst extends React.Component {
  therapist = {};
  authHeader = {};
  loadPlansUrl = "payment/plans/list";
  subscribeToPlanUrl = "customer/membership/register";
  switchPlanUrl = "customer/membership/change";
  cancelSubscriptionUrl = "customer/membership/cancel";
  freeplan = {
    id: 0,
    name: "Free Plan",
    price: 0,
    benefits: [
      'Picture, Bio',
      'Specialities, Location',
      'Contact form without public contact information'
    ]
  };

  state = {
    plans: [],
    pageLoading: false,
    showModal: false
  };

  componentDidMount = () => {
    this.therapist = this.props.userDetails;
    this.authHeader = {
      Authorization: `Bearer ${this.therapist.token}`,
      "Content-type": "application/json"
    };

    if (this.props.userDetails.cardData) {
      this.setState(() => ({
        userHasCard: true
      }));
    }
    this.loadMemberShipPlans();
  };

  componentWillReceiveProps = newProps => {
    let apiData = newProps.apiData;

    if (apiData.membershipPlans && apiData.membershipPlans.success) {
      let plans = apiData.membershipPlans.plans.map(p => ({
        id: p.id,
        name: p.nickname,
        price: p.amount / 100,
        benefits: p.metadata.Benefits.split("#")
      }));

      this.setState(() => ({
        plans: plans
      }));
    } else if (apiData.switchPlan && apiData.switchPlan.success) {
      this.setState(() => ({
        pageLoading: false
      }));
    } else if (apiData.switchPlan && apiData.switchPlan.error) {
      this.setState(() => ({
        pageLoading: false,
        errorMessage: apiData.switchPlan.message
      }));
      this.stripeErrorHandler(apiData.switchPlan.message);
    } else if (apiData.cancelPlan && apiData.cancelPlan.success) {
      this.setState(() => ({
        pageLoading: false
      }));
    } else if (apiData.subscribeToPlan && apiData.subscribeToPlan.success) {
      this.setState(() => ({
        pageLoading: false,
        showModal: false,
        redirect: true
      }));
    } else if (apiData.subscribeToPlan && apiData.subscribeToPlan.error) {
      this.setState(() => ({
        pageLoading: false,
        errorMessage: apiData.subscribeToPlan.message,
        showModal: false,
        redirect: true
      }));
      this.stripeErrorHandler(apiData.subscribeToPlan.message);
    }
  };

  stripeErrorHandler = errorMessage => {
    let popupTitle = "Payment error",
      popupText = errorMessage,
      confirmText = "Ok",
      cancelText = " ";

    this.setState(() => ({
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.hidePopup,
      confirmText: confirmText,
      cancelText: cancelText
    }));
  };

  loadMemberShipPlans = () => {
    let rConfig = {
      body: {},
      headers: this.authHeader,
      resource: this.loadPlansUrl
    };

    this.props.actions.reqListMembershipPlans(rConfig);
  };

  hidePopup = () => {
    this.setState(() => ({
      showPopup: false
    }));
    let el = this.refs.wrap;

    if (!this.therapist.cardData) {
      scrollToComponent(el, { duration: 10 });
    }
  };

  closeModalHandler = () => {
    this.setState(() => ({
      showModal: false
    }));
  };

  handleChoosePlan = planId => {
    if (!this.props.userDetails.cardData) {
      this.displayNoCardPopup();
      return;
    }

    let popupTitle =
      planId === 0 ? "Cancel Subscription?" : "Subscribe To Plan?";

    let popupText =
      planId === 0
        ? "Confirm cancelling current subscription?"
        : "Confirm Subscribing to this plan?";

    this.setState(() => ({
      choosenPlan: planId,
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.confirmChoosePlan,
      confirmText: null,
      cancelText: null
    }));
  };

  displayNoCardPopup = () => {
    let popupTitle = "No Card Saved",
      popupText =
        "Please add your card information before subscribing to a plan.",
      confirmText = "Ok",
      cancelText = " ";

    this.setState(() => ({
      showPopup: true,
      popupText: popupText,
      popupTitle: popupTitle,
      confirmAction: this.hidePopup,
      confirmText: confirmText,
      cancelText: cancelText
    }));
  };

  confirmChoosePlan = () => {
    if (this.state.choosenPlan !== 0) {
      this.choosePayedPlan();
    } else {
      this.cancelSubscription();
    }
  };

  showFreePlan = () => {
    this.setState(() => ({
      showModal: true
    }));
  };

  handleChooseFreePlan = () => {
    this.setState(() => ({
      showModal: false,
      redirect: true
    }));
  };

  cancelSubscription = () => {
    const rConfig = {
      body: {},
      headers: this.authHeader,
      resource: this.cancelSubscriptionUrl,
      userDetails: this.props.userDetails
    };

    this.setState(
      {
        pageLoading: true
      },
      () => {
        this.props.actions.reqCancelCustomerSubscription(rConfig);
      }
    );

    this.handleChooseFreePlan()

  };

  choosePayedPlan = () => {
    const rConfig = {
      body: {
        planId: this.state.choosenPlan
      },
      headers: this.authHeader,
      resource: this.props.userDetails.planData
        ? this.switchPlanUrl
        : this.subscribeToPlanUrl,
      userDetails: this.props.userDetails
    };

    this.setState(
      {
        pageLoading: true
      },
      () => { this.props.actions.reqSubscribeUserToPlan(rConfig); }
    );

  };

  isPlanActive = p => {
    //console.log("User details: ",this.props.userDetails)
    let planData = this.props.userDetails.planData,
      planId = planData ? planData.id : null;

    let active = false;

    if ((planId && p.id === planId) || (!planId && p.id === 0)) {
      active = true;
    }

    return active;
  };

  renderPlans = () => {
    let plans = this.state.plans.map((p, idx) => {
      p.active = this.isPlanActive(p);
      return (
        <PlanCard plan={p} handleChoosePlan={this.handleChoosePlan} key={idx} />
      );
    });

    // let freePlan = {
    //   id: 0,
    //   name: 'Free Plan',
    //   price: 0,
    //   benefits: [
    //     'Picture, Bio, Specialities & Contact Info',
    //     'Link to your website',
    //     'Contact form on public profile'
    //   ]
    // }

    // let freePanCard = (
    //   <PlanCard
    //     plan={freePlan}
    //     handleChoosePlan={this.handleChoosePlan}
    //     key='free-plan'
    //   />
    // )

    // plans.push(freePanCard)

    return <div className="plans-container">{plans}</div>;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/profile" />;
    }
    return (
      <MainWrapper page="payment-first">
        <PopupLoader display={this.state.pageLoading} />

        <div id="payment-first">
          {this.state.showModal ? (
            <div onClick={this.closeModalHandler} className="back-drop"></div>
          ) : null}
          <PopupConfirm
            display={this.state.showPopup}
            text={this.state.popupText}
            title={this.state.popupTitle}
            hidePopup={this.hidePopup}
            calcelAction={this.hidePopup}
            confirmText={this.state.confirmText}
            cancelText={this.state.cancelText}
            confirmAction={this.state.confirmAction}
          ></PopupConfirm>

          <Modal
            display={this.state.showModal}
            className="modal"
            close={this.closeModalHandler}
          >
            <PlanCard
              plan={this.freeplan}
              handleChoosePlan={this.cancelSubscription}
            />
          </Modal>
          <HeaderUser adminFullName="John Doe" />
          <div className="page-background">
            {/* Page Content */}
            <div className="page-content">
              {/* Sidebar */}

              {/* Inner Content */}
              <div className="inner-content-container-first">
                <div className="inner-content">
                  <div className="main-content">
                    
                    {/* Membership Plans */}
                    <h1 className="title column">Choose Membership Plan</h1>
                    <div className="plans">{this.renderPlans()}</div>
                    <h4 className="last-line">
                      I don't want to support Autism Assistant. I want a{" "}
                      <button
                        onClick={this.showFreePlan}
                        className="button-link"
                      >
                        free package.
                      </button>
                    </h4>
                    <div className="top-row" ref="wrap">
                      {/* Title */}
                      {/* Card Information */}
                      <h1 className="page-title">
                        Payment Information For Your Services
                      </h1>
                    </div>
                    <div className="stripe-payment-method">
                      <StripeProvider apiKey={stripePk}>
                        <div className="payment-info">
                          <Elements>
                            <CheckoutForm />
                          </Elements>
                        </div>
                      </StripeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }
}

const mapStateToProps = state => ({
  apiData: state.therapistManagePayment,
  userDetails: state.userDetails
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqListMembershipPlans: bindActionCreators(
        reqListMembershipPlans,
        dispatch
      ),
      reqSubscribeUserToPlan: bindActionCreators(
        reqSubscribeUserToPlan,
        dispatch
      ),
      reqCustomerSwitchPlan: bindActionCreators(
        reqCustomerSwitchPlan,
        dispatch
      ),
      reqCancelCustomerSubscription: bindActionCreators(
        reqCancelCustomerSubscription,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFirst);

// export default Payment
