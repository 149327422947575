export const REQ_ADD_INSURANCE = 'REQ_ADD_INSURANCE'
export const REC_ADD_INSURANCE = 'REC_ADD_INSURANCE'

export const REQ_GET_INSURANCES = 'REQ_GET_INSURANCES'
export const REC_GET_INSURANCES = 'REC_GET_INSURANCES'

export const REQ_DELETE_INSURANCE = 'REQ_DELETE_INSURANCE'
export const REC_DELETE_INSURANCE = 'REC_DELETE_INSURANCE'

export const REQ_UPDATE_INSURANCE = 'REQ_UPDATE_INSURANCE'
export const REC_UPDATE_INSURANCE = 'REC_UPDATE_INSURANCE'

export const REQ_SEARCH_INSURANCES =  'REQ_SEARCH_INSURANCES'
export const REC_SEARCH_INSURANCES =  'REC_SEARCH_INSURANCES'


export const reqAddInsurance = (config) => ({type: REQ_ADD_INSURANCE, config: config})
export const recAddInsurance = data => ({type: REC_ADD_INSURANCE, data})

export const reqGetInsurances = (config) => ({type: REQ_GET_INSURANCES, config: config})
export const recGetInsurances = data => ({type: REC_GET_INSURANCES, data})

export const reqDeleteInsurance = (config) => ({type: REQ_DELETE_INSURANCE, config: config})
export const recDeleteInsurance = data => ({type: REC_DELETE_INSURANCE, data})

export const reqUpdateInsurance = (config) => ({type: REQ_UPDATE_INSURANCE, config: config})
export const recUpdateInsurance = data => ({type: REC_UPDATE_INSURANCE, data})

export const reqSearchInsurances = (config) => ({type: REQ_SEARCH_INSURANCES, config: config})
export const recSearchInsurances = data => ({type: REC_SEARCH_INSURANCES, data})
 