import React from "react";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import { connect } from "react-redux";
import PopupLoader from "../../components/PopupLoader/PopupLoader";
import ArticleContent from "./ArticleContent/ArticleContent";
import ArticleTags from "./ArticleTags/ArticleTags";
import ShareButtons from "./ShareButtons/ShareButtons";
import SimilarArticles from "./SimilarArticles/SimilarArticles";
import AboutAuthor from "./AboutAuthor/AboutAuthor";
import Footer from "../../components/Footer/Footer";
import ArticleReviewsSection from "./ArticleReviewsSection/ArticleReviewsSection";
import {
  reqGetArticleGuest,
  reqGetSimilarArticles,
  reqSubmitArticleReview,
  reqSubmitArticleReply,
  reqListAcceptedArticleReviews,
  reqListAcceptedArticleReplies,
} from "./actions";
import { bindActionCreators } from "redux";
import "./Article.scss";
import { Helmet } from "react-helmet";

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;
class Article extends React.Component {
  state = {
    pageLoading: false,
    reviewName: "",
    reviewComment: "",
    reviewEmail: "",
    replyName: "",
    replyComment: "",
    replyEmail: "",
    acceptedReviews: [],
    acceptedReplies: [],
    authorDetails: {},
    scrolledToTop: false,
  };

  getArticleUrl = "blog/article/view";
  getSimilarArticlesUrl = "blog/article/related";
  reviewArticleUrl = "blog/article/review";
  replyArticleUrl = "blog/article/reply";
  listAcceptedRevsUrl = "therapist/article/reviews/list/accepted";
  listAcceptedRepliesUrl = "therapist/article/replies/list/accepted";

  componentDidUpdate = () => {
    if (!this.state.scrolledToTop) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      this.disableCaption();
      this.externalLinks();
    }
  };

  componentWillMount = (_) => {
    let { id: articleId } = this.props.match.params;
    this.setState(
      {
        pageLoading: true,
        articleId: articleId,
      },
      () => {
        this.loadArticle(articleId);
      }
    );
  };

  componentWillReceiveProps = (newProps) => {
    const apiData = newProps.apiData;
    let { id: articleId } = newProps.match.params;

    // console.log("apiData", apiData);

    if (articleId !== this.state.articleId) {
      this.setState(
        {
          pageLoading: true,
          articleId: articleId,
        },
        () => {
          this.loadArticle(articleId);
        }
      );
    } else if (apiData.articleLoaded && apiData.articleLoaded.success) {
      this.setState(
        {
          pageLoading: false,
          article: apiData.articleLoaded.article.data,
          authorDetails: apiData.articleLoaded.authorDetails,
        },
        () => {
          this.loadSimilarArticles(this.state.articleId);
          this.loadArticleReviews(this.state.articleId);
          this.loadArticleReplies(this.state.articleId);
        }
      );
    } else if (apiData.similarArticles && apiData.similarArticles.success) {
      let similarArticles = []
        .concat(apiData.similarArticles.byTags)
        .concat(apiData.similarArticles.byAuthor);

      this.setState(() => ({
        similarArticles: similarArticles.slice(0, 4),
      }));
    } else if (apiData.revSubmitted && apiData.revSubmitted.success) {
      this.setState(() => ({
        pageLoading: false,
        reviewName: "",
        reviewEmail: "",
        reviewComment: "",
        reviewSuccessMessage:
          "Your comment was successfully submitted. It will be displayed on the page once approved by an administrator.",
      }));
    } else if (apiData.repSubmitted && apiData.repSubmitted.success) {
      this.setState(() => ({
        pageLoading: false,
        replyName: "",
        replyEmail: "",
        replyComment: "",
        replySuccessMessage:
          "Your reply was successfully submitted. It will be displayed on the page once approved by an administrator.",
      }));
    } else if (apiData.acceptedReviews && apiData.acceptedReviews.success) {
      let acceptedReviews = apiData.acceptedReviews.reviews;

      this.setState(() => ({
        pageLoading: false,
        acceptedReviews: acceptedReviews,
      }));
    } else if (apiData.acceptedReplies && apiData.acceptedReplies.success) {
      let acceptedReplies = apiData.acceptedReplies.replies;

      this.setState(() => ({
        pageLoading: false,
        acceptedReplies: acceptedReplies,
      }));
    }
  };

  disableCaption = () => {
    let figcaption = document.querySelector("figcaption");

    if (figcaption) {
      let figcaptionValue = figcaption.innerHTML;

      if (figcaptionValue === `<br data-cke-filler="true">`) {
        figcaption.style.display = "none";
      }
    }
  };

  externalLinks = () => {
    let allLinks = document.querySelectorAll("a");

    for (var entry of allLinks.entries()) {
      let elementId = entry[0],
        element = allLinks[elementId];

      if (!entry[1].href.includes("autismassistant")) {
        let att = document.createAttribute("target");
        let rel = document.createAttribute("rel");
        att.value = "_blank";
        rel.value = "nofollow";
        element.setAttributeNode(att);
        element.setAttributeNode(rel);
      }
    }
  };

  loadArticle = (id) => {
    this.props.actions.reqGetArticleGuest({
      body: {},
      headers: null,
      resource: `${this.getArticleUrl}?articleId=${id}`,
    });
  };

  loadSimilarArticles = (id) => {
    this.props.actions.reqGetSimilarArticles({
      body: {},
      headers: null,
      resource: `${this.getSimilarArticlesUrl}?articleId=${id}`,
    });
  };

  loadArticleReviews = (id) => {
    this.props.actions.reqListAcceptedArticleReviews({
      body: {},
      headers: null,
      resource: `${this.listAcceptedRevsUrl}?articleId=${id}`,
    });
  };

  loadArticleReplies = (id) => {
    this.props.actions.reqListAcceptedArticleReplies({
      body: {},
      headers: null,
      resource: `${this.listAcceptedRepliesUrl}?articleId=${id}`,
    });
  };

  handleChange = (ev) => {
    this.setState({ scrolledToTop: true });
    let name = ev.target.name,
      val = ev.target.value;

    this.setState(() => ({
      [name]: val,
    }));
  };

  handleSubmitReview = () => {
    let formValid = this.validateReviewForm();

    if (!formValid.value) {
      this.setState(() => ({
        reviewFormError: formValid.formError,
        formErrorField: formValid.formErrorField,
      }));
      return;
    } else {
      this.setState(
        {
          reviewFormError: "",
          formErrorField: "",
        },
        () => {
          this.submitReview();
        }
      );
    }
  };

  handleSubmitReply = (reviewId) => {
    let formValid = this.validateReplyForm();

    if (!formValid.value) {
      this.setState(() => ({
        replyFormError: formValid.formError,
        formErrorField: formValid.formErrorField,
      }));
      return;
    } else {
      this.setState(
        {
          replyFormError: "",
          formErrorField: "",
        },
        () => {
          this.submitReply(reviewId);
        }
      );
    }
  };

  submitReview = (_) => {
    let fullName = this.state.reviewName,
      email = this.state.reviewEmail,
      comment = this.state.reviewComment,
      articleId = this.state.articleId;

    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqSubmitArticleReview({
          body: {
            articleId,
            fullName,
            email,
            comment,
          },
          headers: null,
          resource: this.reviewArticleUrl,
        });
      }
    );
  };

  submitReply = (reviewId) => {
    let fullName = this.state.replyName,
      email = this.state.replyEmail,
      comment = this.state.replyComment,
      reviewID = reviewId,
      articleId = this.state.articleId;

    this.setState(
      {
        pageLoading: true,
      },
      () => {
        this.props.actions.reqSubmitArticleReply({
          body: {
            reviewID,
            fullName,
            email,
            comment,
            articleId,
          },
          headers: null,
          resource: this.replyArticleUrl,
        });
      }
    );
  };

  validateReviewForm = () => {
    if (!this.state.reviewName) {
      return {
        value: false,
        formError: "Name field is mandatory",
        formErrorField: "reviewName",
      };
    }

    if (!this.state.reviewEmail) {
      return {
        value: false,
        formError: "Email field is mandatory",
        formErrorField: "reviewEmail",
      };
    }

    if (!this.state.reviewComment) {
      return {
        value: false,
        formError: "Comment field is mandatory",
        formErrorField: "reviewComment",
      };
    }

    return {
      value: true,
    };
  };

  validateReplyForm = () => {
    if (!this.state.replyName) {
      return {
        value: false,
        formError: "Name field is mandatory",
        formErrorField: "replyName",
      };
    }

    if (!this.state.replyEmail) {
      return {
        value: false,
        formError: "Email field is mandatory",
        formErrorField: "replyEmail",
      };
    }

    if (!this.state.replyComment) {
      return {
        value: false,
        formError: "Reply text field is mandatory",
        formErrorField: "replyComment",
      };
    }

    return {
      value: true,
    };
  };

  render = (_) => {
    let metaTitle = "",
      metaDescription = "",
      canonical = "",
      schema = "";


    if (this.state && this.state.article) {

      metaTitle = this.state.article.metaTitle;
      metaDescription = this.state.article.metaDescription;
      canonical = this.state.article.canonical;
      schema = this.state.article.schema_org;
    }

    return (
      <div>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
          {canonical !== "" ? <link rel="canonical preload" href={canonical} /> : ""}
          {schema !== "" && schema ? <script type="application/ld+json" defer>{`{${ schema }}`}</script> : ""}
        </Helmet>

        <div id="article">
          {/* Loader */}
          <PopupLoader display={this.state.pageLoading} />

          {/* Header */}
          <HeaderGuestSimple menu="article" />

          {/* Content */}
          <div id="article-container" className="content">
            <ArticleContent article={this.state.article} />
          </div>
          <div id="article" className="share-button">
            {/* Share Buttons */}
            <ShareButtons url={this.state.getArticleUrl} />
          </div>
          {/* Tags */}
          <ArticleTags tags={(this.state.article || {}).tags} />

          {/* About The Author */}
          <AboutAuthor
            author={this.state.authorDetails}
            avatarUrl={`${baseBackendUrl}/${this.state.authorDetails.avatarUrl}`}
          />

          {/* Similar Articles */}
          <SimilarArticles articles={this.state.similarArticles} />

          {/* Reviews */}

          <ArticleReviewsSection
            handleChange={this.handleChange}
            submitReview={this.handleSubmitReview}
            revFormError={this.state.reviewFormError}
            repFormError={this.state.replyFormError}
            formErrorField={this.state.formErrorField}
            revSuccessMessage={this.state.reviewSuccessMessage}
            repSuccessMessage={this.state.replySuccessMessage}
            reviewName={this.state.reviewName}
            reviewEmail={this.state.reviewEmail}
            reComment={this.state.reviewComment}
            acceptedReviews={this.state.acceptedReviews}
            submitReply={this.handleSubmitReply}
            acceptedReplies={this.state.acceptedReplies}
            repFullName={this.state.replyName}
            repEmail={this.state.replyEmail}
            repComment={this.state.replyComment}
          />
        </div>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  apiData: state.guestArticleReducer,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetArticleGuest: bindActionCreators(reqGetArticleGuest, dispatch),
      reqGetSimilarArticles: bindActionCreators(
        reqGetSimilarArticles,
        dispatch
      ),
      reqSubmitArticleReview: bindActionCreators(
        reqSubmitArticleReview,
        dispatch
      ),
      reqSubmitArticleReply: bindActionCreators(
        reqSubmitArticleReply,
        dispatch
      ),
      reqListAcceptedArticleReviews: bindActionCreators(
        reqListAcceptedArticleReviews,
        dispatch
      ),
      reqListAcceptedArticleReplies: bindActionCreators(
        reqListAcceptedArticleReplies,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
// export default Article
