import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_SIGNIN_ADMIN, recSigninAdmin} from './actions'
import { recFetchUserDetails } from '../../../utils/FetchUser/actions'

import fetchData from '../../../utils/api'


function* signinAdmin(action) {

  try {
    const data = yield call(fetchData, action.config.body, action.config.resource)
    if (data.user) {
      yield put(recFetchUserDetails(data))
    }

    yield put(recSigninAdmin(data))

  } catch (e) {
      console.log("Api error: ", e)
   }

}


export default function* adminSigninSaga() {
    yield takeLatest(REQ_SIGNIN_ADMIN, signinAdmin);
};
