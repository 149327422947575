import { REC_LIST_REVIEWS_MANAGEMENT, REC_TOGGLE_REVIEW_STATUS_MANAGEMENT,
    REC_LIST_REPLIES_MANAGEMENT, REC_TOGGLE_REPLY_STATUS_MANAGEMENT } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_LIST_REVIEWS_MANAGEMENT:
            return {
                lrManagement: data
            };

        case REC_TOGGLE_REVIEW_STATUS_MANAGEMENT:
            return {
                revStatusUpdated: data
            };
        
        case REC_LIST_REPLIES_MANAGEMENT:
            return {
                listRepliesManagement: data
            };

        case REC_TOGGLE_REPLY_STATUS_MANAGEMENT:
            return {
                repStatusUpdated: data
            };
        
        default:
            return state
    }
}