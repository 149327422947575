import React from 'react'
import CheckboxRound from '../../../components/CheckboxRound/CheckboxRound'
import './PlanCard.scss'

const renderSponsorshipPlaces = (places, plan, props) => (
    <ul className="position-list">
        {places.map( (p, i) => (
            <li className="place-item" key={i}>
                <div className="checkbox-container">
                    <CheckboxRound id={`plan-${plan.id}-check-place-${i}`}
                                        place={p}
                                        handleCheck={props.handleCheckPlace.bind(this, {plan: plan, place: p})}
                                        isChecked={ isPlaceChecked(props, p) }/>
                </div>
                <div className="place-name-container">
                    <span className="place-name">{p.name}</span>
                </div>
                <div className="palce-price-container">
                    <div className="place-price">{buildPriceString(p)}</div>
                </div>
            </li>
        ))}
    </ul>
)

const isPlaceChecked = (props, place) => {
    let isActive = props.plan.id === (props.activePlan || {}).plan_id,
        isChecked = place.isChecked,
        selectedPlace = (props.activePlan || {}).bid_place_id === place.id,
        isEdit = props.plan.isEdit

     if (isActive && selectedPlace && !isEdit) {
         return true;
     } else if (!isActive && isChecked) {
         return true
     } else if (isActive && isEdit && isChecked) {
         return true
     } else {
         return false
     }

    
}


const buildPriceString = place => (
    <div className="price-row-container">
        <span className="price-val">{`${place.currency}${place.price}`}</span>
        <span className="price-val-subtext">per impression</span>
    </div>
)

const renderButtonSection = (props, plan) => {
    let planActive = props.plan.id === (props.activePlan || {}).plan_id;

    return planActive ? renderCancelAndChange(props) : renderChooseButton(props, plan)
}

const renderChooseButton = (props, plan) => (
    <button 
        type="button" 
        className="btn btn-default btn-smalll" 
        onClick={props.handleChoosePlan.bind(this, plan)}
    >{plan.id === 1 ? `Choose & Pay` : `Choose Plan`}</button>
)

const renderCancelAndChange = props => (
    <div className="cancel-change-container">
        {/* Cancel Btn */}
        <button 
            type="button" 
            className="btn btn-default card-btn-small" 
            onClick={props.handleCancelPlan.bind(this, props.plan)}
        >{props.plan.isEdit ? `Cancel` : 'Cancel Plan'}</button>
        {/* Change Btn */}
        <button 
            type="button" 
            className="btn btn-default card-btn-small" 
            onClick={props.handleChangePlan.bind(this, props.plan)}
        >{props.plan.isEdit ? (props.plan.id === 1 ? 'Change & Pay' : 'Change') : 'Change Plan'}</button>
    </div>
)

const renderBudgetSection = props => {
    // console.log("ActivePlan: ", props.activePlan)
    let planActive = props.plan.id === (props.activePlan || {}).plan_id,
        isEdit = props.plan.isEdit;
    
    return isEdit 
        ? rederBudgetInputAndDetails(props)
        : planActive 
            ? renderBudgetDetails(props) 
            : renderBudgetInput(props)
    //return planActive ? renderBudgetDetails(props) : renderBudgetInput(props)
}

const renderBudgetText = props => {
    let fixedPlan = props.plan.id === 1,
        goPlan = props.plan.id === 2,
        isEdit = props.plan.isEdit;

    if (fixedPlan) {
        return isEdit ? 'Add $' : 'Budget $'
    } else if (goPlan) {
        return 'Max. Budget $'
    }
    
}

const renderBudgetInput = props => (
    <div className={`plan-budget-container ${noMargitBottom(props)}`}>
        <div className="budget-input-outer">
            <div className="budget-input-inner">
                <div className="budget-label">{renderBudgetText(props)}</div>
                <div className="budget-input-container">
                    <input 
                        type="number" 
                        placeholder="100" 
                        onChange={props.handleBudgetChange.bind(this, props.plan)}/>
                </div>
            </div>
        </div>
    </div>
)

const noMargitBottom = props => ( props.plan.isEdit ? 'marginbottom0' : '')

const renderBudgetDetails = props => (
    <div className="plan-settings-container">
        <div className="pc-title">Balance Details:</div>
        {/* Payed Amount */}
        <div className="psc-row">
            <div className="psc-label">{props.plan.id === 1 ? `Paid Amount: ` : `Budget Limit: `}</div>
            <div className="psc-value">{`$${props.activePlan.payed_amount}`}</div>
        </div>
        {/* Current Balance */}
        <div className="psc-row">
            <div className="psc-label">{props.plan.id === 1 ? `Current Balance: ` : `Used So Far: `}</div>
            <div className="psc-value">{`$${calculateCurrentBalance(props)}`}</div>
        </div>
    </div>
)

const calculateCurrentBalance = props => {
    let balance = 0;

    if (props.plan.id === 1) {
        balance = props.activePlan.current_balance
    } else {
        balance = props.activePlan.used || 0
    }

    return balance
}

const rederBudgetInputAndDetails = props => (
    <div className="budget-section">
        {renderBudgetInput(props)}
        {renderBudgetDetails(props)}
    </div>
)

const renderActivePlanText = props => (
    props.plan.id === (props.activePlan || {}).plan_id ? '- Active Plan -' : ''
)

const PlanCard = props => (
    <div className="sponsorship-plan-card">
        {/* Plan Name */}
        <div className="plan-name">{props.plan.name}</div>
        <div className="active-plan-marker">{renderActivePlanText(props)}</div>
        {/* Plan Places & Prices */}
        <div className="settings-form-container">
            <div className="plan-subtitle">Choose the place you want to show up on</div>
            {renderSponsorshipPlaces(props.plan.places, props.plan, props)}
        </div>
        {/* Budget */}
        {renderBudgetSection(props)}
        {/* Choose Button */}
        <div className="choose-plan-container">
            {renderButtonSection(props, props.plan)}
        </div>
    </div>
)

export default PlanCard