import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

class AttachedInsItem  extends React.Component {

    state = {
        name: this.props.insurance.name,
    }

    render() {
        return (
            <li key={this.props.index} className="ins-at-item">
                <div className="ins-at-outer">
                    <div className="ins-at-info">
                        <h4 className="ins-at-name" >{this.state.name}</h4>
                    </div>
                    <div className="ins-at-actions" >
                        <FontAwesomeIcon icon={faTimesCircle} 
                                        className="delete-icon" 
                                        data-tip="Remove Insurance" 
                                        onClick={this.props.removeAction.bind(this, this.props.insurance.id)}/>
                    </div>
                </div>
                
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }
    
}

export default AttachedInsItem