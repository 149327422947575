import React from 'react'
import './DrawerToggleButton.css'

const drawerToggleButton = props => (
    <button type="button" className={`toggle-button ${props.className}`}  aria-label="mobile-devices-menu" onClick={props.click}>
        <div className="toggle-button__line"/>
        <div className="toggle-button__line"/>
        <div className="toggle-button__line"/>
    </button>
)

export default drawerToggleButton