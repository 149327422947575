import React from 'react'
import defaultAvatar       from '../../../static/images/desktop/annonymous-user-icon.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockOpen, faSearch, faLock, faTrashAlt, faUserTag, faEye }      from '@fortawesome/free-solid-svg-icons'
import ReactTooltip        from 'react-tooltip'
import Rating              from 'react-rating'
import ratingStarFull      from '../../../static/images/desktop/rating-star-full.png'
import ratingStarEmpty     from '../../../static/images/desktop/rating-star-empty.png'
import { Link }            from 'react-router-dom'

const baseBackendUrl = process.env.REACT_APP_BACKEND_BASE_PATH;

class TherapistItem  extends React.Component {

    therapist = this.props.therapist

    getLockValue = () => {
        // console.log("get therapist: ", this.props.therapist)
        return this.props.therapist.banned ? faLock : faLockOpen
        
    }

    renderActions = () => {
    
        return (
            <div className="ai-actions-container">
                    <FontAwesomeIcon icon={this.getLockValue()} 
                                            onClick={this.props.handleLocking.bind(this, {id: this.props.therapist.id, lock: !this.props.therapist.banned})}
                                            className="edit-icon" 
                                            data-tip={`${this.props.therapist.banned ? 'Unlock' : 'Lock'}`} />

                    <FontAwesomeIcon icon={faTrashAlt} 
                                        className="delete-icon" 
                                        data-tip="Delete" 
                                        onClick={this.props.handleDelete.bind(this, this.props.therapist.id)}/>

                    <FontAwesomeIcon icon={faUserTag} 
                                        className={ this.props.therapist.betaUser ? `yellow-icon` : 'edit-icon'}
                                        data-tip="Beta Tester" 
                                        onClick={this.props.toggleBetaTesterStatus.bind(this, this.props.therapist.id, !this.props.therapist.betaUser)}/>

                    <FontAwesomeIcon icon={faEye} 
                                        className={this.props.therapist.isPublic ? `green-icon` : 'red-icon'} 
                                        data-tip="Toggle public therapist profile" 
                                        onClick={this.props.toglePublishProfileStatus.bind(this, this.props.therapist.id, !this.props.therapist.isPublic)}/>

                    <FontAwesomeIcon icon={faSearch} 
                                        data-tip="Display search positions this month" 
                                        onClick={this.props.showSearchPosition.bind(this, this.props.therapist.id)}/>
            </div>
        )
    }

    getMembershipPlan = (membershipPlanId) => {
        switch (membershipPlanId) {
            case "plan_GFE5jaBAqNZh4b" : return <span className="tr-details-value">Silver Membership</span>
            case "plan_GFE4uPMujF5GF5" : return <span className="tr-details-value">Gold Membership</span>
            case "plan_GFE5jzh9sKKWJT" : return <span className="tr-details-value">Platinum Membership</span>
            default: return <span className="tr-details-value">Free Plan Membership</span>
        }

    }

    renderAvatarUrl = () => (
        this.therapist.avatarUrl 
            ? `${baseBackendUrl}/${this.therapist.avatarUrl}`
            : defaultAvatar
    )

    render() {
        return (
            <li key={this.props.index} className="ai-list-item">
                {/* Avatar Container */}
                <div className="ai-image-container">
                    <img src={this.renderAvatarUrl()} alt='therapist-avatar' />
                </div>
                {/* User Profile Container */}
                <div className="ai-info-container">
                    {/* Name & Title */}
                    <div className="ai-main-info" >
                    <Link to={`/therapist/profile/${this.therapist.id}`} >
                        <span className="ai-u-name" >
                            {`${this.therapist.firstName === null || this.therapist.firstName === '' ? '' : this.therapist.firstName} 
                            ${this.therapist.lastName === null || this.therapist.firstName === '' ? '' : ','+this.therapist.firstName}
                            ${this.therapist.businessName}`}
                        </span>
                    </Link>
                    </div>
                    {/* Details */}
                    <div className="ai-details-info">
                        {/* Email */}
                        <div className="tr-reviews-row">
                            <Rating placeholderRating={this.therapist.averageScore || 0}
                                    emptySymbol={<img src={ratingStarEmpty} className="icon" alt="ratingEmpty" width="21" height="21"/> }
                                    placeholderSymbol={<img src={ratingStarFull} className="icon" alt="ratingFull"  width="21" height="21" />} 
                                    readonly/>
                            <span className="tr-reviews-value">{ `(${this.therapist.reviewCnt} reviews)` }</span>
                        </div>
                        {/* Phone */}
                        <div className="tr-details-container">
                            <span className="tr-details-value">{ this.therapist.email || '' }</span>
                        </div>
                        <div className="tr-details-container">
                            {this.getMembershipPlan(this.therapist.membershipPlan)}
                        </div>
                        <div className="tr-details-container">
                            {this.therapist.sponsorshipPlanId === 1 ? `Sponsorship Plan: Fixed Rate Plan - Bided: ${this.therapist.sponsorshipBid}` : ""}
                            {this.therapist.sponsorshipPlanId === 2 ? `Sponsorship Plan: Pay As You Go - Bided: ${this.therapist.sponsorshipBid}` : ""}
                        </div>
                        <div className="tr-details-container">
                            {this.therapist.sponsorshipPlanId === 1 ? `Budget: ${this.therapist.sponsorshipBudget}$ - Remaining: ${this.therapist.sponsorshipBalance}$` : ''}
                            {this.therapist.sponsorshipPlanId === 2 ? `Budget: ${this.therapist.sponsorshipBudget}$ - Remaining: ${this.therapist.sponsorshipBudget-this.therapist.sponsorshipUsed}$` : ''}
                        </div>
                    </div>
                </div>
                {/* Actions Container */}
                {this.renderActions()}
                <ReactTooltip type="info" place="right"/>
            </li>
        )
    }
}

export default TherapistItem