import React from 'react'
import leftShape from '../../../static/images/desktop/admin-signin-left-shape.png'
import rightEllipse from '../../../static/images/desktop/admin-signin-righ-ellipse.png'
import rightShape from '../../../static/images/desktop/admin-signin-right-shape.png'
import leftEllipse from '../../../static/images/desktop/admin-signin-left-ellipse.png'
import logoTextBlue from '../../../static/images/desktop/logo-text-blue.png'
import './ActivateAccount.scss';
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { reqActivateAccount } from './actions';
import PopupLoader from '../../../components/PopupLoader/PopupLoader';

class AdminActivateAccount extends React.Component {

    state = {
        password: '',
        confirmPassword: '',
        errors: [],
        redirectUrl: '/admin/dashboard',
        redicrect: false,
        errorMessage: '',
        pageLoading: false,
        accountActivated: false
    }

    submitUrl = 'admin/activate/account';

    componentWillReceiveProps = (newProps) => {
        const apiData = newProps.apiData;
        let errorMessage = ''
        let newState = {}

        if (apiData.error) {
            if (apiData.display) {
                errorMessage = apiData.error
            } else {
                errorMessage = 'An error occured'
            }
            newState = {
                pageLoading: false,
                errorMessage: errorMessage
            }
        } else if (apiData.success) {
            newState = {
                pageLoading: false,
                accountActivated: true
            }
        }

        this.setState( () => (newState))
    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    }

    handleChange = (event) => {

        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({[inputName]: value}))
    }

    handleSubmit = () => {

        this.setState({
            errors: [],
            errorMessage: '',
            pageLoading: true
        }, () => {

            const formValid = this.validateForm()

            if (!formValid) {
                return;
            }

            const rBody = {
                password: this.state.password,
                token: this.props.match.params.token,
                uid: this.props.match.params.uid
            }

            this.props.reqActivateAccount(rBody, this.submitUrl);

        })
    }

    validateForm = () => {

        let formValid = true;

        if (this.state.password === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'password'}
                ],
                errorMessage: 'Password is mandatory',
                pageLoading: false
            }))
        } else if (this.state.confirmPassword === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'confirmPassword'}
                ],
                errorMessage: 'Confirm Password is mandatory',
                pageLoading: false
            }))
        }

        return formValid

    }

    render() {

        // if (this.state.redirect) {
        //     return (
        //         <Redirect to={this.state.redirectUrl} />
        //     )
        // }


        if (this.state.accountActivated) {
            return (
                <div id="admin-signin">
                    <div className="page-background">
                        <img src={leftShape} alt="leftShape" className="left-shape" />
                        <img src={rightEllipse} alt="rightEllipse" className="right-ellipse" />
                        <img src={rightShape} alt="rightShape" className="right-shape" />
                        <img src={leftEllipse} alt="leftEllipse" className="left-ellipse" />
                    </div>

                    <div className="page-content">
                        <div className="successfull-text">
                            <span className="grey-text">Account Succesfully Activated! You can now</span>
                            <Link to="/admin/signin" >
                                <span className="link-text"> Login </span>
                            </Link>
                            <span className="grey-text">using your credentials</span>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div id="admin-signin">

                <PopupLoader display={this.state.pageLoading} />

                <div className="page-background">
                    <img src={leftShape} alt="leftShape" className="left-shape" />
                    <img src={rightEllipse} alt="rightEllipse" className="right-ellipse" />
                    <img src={rightShape} alt="rightShape" className="right-shape" />
                    <img src={leftEllipse} alt="leftEllipse" className="left-ellipse" />
                </div>

                <div className="page-content">
                    <Link to="/">
                        <img src={logoTextBlue} className="logo" alt="logo" />
                    </Link>
                    <h1 className="page-title">Admin Account Activation</h1>
                    <div className="page-description">
                        <span className="grey-text">Please set a password in order to activate your account.</span>
                    </div>
                    <div className="form-container">
                        <form>
                            <div className="input-row">
                                <input type="password"
                                       placeholder="Password"
                                       name="password"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("password")}`}/>
                            </div>
                            <div className="input-row">
                                <input type="password"
                                       placeholder="Confirm Password"
                                       name="confirmPassword"
                                       onChange={this.handleChange}
                                       className={ `basic-input ${this.inputHasErrors("confirmPassword")}`}/>
                            </div>


                            <div>
                                <p className="error-message">{this.state.errorMessage}</p>
                            </div>

                            <div className="login-row">
                                <ButtonRoundGradient text="Activate" onClick={this.handleSubmit} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({apiData: state.adminActivateAccount})

const mapDispatchToProps = dispatch => bindActionCreators({reqActivateAccount}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdminActivateAccount)
