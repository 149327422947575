import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_GET_ALL_ANALITICS, REQ_GET_ALL_ANALITIC_POINTS, 
        recGetAllAnalitics, recGetAllAnaliticPoints} from './actions'
import fetchData from '../../../utils/api'




function* getAnalitics(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetAllAnalitics(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* getAnaliticPoints(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetAllAnaliticPoints(data))
   
  } catch (e) {console.log("Api error: ", e)}

}





export default function* supportEmailSaga() {
    yield takeLatest(REQ_GET_ALL_ANALITICS, getAnalitics);
    yield takeLatest(REQ_GET_ALL_ANALITIC_POINTS, getAnaliticPoints);
    
};  