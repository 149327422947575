import { REC_MESSAGE_CONTACT } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_MESSAGE_CONTACT:
            //console.log("received message response: ", data)
            return data;
        default:
            return state
    }
}