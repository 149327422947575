import React from 'react'
// import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebookF } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons' 
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faPinterest } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import './MediaIcon.scss'

const getUrl = props => {
    switch (props.label) {
        case 'facebook': 
            return 'https://www.facebook.com/Autism-Assistant-116457263056897/?__tn__=%2Cd%2CP-R&eid=ARBfnoSmmMOmsiXs2UuNy0bjSmxsm2AolIvjgcLEz-Y3h16PmX8MM_DvY8iinYM0P-jLPMVSIHlKKmXG'
        
        case 'linkedin':
            return 'https://www.linkedin.com/company/autism-assistant/about/?viewAsMember=true'

        case 'instagram': 
            return 'https://www.instagram.com/autismassistant/'

        case 'twitter':
            return 'https://twitter.com/aut_assist'

        case 'pinterest':
            return 'https://www.pinterest.com/em1swank/'
        
        case 'youtube':
            return 'https://www.youtube.com/channel/UCYk2tBGhBNph2Ny7uzXG3vg'

        default:
            return ''
    }
}

const getIcon = props => {
    switch (props.label) {
        case 'facebook': 
            return faFacebookF
        
        case 'linkedin':
            return faLinkedinIn

        case 'instagram': 
            return faInstagram

        case 'twitter':
            return faTwitter

        case 'pinterest':
            return faPinterest

        case 'youtube':
            return faYoutube

        default: 
            return ''
    }
}

// const addInheritedStyle = (props) => ({
//     width: props.circleSize || "70px",
//     height: props.circleSize || "70px",
//     background: props.circleBackground || "#ddd"
// })

const hasGradientStyle = props => (
    props.gradient ? 'mi-gradient' : ''
)

const MediaIcon = props => (
    <div className={`media-icon-container ${hasGradientStyle(props)}`}>  
        <a href={getUrl(props)} target="blank" rel="noopener nofollow" aria-label="social media icons">
            <div className={`media-icon-outer ${hasGradientStyle(props)} ${ props.circleClass }`}>
                <div className={`media-icon-icon ${hasGradientStyle(props)}`}>
                    <FontAwesomeIcon icon={getIcon(props)} size={props.iconSize || "2x"}/>
                </div>
            </div>
        </a>   
    </div>
)

export default MediaIcon