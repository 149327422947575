import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_LIST_MESSAGES_ADMIN, 
  recListMessagesAdmin} from './actions'
import fetchData from '../../../utils/api'




function* listMessages(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recListMessagesAdmin(data))
   
  } catch (e) {console.log("Api error: ", e)}

}



export default function* manageMessagesAdmin() {
    yield takeLatest(REQ_LIST_MESSAGES_ADMIN, listMessages);
    
};