export const REQ_SAVE_STRIPE_CUSTOMER = 'REQ_SAVE_STRIPE_CUSTOMER'
export const REC_SAVE_STRIPE_CUSTOMER = 'REC_SAVE_STRIPE_CUSTOMER'

export const REQ_REMOVE_USER_CARD = 'REQ_REMOVE_USER_CARD'
export const REC_REMOVE_USER_CARD = 'REC_REMOVE_USER_CARD'

export const REQ_FETCH_STRIPE_CUSTOMER = 'REQ_FETCH_STRIPE_CUSTOMER'
export const REC_FETCH_STRIPE_CUSTOMER = 'REC_FETCH_STRIPE_CUSTOMER'

export const REQ_LIST_MEMBERSHIP_PLANS = 'REQ_LIST_MEMBERSHIP_PLANS'
export const REC_LIST_MEMBERSHIP_PLANS = 'REC_LIST_MEMBERSHIP_PLANS'

export const REQ_SUBSCRIBE_USER_TO_PLAN = 'REQ_SUBSCRIBE_USER_TO_PLAN'
export const REC_SUBSCRIBE_USER_TO_PLAN = 'REC_SUBSCRIBE_USER_TO_PLAN'

export const REQ_CUSTOMER_SWITCH_PLAN = 'REQ_CUSTOMER_SWITCH_PLAN'
export const REC_CUSTOMER_SWITCH_PLAN = 'REC_CUSTOMER_SWITCH_PLAN'

export const REQ_CANCEL_CUSTOMER_SUBSCRIPTION = 'REQ_CANCEL_CUSTOMER_SUBSCRIPTION'
export const REC_CANCEL_CUSTOMER_SUBSCRIPTION = 'REC_CANCEL_CUSTOMER_SUBSCRIPTION'

export const REQ_BUY_EXTRA_BENEFITS = 'REQ_BUY_EXTRA_BENEFITS'
export const REC_BUY_EXTRA_BENEFITS = 'REC_BUY_EXTRA_BENEFITS'

export const reqSaveStripeCustomer = (config) => ({type: REQ_SAVE_STRIPE_CUSTOMER, config: config})
export const recSaveStripeCustomer = data => ({type: REC_SAVE_STRIPE_CUSTOMER, data})

export const reqFetchStripeCustomer = (config) => ({type: REQ_FETCH_STRIPE_CUSTOMER, config: config})
export const recFetchStripeCustomer = data => ({type: REC_FETCH_STRIPE_CUSTOMER, data})

export const reqRemoveUserCard = (config) => ({type: REQ_REMOVE_USER_CARD, config: config})
export const recRemoveUserCard = data => ({type: REC_REMOVE_USER_CARD, data})

export const reqListMembershipPlans = (config) => ({type: REQ_LIST_MEMBERSHIP_PLANS, config: config})
export const recListMembershipPlans = data => ({type: REC_LIST_MEMBERSHIP_PLANS, data})

export const reqSubscribeUserToPlan = (config) => ({type: REQ_SUBSCRIBE_USER_TO_PLAN, config: config})
export const recSubscribeUserToPlan = data => ({type: REC_SUBSCRIBE_USER_TO_PLAN, data})

export const reqCustomerSwitchPlan = (config) => ({type: REQ_CUSTOMER_SWITCH_PLAN, config: config})
export const recCustomerSwitchPlan = data => ({type: REC_CUSTOMER_SWITCH_PLAN, data})

export const reqCancelCustomerSubscription = (config) => ({type: REQ_CANCEL_CUSTOMER_SUBSCRIPTION, config: config})
export const recCancelCustomerSubscription = data => ({type: REC_CANCEL_CUSTOMER_SUBSCRIPTION, data})

export const reqBuyExtraBenefits = (config) => ({type: REQ_BUY_EXTRA_BENEFITS, config: config})
export const recBuyExtraBenefits = data => ({type: REC_BUY_EXTRA_BENEFITS, data})