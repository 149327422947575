export const REQ_RESET_PASSWORD = 'REQ_RESET_PASSWORD'
export const REC_RESET_PASSWORD = 'REC_RESET_PASSWORD'

export const reqResetPassword = (data, resource) => {
    return  {
        type: REQ_RESET_PASSWORD,
        data,
        resource
    }
}

export const recResetPassword = data => ({type: REC_RESET_PASSWORD, data})


