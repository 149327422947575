import React from "react";
import assistanceForAllImage from "../../static/images/desktop/autism-assistance-for-all.jpg";
import emilysSignature from "../../static/images/desktop/emily-swank-signature.png";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import "./AssistanceForAll.scss";

class AssistanceForAll extends React.Component {
  state = {
    menu: "",
    searchMethod: "location",
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Assistance For All pro bono program | Autism Assistant</title>
          <meta
            name="description"
            content="We created “Assistance For All” to provide pro bono services to
                families who otherwise wouldn’t have access to care."
          ></meta>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
        </Helmet>
        <HeaderGuestSimple menu="AssistanceForAll" />
        <section className="container mt-50">
          <h1 className="text-center mt-30 aboutUsHeading">
            Assistance For All
          </h1>
          <div className="row our-story">
            <div className="text-section mt-50">
              <img
                src={assistanceForAllImage}
                alt="autism pro bono program"
                className="about-img"
              />
              <h2>
                <i>Dear parents,</i>
              </h2>
              <p>
                I founded Autism Assistant to place as many quality resources at
                your disposal as possible when you're navigating an autism
                diagnosis. I know from experience that finding a provider your
                child can connect with is one of the greatest benefits for their
                age-appropriate development.
              </p>

              <p>
                The latest studies on autism spectrum disorder also support
                this. It has been shown that access to services and support in
                the early years of life (early intervention) is a key factor for
                growth and development in children with delays and disabilities.
                However, there's still a staggering amount of autistic children
                who aren't able to receive these services.
              </p>
              <p>
                Whether it be from overloaded government programs, loss of
                health insurance, or financial hardships, too many children are
                falling through the cracks.
              </p>
              <p>
                One of our earliest goals at Autism Assistant was to help with
                this problem and we had a feeling there were providers on our
                platform who shared our vision.
              </p>
              <p>
                We created “Assistance For All” to provide pro bono services to
                families who otherwise wouldn’t have access to care. We began
                reaching out to our providers listed on our platform and were
                blown away by the overwhelming response we got from different
                therapists across the country who were willing to donate their
                time and services to families in need.
              </p>
              <p>
                We have providers in several different therapy specialties
                offering services ranging from one on one therapy to virtual
                parent training programs. If you find yourself in need of
                therapy for your child and you don’t have the means to access
                care, we invite you to apply for our “Assistance For All”
                program.
              </p>
              <p>
                Emily Swank <br></br>Autism Assistant -- Because finding help
                should be easy
              </p>
              <img
                src={emilysSignature}
                alt="emily's signature"
                className="signature"
              />

              <div className="apply-button-container mt-30 mb-30">
                <a
                  className="btn btn-default apply-button"
                  href="https://hushforms.com/autismassistant-pro-bono"
                  target="blank"
                  rel="nofollow"
                >
                  Apply For Pro Bono Therapy
                </a>
              </div>
              
              <p>
                <i>
                  If you’re a provider and you’d like more information about
                  participating in our “Assistance For All” program,
                  <a href="mailto:e.swank@autismassistant.com">
                    {" "}
                    get in touch with us
                  </a>
                  .
                </i>
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default AssistanceForAll;
