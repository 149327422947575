export const REQ_ADD_LOCATION = 'REQ_ADD_LOCATION'
export const REC_ADD_LOCATION = 'REC_ADD_LOCATION'

export const REQ_GET_ALL_LOCATIONS = 'REQ_GET_ALL_LOCATIONS'
export const REC_GET_ALL_LOCATIONS = 'REC_GET_ALL_LOCATIONS'

export const REQ_DEL_LOCATION = 'REQ_DEL_LOCATION'
export const REC_DEL_LOCATION = 'REC_DEL_LOCATION'


export const reqAddLocation = (config) => ({type: REQ_ADD_LOCATION, config: config})
export const recAddLocation = data => ({type: REC_ADD_LOCATION, data})

export const reqDelLocation = (config) => ({type: REQ_DEL_LOCATION, config: config})
export const recDelLocation = data => ({type: REC_DEL_LOCATION, data})

export const reqGetAllLocations = (config) => ({type: REQ_GET_ALL_LOCATIONS, config: config})
export const recGetAllLocations = data => ({type: REC_GET_ALL_LOCATIONS, data})

 