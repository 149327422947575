import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_LIST_REVIEWS_MANAGEMENT, REQ_TOGGLE_REVIEW_STATUS_MANAGEMENT, REQ_LIST_REPLIES_MANAGEMENT, REQ_TOGGLE_REPLY_STATUS_MANAGEMENT,
  recListReviewsManagement, recToggleReviewStatusManagement, recListRepliesManagement, recToggleReplyStatusManagement } from './actions'
import fetchData from '../../../utils/api'




function* listReviews(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListReviewsManagement(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* toggleReviewStatus(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recToggleReviewStatusManagement(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* listReplies(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recListRepliesManagement(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

function* toggleReplyStatus(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recToggleReplyStatusManagement(data))
   
  } catch (e) {console.log("Api error: ", e)}

}

export default function* manageTherapistReviews() {
    yield takeLatest(REQ_LIST_REVIEWS_MANAGEMENT, listReviews);
    yield takeLatest(REQ_TOGGLE_REVIEW_STATUS_MANAGEMENT, toggleReviewStatus);
    yield takeLatest(REQ_LIST_REPLIES_MANAGEMENT, listReplies);
    yield takeLatest(REQ_TOGGLE_REPLY_STATUS_MANAGEMENT, toggleReplyStatus);
    
};