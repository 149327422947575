import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_ADD_INSURANCE,
         REQ_GET_INSURANCES,
         REQ_DELETE_INSURANCE,
         REQ_UPDATE_INSURANCE,
         REQ_SEARCH_INSURANCES,
         recAddInsurance,
         recGetInsurances,
         recUpdateInsurance,
         recSearchInsurances,
         recDeleteInsurance} from './actions'

import fetchData from '../../../utils/api'


function* addInsurance(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers)
    yield put(recAddInsurance(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* getInsurances(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'GET')
    yield put(recGetInsurances(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* deleteInsurance(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recDeleteInsurance(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* updateInsurance(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recUpdateInsurance(data))

  } catch (e) {console.log("Api error: ", e)}

}

function* searchInsurances(action) {
  try {
    const data = yield call(fetchData, action.config.body, action.config.resource, action.config.headers, 'POST')
    yield put(recSearchInsurances(data))

  } catch (e) {console.log("Api error: ", e)}
}


export default function* adminManageInsurances() {
    yield takeLatest(REQ_ADD_INSURANCE, addInsurance);
    yield takeLatest(REQ_GET_INSURANCES, getInsurances);
    yield takeLatest(REQ_DELETE_INSURANCE, deleteInsurance);
    yield takeLatest(REQ_UPDATE_INSURANCE, updateInsurance);
    yield takeLatest(REQ_SEARCH_INSURANCES, searchInsurances);
};
