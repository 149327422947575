import { call, put, takeLatest } from 'redux-saga/effects'
import { REQ_ACTIVATE_USER_ACCOUNT, recActivateUserAccount} from './actions'

import fetchData from '../../../utils/api'


function* activateAccount(action) {

    try {
        const data = yield call(fetchData, action.data, action.resource)
        yield put(recActivateUserAccount(data))

    } catch (e) {
        console.log("Api error: ", e)
    }

}


export default function* userActivateAccountSaga() {
    yield takeLatest(REQ_ACTIVATE_USER_ACCOUNT, activateAccount);
};
