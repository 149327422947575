import React from "react";
import HeaderUser from "../../../components/HeaderUser/HeaderUser";
import SidebarTherapist from "../../../components/SidebarTherapist/SidebarTherapist";
import "./Specialities.scss";
import "../../../components/RoundInputGradient/RoundInputGradient";
import RoundInputGradient from "../../../components/RoundInputGradient/RoundInputGradient";
import {
  reqGetAllSpecialities,
  reqAddSpeciality,
  reqRemoveSpeciality
} from "./actions";
import MenuStepNavigation from "../../../components/MenuStepNavigation/MenuStepNavigation";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import SpecialityItem from "./SpecialityItem";
import PopuConfirm from "../../../components/PopupConfirm/PopupConfirm";
import MainWrapper from "../MainWrapper/MainWrapper";
import AttachedSpItem from "./AttachedSpItem";

class TherapistSpecialities extends React.Component {
  state = {
    searchSpName: "",
    allSpecialities: [],
    displayedSpecialities: [],
    attachedSpecialities: [],
    pageLoading: false,
    showPopup: false
  };

  therapist = {};
  authHeader = {};
  allSpecialitiesUrl = "specialities/list";
  addSpecialityUrl = "therapist/specialities/add";
  removeSpecialityUrl = "therapist/specialities/remove";

  componentDidMount = () => {
    this.therapist = this.props.userDetails;
    this.authHeader = {
      Authorization: `Bearer ${this.therapist.token}`,
      "Content-type": "application/json"
    };

    this.setState(
      {
        attachedSpecialities: this.therapist.specialities
      },
      () => {
        this.loadAllSpecialities();
      }
    );
  };

  loadAllSpecialities = () => {
    this.setState(
      {
        pageLoading: true
      },
      () => {
        const rConfig = {
          body: {},
          resource: this.allSpecialitiesUrl,
          headers: null
        };
        this.props.actions.reqGetAllSpecialities(rConfig);
      }
    );
  };

  getAvailiableSpecialities = (allSpecialities, attachedSpecialities) => {
    const availiableSpecialities = allSpecialities.filter(
      s => attachedSpecialities.filter(ats => ats.id === s.id).length === 0
    );

    return availiableSpecialities;
  };

  componentWillReceiveProps = newProps => {
    const apiData = newProps.apiData;

    if (apiData.allSpecialities && apiData.allSpecialities.specialities) {
      this.setState(() => ({
        pageLoading: false,
        allSpecialities: apiData.allSpecialities.specialities,
        displayedSpecialities: this.getAvailiableSpecialities(
          apiData.allSpecialities.specialities,
          this.state.attachedSpecialities
        )
      }));
    } else if (apiData.removeSpeciality && apiData.removeSpeciality.success) {
      this.setState(
        {
          pageLoading: false,
          attachedSpecialities: apiData.removeSpeciality.specialities,
          displayedSpecialities: this.getAvailiableSpecialities(
            this.state.allSpecialities,
            apiData.removeSpeciality.specialities
          )
        },
        () => {
          this.filterDisplayedSpecialities();
        }
      );
    } else if (apiData.addSpeciality && apiData.addSpeciality.success) {
      this.setState(
        {
          pageLoading: false,
          attachedSpecialities: apiData.addSpeciality.specialities,
          displayedSpecialities: this.getAvailiableSpecialities(
            this.state.allSpecialities,
            apiData.addSpeciality.specialities
          )
        },
        () => {
          this.filterDisplayedSpecialities();
        }
      );
    }
  };

  handleChange = event => {
    let inputName = event.target.name,
      value = event.target.value;

    this.setState(
      {
        [inputName]: value
      },
      () => {
        this.filterDisplayedSpecialities();
      }
    );
  };

  filterDisplayedSpecialities = () => {
    const searchFilter = this.state.searchSpName.toLowerCase();
    const filteredSpecialities = this.state.allSpecialities.filter(s => {
      const match = s.name.toLowerCase().indexOf(searchFilter) > -1,
        notAdded =
          this.state.attachedSpecialities.filter(ats => ats.id === s.id)
            .length === 0;

      return match & notAdded;
    });

    this.setState(() => ({
      displayedSpecialities: filteredSpecialities
    }));
  };

  renderAvailableSpecialities = () => {
    let items = [];

    for (let i = 0; i < this.state.displayedSpecialities.length; i++) {
      let speciality = this.state.displayedSpecialities[i];

      items.push(
        <SpecialityItem
          index={speciality.id}
          key={speciality.id}
          speciality={speciality}
          attachAction={this.attachSpecialityHandler}
        />
      );
    }

    if (!items.length) {
      return (
        <div className="message-row">
          <p>There are no more available specialities to add.</p>
        </div>
      );
    }

    return <ul className="specialities-list">{items}</ul>;
  };

  handleRemoveSpeciality = spId => {
    this.setState(() => ({
      showPopup: true,
      removeId: spId,
      popupText: "Confirm removing this from your specialities?"
    }));
  };

  confirmRemove = () => {
    const rConfig = {
      body: {
        speciality: this.state.removeId
      },
      headers: this.authHeader,
      resource: this.removeSpecialityUrl,
      userDetails: this.props.userDetails
    };

    this.setState(
      {
        pageLoading: true
      },
      () => {
        this.props.actions.reqRemoveSpeciality(rConfig);
      }
    );
  };

  renderAttachedSpecialities = () => {
    const items = [];

    for (let i = 0; i < this.state.attachedSpecialities.length; i++) {
      const speciality = this.state.attachedSpecialities[i];

      items.push(
        <AttachedSpItem
          index={speciality.id}
          key={speciality.id}
          speciality={speciality}
          removeAction={this.handleRemoveSpeciality}
        />
      );
    }

    return items;
  };

  attachSpecialityHandler = spId => {
    const rConfig = {
      body: {
        specialities: `${spId}`
      },
      headers: this.authHeader,
      resource: this.addSpecialityUrl,
      userDetails: this.props.userDetails
    };

    this.setState(
      {
        pageLoading: true
      },
      () => {
        this.props.actions.reqAddSpeciality(rConfig);
      }
    );
  };

  hidePopup = () => {
    this.setState(() => ({ showPopup: false }));
  };

  render() {
    return (
      <MainWrapper page="specialities">
        <div id="therapist-manage-specialities">
          <PopupLoader display={this.state.pageLoading} />
          <PopuConfirm
            display={this.state.showPopup}
            text={this.state.popupText}
            title={this.state.popupTitle}
            hidePopup={this.hidePopup}
            calcelAction={this.hidePopup}
            confirmAction={this.confirmRemove}
          />

          <HeaderUser adminFullName="John Doe" />
          <div className="page-background">
            {/* Page Content */}
            <div className="page-content">
              {/* Sidebar */}
              <SidebarTherapist
                page="specialities"
                roleId={this.therapist.roleId}
              />
              {/* Inner Content */}
              <div className="inner-content-container">
                <div className="inner-content">
                  <MenuStepNavigation
                    leftLink="/profile"
                    leftText="Account Information"
                    rightLink="/therapist-locations"
                    rightText="Add Locations"
                  />

                  <div className="top-row">
                    {/* Title */}
                    <h1 className="page-title">Your Specialities</h1>
                  </div>

                  <div className="attached-specialities-section">
                    <ul className="attached-specialities-list">
                      {this.renderAttachedSpecialities()}
                    </ul>
                  </div>

                  <div className="results-section">
                    {/* Results Title */}
                    <h2 className="page-title">Available Specialities</h2>
                    <div className="search-row">
                      <RoundInputGradient
                        placeholder="Filter by Name"
                        className="sp-search-input"
                        inputName="searchSpName"
                        value={this.state.searchSpName}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="specialities-container">
                      {this.renderAvailableSpecialities()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    );
  }
}

const mapStateToProps = state => ({
  apiData: state.therapistManageSpecialities,
  userDetails: state.userDetails
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqGetAllSpecialities: bindActionCreators(
        reqGetAllSpecialities,
        dispatch
      ),
      reqAddSpeciality: bindActionCreators(reqAddSpeciality, dispatch),
      reqRemoveSpeciality: bindActionCreators(reqRemoveSpeciality, dispatch)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TherapistSpecialities);
// export default TherapistSpecialities
