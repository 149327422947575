import React from 'react'
import './CookiesConsent.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

class CookiesConsent extends React.Component {

    state = {
        cookiesAccepted: false,
        detailsOpen: false
    };

    componentDidMount = () => {
        // localStorage.removeItem('cookiesAccepted');
        let cookiesAccepted = localStorage.getItem('cookiesAccepted')
        this.setState(() => ({ cookiesAccepted: !!cookiesAccepted}))
    };

    componentWillReceiveProps = () => {
        let cookiesAccepted = localStorage.getItem('cookiesAccepted')
        this.setState(() => ({ cookiesAccepted: !!cookiesAccepted}))

        // console.log("cookie: ", cookiesAccepted)
    };

    acceptHandler = () => {
        localStorage.setItem('cookiesAccepted', true)
        this.setState(() => ({cookiesAccepted: true}))
    }

    renderEmpty = () => ('')

    renderDetails = () => (
        <div className="ccc-text-details">Autism Assistant uses cookies and local storage for site-related data only. There is no passive data collection, and no personal data will be collected or shared with third parties without your consent. Read our <Link to="/privacy-policy">Privacy Policy</Link> for further information.</div>
    )

    renderDetailsSection = () => (
        this.state.detailsOpen ?
            this.renderDetails()
            : this.renderEmpty()
    )

    renderDetailsText = () => (
        this.state.detailsOpen ?
            'Less Details'
            : 'More Details'
    )

    toggleDetailsStatus = () => {
        this.setState((prevState) => ({ detailsOpen: !prevState.detailsOpen}))
    }

    renderCookiesConsent = () => (
        <div className="custom-cookies-consent-container">

            <div className="ccc-main-container">
                <div className="ccc-text">
                    <div className="ccc-text-main">This website uses cookies in order to function correctly. Read our <Link to="/privacy-policy">Privacy Policy</Link> for further information.</div>
                </div>
                <div className="ccc-actions">
                    <div className="ccc-action-button ccc-accept-button" onClick={this.acceptHandler}>I understand</div>
                    <div className="ccc-action-button ccc-details-button" onClick={this.toggleDetailsStatus}>
                        <span className="ccc-details-text">{ this.renderDetailsText() }</span>
                        <FontAwesomeIcon icon={faAngleDoubleDown} />
                    </div>
                </div>
            </div>

            <div className="ccc-details-container">
                { this.renderDetailsSection() }
            </div>
        </div>
    )

    render = () => (
       this.state.cookiesAccepted ?
            this.renderEmpty()
            : this.renderCookiesConsent()
    )
}

export default CookiesConsent
