import React from "react";
import Footer from "../../../components/Footer/Footer";
import "./PublicProfile.scss";
import ReviewSection from "./ReviewSection";
import ProfileSection from "./ProfileSection";
import ReviewsSection from "./ReviewsSection";
import LocationSection from "./LocationSection";
import PopupLoader from "../../../components/PopupLoader/PopupLoader";
import { reqLogProfileVisit } from "./actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import HeaderGuestSimple from "../../../components/Header/HeaderGuestSimple";
import ButtonRoundGradient from "../../../components/ButtonRoundGradient/ButtonRoundGradient";
import { Link } from "react-router-dom";

const exclusionCountryCode = process.env.REACT_APP_ANALYTICS_EXCLUSION_COUNTRY_CODE,
      exclusionIp = process.env.REACT_APP_ANALYTICS_EXCLUSION_IP

class PublicProfile extends React.Component {
  state = {
    pageLoading: false,
    items: {},
    error: "",
  };

  getProfileUrl = "/therapist/profile/get";
  logProfileVisitUrl = "analitics/profile/visit/log";

  componentDidUpdate() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  componentDidMount = () => {

    fetch("https://geolocation-db.com/json/")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            items: result,
          });
          if (result.country_code !== exclusionCountryCode && result.IPv4 !== exclusionIp) {
            this.logProfileVisit(this.props.match.params.id);
          }
        },
        (error) => {
          this.setState({
            error,
          });
          this.logProfileVisit(this.props.match.params.id);
        }
      );
  };

  logProfileVisit = (id) => {
    const rConfig = {
      body: {
        therapistId: id,
      },
      headers: null,
      resource: this.logProfileVisitUrl,
    };
    // console.log("state items: ", this.state.items);
    this.props.actions.reqLogProfileVisit(rConfig);
  };

  // componentWillReceiveProps = (newProps) => {
  // console.log("Profile new props: ", newProps)
  // }

  handleChoosProfileImg = (ev) => {
    let profileImgFile = ev.target.files[0];
    let previewFile = URL.createObjectURL(profileImgFile);

    this.setState(() => ({
      profileImgFile: profileImgFile,
      previewFile: previewFile,
    }));
  };

  render() {
    return (
      <div id="therapist-public-profile">
        <PopupLoader display={this.state.pageLoading} />

        <HeaderGuestSimple menu="public-profile" />

        {/* Contact Details */}
        <ProfileSection
          therapistId={this.props.match.params.id}
          previewFile={this.state.previewFile}
        />

        {/* Location */}
        <LocationSection therapistId={this.props.match.params.id} />

        <div className="more-therapists">
          <Link to="/specialists">
            <ButtonRoundGradient text={"See More Specialists"} />
          </Link>
        </div>
        {/* Review Section */}
        <ReviewSection therapistId={this.props.match.params.id} />

        {/* Published Reviews */}
        <ReviewsSection therapistId={this.props.match.params.id} />

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: state.publicProfileReducer,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      reqLogProfileVisit: bindActionCreators(reqLogProfileVisit, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicProfile);

// export default PublicProfile
