export const REQ_LIST_THERAPISTS_ADMIN = 'REQ_LIST_THERAPISTS_ADMIN'
export const REC_LIST_THERAPISTS_ADMIN = 'REC_LIST_THERAPISTS_ADMIN'

export const REQ_LIST_ALL_SPEC_ADMIN_SEARCH = 'REQ_LIST_ALL_SPEC_ADMIN_SEARCH'
export const REC_LIST_ALL_SPEC_ADMIN_SEARCH = 'REC_LIST_ALL_SPEC_ADMIN_SEARCH'

export const REQ_SEARCH_THERAPISTS_ADMIN = 'REQ_SEARCH_THERAPISTS_ADMIN'
export const REC_SEARCH_THERAPISTS_ADMIN = 'REC_SEARCH_THERAPISTS_ADMIN'

export const REQ_THERAPISTS_LOCK_UNLOCK = 'REQ_THERAPISTS_LOCK_UNLOCK'
export const REC_THERAPISTS_LOCK_UNLOCK = 'REC_THERAPISTS_LOCK_UNLOCK'

export const REQ_THERAPISTS_DELETE = 'REQ_THERAPISTS_DELETE'
export const REC_THERAPISTS_DELETE = 'REC_THERAPISTS_DELETE'

export const REQ_THERAPISTS_TOGGLE_BETA_STATUS = 'REQ_THERAPISTS_TOGGLE_BETA_STATUS'
export const REC_THERAPISTS_TOGGLE_BETA_STATUS = 'REC_THERAPISTS_TOGGLE_BETA_STATUS'

export const REQ_THERAPISTS_TOGGLE_PUBLISH_STATUS = 'REQ_THERAPISTS_TOGGLE_PUBLISH_STATUS'
export const REC_THERAPISTS_TOGGLE_PUBLISH_STATUS = 'REC_THERAPISTS_TOGGLE_PUBLISH_STATUS'

export const REQ_SEARCH_POSITION = 'REQ_SEARCH_POSITION'
export const REC_SEARCH_POSITION = 'REC_SEARCH_POSITION'

export const reqListTherapists = (config) => ({type: REQ_LIST_THERAPISTS_ADMIN, config: config})
export const recListTherapists = data => ({type: REC_LIST_THERAPISTS_ADMIN, data})

export const reqListSpecialities = (config) => ({type: REQ_LIST_ALL_SPEC_ADMIN_SEARCH, config: config})
export const recListSpecialities = data => ({type: REC_LIST_ALL_SPEC_ADMIN_SEARCH, data})

export const reqSearchTherapists = (config) => ({type: REQ_SEARCH_THERAPISTS_ADMIN, config: config})
export const recSearchTherapists = data => ({type: REC_SEARCH_THERAPISTS_ADMIN, data})

export const reqTherapistLockUnlock = (config) => ({type: REQ_THERAPISTS_LOCK_UNLOCK, config: config})
export const recTherapistLockUnlock = data => ({type: REC_THERAPISTS_LOCK_UNLOCK, data})

export const reqDeleteTherapist = (config) => ({type: REQ_THERAPISTS_DELETE, config: config})
export const recDeleteTherapist = data => ({type: REC_THERAPISTS_DELETE, data})

export const reqTherapistToggleBetaStatus = (config) => ({type: REQ_THERAPISTS_TOGGLE_BETA_STATUS, config: config})
export const recTherapistToggleBetaStatus = data => ({type: REC_THERAPISTS_TOGGLE_BETA_STATUS, data})

export const reqTherapistTogglePublishStatus = (config) => ({type: REQ_THERAPISTS_TOGGLE_PUBLISH_STATUS, config: config})
export const recTherapistTogglePublishStatus = data => ({type: REC_THERAPISTS_TOGGLE_PUBLISH_STATUS, data})

export const reqSearchPosition = (config) => ({type: REQ_SEARCH_POSITION, config: config})
export const recSearchPosition = data => ({type: REC_SEARCH_POSITION, data})
