import React from 'react'
import ButtonRoundGradient from '../../../components/ButtonRoundGradient/ButtonRoundGradient'
import PopupLoader from '../../../components/PopupLoader/PopupLoader'
import { reqFormSubmit } from './actions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'



class ContactForm extends React.Component {


    state = {
        fullName: '',
        email: '',
        message: '',
        errors: [],
        errorMessage: '',
        successMessage: '',
        pageLoading: false,
        therapistId:''
    }

    sendFormUrl = 'therapist/form/send';

    componentWillReceiveProps = (props) => {
        // console.log("Props: ", props)

        const apiData = props.apiData
        
        if (apiData.success && apiData.message === 'Contact form sent') {
            this.setState(() => ({
                successMessage: 'Your form was successfully submitted.',
                pageLoading: false,
                fullName: '',
                email: '',
                message: ''
            }))
        }
    }

    handleChange = (event) => {

        let inputName = event.target.name,
            value = event.target.value

        this.setState(() => ({
            [inputName]: value
        }))
    }

    submitForm = () => {

        this.setState({
            errors: [],
            errorMessage: ''
        }, () => {

            if (!this.validateForm()) return;

            this.setState({
                pageLoading: true
            }, () => { this.reqSubmitForm() })
        })
    }

    reqSubmitForm = () => {

        // console.log("props.therapistId: ", this.props.therapistId)
        const rConfig = {
            body: {
                fullName: this.state.fullName,
                message: this.state.message,
                tid: this.props.therapistId,
                email: this.state.email
            },
            headers: null,
            resource: this.sendFormUrl
        }

        this.props.actions.reqFormSubmit(rConfig);
    }

    validateForm = () => {

        let formValid = true;


        if (this.state.email === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'email'}
                ],
                errorMessage: 'Email field is mandatory'
            }))
        } else if (this.state.fullName === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'fullName'}
                ],
                errorMessage: 'Name field is mandatory'
            }))
        } else if (this.state.message === '') {
            formValid = false;
            this.setState( () => ({
                errors: [
                    {input: 'message'}
                ],
                errorMessage: 'Message field is mandatory'
            }))
        }

        return formValid

    }

    inputHasErrors = (inputName) => {
        const errors = this.state.errors.filter(err => err.input === inputName)
        return errors.length ? 'input-error' : ''
    }

    render () {
        return (
            <section className="contact-form-section">
                <PopupLoader display={this.state.pageLoading} />
                <div className="review-first-row">
                    <div className="half-width">
                        <h3 className="section-title">Contact form</h3>
                    </div>
                    
                </div>
                <form className="review-form">
                    <div className="input-row">
                        <div className="leftside-input-container">
                            <input
                                type="text"
                                name="fullName"
                                className={ `basic-input half-input ${this.inputHasErrors("fullName")}`}
                                value={this.state.fullName}
                                onChange={this.handleChange}
                                placeholder="Name"/>
                        </div>
                        <div className="rightside-input-container">
                            <input
                                type="text"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                className={ `basic-input half-input ${this.inputHasErrors("email")}`}
                                placeholder="Email"/>
                        </div>
                    </div>
                    <div className="review-row">
                        <input
                            type="text"
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            className={ `basic-input full-input ${this.inputHasErrors("message")}`}
                            placeholder="Write your message"/>
                    </div>
                    <div className="info-row">
                        <span className="error-message">{this.state.errorMessage}</span>
                        <span className="success-message">{this.state.successMessage}</span>
                    </div>
                    <div className="submit-row">
                        <ButtonRoundGradient
                            text="Submit"
                            otherClass="small-btn submit-btn"
                            onClick={this.submitForm}/>
                    </div>
                </form>
            </section>
        )
    }
}

const mapStateToProps = state => ({
    apiData: state.publicProfileReducer
})

function mapDispatchToProps(dispatch) {
    return {
      actions: {
        reqFormSubmit: bindActionCreators(reqFormSubmit, dispatch),
      }
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
