import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'

// import './InsuranceList.scss'

class InsuranceList extends React.Component {

    state = {
        insurances: this.props.suggestedInsurances || [],
        isOpen: false
    }
    
    componentWillReceiveProps = (newProps) => {
        // console.log("newProps: ", newProps)
        this.setState(() => ({
            insurances: newProps.suggestedInsurances || [],
            isOpen: newProps.searchTerm !== '' && newProps.isOpen
        }))
    }

    renderList = () => {
        // console.log("this items: ", this.items)
        const items = [];

        for (let i = 0; i < this.state.insurances.length; i++) {
            const insurance = this.state.insurances[i];
            // console.log("speciality: ", speciality)
            const item = (
                <li 
                    className="suggested-ins-item" 
                    key={insurance.id} 
                    onClick={this.props.handleSelectInsurance.bind(this, insurance)}
                >
                    <div className="insurance-select-name">{insurance.name}</div>
                </li>
                
            )

            items.push(item);
        }

        return (
            <ul className="suggested-insurances-list">
                {items}
            </ul>
        )

    }

    handleClickOutside = () => {
        this.props.handleBlur()
        this.toggle();
    }
     
    toggle = () => {
        this.setState(() => ({
            isOpen: false
        }));
    }

    render() {
        return (
            <div id="ms-suggested-insurances" className={ `suggested-ins-container ${this.state.isOpen ? '' : 'hidden-element'}` } >
                {this.renderList()} 
            </div>
        )
    }

}

export default enhanceWithClickOutside(InsuranceList)