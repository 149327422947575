import { REC_SIGNUP_THERAPIST } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_SIGNUP_THERAPIST:
            // console.log("Signup Reducer data: ", data)
            return data;
        default:
            return state
    }
}