import { REC_GET_SUPPERT_EMAIL, REC_UPDATE_SUPPORT_EMAIL } from './actions';

export default (state = {}, {type, data}) => {

    switch (type) {
        case REC_GET_SUPPERT_EMAIL:
            return {
                supportEmail: data
            };
        
        case REC_UPDATE_SUPPORT_EMAIL:
            return {
                emailUpdated: data
            };
        
        default:
            return state
    }
}