import React from "react";
//import { Accordion, Card } from "react-bootstrap";

import "./Faq.scss";
import { Helmet } from "react-helmet";
// import {Link} from "react-router-dom";
import HeaderGuestSimple from "../../components/Header/HeaderGuestSimple";
import Footer from "../../components/Footer/Footer";
// import SideDrawer from "../../components/SideDrawer/SideDrawer";

class Faq extends React.Component {
  state = {
    sideDrawerOpen: false,
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => ({
      sideDrawerOpen: !prevState.sideDrawerOpen,
    }));
  };
  render() {
    // if (this.state.sideDrawerOpen) {
    //     let sideDrawer = <SideDrawer/>
    // }
    // eslint-disable-next-line
    return (
      <div>
        <Helmet>
          <meta name="robot" content="noindex"></meta>
          <meta name="googlebot" content="noindex"></meta>
        </Helmet>
        <div id="faq">
          {/* Loader */}

          {/* Header */}
          <HeaderGuestSimple
            menu=""
            menuVisible={this.state.sideDrawerOpen}
            drawerClickHandler={this.drawerToggleClickHandler}
          />

          {/* Main Container */}
          <div className="faq-container">
            <h1 className="page-title">Frequently Asked Questions</h1>
            <h2 className="mt-50">For Parents of Children With Autism</h2>
            {/* List Container */}
            <h3>What is Autism Assistant?</h3>
            <p>
              We’re a platform dedicated to families that have children on the
              spectrum. Our aim is to give you access to as many high quality
              resources as possible, including getting you in touch with autism
              therapists near you.
            </p>
            <h3>Why did you create Autism Assistant?</h3>
            <p>
              We founded Autism Assistant based on our own experience of how
              difficult it was to track down the right therapy providers for our
              children. After talking with many other parents who shared similar
              struggles, we wanted to create a website that would make it easier
              for parents to find the help they needed. No matter what{" "}
              <a href="https://autismassistant.com/autism-therapy-types">
                {" "}
                type of autism therapy
              </a>{" "}
              you’re looking for, or how many times you{" "}
              <a href="https://autismassistant.com/"> use our search forms </a>
              to find therapists, you won’t ever have to pay a dollar. You can
              read more about it in{" "}
              <a href="https://autismassistant.com/about-us">
                this section.
              </a>{" "}
            </p>
            <h3>Is your platform free to use?</h3>
            <p>
              Yes! We believe that finding help should be easy, so we won’t ever
              charge you for it.
            </p>
            <h3> How do I search for autism therapists?</h3>
            <p>
              Depending on what you’re looking for, you can find both autism
              specialists in your area, as well as telemedicine options. Simply
              head to our <a href="https://autismassistant.com/"> homepage </a>,
              check the type of search you’d like to perform (either by
              location, or for telemedicine), and then fill out the search
              form’s fields appropriately. Click on any of the results, and
              you’ll be taken to that therapist’s profile, where you can get in
              touch with them!
            </p>
            <h3>How do I contact the therapists?</h3>
            <p>
              After you{" "}
              <a href="https://autismassistant.com/specialists">
                search for an autism specialist
              </a>
              , simply click on their profile, and then either fill out their
              contact form, or make use of any of their displayed contact
              information, depending on each provider's profile.
            </p>
            <h3>Is your service available in my location?</h3>
            <p>
              While we aim to be a nationwide database, we currently do not have
              autism providers listed in every US state, yet. If you’d like us
              to search for and reach out to therapists in your area, please{" "}
              <a href="https://autismassistant.com/contact"> contact us </a>.
              Meanwhile, head on over to our{" "}
              <a href="https://autismassistant.com/online-therapies">
                {" "}
                online therapies section
              </a>
              , where you can find telemedicine options for your child.
            </p>
            <h3>Do I need to create an account?</h3>
            <p>
              Accounts are meant mainly for therapists, so if you’re using our
              platform as a tool for autism resources, there’s no need to create
              an account. However, if you’d like to share your thoughts on{" "}
              <a href="https://autismassistant.com/blog">our blog</a>, you can
              sign up for a blogger account! They are completely free, and
              you’re welcome to write about anything autism related.
            </p>
            <h3>How many autism therapies do you have listed?</h3>
            <p>
              Our first phase of specialists include the following (stay tuned
              for additional categories coming soon):
              <ul>
                <li>
                  {" "}
                  <a href="https://autismassistant.com/autism-therapy-types/153/aba-therapy">
                    Applied Behavioral Analysis (ABA)
                  </a>
                </li>
                <li>Autism coaching/Parent training</li>
                <li>
                  {" "}
                  <a href="https://autismassistant.com/autism-therapy-types/155/developmental-pediatrician">
                    Developmental Pediatricians
                  </a>
                </li>
                <li>
                  <a href="https://autismassistant.com/autism-therapy-types/154/equine-therapy">
                    Equine Therapy
                  </a>
                </li>
                <li>
                  <a href="https://autismassistant.com/autism-therapy-types/156/feeding-therapy">
                    Feeding Therapy
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="https://autismassistant.com/autism-therapy-types/157/music-therapy">
                    Music Therapy
                  </a>
                </li>
                <li>
                  <a href="https://autismassistant.com/autism-therapy-types/158/occupational-therapy">
                    Occupational Therapy
                  </a>
                </li>
                <li>
                  <a href="https://autismassistant.com/autism-therapy-types/159/pediatric-dentist">
                    Pediatric Dentistry
                  </a>
                </li>
                <li>
                  <a href="https://autismassistant.com/autism-therapy-types/160/pediatrician">
                    Pediatricians
                  </a>
                </li>
                <li>
                  <a href="https://autismassistant.com/autism-therapy-types/161/physical-therapy">
                    Physical Therapy
                  </a>
                </li>
                <li>
                  <a href="https://autismassistant.com/autism-therapy-types/162/social-skills-therapy">
                    Social Skills
                  </a>
                </li>
                <li>
                  <a href="https://autismassistant.com/autism-therapy-types/167/speech-therapy">
                    Speech Therapy
                  </a>{" "}
                </li>
              </ul>
            </p>
            <h2 className="mt-50">For Autism Treatment Providers</h2>
            <h3>What is Autism Assistant?</h3>
            <p>
              We’re a platform where you can list your business, so that
              families with members on the spectrum can find your practice more
              easily, no matter where you’re located within the United States.
              We aim to ensure that children with autism get the therapy that
              they need, while also generating valuable leads for you.
            </p>
            <h3>Why did you create Autism Assistant?</h3>
            <p>
              We know from our own experience how difficult it can be to find
              providers in your community who are able to work with your child.
              I kept searching for some sort of website that was dedicated
              strictly to listing autism therapy providers and other resources
              available across the U.S. I couldn't find one, so we decided to
              create Autism Assistant.
            </p>
            <h3>Is there a cost of being listed?</h3>
            <p>
              We offer three membership packages as well as a free listing
              option. The membership packages give your profile much more
              visibility on the site as well as giving our users the ability to
              contact you directly. Our free listing gets you added to the
              database but our users can't contact you and you don't receive any
              leads. All of our membership packages also include you being
              listed in our monthly Google Ads campaigns which we use to drive
              traffic to your Autism Assistant profile. Before signing-up, head
              on over to our{" "}
              <a href="https://autismassistant.com/specialists">
                Autism Specialists page
              </a>
              , to see if we haven’t listed you already, as we make efforts to
              extend our database as much as possible. You can then claim that
              listing and add further details.
            </p>
            <h3>What states/areas are covered?</h3>
            <p>
              Our goal for the platform is to have specialists listed from the
              entire country!
            </p>
            <h3>How do I customize my profile?</h3>
            <p>
              You’ll be able to add a lot of information through your provider
              account. Simply sign up, confirm your email address, and you’ll be
              guided through all of your account’s categories, such as your
              specialities, location, accepted insurances, and more
            </p>
            <h3>I have multiple locations, can I list all of them?</h3>{" "}
            <p>
              With our platinum package, you can manage multiple locations
              through one easy account.
            </p>
            <h3>
              Is there a way to guarantee I show up at the top of the search
              results?
            </h3>{" "}
            <p>
              Yes! By activating a sponsorship plan, you can invest as much or
              as little as you want, and choose which position you want to be
              for a number of search queries. You can combine this with your
              membership plan, which guarantees you’ll be the number 1 result in
              search queries every month X times (depending on your particular
              plan).
            </p>
            <h3>I’d like to discuss more over the phone, is that possible?</h3>{" "}
            <p>
              Absolutely!{" "}
              <a href="https://autismassistant.com/contact">
                Get in touch with us
              </a>
              , and we’ll schedule a meeting.
            </p>
            <h3>How do I know how many leads I’m getting from your service?</h3>
            <p>
              At the end of every 30 days we will provide you with analytics so
              you can see your ROI. We will tell you how many searches you
              showed up in, how many times you appeared in a top 3 search
              results position, and how many people clicked on your profile
              and/or website
            </p>
            <h3>Do your membership packages require a contract?</h3>
            <p>
              Nope. Our memberships are month to month. If you’re ever unhappy
              with your membership, simply log into your account and cancel at
              any time.
            </p>
            <h3>How do I list myself for telemedicine?</h3>
            <p>
              <a href="https://autismassistant.com/signup"> Sign up</a> for any
              of the membership packages, and you’ll be able to add that
              speciality to your account (profile badge included). This will
              also include you in the search results for online therapies,
              nationwide!
            </p>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Faq;
